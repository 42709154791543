import React, { FC, useState } from 'react';
import { makeStyles, SnackbarProps, Theme } from '@material-ui/core';
import { ToastProps, ToastMessage } from 'mui-wrapper-utils';

interface ContextProps {
    open: boolean,
    message: string,
    variant: ToastProps['variant'],
    showToast: (message: string, options?: Partial<IToastContext>) => void,
    hideToast: () => void
}

export const AppToastContext = React.createContext<ContextProps>({
    open: false,
    message: '',
    variant: 'success',
    showToast: (message: string, options?: Partial<IToastContext>) => { },
    hideToast: () => { }
});

export interface IToastContext extends SnackbarProps {
    message?: string,
    variant?: ToastProps['variant']
}

export const useAppToast = (initialState: IToastContext) => {
    const [toastState, setToastState] = useState({ open: false, message: '', ...initialState });

    const showToast = (message: string, options?: Partial<IToastContext>) => {
        setToastState({
            ...toastState,
            message,
            open: true,
            ...options
        })
    };
    const hideToast = () => {
        setToastState({
            ...toastState,
            open: false,
            message: ''
        })
    }
    return { showToast, hideToast, message: toastState.message || '', open: toastState.open, variant: toastState.variant || 'success' };
}

const AppToastProvider: FC = (props) => {
    const { showToast, hideToast, message, open, variant } = useAppToast({ open: false });
    const classes = useStyles({});
    return (
        <AppToastContext.Provider value={{
            message,
            open,
            showToast,
            hideToast,
            variant
        }}>
            {props.children}
            <ToastMessage ContentProps={{ style: { fontSize: 16 }, classes: { message: classes.message, action: classes.action } }} autoHideDuration={6000} message={message} open={open} variant={variant} onClose={hideToast} />
        </AppToastContext.Provider>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    message: {
        '& svg': {
            display: 'none'
        }
    },
    action: {
        '& button': {
            backgroundColor: 'transparent'
        },
        '& svg': {
            color: 'white'
        }

    }
}));

export default AppToastProvider;