import React, { FC, useCallback, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import DialogWrapper, { DialogWrapperProps } from 'Dialogs';
import TagPreferenceSelectorPage from 'Features/Tag/TagPreferenceSelectorPage';
import CloseIconButton from 'Components/Buttons/CloseIconButton';

export interface InterestDialogProps extends DialogWrapperProps {

}

const InterestDialog: FC<InterestDialogProps> = (props) => {
    const classes = useStyles({});


    return (
        <DialogWrapper open={props.open} onClose={props.onClose}>
            <Box display='flex' justifyContent='flex-end'>
                <CloseIconButton onClick={props.onClose} />
            </Box>
            <TagPreferenceSelectorPage onDone={props.onClose} />
        </DialogWrapper>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default InterestDialog;