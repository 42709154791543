import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { Box, Grid } from '@material-ui/core';
import { THEME_PALETTE, FONT_FAMILY } from 'Theme/themeConstants';
// import InstagramIcon from '../../Assets/images/icons/insta.svg';
// import FacebookIcon from '../../Assets/images/icons/fb.svg';
import AppStoreLogo from '../../Assets/images/buttons/app_store_button.svg';
import PlayStoreLogo from '../../Assets/images/buttons/play_store_button.svg';
import Spacer from 'Components/Spacer';
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import { Twitter, MusicNote, Instagram, Facebook } from '@material-ui/icons';
import { NAVIGATION_TAB_HEIGHT } from 'Components/NewNavigationTab';
import TikTokIcon from 'Assets/images/tok tok.svg';
import SectionContainer from 'Components/SectionContainer';
import {
    FACEBOOK_URL,
    FAQ_URL,
    INSTAGRAM_URL,
    PRIVACY_POLICY_URL,
    TERMS_CONDITIONS_URL,
    TIK_TOK_URL,
    TWITTER_URL,
} from 'Constants/links';
import { useStoreState } from 'Stores';
import clsx from 'clsx';
import { PLACE_FILTER_WIDTH } from 'Features/Place/PlaceFilters';

const hiddenPaths = ["/login", "/sign-up",  "/nearby*"];
const reducedWidthPaths = ["/places$"]

export interface AppFooterProps {}

const AppFooter: FC<AppFooterProps> = (props) => {
    const history = useHistory();

    const { isDeviceSm } = useMediaQuery();
    const { isCordovaApp } = useStoreState(({ App: { isCordovaApp } }) => ({ isCordovaApp }));
    const classes = useStyles({ isCordovaApp });
    const year = dayjs().format('YYYY');
    const details =
        'Mamakoo is a digital food guide for curious travelers. For each destination, we introduce unique food & restaurant recommendations from local insiders. Our team is based in New York City where we are spoiled with diverse, world-class eateries and watering holes.';

    // const filterPathsWithDiffStyles = (suspectedPath) => {
    //     let reg = new RegExp(suspectedPath);
    //     return reg.test(history.location.pathname);
    // };

    const isIn = (paths: string[], _pathname: string) => {
        return paths.reduce((acc, path) => {
            const reg = new RegExp(path);
            return acc || reg.test(_pathname);
        }, false)
    }

   
    if(isIn(hiddenPaths, history.location.pathname))
        return null;

    return (
        <footer
            className={clsx(classes.root, {
                [classes.reducedWidth]: isIn(reducedWidthPaths, history.location.pathname) && !isDeviceSm,
            })}
        >
            <SectionContainer>
                <Grid item xs={12}>
                    <Grid
                        item={isDeviceSm}
                        container={!isDeviceSm}
                        className={`${classes.root}`}
                        spacing={6}
                    >
                        <Grid item xs={5} className={classes.details}>
                            <Box mb={2}>{details}</Box>
                            <Link to="#" className={classes.link}>
                                &copy; Mamakoo {year}
                            </Link>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display="flex" flexDirection="column">
                                <Link to="/about" className={classes.link}>
                                    About Us
                                </Link>
                                <Link to="/destinations" className={classes.link}>
                                    Destinations
                                </Link>
                                <Link to="/login" className={classes.link}>
                                    Members login
                                </Link>
                                <Link to={{ pathname: TERMS_CONDITIONS_URL }} target="_blank" className={classes.link}>
                                    Terms of Service
                                </Link>
                                <Link to={{ pathname: PRIVACY_POLICY_URL }} target="_blank" className={classes.link}>
                                    Privacy Policy
                                </Link>
                            </Box>
                        </Grid>
                        <Grid container xs={3}>
                            {!isCordovaApp ? (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    height={isDeviceSm ? 'auto' : '100%'}
                                    className={classes.storeButtonBox}
                                >
                                    <a
                                        href={'https://itunes.apple.com/in/app/mamakoo/id1439366195?mt=8'}
                                        target="_blank"
                                    >
                                        <div className={classes.storeButton}>
                                            <img src={AppStoreLogo} alt="App Store Icon" style={{ height: 30 }} />
                                        </div>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.mamakoo" target="_blank">
                                        <div className={classes.storeButton}>
                                            <img src={PlayStoreLogo} alt="Play Store Icon" style={{ height: 30 }} />
                                        </div>
                                    </a>
                                </Box>
                            ) : null}
                            <Box
                                display="flex"
                                height={isDeviceSm ? 'auto' : APP_FOOTER_HEIGHT}
                                my={isDeviceSm ? 2 : 0}
                                alignItems="center"
                                className={classes.socialIconBox}
                            >
                                <a href={INSTAGRAM_URL} target="_blank" className={classes.socialIcon}>
                                    <Instagram className={classes.icon} />
                                </a>
                                <a href={FACEBOOK_URL} target="_blank" className={classes.socialIcon}>
                                    <Facebook className={classes.icon} />
                                </a>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </SectionContainer>
        </footer>
    );
};

export default AppFooter;

export const APP_FOOTER_HEIGHT = 70;

export const MOBILE_APP_FOOTER_HEIGHT = 215;
export const MOBILE_WEB_APP_FOOTER_HEIGHT = 120;

const useStyles = makeStyles<Theme, { isCordovaApp: boolean }>((theme: Theme) =>
    createStyles({
        root: {
            // height: APP_FOOTER_HEIGHT,
            backgroundColor: THEME_PALETTE.others.Pearl,
            // paddingLeft: "2rem",
            // paddingRight: "2rem",
            paddingTop: '2rem',
            paddingBottom: '2rem',
            '& *': {
                ...theme.typography.subtitle1,
                color: THEME_PALETTE.others.T3,
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '100%',
                    '& .MuiGrid-root': {
                        marginBottom: '1.5rem',
                    },
                },
            },
        },
        reducedWidth: {
            width: `calc(100vw - ${PLACE_FILTER_WIDTH}px)`,
            marginLeft: 'auto',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        storeButtonBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
            width: '60%',
            gap: '2.5rem',
            '& >*': {
                width: '100%',
            },
        },
        storeButton: {
            border: `1px solid ${THEME_PALETTE.others.B1}`,
            borderRadius: 8,
            background: THEME_PALETTE.common.black,
            padding: '10px 10px 7px',
            display: 'flex',
            justifyContent: 'center',
        },
        link: {
            textTransform: 'uppercase',
            fontFamily: FONT_FAMILY.secondary,
            fontWeight: theme.typography.fontWeightMedium,
        },
        socialIconBox: {
            height: '100%',
            display: 'flex',
            alignItems: 'end',
            paddingBottom: '2rem',
            paddingTop: '2rem',
            width: '40%',
            justifyContent: 'space-evenly',
        },
        socialIcon: {},
        icon: {
            fontSize: 20,
        },
        leftPadding: {
            // paddingLeft: '250px',
        },
    })
);
