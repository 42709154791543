import React, { createContext } from 'react';
import useSubscriptionPackUpgradeDialog, { SubscriptionPackUpgradeConfig } from './SubscriptionPackUpgrade/useSubscriptionPackUpgradeDialog';

export interface DialogContextProviderValue {
    openSubscriptionUpgradeDialog: (config?: SubscriptionPackUpgradeConfig | undefined) => void
    closeSubscriptionUpgradeDialog: () => void
}

export const DialogContext = createContext<DialogContextProviderValue>({
    openSubscriptionUpgradeDialog: () => { },
    closeSubscriptionUpgradeDialog: () => { },
});

export const DialogProvider: React.FC = (props) => {
    const { DialogComponent, closeDialog: closeSubscriptionUpgradeDialog, openDialog: openSubscriptionUpgradeDialog } = useSubscriptionPackUpgradeDialog();


    return (
        <DialogContext.Provider value={{
            openSubscriptionUpgradeDialog,
            closeSubscriptionUpgradeDialog
        }}>
            {props.children}
            {DialogComponent}
        </DialogContext.Provider>
    )
}
