import { action, Action, actionOn, ActionOn, thunk, Thunk } from "easy-peasy";
import { omit } from "lodash";
import PlaceModel from "Models/Place";
import { TPlace } from "Models/Place/@types";
import { TRootStore } from "Stores";

export interface TPlaceDetailScreenState {
    place: TPlace | undefined
    loading: boolean
    setPlace: Action<TPlaceDetailScreenState, TPlace | undefined>
    setLoading: Action<TPlaceDetailScreenState, boolean>
    fetchPlaceDetail: Thunk<TPlaceDetailScreenState, string, {}, TRootStore>

    // onPlaceSave: ActionOn<TPlaceDetailScreenState, TRootStore>
    // onPlaceUnSave: ActionOn<TPlaceDetailScreenState, TRootStore>
    onCommentAdded: ActionOn<TPlaceDetailScreenState, TRootStore>
}

const PlaceDetailScreenStore: TPlaceDetailScreenState = {
    place: undefined,
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    setPlace: action((state, payload) => {
        state.place = payload
    }),
    fetchPlaceDetail: thunk(async (actions, id) => {
        actions.setLoading(true);
        try {
            const { data } = await PlaceModel.get_Establishments_id<TPlace>(id, {
                // const { data } = await PlaceModel.get_Establishments_id_details<TPlace>(id, {
                include: [
                    'tags',
                    'foodDrinks',
                    'primarySuggesters',
                    'similarEstablishments',
                    'featuredIn',
                    {
                        relation: 'comments',
                        scope: { include: ['user'], limit: 5, order: 'created DESC' },
                    },
                    'cuisines',
                    {
                        relation: 'suggestions',
                        scope: { include: ['user'] },
                    },
                    {
                        relation: 'destination',
                        scope: {
                            include: {
                                relation: 'destinationPack',
                            },
                        },
                    },
                ],
            });
            actions.setPlace(data);
        } catch (error) {
            actions.setPlace(undefined);
        }
        actions.setLoading(false);
    }),


    onCommentAdded: actionOn(
        (actions, storeActions) => storeActions.EntityActionsStore.commentAdded,
        (state, { payload }) => {
            if (!state.place || state.place.id !== payload.placeId) return;
            state.place = { ...state.place, comments: [payload.comment, ...(state.place.comments || [])] }
        }
    ),
};

export default PlaceDetailScreenStore;

