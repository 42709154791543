import axios from 'axios';
import { TExplore } from './@types';
import Lists from 'BaseModels/Lists'

class ExploreModel extends Lists {

    
}

export default ExploreModel;
