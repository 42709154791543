
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class DestinationsBase {

/** 
 * Find a related item by id for recommenders.
 */ 
    static get_Destinations_id_recommenders_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/recommenders/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for recommenders.
 */ 
    static put_Destinations_id_recommenders_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/recommenders/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for recommenders.
 */ 
    static delete_Destinations_id_recommenders_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/recommenders/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for voters.
 */ 
    static get_Destinations_id_voters_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for voters.
 */ 
    static put_Destinations_id_voters_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for voters.
 */ 
    static delete_Destinations_id_voters_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Add a related item by id for voters.
 */ 
    static put_Destinations_id_voters_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters/rel/${fk}`,
            method: 'put',
            
            
        })
    }

/** 
 * Remove the voters relation to an item by id.
 */ 
    static delete_Destinations_id_voters_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters/rel/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for landmarks.
 */ 
    static get_Destinations_id_landmarks_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landmarks/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for landmarks.
 */ 
    static put_Destinations_id_landmarks_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landmarks/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for landmarks.
 */ 
    static delete_Destinations_id_landmarks_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landmarks/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for pictures.
 */ 
    static get_Destinations_id_pictures_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pictures/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for pictures.
 */ 
    static put_Destinations_id_pictures_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pictures/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for pictures.
 */ 
    static delete_Destinations_id_pictures_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pictures/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for landscapePictures.
 */ 
    static get_Destinations_id_landscapePictures_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landscapePictures/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for landscapePictures.
 */ 
    static put_Destinations_id_landscapePictures_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landscapePictures/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for landscapePictures.
 */ 
    static delete_Destinations_id_landscapePictures_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landscapePictures/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for establishments.
 */ 
    static get_Destinations_id_establishments_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/establishments/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for establishments.
 */ 
    static put_Destinations_id_establishments_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/establishments/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for establishments.
 */ 
    static delete_Destinations_id_establishments_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/establishments/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for tags.
 */ 
    static get_Destinations_id_tags_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for tags.
 */ 
    static put_Destinations_id_tags_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for tags.
 */ 
    static delete_Destinations_id_tags_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Add a related item by id for tags.
 */ 
    static put_Destinations_id_tags_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags/rel/${fk}`,
            method: 'put',
            
            
        })
    }

/** 
 * Remove the tags relation to an item by id.
 */ 
    static delete_Destinations_id_tags_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags/rel/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for cuisines.
 */ 
    static get_Destinations_id_cuisines_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for cuisines.
 */ 
    static put_Destinations_id_cuisines_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for cuisines.
 */ 
    static delete_Destinations_id_cuisines_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Add a related item by id for cuisines.
 */ 
    static put_Destinations_id_cuisines_rel_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines/rel/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Remove the cuisines relation to an item by id.
 */ 
    static delete_Destinations_id_cuisines_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines/rel/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for foodDrinks.
 */ 
    static get_Destinations_id_foodDrinks_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for foodDrinks.
 */ 
    static put_Destinations_id_foodDrinks_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for foodDrinks.
 */ 
    static delete_Destinations_id_foodDrinks_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Add a related item by id for foodDrinks.
 */ 
    static put_Destinations_id_foodDrinks_rel_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks/rel/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Remove the foodDrinks relation to an item by id.
 */ 
    static delete_Destinations_id_foodDrinks_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks/rel/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for pins.
 */ 
    static get_Destinations_id_pins_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pins/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for pins.
 */ 
    static put_Destinations_id_pins_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pins/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for pins.
 */ 
    static delete_Destinations_id_pins_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pins/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Queries recommenders of Destination.
 */ 
    static get_Destinations_id_recommenders<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/recommenders`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in recommenders of this model.
 */ 
    static post_Destinations_id_recommenders<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/recommenders`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all recommenders of this model.
 */ 
    static delete_Destinations_id_recommenders<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/recommenders`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts recommenders of Destination.
 */ 
    static get_Destinations_id_recommenders_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/recommenders/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries voters of Destination.
 */ 
    static get_Destinations_id_voters<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in voters of this model.
 */ 
    static post_Destinations_id_voters<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all voters of this model.
 */ 
    static delete_Destinations_id_voters<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts voters of Destination.
 */ 
    static get_Destinations_id_voters_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/voters/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries landmarks of Destination.
 */ 
    static get_Destinations_id_landmarks<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landmarks`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in landmarks of this model.
 */ 
    static post_Destinations_id_landmarks<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landmarks`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all landmarks of this model.
 */ 
    static delete_Destinations_id_landmarks<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landmarks`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts landmarks of Destination.
 */ 
    static get_Destinations_id_landmarks_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landmarks/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries pictures of Destination.
 */ 
    static get_Destinations_id_pictures<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pictures`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in pictures of this model.
 */ 
    static post_Destinations_id_pictures<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pictures`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all pictures of this model.
 */ 
    static delete_Destinations_id_pictures<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pictures`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts pictures of Destination.
 */ 
    static get_Destinations_id_pictures_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pictures/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries landscapePictures of Destination.
 */ 
    static get_Destinations_id_landscapePictures<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landscapePictures`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in landscapePictures of this model.
 */ 
    static post_Destinations_id_landscapePictures<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landscapePictures`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all landscapePictures of this model.
 */ 
    static delete_Destinations_id_landscapePictures<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landscapePictures`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts landscapePictures of Destination.
 */ 
    static get_Destinations_id_landscapePictures_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landscapePictures/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries establishments of Destination.
 */ 
    static get_Destinations_id_establishments<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/establishments`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in establishments of this model.
 */ 
    static post_Destinations_id_establishments<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/establishments`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all establishments of this model.
 */ 
    static delete_Destinations_id_establishments<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/establishments`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts establishments of Destination.
 */ 
    static get_Destinations_id_establishments_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/establishments/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries tags of Destination.
 */ 
    static get_Destinations_id_tags<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in tags of this model.
 */ 
    static post_Destinations_id_tags<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all tags of this model.
 */ 
    static delete_Destinations_id_tags<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts tags of Destination.
 */ 
    static get_Destinations_id_tags_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/tags/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries cuisines of Destination.
 */ 
    static get_Destinations_id_cuisines<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in cuisines of this model.
 */ 
    static post_Destinations_id_cuisines<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all cuisines of this model.
 */ 
    static delete_Destinations_id_cuisines<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts cuisines of Destination.
 */ 
    static get_Destinations_id_cuisines_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/cuisines/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries foodDrinks of Destination.
 */ 
    static get_Destinations_id_foodDrinks<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in foodDrinks of this model.
 */ 
    static post_Destinations_id_foodDrinks<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all foodDrinks of this model.
 */ 
    static delete_Destinations_id_foodDrinks<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts foodDrinks of Destination.
 */ 
    static get_Destinations_id_foodDrinks_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/foodDrinks/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries pins of Destination.
 */ 
    static get_Destinations_id_pins<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pins`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in pins of this model.
 */ 
    static post_Destinations_id_pins<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pins`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all pins of this model.
 */ 
    static delete_Destinations_id_pins<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pins`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts pins of Destination.
 */ 
    static get_Destinations_id_pins_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/pins/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Destinations<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Destinations`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_Destinations<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Destinations`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static put_Destinations<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Destinations`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
    static patch_Destinations<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Destinations`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static post_Destinations_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Destinations/replaceOrCreate`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
    static post_Destinations_upsertWithWhere<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Check whether a model instance exists in the data source.
 */ 
    static get_Destinations_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/exists`,
            method: 'get',
            
            
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_Destinations_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static put_Destinations_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_Destinations_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
    static delete_Destinations_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static post_Destinations_id_replace<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/${id}/replace`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_Destinations_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
    static post_Destinations_update<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Destinations/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Count instances of the model matched by where from the data source.
 */ 
    static get_Destinations_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Destinations/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static get_Destinations_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Destinations/change-stream`,
            method: 'get',
            params: {
                options
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static post_Destinations_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Destinations/change-stream`,
            method: 'post',
            
            data: {
                options
            },
        })
    }

/** 
 * Create destinartion
 */ 
    static post_Destinations_save<T = any>(userId?: any,destination?: any,) {
        return axios.request<T>({
            url: `/Destinations/save`,
            method: 'post',
            
            data: {
                userId, ...destination
            },
        })
    }

/** 
 * details
 */ 
    static get_Destinations_id_details<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/details`,
            method: 'get',
            
            
        })
    }

/** 
 * updateEstablishments
 */ 
    static put_Destinations_id_update_establishments<T = any>(establishmentIds: any,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/update-establishments`,
            method: 'put',
            
            data: {
                establishmentIds
            },
        })
    }

/** 
 * changeLandmarkOrder
 */ 
    static patch_Destinations_id_landmarks_landmarkId_change_order<T = any>(order: any,landmarkId: any,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/landmarks/${landmarkId}/change-order`,
            method: 'patch',
            
            data: {
                order
            },
        })
    }

/** 
 * changeRecommendersOrder
 */ 
    static patch_Destinations_id_recommenders_recommenderId_change_order<T = any>(order: any,recommenderId: any,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/recommenders/${recommenderId}/change-order`,
            method: 'patch',
            
            data: {
                order
            },
        })
    }

/** 
 * changeListOrder
 */ 
    static patch_Destinations_id_lists_listId_change_order<T = any>(order: any,listId: any,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/lists/${listId}/change-order`,
            method: 'patch',
            
            data: {
                order
            },
        })
    }

/** 
 * Publish entity.
 */ 
    static post_Destinations_id_publish<T = any>(publish: any,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/publish`,
            method: 'post',
            
            data: {
                publish
            },
        })
    }

/** 
 * 
 */ 
    static get_Destinations_id_piners<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/piners`,
            method: 'get',
            
            
        })
    }

/** 
 * 
 */ 
    static get_Destinations_pined_by_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Destinations/pined-by/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * 
 */ 
    static post_Destinations_id_create_pin_userId<T = any>(userId: any,id: string,) {
        return axios.request<T>({
            url: `/Destinations/${id}/create-pin/${userId}`,
            method: 'post',
            
            
        })
    }

/** 
 * To get destination order settings
 */ 
    static get_Destinations_settings<T = any>(filter?: any) {
        return axios.request<T>({
            url: `/settings`,
            method: 'get',
            params: {
                filter
            },
        });
    }

}

export default DestinationsBase;
    