import { requestDeviceLocation } from "Features/Cordova/nativeEvents";
import { useEffect } from "react";
import { useStoreActions, useStoreState } from "Stores";


export function useLocationEventListener() {
    const { setAppPermissions, setGeoLocation } = useStoreActions(({ App: { setAppPermissions, setGeoLocation } }) => ({ setAppPermissions, setGeoLocation }))
    const { permissions, geoLocation } = useStoreState(({ App: { permissions, geoLocation }, }) => ({ permissions, geoLocation }))

    useEffect(() => {
        document.addEventListener('geoCoords', (e: any) => {
            // if (e?.detail?.error) {
            //     setAppPermissions({ location: false });
            //      return;
            // }
            if (e?.detail?.coords?.latitude && e?.detail?.coords?.longitude) {
                const loc = { lat: e?.detail?.coords?.latitude, lng: e?.detail?.coords?.longitude }
                if (loc.lat && loc.lng) {
                    setAppPermissions({ location: true, gps: true });
                    setGeoLocation(loc);
                }

            }
        });

        document.addEventListener('LOCATION_PERMISSION_DENIED', (e: any) => {
            setAppPermissions({ location: false });
        })

        document.addEventListener('GPS_DENY', (e: any) => {
            setAppPermissions({ gps: false, location: true })
        })

        document.addEventListener('HAS_LOCATION_PERMISSION', (e: any) => {
            setAppPermissions({ location: e?.detail?.HAS_LOCATION_PERMISSION || false })
        })




    }, [])


    useEffect(() => {
        if (permissions.gps && permissions.location) {
            if (!geoLocation)
                requestDeviceLocation()
        }
    }, [permissions, geoLocation])
}