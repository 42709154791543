import React from 'react';
import { ReactForm } from 'react-forms';
import { useFormConfig } from './config';

export interface ForgotPasswordFormData {
    email: string;
}

export interface ForgotPasswordFormProps {
    onSubmit: Function;
    initialData?: ForgotPasswordFormData;
    isSubmitting?: boolean;
}


const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
    const { actionConfig, inputConfig, validationSchema } = useFormConfig(props);
    return (
        <ReactForm
            initialValues={props.initialData}
            formId="ForgotPassword"
            config={inputConfig}
            actionConfig={actionConfig}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        />
    );
};



export default ForgotPasswordForm;
