import { Thunk, Action, thunk, action } from "easy-peasy";
import HomeModel from "Models/Home";
import { THome, TQuotes } from "Models/Home/@types";

export interface TLandingScreenState {
    loading: boolean;
    data: THome[];
    quoteData: TQuotes[];
    getHomeData: Thunk<TLandingScreenState, { quotesModelName: string, quoteCount: number }>
    setData: Action<TLandingScreenState, THome[]>;
    setLoading: Action<TLandingScreenState, boolean>;
    setQuoteData: Action<TLandingScreenState, TQuotes[]>;
}


function filterDataWithoutSubject(data: any) {
    data.sectionEntity = data.sectionEntity.filter(s => !!s.subject);
    return data;
}

const LandingScreenStore: TLandingScreenState = {

    loading: true,
    data: [],
    quoteData: [],

    getHomeData: thunk(async (actions, { quotesModelName, quoteCount }) => {

        actions.setLoading(true);

        // const { data } = await HomeModel.get_HomeDetails({
        //     "include": [{
        //         "relation": "sectionEntity",
        //         "scope": {
        //             "include": [
        //                 {
        //                     "relation": "subject",
        //                     "scope": {
        //                         "include": ["lists",
        //                             {
        //                                 relation: "destination",
        //                                 scope: {
        //                                     fields: ['name', 'id', 'slug']
        //                                 }
        //                             }
        //                         ]
        //                     }
        //                 },
        //                 {
        //                     "relation": "destination",
        //                     "scope": {
        //                         fields: ['name', 'id', 'slug']
        //                     }
        //                 }
        //             ]
        //         }
        //     }]
        // });

        // const quoteData = await HomeModel.get_Quotes("Quote", 1);

        Promise.all([
            await HomeModel.get_HomeDetails({
                include: [
                    {
                        relation: 'sectionEntity',
                        scope: {
                            include: [
                                {
                                    relation: 'subject',
                                    scope: {
                                        include: [
                                            'lists',
                                            'destinationPack',
                                            {
                                                relation: 'destination',
                                                scope: {
                                                    fields: ['name', 'id', 'slug', 'destinationPackId'],
                                                    include: {
                                                        relation: 'destinationPack',
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    relation: 'destination',
                                    scope: {
                                        fields: ['name', 'id', 'slug', 'destinationPackId'],
                                        include: {
                                            relation: 'destinationPack',
                                        },
                                    },
                                },
                            ],
                        },
                    },
                ],
            }),

            await HomeModel.get_Quotes(quotesModelName, quoteCount),
        ]).then((data) => {
            actions.setData((data[0].data || []).map(filterDataWithoutSubject));
            actions.setQuoteData(data[1].data);

            actions.setLoading(false);
        });

        // console.log(quoteData, 'fffffffffffffffffffffff');

        // actions.setData(data);

        // actions.setLoading(false);

    }),

    setData: action((state, payload) => {
        state.data = payload;
    }),

    setQuoteData: action((state, payload) => {
        state.quoteData = payload;
    }),

    setLoading: action((state, payload) => {
        state.loading = payload;
    }),

};

export default LandingScreenStore;

