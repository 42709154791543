import { Box, ButtonBase, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import Typo from 'Components/Typo';
import { NameValuePair } from 'Models/@types';
import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { FONT_FAMILY, THEME_PALETTE } from 'Theme/themeConstants';
import Spacer from 'Components/Spacer';
import { APP_FOOTER_HEIGHT } from 'Features/Footer/AppFooter';

export interface PlaceFiltersProps {
    className?: string;
    onFilterChange?: (name: string) => void;
    filters: {
        id: string;
        title: string;
        options: NameValuePair<string | number>[];
        handleReset?: (id: string) => void;
        checkedValues: (string | number)[];
        handleClick: (value: any, id: string) => void;
    }[];
}



const PlaceFilters: FC<PlaceFiltersProps> = ({ filters, onFilterChange, className }) => {


    const classes = useStyles({});
    const { isDeviceSm } = useMediaQuery();

    return (
        <div className={clsx(classes.root, className)}>
            {filters.map(item => {
                return item.options.length ? (
                    <Box key={item.id} pb={3}>
                        <Box py={1} display='flex' justifyContent='space-between'>
                            <Typo weight='bold' variant='subtitle1' className={classes.title}>{item.title}</Typo>
                            {item.handleReset ? (
                                <ButtonBase color='primary' onClick={() => item.handleReset?.(item.id)} className={classes.resetBtn}>Reset</ButtonBase>
                            ) : null}
                        </Box>
                        <FormGroup>
                            {item.options.map(o => {
                                return (
                                    <Box key={o.value} className={classes.checkboxContainer}>
                                        <FormControlLabel
                                            control={<Checkbox checked={!!item.checkedValues.find(i => i === o.value)} />}
                                            label={<Typo variant='subtitle1' className={classes.checkLable}>{o.name}</Typo>}
                                            value={o.value}
                                            onChange={e => {
                                                item.handleClick(o.value, item.id);
                                                onFilterChange?.(item.title);
                                            }}
                                        />
                                    </Box>
                                );
                            })}
                        </FormGroup>
                    </Box>
                ) : null;
            })}
        </div>
    );
}

export default PlaceFilters;

export const PLACE_FILTER_WIDTH = 300;

const useStyles = makeStyles<Theme, any>((theme: Theme) => createStyles({
    root: {
        minHeight: '100%',
        width: PLACE_FILTER_WIDTH,
        display: 'inline-block',
        backgroundColor: THEME_PALETTE.common.white,
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(18),
    },
    resetBtn: {
        color: THEME_PALETTE.primary.main,
        font: `normal normal ${theme.typography.fontWeightMedium} 14px/18px ${FONT_FAMILY.secondary}`,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    checkboxContainer: {
        '& .MuiIconButton-root': {
            padding: '2px 9px',
        },
    },
    title: { color: THEME_PALETTE.common.black, },
    checkLable: { color: THEME_PALETTE.common.black, },
}));
