import { useAnalyticsAction } from 'Analytics/analyticActions';
import { DESTINATION_FIELDS } from 'Constants/fields';
import useGeoLocation from 'Features/geoLocation/useGeoLocation';
import { TSavedCard } from 'Features/Subscription/@types';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import useSocketListener from 'Hooks/useSocketListener';
import { TLocation } from 'Models/@types';
import AppModelModel from 'Models/AppModel';
import { TAppConstants } from 'Models/AppModel/@types';
import CityModel from 'Models/City';
import { TCity } from 'Models/City/@types';
import TagModel from 'Models/Tag';
import { TTag } from 'Models/Tag/@types';
import TagCategoryModel from 'Models/TagCategory';
import { TTagCategory } from 'Models/TagCategory/@types';
import UserModel from 'Models/User';
import { useState, useEffect, useCallback } from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import utils from 'Utils';
import helpers from 'Utils/helpers';


export function fetchAppConstants() {
    return AppModelModel.get_AppModels_constants<TAppConstants>()
}

export function fetchCities(location?: TLocation) {
    return CityModel.get_Destinations<TCity[]>(location ? {
        fields: DESTINATION_FIELDS,
        include: 'destinationPack',
        where: {
            location: {
                near: location,
            },
        }
    } : { fields: DESTINATION_FIELDS, include: 'destinationPack', })
}

export function fetchSettings() {
    return CityModel.get_Destinations_settings();
}

export function fetchTagCategories() {
    return TagCategoryModel.get_TagCategories<TTagCategory[]>({ include: ['tags'] })
}
export function fetchTags() {
    return TagModel.get_Tags<TTag[]>()
}



function useAppInit() {
    const [loading, setLoading] = useState(true);
    const { dispatchUserEvent } = useAnalyticsAction();
    const { setAppConstants, fetchTagCategories, setAppInitialized, fetchSubscriptionPacks, fetchCities, authenticate, fetchSettings,getReviewData } = useStoreActions(
        ({
            App: { setAppConstants, fetchTagCategories, fetchSubscriptionPacks, fetchCities, setAppInitialized, fetchSettings,getReviewData },
            AuthStore: { authenticate } }
        ) => (
            { fetchTagCategories, fetchSubscriptionPacks, setAppInitialized, setAppConstants, fetchCities, authenticate, fetchSettings,getReviewData }
        ));
    const { appUser, isCordovaApp, appInitialized } = useStoreState(({ AuthStore: { appUser }, App: { isCordovaApp, appInitialized } }) => ({ isCordovaApp, appUser, appInitialized }))
    // const { initGeoLocation, initNativeGeoLocation } = useGeoLocation();
    const { isDeviceSm } = useMediaQuery();
    const { init: initSocket } = useSocketListener();



    useEffect(() => {
        if (appUser) {
            if (appInitialized) {
                getCities();
                initSocket();
            }
        }
        // if (appInitialized) {

        //     if (appUser && isDeviceSm && !isCordovaApp)
        //         initGeoLocation()
        //     else if (appUser && isDeviceSm && isCordovaApp) {
        //         initNativeGeoLocation();
        //     }
        //     else if (!isDeviceSm)
        //         initGeoLocation()
        // }
    }, [appUser, isCordovaApp, appInitialized])

    const init = useCallback(async () => {
        dispatchUserEvent({ eventName: 'WEBSITE_OPEN' })
        try {
            await Promise.all([
                login(),
                initAppConstants(),
                getCities(),
                getSettings(),
                getReviews(),
                getSubscriptionPacks(),
                getTagCategories()
            ]);
            setAppInitialized(true);
        } catch (error) {

        }
        setLoading(false);
    }, []);

    const getTagCategories = async () => {
        try {
            await fetchTagCategories();
        } catch (error) {

        }
    }

    const getSubscriptionPacks = async () => {
        try {
            await fetchSubscriptionPacks();
        } catch (error) {

        }
    }


    const getCities = async () => {
        try {
            await fetchCities(undefined)
        } catch (error) {

        }
    }


    const getSettings = async () => {
        try {
            await fetchSettings();
        } catch (error) {
            
        }
    }

    const getReviews = async () => {
        try {
            await getReviewData();
        } catch (error) {
            
        }
    }


    const initAppConstants = async () => {
        try {
            const { data } = await fetchAppConstants();
            setAppConstants(data);
        } catch (error) {
            helpers.errorLog('error fetching constants')
        }
    }

    const login = async () => {
        const access_token = utils.getCookie('access_token');
        if (access_token) {
            await authenticate(access_token);
        }

    }



    return {
        loading,
        init
    }
}

export default useAppInit;