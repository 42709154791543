import { appHistory } from '../../App'

const appNavigator = {
    searchPlaces: (query: string, getUrl?: boolean) => {
        const url = `/places?${query}`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    searchNearbyPlaces: (tab: string, query?: string, getUrl?: boolean) => {
        const url = `/nearby/${tab}` + (query ? `?${query}` : '');
        if (getUrl)
            return url;
        appHistory.push(url)
    },




    navigateToDishPlaces: (dishId: string, query?: string, getUrl?: boolean) => {
        const url = `/dishes/${dishId}/places` + (query ? `?${query}` : '');
        if (getUrl)
            return url;
        appHistory.push(url)
    },

    navigateToCuisinePlaces: (cuisineId: string, query?: string, getUrl?: boolean) => {
        const url = `/cuisines/${cuisineId}/places` + (query ? `?${query}` : '');
        if (getUrl)
            return url;
        appHistory.push(url)
    },


    navigateToPlaceComments: (placeId: string, getUrl?: boolean) => {
        const url = `/places/${placeId}/comments`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },


    navigateToSavedPlaces: (userId: string, query?: string, getUrl?: boolean) => {
        const url = `/profile/${userId}/saved` + (query ? `?${query}` : '');
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToSuggestedPlaces: (userId: string, query?: string, getUrl?: boolean) => {
        const url = `/profile/${userId}/suggested` + (query ? `?${query}` : '');
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToExplore: (exploreId: string, getUrl?: boolean) => {
        const url = `/explore/${exploreId}`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToPlace: (placeId: string, getUrl?: boolean) => {
        const url = `/places/${placeId}`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToCity: (cityId: string, getUrl?: boolean) => {
        const url = `/cities/${cityId}`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToDish: (dishId: string, query?: string, getUrl?: boolean) => {
        const url = `/dishes/${dishId}` + (query ? `?${query}` : '');
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToCuisine: (cuisineId: string, query?: string, getUrl?: boolean) => {
        const url = `/cuisines/${cuisineId}` + (query ? `?${query}` : '');
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToLandmark: (landmarkId: string, getUrl?: boolean) => {
        const url = `/landmarks/${landmarkId}`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToProfile: (userId: string, getUrl?: boolean) => {
        const url = `/profile/${userId}`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToPlaceDishes: (placeId: string, getUrl?: boolean) => {
        const url = `/places/${placeId}/dishes`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToCuisineDishes: (cuisineId: string, getUrl?: boolean) => {
        const url = `/cuisines/${cuisineId}/dishes`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToSettings: (userId: string, getUrl?: boolean) => {
        const url = `/settings`;
        // const url = `/profile/${userId}/settings`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToCityDishes: (cityId: string, getUrl?: boolean) => {
        const url = `/cities/${cityId}/dishes`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToCityCuisines: (cityId: string, getUrl?: boolean) => {
        const url = `/cities/${cityId}/cuisines`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToCityExplores: (cityId: string, getUrl?: boolean) => {
        const url = `/cities/${cityId}/explores`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToCityLandmarks: (cityId: string, getUrl?: boolean) => {
        const url = `/cities/${cityId}/landmarks`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToCityEatineraries: (cityId: string, getUrl?: boolean) => {
        const url = `/cities/${cityId}/eatineraries`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },
    navigateToHome: () => appHistory.push('/'),
    navigateToSearch: () => appHistory.push('/search'),
    navigateToDestinations: () => appHistory.push('/destinations'),
    goBack: () => appHistory.goBack(),

    navigateToEatineries: (eatId: string, getUrl?: boolean) => {
        const url = `/eatinerary/${eatId}`;
        if (getUrl)
            return url;
        appHistory.push(url)
    },

    navigateToAuth: (getUrl?: boolean) => {
        const url = `/login`;
        if (getUrl)
            return url;
        appHistory.push(url);
    },

    navigateToPassport: (userId: string, getUrl?: boolean) => {
        const url = `/profile/${userId}/passport`;
        if (getUrl)
            return url;
        appHistory.push(url);
    },

    navigateToUserlist: (userId: string, listId: string, getUrl?: boolean) => {
        const url = `/profile/${userId}/savedlist/${listId}`;
        if (getUrl)
            return url;
        appHistory.push(url);
    },
}

export default appNavigator