import React, { useState, useMemo } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import withViewModel from '../../Core/withViewModel';
import { Box, CircularProgress, Button } from '@material-ui/core';
import { AuthType } from 'Dialogs/Auth';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { useGlobalStyle } from 'Constants/classes';
import { DIALOG_CONTENT_WIDTH } from 'Constants/dimension';
import LoginPage from 'Features/Auth/LoginPage';
import EmailVerificationForm, { EmailVerificationFormData } from 'Forms/EmailVerification';
import LottieImage from 'Components/LottieImage';
import OtpPage from 'Features/Auth/OtpPage';
import Onboarding from 'Features/Onboarding';
import SignUpPage from 'Features/Auth/SignUpPage';
import TagPreferenceSelectorPage from 'Features/Tag/TagPreferenceSelectorPage';
import useAsyncTask from 'Hooks/useAsyncTask';
import { OtpFormData } from 'Forms/OtpForm';
import GRAPHIC from '../../Assets/animated/autorickshaw.json';
import Typo from 'Components/Typo';
import { NAVIGATION_TAB_HEIGHT } from 'Components/NavigationTab';


export interface TAuthScreenProps {
    authType: AuthType
    onSubmit: Function
    handleCheck: (value: string) => Promise<boolean>
    onTypeChange?: (type: AuthType) => void
    onForgotPassword?: (email: string) => void
    errorMessage?: string
    loading?: boolean
    onClose?: () => void
}

const AuthScreen: React.FC<TAuthScreenProps> = (props) => {

    const { authType = 'check', loading = false, handleCheck: _handleCheck, onSubmit, onTypeChange } = props;
    
    const { isDeviceSm } = useMediaQuery();
    const globalClasses = useGlobalStyle({});
    const classes = useStyles({})

    const [val, setVal] = useState('');

    let TITLE = ''
    if (authType === 'login')
        TITLE = 'You are already in our system.\nWelcome back!';
    else if (authType === 'check')
        TITLE = 'Welcome to Mamakoo'
    else
        TITLE = 'Create an account'
    
    const setAuthType = (type: AuthType) => {
        if (onTypeChange)
            onTypeChange(type)
    }

    const handleOtpInput = async (data: OtpFormData) => {
        try {
            await props.onSubmit?.(data);
        } catch (error) {

        }
    }

    const handleCheck = async (data: EmailVerificationFormData) => {
        setVal(data.email);
        try {
            const isRegistered = await props.handleCheck(data.email || '')
            if (isRegistered) setAuthType('login')
            else setAuthType('signup')
        } catch (error) {

        }
    }

    const otpHandler = useAsyncTask(handleOtpInput);
    const checkHandler = useAsyncTask(handleCheck)

    const restartFlow = () => {
        setAuthType('check');
    }

    const AuthComponent: Record<AuthType, JSX.Element> = {
        login: <LoginPage onBack={restartFlow} initialData={{ email: val }} onSubmit={onSubmit} />,
        check: (
            <Box px={5} mt={4} pb={4}>
                <EmailVerificationForm onSubmit={checkHandler.run} isSubmitting={checkHandler.status === 'PROCESSING'}
                    initialData={{ email: val }}
                />
                {isDeviceSm ?
                    <div className={classes.img}>
                        <LottieImage lotteJSON={GRAPHIC} dimension={{ width: '100vw', height: 'unset' }} />
                    </div> : null
                }
            </Box>
        ),
        otp: <OtpPage initialData={{ email: val }} onSubmit={otpHandler.run} isSubmitting={otpHandler.status === 'PROCESSING'} />,
        signup: <SignUpPage onBack={restartFlow} initialData={{ email: val, newsletter: true }} onSubmit={onSubmit} />,
        onboarding: <Onboarding onDone={() => onSubmit()} />,
        interest: <TagPreferenceSelectorPage onDone={() => onSubmit()} />

    }

    const editEmail = () => setAuthType('check');

    const shouldShowCloseIcon = useMemo(() => {
        if (authType === 'otp' || authType === 'onboarding' || authType === 'interest') return false;
        return true;
    }, [authType])

    return (
        <div className={classes.container}>
            {loading && !isDeviceSm ?
                <CircularProgress />
                : (
                    <Box
                        width={isDeviceSm ? '100%' : DIALOG_CONTENT_WIDTH}
                        height={isDeviceSm ? '100%' : undefined} mx="auto" position="relative"
                    >
                        <Box px={1} pt={1} display='flex' justifyContent='space-between'>
                            {authType === 'login' || authType === 'signup' || authType === 'otp' ? (
                                <Box mb={2}>
                                    <Button className={globalClasses.underlinedTextButton} onClick={editEmail}>Edit email</Button>
                                </Box>
                            ) : <div />}
                        </Box>

                        <Box className={shouldShowCloseIcon || authType === 'check' ? classes.content : ''} pb={0}>
                            {authType === 'check' ? (
                                <Typo gutterBottom className={classes.title}>{TITLE}</Typo>
                            ) : null}

                            {AuthComponent[authType]}
                        </Box>

                    </Box>
                )}
        </div>
    )
}

const useStyles = makeStyles<Theme, any>((theme: Theme) => createStyles({
    container: {
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '100vw',
            // paddingBottom: NAVIGATION_TAB_HEIGHT,
        }
    },
    content: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(5)
        }

    },
    img: {
        width: 100,
        position: 'absolute',
        bottom: 0,
        left: 0,
        objectFits: 'cover'
    },
    closeButton: {
        color: theme.palette.primary['contrastText'],
        position: 'absolute',
        top: 16,
        right: 16
    },
    title: {
        ...theme.typography.h6,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            ...theme.typography.h5,
        }

    },
}))

export default AuthScreen;


