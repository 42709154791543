import React, { FC, lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import Page404 from '../Screens/Page404';
import lazyImport from '../lazy-import';
import { useStoreActions, useStoreState } from 'Stores';
import Loader from 'Components/Loader';
import useGeoLocation from 'Features/geoLocation/useGeoLocation';

const DishPlaces = lazyImport('../Screens/DishPlaces');
const DishDetail = lazyImport('../Screens/DishDetail');

const DishNavigation: FC = () => {
    const { fetchPageData, setDish, setCuisines, setPlaces } = useStoreActions(
        ({ DishDetailScreenStore: { fetchPageData, setQuery, setDish, setCuisines, setPlaces } }) => ({
            fetchPageData,
            setQuery,
            setDish,
            setCuisines,
            setPlaces,
        })
    );
    const { dish, loading } = useStoreState(({ DishDetailScreenStore: { dish, loading } }) => ({ dish, loading }));
    const { id: dishId } = useParams<{ id: string }>();

    useGeoLocation();

    useEffect(() => {
        if (dish && (dish.id === dishId || dish.slug === dishId)) return;
        resetPageData();
        getDishDetail(dishId);
    }, [dishId]);

    const resetPageData = () => {
        setDish(undefined);
        setCuisines([]);
        setPlaces([]);
    };

    const getDishDetail = async (dishId: string) => {
        fetchPageData(dishId);
    };

    return loading ? (
        <Loader fullScreen />
    ) : (
        <>
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route path="/dishes/:id/places" exact component={DishPlaces} />
                    <Route path="/dishes/:id" exact component={DishDetail} />
                    <Route path="/*" exact component={Page404} />
                </Switch>
            </Suspense>
        </>
    );
};

export default DishNavigation;
