/* eslint-disable react-hooks/exhaustive-deps */
import { get } from 'lodash';
import React, { useState, StrictMode, useCallback, useContext, useEffect } from 'react';
import AuthDialog, { AuthDialogProps, AuthType } from '.';
import { LoginFormData } from '../../Forms/Login';
import { SignUpFormData } from '../../Forms/SignUp';
import helpers from '../../Utils/helpers';
import useToastMessage from '../../Hooks/useToastMessage';
import { TUser } from '../../Models/User/@types';
import { OtpFormData } from 'Forms/OtpForm';
import AuthScreen from 'Screens/Auth/AuthScreen';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth } from 'Features/Auth/useAuth';
import { AppVariablesContext } from 'Contexts/AppVariablesContext';




export type useAuthDialogConfig = {
    onLogin?: (form: LoginFormData) => Promise<void>;
    onSignup?: (form: SignUpFormData) => Promise<void>;
    onOtpSubmit?: (form: OtpFormData) => Promise<void>;
    // onInterestSubmit: (form: Partial<TUser>) => Promise<void>
    onClose?: () => void;
    onForgotPassword?: (email: string) => Promise<void>;
    handleCheck?: (val: string) => Promise<boolean>;
    afterAuthAction?: () => any;
};

export interface useAuthDialogExportType {
    isAuthDialogOpen: boolean;
    openAuthDialog: () => void;
    openLoginDialog: () => void;
    openSignupDialog: () => void;
    openOtpDialog: () => void;
    closeDialog: () => void;
    isScreen: (e: boolean) => void;
    authType: AuthType;
    AuthComponent: React.FC<{
        children?: (StepperComponent?: JSX.Element, handleNext?: () => void) => JSX.Element[] | JSX.Element;
        afterClose?: () => void;
        hasCloseBtn?: boolean;
    }>;
    AuthScreenComponent: JSX.Element;
}


const EMAIL_EXISTS_ERROR = 'Email already exists. Please login.'
const FORM_ERROR = 'Please check username or password and try again.'

export const useAuthDialog = (config: useAuthDialogConfig): useAuthDialogExportType => {
    const { userLogin, userSignup, submitOtp } = useAuth();
    const {
        onLogin =  async (data) => {
            await userLogin(data);
        },
        onSignup = userSignup,
        onOtpSubmit = submitOtp,
    } = config;
    const { setIsDialogOpen } = useContext(AppVariablesContext);
    const [open, setOpen] = useState(false);
    const [strictMode, setStrictMode] = useState(false);
    const [type, setType] = useState<AuthType>('login');
    const [errorMessage, setErrorMessage] = useState('');
    const withToast = useToastMessage();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [isPage, setIsPage] = useState(false);

    let history = useHistory();

    const isScreen = (e: boolean) => {
        setIsPage(e);
    };

    useEffect(() => {
      setIsDialogOpen(open);
    }, [open])
    

    const openAuthDialog = (strictMode: boolean = false) => {
        setErrorMessage('');
        setType('check');
        setOpen(true);
        setStrictMode(strictMode);
    };

    const openOtpDialog = () => {
        setErrorMessage('');
        setType('otp');
        setOpen(true);
    };

    const openLoginDialog = () => {
        setErrorMessage('');
        setType('login');
        setOpen(true);
    };

    const openSignupDialog = () => {
        setErrorMessage('');
        setType('signup');
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
        setStrictMode(false);
    };

    const handleClose = () => {
        closeDialog();
        if (config.onClose) config.onClose();
    };

    const handleTypeChange = (type: AuthType) => {
        setErrorMessage('');
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setType(type);
        }, 500);
    };

    const handleEmailCheck = async (email: string) => {
        setErrorMessage('');
        if (!helpers.validateEmail(email)) {
            setErrorMessage('Invalid email');
            throw '';
        }

        try {
            setLoading(true);
            if (config.handleCheck) {
                setLoading(false);
                return await config.handleCheck(email);
            } else {
                setLoading(false);
                return false;
            }
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const handleForgotPassword = async (email: string) => {
        if (!config.onForgotPassword) return;
        withToast(async () => await config.onForgotPassword!(email), {
            successToastMessage: 'Password reset link has been sent to ' + email,
        });
    };

    const handleSubmit =  (formData: any) => {
        return new Promise(async(resolve, reject) => {
            setLoading(true);
            setErrorMessage('');
            try {
                switch (type) {
                    case 'login': {
                        await onLogin(formData).then(() => {
                            resolve(true);
                            console.log('HELLO nnkn');
                            config?.afterAuthAction?.();
                        }).catch(() => {
                            reject(false)
                        });
                        if (isPage === true) {
                            history.push('/');
                        }
                        break;
                    }
                    case 'signup': {
                        await onSignup(formData)
                            .then(async () => {
                                resolve(true);
                                await config?.afterAuthAction?.();
                                setPassword(formData.password);
                                await onLogin({ email: formData.email, password: formData.password });
                            })
                            .catch(() => {
                                reject(false);
                            });
                        // setType('otp');
                        
                        break;
                    }
                    case 'otp': {
                        await onOtpSubmit(formData);
                        await onLogin({ email: formData.email, password: password });
                        // setType('interest')
                        // setType('onboarding').
                        if (isPage === true) {
                            history.push('/');
                        } else {
                            closeDialog();
                        }
                        break;
                    }
                    case 'onboarding': {
                        // setType('interest');
                        break;
                    }
                }
            } catch (error) {
                if (get(error, 'response.data.error.statusCode') === 422) setErrorMessage(EMAIL_EXISTS_ERROR);
                else if (get(error, 'response.data.error.statusCode') === 401) setErrorMessage(FORM_ERROR);
                else setErrorMessage(get(error, 'response.data.error.message'));
            }
        })
    };
    return {
        isAuthDialogOpen: open,
        openAuthDialog,
        openLoginDialog,
        openSignupDialog,
        openOtpDialog,
        closeDialog,
        isScreen,
        authType: type,
        AuthComponent: useCallback(
            (props) => {
                if (open) {
                    return (
                        <AuthDialog
                            strictMode={strictMode}
                            onForgotPassword={handleForgotPassword}
                            handleCheck={handleEmailCheck}
                            onTypeChange={handleTypeChange}
                            errorMessage={errorMessage}
                            type={type}
                            open={open}
                            onSubmit={handleSubmit}
                            onClose={() => {
                                handleClose();
                                props?.afterClose?.();
                            }}
                            hasCloseBtn={props.hasCloseBtn}
                            {...props}
                        />
                    );
                }
                return null;
            },
            [open, type]
        ),
        AuthScreenComponent: (
            <AuthScreen
                loading={loading}
                onForgotPassword={handleForgotPassword}
                handleCheck={handleEmailCheck}
                onTypeChange={handleTypeChange}
                errorMessage={errorMessage}
                authType={type}
                onSubmit={handleSubmit}
            />
        ),
    };
};


