
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class TransactionsBase {

/** 
 * Fetches belongsTo relation couponCode.
 */ 
    static get_Transactions_id_couponCode<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/couponCode`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Fetches belongsTo relation pack.
 */ 
    static get_Transactions_id_pack<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/pack`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Fetches hasOne relation subscription.
 */ 
    static get_Transactions_id_subscription<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/subscription`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Creates a new instance in subscription of this model.
 */ 
    static post_Transactions_id_subscription<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/subscription`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update subscription of this model.
 */ 
    static put_Transactions_id_subscription<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/subscription`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes subscription of this model.
 */ 
    static delete_Transactions_id_subscription<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Transactions/${id}/subscription`,
            method: 'delete',
            
            
        })
    }

/** 
 * Fetches belongsTo relation user.
 */ 
    static get_Transactions_id_user<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/user`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Fetches belongsTo relation creator.
 */ 
    static get_Transactions_id_creator<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/creator`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Fetches belongsTo relation referrerLeadUser.
 */ 
    static get_Transactions_id_referrerLeadUser<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/referrerLeadUser`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Fetches belongsTo relation referCode.
 */ 
    static get_Transactions_id_referCode<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/referCode`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Transactions<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Transactions`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_Transactions<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Transactions`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static put_Transactions<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Transactions`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
    static patch_Transactions<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Transactions`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete all matching records.
 */ 
    static delete_Transactions<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Transactions`,
            method: 'delete',
            
            data: {
                where
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static post_Transactions_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Transactions/replaceOrCreate`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
    static post_Transactions_upsertWithWhere<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Transactions/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Check whether a model instance exists in the data source.
 */ 
    static get_Transactions_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Transactions/${id}/exists`,
            method: 'get',
            
            
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_Transactions_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static put_Transactions_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_Transactions_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
    static delete_Transactions_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Transactions/${id}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static post_Transactions_id_replace<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Transactions/${id}/replace`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_Transactions_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Transactions/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
    static post_Transactions_update<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Transactions/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Count instances of the model matched by where from the data source.
 */ 
    static get_Transactions_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Transactions/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static get_Transactions_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Transactions/change-stream`,
            method: 'get',
            params: {
                options
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static post_Transactions_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Transactions/change-stream`,
            method: 'post',
            
            data: {
                options
            },
        })
    }

/** 
 * 
 */ 
    static post_Transactions_createTransaction<T = any>(transactionData: any,) {
        return axios.request<T>({
            url: `/Transactions/createTransaction`,
            method: 'post',
            
            data: {
                ...transactionData
            },
        })
    }

/** 
 * 
 */ 
    static get_Transactions_csv<T = any>() {
        return axios.request<T>({
            url: `/Transactions/csv`,
            method: 'get',
            
            
        })
    }

}

export default TransactionsBase;
    