import Axios, { CancelTokenSource } from "axios";
import { action, Action, actionOn, ActionOn, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import Query from "Features/Query";
import EstablishmentQuery from "Features/Query/EstablishmentQuery";
import { TSearchResult } from "Features/Search/@types";
import { omit } from "lodash";
import { Facets } from "Models/@types";
import PlaceModel from "Models/Place";
import { TPlace, TPlaceFilterResponse } from "Models/Place/@types";
import { DESTINATION_FIELDS } from 'Constants/fields';
import TagModel from "Models/Tag";
import { TTag } from "Models/Tag/@types";
import UserModel from "Models/User";
import { TRootStore } from "Stores";

export interface TPlaceListingState {
    places: TPlace[]
    loading: boolean
    tag: TTag | undefined
    query: Query
    initialFacets: Facets
    facets: Facets
    pageNumber: number
    total: number
    hasMore: boolean
    facetCityBuckets: { key: string, doc_count: number }[]
    isFirstTime: boolean
    setIsFirstTime: Action<TPlaceListingState, boolean>
    setFacetCityBuckets: Action<TPlaceListingState, TPlaceListingState['facetCityBuckets']>
    setTag: Action<TPlaceListingState, TTag | undefined>
    setInitialFacets: Action<TPlaceListingState, Facets>
    setFacets: Action<TPlaceListingState, Facets>
    setTotal: Action<TPlaceListingState, number>
    setHasMore: Action<TPlaceListingState, boolean>
    setPageNumber: Action<TPlaceListingState, number>
    setPlaces: Action<TPlaceListingState, TPlace[]>
    setLoading: Action<TPlaceListingState, boolean>
    loadMore: Thunk<TPlaceListingState, void, {}, TRootStore>
    setQuery: Action<TPlaceListingState, Query>
    fetchTagDetail: Thunk<TPlaceListingState, string, {}, TRootStore>
    searchPlaces: Thunk<TPlaceListingState, { query: Query, pageNumber?: number, append?: boolean }, {}, TRootStore>

    onQueryChange: ThunkOn<TPlaceListingState, {}, TRootStore>
}
const LISTING_LIMIT = 21;

let cancelToken: CancelTokenSource | undefined = undefined;


const PlaceListingStore: TPlaceListingState = {
    places: [],
    loading: true,
    initialFacets: {},
    facets: {},
    tag: undefined,
    total: 0,
    hasMore: true,
    facetCityBuckets: [],
    pageNumber: 1,
    query: new Query({}),
    isFirstTime: true,
    setIsFirstTime: action((state, payload) => {
        state.isFirstTime = payload
    }),
    setFacetCityBuckets: action((state, payload) => {
        state.facetCityBuckets = payload
    }),
    setInitialFacets: action((state, payload) => {
        state.initialFacets = payload
    }),
    setFacets: action((state, payload) => {
        state.facets = payload
    }),
    setPlaces: action((state, payload) => {
        state.places = payload;
    }),
    setTotal: action((state, payload) => {
        state.total = payload;
    }),
    setTag: action((state, payload) => {
        state.tag = payload;
    }),
    setHasMore: action((state, payload) => {
        state.hasMore = payload;
    }),
    setPageNumber: action((state, payload) => {
        state.pageNumber = payload;
    }),
    setQuery: action((state, payload) => {
        state.query = payload;
    }),
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    loadMore: thunk(async (actions, _, { getState }) => {
        const { query, pageNumber } = getState();
        await actions.searchPlaces({ query, pageNumber: pageNumber + 1, append: true })
    }),
    fetchTagDetail: thunk(async (actions, tagId, { getState }) => {
        const state = getState();
        if (state.tag?.id === tagId) return;
        const { data } = await TagModel.get_Tags_id<TTag>(tagId);
        const { data: facets } = await UserModel.get_users_autocomplete<TSearchResult>('', {
            where: {
                doc_type: "Establishment",
                "tags.id": data.id,
            },
            limit: 1
        });
        actions.setFacetCityBuckets(facets?.aggregations?.destinationName?.buckets || [])
        actions.setTag(data);
    }),
    searchPlaces: thunk(async (actions, { pageNumber = 1, query, append = false }, { getState, getStoreState }) => {
        const state = getState();
        actions.setPageNumber(pageNumber);
        actions.setLoading(true);
        const { App: { geoLocation: location } } = getStoreState();
        // const locationFilter = (location) ? EstablishmentQuery.getLocationFilter(location) : {};
        if (cancelToken) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = Axios.CancelToken.source();
        const { data } = await PlaceModel.searchPlaces(
            {
                Establishment: {
                    include: [
                        'cuisines',
                        'tags',
                        {
                            relation: 'destination',
                            scope: {
                                fields: DESTINATION_FIELDS,
                                include: {
                                    relation: 'destinationPack',
                                },
                            },
                        },
                    ],
                },
                ...query.paginationQuery,
                ...query.getSearchFilter,
                // ...locationFilter,
                where: {
                    ...query.getSearchFilter.where,
                },
            },
            { cancelToken: cancelToken.token }
        );

        const places = PlaceModel.transformSearchResult(data.hits);
        if (!places.length || places.length < LISTING_LIMIT)
            actions.setHasMore(false)
        else
            actions.setHasMore(true)

        if (state.isFirstTime) {
            actions.setInitialFacets(data.facets)
            actions.setIsFirstTime(false);
        }

        actions.setFacets(data.facets)
        actions.setTotal(data.total || 0);
        actions.setPlaces(append ? [...state.places, ...places] : places);
        console.log(places)
        actions.setLoading(false)
    }),



    onQueryChange: thunkOn(
        (actions) => actions.setQuery,
        async (actions, { payload }) => {
            actions.searchPlaces({ query: payload })
        }
    ),



};

export default PlaceListingStore;

