import axios from 'axios';
import { TCity } from './@types';
import Destinations from 'BaseModels/Destinations'
import { NameValuePair } from 'Models/@types';
import { groupBy, sortBy } from 'lodash';
import { TSettings } from 'Models/AppModel/@types';
import orderBy from 'lodash/orderBy';

interface Obj {
    [key: string]: TCity[];
}

class CityModel extends Destinations {

    static sortByPinned(cities: TCity[]) {
        return cities.sort(c => c.isPined ? -1 : 1)
    }

    static groupByContinents(cities: TCity[]) {
        
        return groupBy(cities, city => city.continent);

    }

    static getPortraitImage(city: TCity) {
        const image = city._pictures.find(p => p.isPortrait && !p.isDetail && !p.isAnimated);
        return image
    }

    static getLandscapeImage(city: TCity) {
        const image = city._landscapePictures.find(p => !p.isPortrait);
        return image
    }
    static getDetailPageImage(city: TCity) {
        const image = city._pictures.find(p => p.isDetail);
        return image
    }


    static getDummyCityOption(option: NameValuePair) {
        return {
            id: option.value,
            name: option.name
        } as TCity
    }



}

export default CityModel;
