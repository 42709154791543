import { Thunk, thunk, Action } from "easy-peasy";
export interface TDestinationScreenState {

}

const DestinationScreenStore: TDestinationScreenState = {
    
};

export default DestinationScreenStore;

