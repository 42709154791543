import React, { FC, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Dialog, DialogProps, fade } from '@material-ui/core';
import { useMediaQuery } from '../Hooks/useMediaQuery';
import { GRADIENTS, THEME_PALETTE } from 'Theme/themeConstants';
import useStepper from 'Components/Stepper';

export interface DialogWrapperProps extends Omit<DialogProps, 'onClose'> {
    onClose?: () => void;
    hasStepper?: boolean;
    numOfSteps?: number;
    paperClasses?: string;
    children?:
        | JSX.Element
        | JSX.Element[]
        | ((
              Stepper: () => JSX.Element | undefined,
              handleNext: () => void,
              currentStep: number
          ) => JSX.Element | JSX.Element[]);
}

const DialogWrapper: FC<DialogWrapperProps> = (props) => {
    const { children, hasStepper= false,numOfSteps = 1, paperClasses, ...dialogProps } = props;
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles({ isSmAndNotFullScreen: isDeviceSm && !dialogProps.fullScreen });

    const { StepperComponent, handleNext, currentStep } = useStepper(numOfSteps);

    const Stepper = () => {
        if (numOfSteps > 1)
            return (
                <Box className={classes.stepper} display="flex" justifyContent="center">
                    <StepperComponent />
                </Box>
            );
        return undefined;
    };

    return (
        <Dialog
            disableBackdropClick
            fullScreen={isDeviceSm}
            {...dialogProps}
            PaperProps={{ className: `${classes.paper} ${paperClasses}` }}
            BackdropProps={{
                classes: {
                    root: classes.backdrop,
                },
            }}
            className={classes.root}
            scroll="body"
        >
            {hasStepper && typeof children === 'function' ? children(Stepper, handleNext, currentStep) : children}
        </Dialog>
    );
}

const useStyles = makeStyles<Theme, { isSmAndNotFullScreen: boolean }>((theme) => ({
    root: {
        backdropFilter: 'blur(3px)',
    },
    paper: {
        background: theme.palette.background.default,
        boxShadow: 'none',
        maxWidth: '100% !important',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            height: (props) => (props.isSmAndNotFullScreen ? 'unset' : '100%'),
            // height: props => props.isSmAndNotFullScreen ? '90vh' : '100%',
            // width: props => props.isSmAndNotFullScreen ? '95vw' : '100%',
            // maxWidth: props => props.isSmAndNotFullScreen ? '95vw !important' : undefined,
            // height: `calc(100vh - ${HEADER_HEIGHT_SMALL}px)`
        },
    },
    stepper: {
        '& >*': {
            width: '100%',
        },
    },
    backdrop: {
        opacity: '0.80 !important',
        backdropFilter: 'blur(29px)',
        background: `${THEME_PALETTE.others.Pearl} 0% 0% no-repeat padding-box`,
    },
}));

export default DialogWrapper;