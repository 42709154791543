import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Menu as MuiMenu, MenuItem } from '@material-ui/core';

export interface MenuProps {
    id: string
    anchorEl: HTMLElement | null
    handleClose: () => void
    options: MenuOption[]
}

export type MenuOption = {
    onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void,
    label: string
}

const Menu: FC<MenuProps> = ({ id, anchorEl, handleClose, options }) => {
    const classes = useStyles({});

    return (
        <MuiMenu
            id={id}
            elevation={2}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {options.map((o, i) => (
                <MenuItem key={i} onClick={o.onClick}>{o.label}</MenuItem>
            ))}
        </MuiMenu>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default Menu;