export const requestDeviceLocation = () =>
    document.dispatchEvent(new CustomEvent('requestLocation', {
        detail: {
        }
    }));


export const openApplicationSettings = () =>
    document.dispatchEvent(new CustomEvent('openApplicationSettings', {
        detail: {
        }
    }));



export const identifyUser = (type: 'login' | 'logout', email?: string,) => {
    const payload = email ? { value: { email } } : undefined;
    document.dispatchEvent(new CustomEvent('authEvent', {
        detail: {
            type,
            ...payload
        }
    }));
}


export const openInAppSubscriptionSettings = () => {
    // const payload = email ? { value: { email } } : undefined;
    document.dispatchEvent(new CustomEvent('openInAppSubscriptionSettings', {
        detail: {

        }
    }));
}

