
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class TagsBase {

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Tags<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Tags`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_Tags<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Tags`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static put_Tags<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Tags`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
    static patch_Tags<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Tags`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static post_Tags_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Tags/replaceOrCreate`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
    static post_Tags_upsertWithWhere<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Tags/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Check whether a model instance exists in the data source.
 */ 
    static get_Tags_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Tags/${id}/exists`,
            method: 'get',
            
            
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_Tags_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Tags/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static put_Tags_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Tags/${id}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_Tags_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Tags/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
    static delete_Tags_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Tags/${id}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static post_Tags_id_replace<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Tags/${id}/replace`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_Tags_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Tags/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
    static post_Tags_update<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Tags/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Count instances of the model matched by where from the data source.
 */ 
    static get_Tags_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Tags/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static get_Tags_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Tags/change-stream`,
            method: 'get',
            params: {
                options
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static post_Tags_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Tags/change-stream`,
            method: 'post',
            
            data: {
                options
            },
        })
    }

/** 
 * Publish/Unpublish Multiple
 */ 
    static patch_Tags_changePublishStatus<T = any>(publish: any,ids: any,) {
        return axios.request<T>({
            url: `/Tags/changePublishStatus`,
            method: 'patch',
            
            data: {
                publish, ids
            },
        })
    }

/** 
 * Delete mutilple tags by ids
 */ 
    static delete_Tags_delete_multiple<T = any>(ids: any,) {
        return axios.request<T>({
            url: `/Tags/delete-multiple`,
            method: 'delete',
            
            data: {
                ids
            },
        })
    }

/** 
 * Publish entity.
 */ 
    static post_Tags_id_publish<T = any>(publish: any,id: string,) {
        return axios.request<T>({
            url: `/Tags/${id}/publish`,
            method: 'post',
            
            data: {
                publish
            },
        })
    }

}

export default TagsBase;
    