import { CORDOVA_LOCAL_STORAGE_USER_EMAIL, CORDOVA_LOCAL_STORAGE_USER_KEY } from "Constants/keys";
import { action, Action, thunk, Thunk } from "easy-peasy";
import { identifyUser } from "Features/Cordova/nativeEvents";
import { LoginFormData } from "Forms/Login";
import UserModel from "Models/User";
import { TUser } from "Models/User/@types";
import utils from "Utils";

export interface TAuthState {
    appUser?: TUser
    setAppUser: Action<TAuthState, TUser | undefined>

    isAuthenticated: boolean;

    setIsAuthenticated: Action<TAuthState, boolean>

    authenticate: Thunk<TAuthState, string>

    login: Thunk<TAuthState, LoginFormData>
    fetchMe: Thunk<TAuthState>
    logout: Thunk<TAuthState>

}

const AuthStore: TAuthState = {
    isAuthenticated: false,
    setIsAuthenticated: action((state, payload) => {
        state.isAuthenticated = payload;
    }),
    setAppUser: action((state, payload) => {
        state.appUser = payload;
    }),

    authenticate: thunk(async (actions, token) => {
        utils.setAuthHeader(token);
        utils.setCookie('access_token', token);
        try {
            const { data: user } = await UserModel.get_users_me<TUser>({
                include: {
                    relation: 'destination',
                    scope: {
                        fields: ['name', 'id', 'slug', 'destinationPackId'],
                    },
                },
            });
            utils.setCookie(CORDOVA_LOCAL_STORAGE_USER_KEY, user.id);
            utils.setCookie(CORDOVA_LOCAL_STORAGE_USER_EMAIL, user.email);
            identifyUser('login', user.email);
            actions.setAppUser({ ...user });
            actions.setIsAuthenticated(true);
            return user;
        } catch (error) {
            actions.logout();
            throw error;
        }
    }),

    login: thunk(async (actions, params) => {
        const { data } = await UserModel.post_users_login<{ userId: string; id: string }>({ ...params });
        return await actions.authenticate(data.id);
    }),
    fetchMe: thunk(async (actions) => {
        const { data: user } = await UserModel.get_users_me<TUser>();
        utils.setCookie(CORDOVA_LOCAL_STORAGE_USER_KEY, user.id);
        utils.setCookie(CORDOVA_LOCAL_STORAGE_USER_EMAIL, user.email);
        identifyUser('login', user.email);
        actions.setAppUser(user);
        actions.setIsAuthenticated(true);
    }),
    logout: thunk(async (actions) => {
        try {
            await UserModel.post_users_logout();
        } catch (error) {}
        utils.clearCookie(CORDOVA_LOCAL_STORAGE_USER_KEY);
        utils.clearCookie(CORDOVA_LOCAL_STORAGE_USER_EMAIL);
        identifyUser('logout');
        utils.setAuthHeader(undefined);
        utils.clearCookies();
        actions.setAppUser(undefined);
        actions.setIsAuthenticated(false);
    }),

};

export default AuthStore;
