import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';





export const useFormConfig = () => {
    const classes = useStyles();
    const validationSchema = Yup.object({
        message: Yup.string().required('Cannot be empty'),
    })
    const actionConfig: IFormActionProps = {
        submitButtonText: 'SUBMIT',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large'
        },
        containerClassNames: classes.buttonContainer
    };
    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: 'text',
            id: 'help',
            valueKey: 'message',
            fieldProps: {
                multiline: true,
                InputProps: { disableUnderline: true },
                rows: 5,
                label: 'Enter',
                fullWidth: true,
                className: classes.formItem
            }
        },
    ];
    return { actionConfig, inputConfig, validationSchema }
}




const useStyles = makeStyles((theme: Theme) => createStyles({
    buttonContainer: {

    },
    formItem: {
        '& label': {
            marginLeft: 4
        },
        '& textarea': {
            padding: 4
        }
    }
}))