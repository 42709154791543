import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import FAILURE_IMAGE from '../../Assets/images/payment/payment_failure.png';
import { NAVIGATION_TAB_HEIGHT } from 'Components/NavigationTab';
import Typo from 'Components/Typo';
import { THEME_PALETTE } from 'Theme/themeConstants';


export interface SubscriptionFailurePageProps {
    onClick: () => void
}

const SubscriptionFailurePage: FC<SubscriptionFailurePageProps> = ({ onClick }) => {
    const classes = useStyles({});

    return (
        <Box className={classes.root}>
            <Box width={275} mx="auto" className={classes.content}>
                <img src={FAILURE_IMAGE} width='100%' />
                <Box height={24} />
                <Typo variant='h5' align='center' weight='medium' color='primary'>Uh-oh</Typo>
                <Box height={8} />
                <Typo variant='body2' align='center' weight='medium' >Something went wrong.<br />Let's try again</Typo>
                <Box height={24} />
                <Button fullWidth onClick={onClick} variant='contained' color='primary'>GO BACK</Button>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        boxShadow: theme.shadows[2],
        position: 'relative',
        // backgroundColor: theme.palette.background.default,
        backgroundColor: THEME_PALETTE.common.white,
        // backgroundImage: `url(${FAILURE_IMAGE})`,
        // backgroundPosition: 'top',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        display: 'flex',
        // maxWidth: 460,
        flexDirection: 'column',
        alignItems: 'center',
        // height: '720px',
        height: 450,
        padding: theme.spacing(6, 5, 5, 5),
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'contain',
            height: '100vh',
            width: '100vw',
            paddingBottom: NAVIGATION_TAB_HEIGHT
        }
    },
    content: {
        transform: 'translateY(-50%)',
        top: '50%',
        position: 'relative'
    },
    bgImage: {

    }
}));

export default SubscriptionFailurePage;