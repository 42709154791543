import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import MobileHeader from 'Features/Header/MobileHeader';
import Typo from 'Components/Typo';

const Page404: FC = () => {
    return (
        <div>
            <MobileHeader leading={<Typo>Go Back</Typo>} />
            <Typography variant="h2">404: Page Not Found</Typography>
        </div>
    )
}

export default Page404;