import axios from 'axios';
import { TCuisine } from './@types';
import Cuisines from 'BaseModels/Cuisines'

class CuisineModel extends Cuisines {
    static getDishNameList(cuisine: TCuisine, limit?: number) {
        return cuisine.foodDrinks?.slice(0, limit || Number.POSITIVE_INFINITY).map(dish => dish.name).join(", ")
    }

}

export default CuisineModel;
