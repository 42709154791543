import { LoginFormData } from '../../Forms/Login';
// import { OtpFormData } from '../../Forms/OtpForm';
// import { TRemindMeFormData } from '../../Forms/RemindMeForm';
import { SignUpFormData } from '../../Forms/SignUp';
import useToastMessage from '../../Hooks/useToastMessage';
import userModel from '../../Models/User';
import { useStoreActions, useStoreState } from '../../Stores';
import { get, isObject } from 'lodash';
import { INVALID_PHONE } from '../../Constants/ErrorMessages';
import { TUser } from 'Models/User/@types';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import appNavigator from 'Features/Navigation/appNavigator';
import UserModel from '../../Models/User';

export const useAuth = () => {
    // const { eventDetails } = useStoreState(state => state.Events);
    const { login, logout, setAppUser } = useStoreActions(({ AuthStore: { login, setAppUser, logout } }) => ({
        login,
        logout,
        setAppUser,
    }));
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const withToast = useToastMessage();

    let history = useHistory();

    const isRegistered = useCallback(async (email: string) => {
        try {
            const { data } = await userModel.get_users_is_registered<boolean>(email);
            return data;
        } catch (ee) {
            return false;
        }
    }, []);

    const userLogout = useCallback(async () => {
        try {
            await logout();
            window.location.reload();
            // if (history.location.pathname !== '/') {
            //     history.push('/');
            // } else {
            //     history.go(0);
            // }
            // setSavedPlaces([]);
        } catch (error) {}
    }, [history, logout]);

    const deleteUserAccount = useCallback(
        async (reason: string) => {
            try {
                if (appUser)
                    await UserModel.delete_user_account(appUser.id, reason).then(async () => {
                        await logout().then(() => {
                            appNavigator.navigateToHome();
                        });
                    });
            } catch (error) {}
        },
        [appUser]
    );

    const userLogin = useCallback(
        async (formData: LoginFormData) => {
            await withToast(
                async () => {
                    const user = await login({ ...formData });
                },
                {
                    successToastMessage: 'Signed in successfully',
                }
            );
        },
        [login, withToast]
    );

    /**
     * Send OTP to email for email verification
     * @param email
     */
    const sendEmailVerificationLink = useCallback(
        async (email: string) => {
            await withToast(
                async () => {
                    await userModel.post_users_sendToken({ email });
                },
                {
                    successToastMessage: `Verification code sent to ${email}`,
                }
            );
        },
        [withToast]
    );

    /**
     * Verify email id
     * @param formData
     */
    const submitOtp = useCallback(
        async (formData: any) => {
            await withToast(
                async () => {
                    await userModel.post_users_verifyToken({ ...formData });
                    if (appUser) setAppUser({ ...appUser, emailVerified: true });
                },
                {
                    successToastMessage: 'Email verified successfully',
                    errorToastMessage: 'Invalid OTP',
                    showApiErrorMsg: false,
                }
            );
        },
        [appUser, setAppUser, withToast]
    );

    /**
     * Send reset link to email
     * @param email
     */
    const forgotPassword = useCallback(async (email: string) => {
        // const origin = window.location.origin;
        // await userModel.post_users_forgetPassword(email, `${origin}/reset-password`);
    }, []);

    const userSignup = useCallback(
        async (formData: SignUpFormData) => {
            const { email, name, password } = formData;
            await withToast(
                async () => {
                    await userModel.post_users_signupAuth<{ id: string; accessToken: { id: string } }>({
                        email,
                        name,
                        password,
                    });
                },
                {
                    successToastMessage: 'Signed up successfully',
                }
            );
        },
        [withToast]
    );

    /**
     * send OTP to phone
     * @param data {TRemindMeFormData}
     */
    const addPhoneNumber = useCallback(
        async (data: any) => {
            await withToast(
                async () =>
                    await userModel.post_users_sendToken({
                        phone: data,
                    }),
                {
                    successToastMessage: 'OTP sent to ' + data.phone,
                    showApiErrorMsg: false,
                    errorToastMessage: (err) => {
                        if (
                            isObject(get(err, 'response.data.error.message')) &&
                            get(err, 'response.data.error.message.message')
                        ) {
                            return get(err, 'response.data.error.message.message');
                        }
                        if (get(err, 'response.data.error.message') === 'INVALID_PHONE_NUMBER') return INVALID_PHONE;
                        return get(err, 'response.data.error.message');
                    },
                }
            );
        },
        [withToast]
    );

    /**
     * Verify phone number
     * @param token
     * @param phone
     * @param countryCode
     */
    const verifyPhoneToken = useCallback(
        async (token: string, phone: string, countryCode: string) => {
            await withToast(
                async () => {
                    await userModel.post_users_updatePhoneWithToken(token, phone, countryCode);
                    if (!appUser) return;
                    setAppUser({ ...appUser, phoneVerified: true });
                },
                {
                    successToastMessage: 'Phone verified successfully',
                    showApiErrorMsg: true,
                }
            );
        },
        [appUser, setAppUser, withToast]
    );

    /**
     * Submit user interests
     * @param formData
     */
    const userInterestSubmit = useCallback(async (formData: Partial<TUser>) => {
        // await updateUser({ ...formData, id: appUser?.id || '' });
        return;
    }, []);

    return {
        sendEmailVerificationLink,
        isRegistered,
        userLogin,
        userSignup,
        addPhoneNumber,
        verifyPhoneToken,
        forgotPassword,
        submitOtp,
        userLogout,
        userInterestSubmit,
        deleteUserAccount,
    };
};
