import { TTag } from "Models/Tag/@types";
import { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "Stores";


function useTagSelector(selectedIds: string[]) {
    const { tagCategories } = useStoreState(({ App: { tagCategories } }) => ({ tagCategories }))
    const { fetchTagCategories } = useStoreActions(({ App: { fetchTagCategories } }) => ({ fetchTagCategories }))
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

    useEffect(() => {
        setSelectedTagIds(selectedIds)
    }, [selectedIds])

    useEffect(() => {
        if (tagCategories.length) return;
        fetchTagCategories();
    }, [])

    const toggleSelect = (id: string) => {
        if (selectedTagIds.includes(id))
            setSelectedTagIds(_ids => _ids.filter(_id => _id !== id));
        else
            setSelectedTagIds(_ids => ([..._ids, id]));
    }


    return {
        toggleSelect,
        selectedTagIds,
        tagCategories
    }
}


export default useTagSelector;