import axios, { AxiosRequestConfig } from 'axios';
import { TPlace, TPlaceSearchResponse } from './@types';
import Establishments from 'BaseModels/Establishments'
import { PlaceCommentFormData } from 'Forms/PlaceComment';
import { TLocation } from 'Models/@types';
import helpers from 'Utils/helpers';
import { round } from 'lodash';

class PlaceModel extends Establishments {
    static isPlaceLocked(place?: TPlace) {
        return !(place?.destination?.isGuidePurchased);
    }

    static getPrimarySuggester(place?: TPlace) {
        if (!place) return null;
        const primarySuggestion = place.suggestions?.find(s => s.isPrimary && !s.deleted);
        return primarySuggestion?.user || null
    }

    static getTagsNameList(place: TPlace, limit?: number) {
        return place.tags?.slice(0, limit || Number.POSITIVE_INFINITY).map(t => t.name).join(", ")
    }


    static getAddressString(place: TPlace) {
        return [place.locality, place.destination?.name].filter(t => !!t).join(", ")
    }
    static getCuisinesString(place: TPlace) {
        return place.cuisines?.map(c => c.name).join(", ") || ''
    }

    static getDistanceFromAppUser(place: TPlace, userLocation?: TLocation) {
        if (!userLocation || !place.location)
            return ''

        const distanceInMiles = helpers.getDistance(place.location, userLocation);
        return (distanceInMiles >= 10 ? round(distanceInMiles) : round(helpers.getDistance(place.location, userLocation), 1)) + 'mi'
    }
    static getPriceRangeString(place: TPlace) {
        return Array.from({ length: place.range }).map((_, i) => '$').join('')
    }

    static getGoogleMapUrl(place: TPlace) {
        if (!place || !place.location) return null;
        const url = `https://www.google.com/maps/search/?api=1&query=${place.location.lat},${place.location.lng}&query_place_id=${place.placeId}`;
        return url;
    }
    static addComment<T = any>(placeId: string, userId: string, form: PlaceCommentFormData) {
        return axios.request<T>({
            url: `/establishments/${placeId}/create-comment/${userId}`,
            method: 'POST',
            data: form
        })
    }

    static searchPlaces(filter?: Record<string, any>, axiosConfig?: AxiosRequestConfig) {
        return axios.request<TPlaceSearchResponse>({
            ...axiosConfig,
            url: `/establishments/search`,
            method: 'GET',
            params: {
                filter
            }
        })
    }

    static transformSearchResult(result: any) {
        // TODO
        return result.map(res => ({ ...res.hit }))
    }
}

export default PlaceModel;
