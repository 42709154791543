import React from "react";
import { useCallback, useState } from "react";
import SearchDialog from ".";


function useSearchDialog() {
    const [open, setOpen] = useState(false);

    const closeDialog = useCallback(() => setOpen(false), []);
    const openDialog = useCallback(() => setOpen(true), []);

    return {
        openDialog,
        closeDialog,
        DialogComponent: (
            open ?
                <SearchDialog open={open} onClose={closeDialog} />
                : null
        )
    }
}


export default useSearchDialog;