import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, SvgIcon, SvgIconProps } from '@material-ui/core';

export interface LocationOnIconProps extends SvgIconProps {

}

const LocationOnIcon: FC<LocationOnIconProps> = (props) => {
    const classes = useStyles({});

    return (
        <SvgIcon {...props} width="10.836" height="15" viewBox="0 0 10.836 15">
            <path d="M5.918,15.6h0a.529.529,0,0,1-.4-.192C5.316,15.119.5,9.062.5,5.792A5.287,5.287,0,0,1,5.918.6a5.287,5.287,0,0,1,5.418,5.192c0,3.654-4.816,9.423-5.117,9.615C6.219,15.5,6.119,15.6,5.918,15.6Zm0-14.135A4.364,4.364,0,0,0,1.5,5.7c0,2.5,3.311,7.019,4.515,8.558,1.2-1.442,4.415-5.865,4.415-8.558A4.389,4.389,0,0,0,5.918,1.465Z" transform="translate(-0.5 -0.6)" /><g transform="translate(3.175 2.762)"><path d="M5.907,7.922h-.4A3.329,3.329,0,0,1,4.1,6.96a1.947,1.947,0,0,1-.4-1.635A2.329,2.329,0,0,1,6.309,3.5,2.256,2.256,0,0,1,8.215,6a1.872,1.872,0,0,1-.9,1.442A2.463,2.463,0,0,1,5.907,7.922Zm0-3.462A1.263,1.263,0,0,0,4.7,5.518a1.145,1.145,0,0,0,.2.962,1.259,1.259,0,0,0,.8.385,1.279,1.279,0,0,0,1-.192.976.976,0,0,0,.5-.769,1.3,1.3,0,0,0-1.1-1.442Z" transform="translate(-3.664 -3.472)" /></g>
        </SvgIcon>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default LocationOnIcon;