import React, { FC, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Icon, Portal } from '@material-ui/core';
import SearchBox from './SearchBox';
import useSearch from './useSearch';
import CloseIcon from 'Components/Buttons/CloseIconButton';
import Loader from 'Components/Loader';
import SearchResultItem from './SearchResultItem';
import { DIVIDER_BORDER_DOTTED } from 'Theme/themeConstants';
import UserTags from './UserTags';
import TagAccordion from 'Features/Tag/TagAccordion';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import Typo from 'Components/Typo';
import emptyStateMessages from 'Constants/emptyStateMessages';
import LinkButton from 'Components/LinkButton';
import useSupportFormDialog from 'Dialogs/Support/useSupportFormDialog';
import { Slide } from "react-reveal";
import { DialogContext } from 'Dialogs/DialogContext';



export interface SearchPageProps {
    onClose?: () => void;
}

const SearchPage: FC<SearchPageProps> = (props) => {
    const { onClose } = props;
    const classes = useStyles({});
    const { suggestions, handleChange, loading, value: searchText } = useSearch();
    const { openSubscriptionUpgradeDialog } = useContext(DialogContext);
    const { DialogComponent: SupportFormDialog, openDialog: support } = useSupportFormDialog();
    const { isDeviceSm } = useMediaQuery();

    const getBody = () => {
        if (loading) return <Loader />
        if (searchText) return SearchResult
        return Tags
    }

    const Tags = (
        <Box my={5}>
            <UserTags closeDialog={onClose} />
            <Box height={20} />
            <TagAccordion onClick={onClose} />
        </Box>
    )


    const SearchResult = (
        <div className={classes.suggestions}>
            {suggestions.length ?
                suggestions.map((s, i) => {
                    const timeoutFormula = (i * 100) + 200;
                    return (
                        <Box py={2} key={i} className={classes.resultItem}>
                            <Slide bottom timeout={timeoutFormula} >
                                <SearchResultItem handleLockedPlaceClick={openSubscriptionUpgradeDialog} closeDialog={onClose} searchText={searchText} item={s} />
                            </Slide>
                        </Box>
                    )
                }) : (
                    <Typo variant={isDeviceSm ? 'body2' : 'body1'} align='center'>
                        {emptyStateMessages.searchResult} <br />
                        <LinkButton onClick={support} text="Tell Mamakoo" />
                    </Typo>
                )
            }
        </div>
    )

    return (
        <Box py={1} px={isDeviceSm ? 3 : 1} className={classes.root}>
            <div className={classes.searchContainer}>
                <SearchBox autoFocus={!isDeviceSm} onChange={handleChange} />
                {onClose ? (
                    <Box ml={1}>
                        <CloseIcon bordered onClick={onClose} />
                    </Box>
                ) : null}
            </div>
            <div >
                {getBody()}
            </div>
            <Portal>
                {SupportFormDialog}
            </Portal>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
    },
    searchContainer: {
        position: 'sticky',
        zIndex: 1,
        top: 8,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            top: theme.spacing(2)
        }
    },
    suggestions: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
        }
        // padding: theme.spacing(0, 1)
    },
    resultItem: {
        borderBottom: DIVIDER_BORDER_DOTTED
    }
}));

export default SearchPage;