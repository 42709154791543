import { useAnalyticsAction } from 'Analytics/analyticActions';
import TransactionsBase from 'BaseModels/Transactions';
import useToastMessage from 'Hooks/useToastMessage';
import UserModel from 'Models/User';
import { TCurrentSubscription } from 'Models/User/@types';
import { useStoreActions, useStoreState } from 'Stores';
import { CouponInstance, TSavedCard } from './@types';
import { BillingDetail, BillingFormData, CardDetail } from './CardDetailInput';
import { transformBillingFormData } from './utils';

export interface TTransactionData {
    packId: string;
    card?: CardDetail;
    billingInfo?: BillingDetail;
    couponCode?: string;
}

function useSubscriptionActions() {
    const { appUser, isCordovaApp } = useStoreState(({ AuthStore: { appUser }, App: { isCordovaApp } }) => ({
        appUser,
        isCordovaApp,
    }));
    const { setAppUser, setSavedCards } = useStoreActions(({ AuthStore: { setAppUser }, App: { setSavedCards } }) => ({
        setAppUser,
        setSavedCards,
    }));
    const { dispatchUserEvent } = useAnalyticsAction();
    const withToast = useToastMessage();

    const changeCard = async (form: BillingFormData) => {
        if (!appUser) return null;
        try {
            await withToast(
                async () => {
                    const { data } = await UserModel.patch_users_id_change_cards<TSavedCard[]>(
                        transformBillingFormData(form),
                        appUser.id
                    );
                    dispatchUserEvent({ eventName: 'CHANGE_CARD' });
                    setSavedCards(data);
                },
                {
                    successToastMessage: 'Card detail updated',
                }
            );
        } catch (error) {}
    };

    const renewSubscription = async () => {
        if (!appUser) return null;
        // if (!isCordovaApp && !appUser.subscriptions?.currentSubscription?.isWebTransaction) {
        //     // TODO

        //     return;
        // }
        try {
            await withToast(
                async () => {
                    const { data } = await UserModel.patch_users_autoRenewal(true);
                    dispatchUserEvent({ eventName: 'RENEW_SUBSCRIPTION' });
                    setAppUser({ ...appUser, isPaidUser: true });
                },
                {
                    successToastMessage: 'Success! Auto-renewal has been turned on.',
                }
            );
        } catch (error) {}
    };

    const togglePauseSubscription = async (pause: boolean) => {
        if (!appUser) return null;
        try {
            await withToast(
                async () => {
                    const { data } = await UserModel.patch_users_pause_subscription(pause);
                    dispatchUserEvent({ eventName: pause ? 'PAUSE_SUBSCRIPTION' : 'RESUME_SUBSCRIPTION' });
                    setAppUser({ ...appUser, subscriptionPaused: pause });
                },
                {
                    successToastMessage: pause ? 'Membership paused' : 'Membership resumed',
                }
            );
        } catch (error) {}
    };

    const cancelSubscription = async (reason?: string) => {
        if (!appUser) return null;
        try {
            await withToast(
                async () => {
                    const { data } = await UserModel.patch_users_cancel_subscription(reason);
                    dispatchUserEvent({ eventName: 'CANCEL_SUBSCRIPTION', reason });
                    setAppUser({ ...appUser, isPaidUser: false });
                },
                {
                    successToastMessage: 'Membership cancelled',
                }
            );
        } catch (error) {}
    };

    const createTransaction = async ({ packId, card, couponCode, billingInfo }: TTransactionData) => {
        if (!appUser) return null;
        try {
            let form: Record<string, any> = {};
            if (card && billingInfo) {
                form = {
                    ...transformBillingFormData({ card, billing: billingInfo }),
                    packId,
                    couponCode,
                };
            } else {
                form = {
                    packId,
                    couponCode,
                };
            }
            const { data } = await TransactionsBase.post_Transactions_createTransaction<TCurrentSubscription>(form);
            if (data.isActive) {
                setAppUser({ ...appUser, subscriptions: { ...appUser.subscriptions, currentSubscription: data } });
            }
            return data;
        } catch (error) {
            throw error;
        }
    };

    return { createTransaction, changeCard, renewSubscription, cancelSubscription, togglePauseSubscription };
}

export default useSubscriptionActions;
