import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';





export const useFormConfig = () => {
    const classes = useStyles();
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email - check for an extra space in auto-filled email address').required('Email is required')
    })
    const actionConfig: IFormActionProps = {
        submitButtonText: 'NEXT',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large'
        },
        containerClassNames: classes.buttonContainer
    };
    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: 'text',
            valueKey: 'email',
            fieldProps: {
                label: 'Email',
                fullWidth: true,
                autoFocus: true,
            }
        }
    ];
    return { actionConfig, inputConfig, validationSchema }
}




const useStyles = makeStyles((theme: Theme) => createStyles({
    buttonContainer: {

    }
}))