import { useEffect } from "react"
import { useStoreActions } from "Stores";


function usePaymentEventListener() {
    const { setNativePayStatus } = useStoreActions(({ SubscriptionStore: { setNativePayStatus } }) => ({ setNativePayStatus }))

    useEffect(() => {
        // PAYMENT
        document.addEventListener('buySubscriptionStatus', (e: any) => {
            const status = e?.detail?.status as 'SUCCESS' | 'ERROR' | undefined;
            setNativePayStatus(status === 'SUCCESS' ? 'success' : 'error')
        })
    }, [])
}

export default usePaymentEventListener