import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { attachField, IReactFormProps, ReactForm } from 'react-forms';

import * as Yup from 'yup';
import OtpInput from 'Components/Input/OtpInput';
const formValidation = Yup.object({
    token: Yup.string().required('OTP is required'),
})
export interface OtpFormData {
    email: string
    token: any
}
export interface OtpFormProps {
    onSubmit: Function,
    isSubmitting?: boolean
    initialData?: Partial<OtpFormData>
}

attachField('otp', <OtpInput />)


const OtpForm: FC<OtpFormProps> = (props) => {
    const classes = useStyles();
    const config: IReactFormProps['config'] = [{
        valueKey: 'token',
        type: 'otp',
    }];
    const actionConfig: IReactFormProps['actionConfig'] = {
        submitButtonText: 'SUBMIT',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large',
            style: {
                paddingTop: 8,
                paddingBottom: 8
            }
        },

        containerClassNames: classes.buttonContainer
    }
    return (
        <ReactForm
            formId='otp-form'
            config={config}
            actionConfig={actionConfig}
            initialValues={props.initialData || {}}
            isInProgress={props.isSubmitting}
            onSubmit={props.onSubmit}
            validationSchema={formValidation}
            formSettings={{
                verticalSpacing: 20,
            }}
        />
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        buttonContainer: {
            marginTop: 40
        }
    }))
})

export default OtpForm