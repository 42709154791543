import { useCallback, useEffect } from 'react';
import { useStoreActions } from '../../Stores';


function useCordovaEvents() {
    const { setIsCordovaApp, setDevicePlatform } = useStoreActions(({ App: { setIsCordovaApp, setDevicePlatform } }) => ({ setIsCordovaApp, setDevicePlatform }))

    useEffect(() => {
        attachListeners();
    }, [])


    const attachListeners = useCallback(() => {
        document.addEventListener('nativeAppLoaded', (e: any) => {
            setIsCordovaApp(true);
            const platform = e?.detail?.platform as 'Android' | 'iOS' | undefined
            if (platform) {
                setDevicePlatform(platform === 'Android' ? 'android' : 'ios');
            }
        })
    }, []);
}


export default useCordovaEvents;