import { useAuthDialog } from 'Dialogs/Auth/useAuthDialog';
import { useAuth } from 'Features/Auth/useAuth';
import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'Stores';
import utils from 'Utils';

export interface PrivateRouteProps extends RouteProps {}

export const REDIRECT_URL_KEY = 'REDIRECT_URL_KEY';
const PrivateRoute: React.FC<PrivateRouteProps> = ({ ...routeProps }) => {
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

    if (!appUser) return <Redirect to="/" />;

    return <Route {...routeProps} />;
};

export default PrivateRoute;
