import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton, useScrollTrigger } from '@material-ui/core';
import { ArrowBackIos, Close } from '@material-ui/icons';
import clsx from 'clsx';
import appNavigator from 'Features/Navigation/appNavigator';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import Typo from 'Components/Typo';
import { TEXT_COLOR } from 'Theme/themeConstants';


export interface MobileHeaderProps {
    disableBackButton?: boolean
    transparent?: boolean
    dynamicElevation?: boolean
    drawer?: boolean
    onClose?: () => void
    className?: string
    onBack?: () => void
    leading?: JSX.Element | string | null
    sticky?: boolean
    action?: JSX.Element | null
}

const MobileHeader: FC<MobileHeaderProps> = (props) => {
    const { disableBackButton, drawer = false, className, dynamicElevation = false, onBack, sticky = false, transparent, onClose, leading, action } = props;
    const classes = useStyles({ drawer });
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ?? undefined,
    })


    return (
        <header className={clsx('mobile-header', classes.root, className, dynamicElevation ? { [classes.transparent]: !trigger, [classes.sticky]: true } : { [classes.transparent]: transparent, [classes.sticky]: sticky })}>
            <Box display='flex' alignItems='center'>
                {!!disableBackButton ? null : (
                    <IconButton className={classes.icon} onClick={onBack ?? appNavigator.goBack}>
                        <ArrowBackIos />
                    </IconButton>
                )}
                {typeof leading === 'string' ? (
                    <Typo className={classes.leading} limitLines={2} weight='medium'>{leading}</Typo>
                ) : leading}
            </Box>
            <Box display='flex' alignItems='center'>
                {action}
                {onClose ? (
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                ) : null}
            </Box>
        </header>
    )
}

export const MOBILE_HEADER_HEIGHT = 60;

const useStyles = makeStyles<Theme, { drawer: boolean }>((theme) => ({
    root: {
        zIndex: theme.zIndex.appBar,
        padding: props => theme.spacing(0, 1.5, 0, props.drawer ? 3 : 1.5),
        height: MOBILE_HEADER_HEIGHT,
        minHeight: MOBILE_HEADER_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        boxShadow: theme.shadows[1],
    },
    transparent: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    sticky: {
        position: 'sticky',
        top: 0,
    },
    leading: {
        lineHeight: 1.3,
        color: TEXT_COLOR.title
    },
    icon: {
        backgroundColor: 'transparent'
    }
}));

export default MobileHeader;