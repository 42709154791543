
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class TagCategoriesBase {

/** 
 * Find a related item by id for tags.
 */ 
    static get_TagCategories_id_tags_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for tags.
 */ 
    static put_TagCategories_id_tags_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for tags.
 */ 
    static delete_TagCategories_id_tags_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Add a related item by id for tags.
 */ 
    static put_TagCategories_id_tags_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags/rel/${fk}`,
            method: 'put',
            
            
        })
    }

/** 
 * Remove the tags relation to an item by id.
 */ 
    static delete_TagCategories_id_tags_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags/rel/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Queries tags of TagCategory.
 */ 
    static get_TagCategories_id_tags<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in tags of this model.
 */ 
    static post_TagCategories_id_tags<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all tags of this model.
 */ 
    static delete_TagCategories_id_tags<T = any>(id: string,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts tags of TagCategory.
 */ 
    static get_TagCategories_id_tags_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/tags/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_TagCategories<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/TagCategories`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_TagCategories<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/TagCategories`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static put_TagCategories<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/TagCategories`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
    static patch_TagCategories<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/TagCategories`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static post_TagCategories_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/TagCategories/replaceOrCreate`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
    static post_TagCategories_upsertWithWhere<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/TagCategories/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Check whether a model instance exists in the data source.
 */ 
    static get_TagCategories_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/exists`,
            method: 'get',
            
            
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_TagCategories_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/TagCategories/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static put_TagCategories_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/TagCategories/${id}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_TagCategories_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/TagCategories/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
    static delete_TagCategories_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/TagCategories/${id}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static post_TagCategories_id_replace<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/replace`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_TagCategories_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/TagCategories/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
    static post_TagCategories_update<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/TagCategories/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Count instances of the model matched by where from the data source.
 */ 
    static get_TagCategories_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/TagCategories/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static get_TagCategories_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/TagCategories/change-stream`,
            method: 'get',
            params: {
                options
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static post_TagCategories_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/TagCategories/change-stream`,
            method: 'post',
            
            data: {
                options
            },
        })
    }

/** 
 * Delete mutilple Tagcategory by ids
 */ 
    static delete_TagCategories_delete_multiple<T = any>(ids: any,) {
        return axios.request<T>({
            url: `/TagCategories/delete-multiple`,
            method: 'delete',
            
            data: {
                ids
            },
        })
    }

/** 
 * changeOrder
 */ 
    static patch_TagCategories_change_order<T = any>(order: any,id: string,) {
        return axios.request<T>({
            url: `/TagCategories/change-order`,
            method: 'patch',
            
            data: {
                order, id
            },
        })
    }

/** 
 * changeOrder
 */ 
    static post_TagCategories_id_new_tags<T = any>(tags: any,id: string,) {
        return axios.request<T>({
            url: `/TagCategories/${id}/new-tags`,
            method: 'post',
            
            data: {
                tags
            },
        })
    }

}

export default TagCategoriesBase;
    