import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, fade, Fade, Input } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import Typo from 'Components/Typo';
import SearchIcon from 'Components/Icons/SearchIcon';

export interface SearchBoxProps {
    onChange: (value: string) => void
    placeHolder?: string
    autoFocus?: boolean
}

const SearchBox: FC<SearchBoxProps> = (props) => {
    const classes = useStyles({});
    const [value, setValue] = useState('');
    const { placeHolder, autoFocus = true } = props;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        props.onChange(e.target.value);
    }

    const onClear = () => {
        setValue('');
        props.onChange('');
    }

    return (
        <div className={classes.searchBar}>
            <SearchIcon />
            {/* <SearchOutlined name="search" /> */}
            <Input
                fullWidth
                placeholder={placeHolder ?? "Cities, Places, Dishes, Cuisines, Tags, Users"}
                name="searchQuery"
                onChange={onChange}
                value={value}
                inputProps={{ className: classes.inputBox }}
                classes={{ root: classes.inputRoot }}
                autoComplete={'off'}
                disableUnderline
                autoFocus={autoFocus}
            />
            {
                value &&
                <Fade in={true}>
                    <Typo variant="caption" color="primary" onClick={onClear}>CLEAR</Typo>
                </Fade>
            }
        </div>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    searchBar: {
        flex: 1,
        height: 50,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 22px 8px 16px',
        backgroundColor: 'white',
        borderRadius: 4,
        flexShrink: 0,
        boxShadow: `0px 1px 3px ${fade('#000000', 0.2)}`,
        '& > svg': {
            marginRight: 12
        },
        '& > span': {
            cursor: 'pointer'
        },
        '@media (max-width: 599px)': {
            marginTop: 0,
            width: '100%',
            height: 40
        }
    },
    inputRoot: {
        lineHeight: 'unset',
        '& ::placeholder': {
            opacity: 0.9,
            fontSize: 16,
            color: theme.palette.primary.main
        }
    },
    inputBox: {
        '@media (max-width: 599px)': {
            fontSize: 18,
        }
    }

}));

export default SearchBox;