import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { APP_HEADER_HEIGHT } from 'Features/Header/AppHeader';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { NAVIGATION_TAB_HEIGHT } from 'Components/NewNavigationTab';
import SignUpPage from 'Features/Authentication/SignUpPage';
import { useAuth } from 'Features/Auth/useAuth';


const SignUp: React.FC = (props) => {
    const classes = useStyles({});
    const { userSignup } = useAuth();

    return (
        <div className={classes.root}>
            <SignUpPage onSubmit={userSignup} />
        </div>
    );
};

const useStyles = makeStyles<Theme, any>((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: THEME_PALETTE.background.background2,
            display: 'grid',
        },
    })
);

export default SignUp;
