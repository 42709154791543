import qs from 'qs';
import { HeaderFilter } from "Features/Header/FilterHeader";
import isEqual from 'lodash/isEqual'


export type TQuery = {
    cityIds?: string[]
    mamakooRating?: number[]
    dishIds?: string[]
    range?: number[]
    cuisineId?: string
    tagIds?: string[]
    bookWeeksAhead?: boolean;
};

export type TPaginationQuery = {
    page?: number
}

class Query {
    _query: TQuery = {}
    _pageNation: TPaginationQuery = {
        page: 1
    }
    constructor(query: TQuery, pagination?: TPaginationQuery) {
        this._query = query
        this._pageNation = pagination ?? { page: 1 }
    }

    get query() {
        return this._query;
    }

    static fromQuery(query: string) {
        let { page, ...data } = qs.parse(query, { ignoreQueryPrefix: true }) as TQuery & TPaginationQuery;
        return new Query({
            ...data,
            mamakooRating: data.mamakooRating?.map(d => Number(d)),
            range: data.range?.map(d => Number(d))
        }, { page: Number(page) || 1 })
    }

    get getSearchFilter() {
        let _filter: Record<string, any> = {
            where: {}
        };

        const { cityIds, range, cuisineId, tagIds, dishIds, mamakooRating, bookWeeksAhead } = this._query;
        if (cityIds)
            _filter.where.destinationId = cityIds
        if (tagIds)
            _filter.where.tagIds = tagIds
        if (range)
            _filter.where.range = range
        if (mamakooRating)
            _filter.where.mamakooRating = mamakooRating
        if (dishIds)
            _filter.foodDrinkIds = dishIds
        if (cuisineId)
            _filter.cuisines = [cuisineId]
        if (bookWeeksAhead)
            _filter.where.bookWeeksAhead = true
        return _filter
    }

    get paginationQuery() {
        return {
            limit: 30,
            skip: 30 * ((this._pageNation.page || 1) - 1),
        }
    }

    isSame(q: Query) {
        if (!isEqual(this._query, q._query))
            return false;
        if (!isEqual(this._pageNation, q._pageNation))
            return false;
        return true
    }

    clone(query: TQuery, pagination?: TPaginationQuery) {
        return new Query({
            mamakooRating: query.mamakooRating ?? this._query.mamakooRating,
            cityIds: query.cityIds ?? this._query.cityIds,
            cuisineId: query.cuisineId ?? this._query.cuisineId,
            dishIds: query.dishIds ?? this._query.dishIds,
            range: query.range ?? this._query.range,
            tagIds: query.tagIds ?? this._query.tagIds
        }, pagination)
    }

    get getQueryString() {

        return qs.stringify({ ...this._query, ...this._pageNation })
    }



}

export default Query;