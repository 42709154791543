import EmailField from 'Components/Input/EmailField';
import React from 'react';
import { ReactForm, attachField } from 'react-forms';
import { useFormConfig } from './config';

export interface LoginFormData {
    email: string
    password: string
}

attachField('email', <EmailField />)


export interface LoginFormProps {
    onSubmit: Function,
    initialData?: Partial<LoginFormData>
    isSubmitting?: boolean
    onBack?: (email: string) => void
}


const LoginForm: React.FC<LoginFormProps> = (props) => {
    const { actionConfig, inputConfig, validationSchema } = useFormConfig(props)
    return (
        <ReactForm
            initialValues={props.initialData}
            formId="Login"
            config={inputConfig}
            actionConfig={actionConfig}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        />
    )
}



export default LoginForm
