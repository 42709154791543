import { action, Action, actionOn, ActionOn, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import CityModel from "Models/City";
import { TCity } from "Models/City/@types";
import { TRootStore } from "Stores";
import helpers from "Utils/helpers";
import shuffle from 'lodash/shuffle';
import { TPlace } from "Models/Place/@types";


export interface TCityDetailScreenState {
    loading: boolean;
    city?: TCity;
    setLoading: Action<TCityDetailScreenState, boolean>;
    setCity: Action<TCityDetailScreenState, TCity | undefined>;
    fetchCity: Thunk<TCityDetailScreenState, { id: string }>;
}

const CityDetailScreenStore: TCityDetailScreenState = {
    loading: true,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),

    setCity: action((state, payload) => {
        state.city = payload;
    }),
    fetchCity: thunk(async (actions, { id }) => {
        actions.setLoading(true);
        const { data } = await CityModel.get_Destinations_id_details<TCity>(id);
        data.establishments = randomizePlaces(data.establishments || []);
        actions.setCity(data);
        actions.setLoading(false);
    }),
};


function randomizePlaces(places: TPlace[]) {
    return shuffle(places);
}

export default CityDetailScreenStore;

