import React from 'react';
import { ReactForm, attachField } from 'react-forms';
import { useFormConfig } from './config';

export interface SignUpFormData {
    email: string
    name: string
    password: string
    newsletter: boolean
}

export interface SignUpFormProps {
    onSubmit: Function,
    initialData?: Partial<SignUpFormData>
    isSubmitting?: boolean
    onBack?: (email: string) => void
}


const SignUpForm: React.FC<SignUpFormProps> = (props) => {
    const { actionConfig, inputConfig, validationSchema } = useFormConfig()
    return (
        <ReactForm
            initialValues={props.initialData}
            formId="SignUp"
            config={inputConfig}
            actionConfig={actionConfig}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        />
    )
}



export default SignUpForm
