import { DialogProvider } from 'Dialogs/DialogContext';
import React from 'react';
import AppDialogProvider from "./AppDialogContext"
import AppToastProvider from './AppToastContext';
import AppVariablesProvider from './AppVariablesContext';


const RootContextProviders: React.FC = (props) => {

    return (
        <AppDialogProvider>
            <AppToastProvider>
                <DialogProvider>
                    <AppVariablesProvider>
                        {props.children}
                    </AppVariablesProvider>
                </DialogProvider>
            </AppToastProvider>
        </AppDialogProvider>
    )
}

export default RootContextProviders