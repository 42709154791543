import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, ButtonProps, CircularProgress, LinearProgress } from '@material-ui/core';

export interface SubmitButtonProps extends ButtonProps {
    loading: boolean
    loaderType?: 'linear' | 'circular'
}

const SubmitButton: FC<SubmitButtonProps> = ({ loading, loaderType = 'circular', ...buttonProps }) => {
    const classes = useStyles({});

    return loading ? (
        <Box p={2} >
            {loaderType === 'circular' ?
                <CircularProgress color={buttonProps.color === 'default' ? 'primary' : buttonProps.color} /> :
                <LinearProgress style={{ width: '100px' }} color={buttonProps.color === 'default' || buttonProps.color === 'inherit' ? 'primary' : buttonProps.color} />
            }
        </Box>
    ) : (
            <Button {...buttonProps} />
        )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default SubmitButton;