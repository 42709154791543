import { Box, Button, CircularProgress, DialogProps, Icon, IconButton, Link, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { attachField } from 'react-forms';
import DialogWrapper from '..';
import ErrorText from '../../Components/ErrorText';
import Typo from '../../Components/Typo';
import EmailVerificationForm, { EmailVerificationFormData } from '../../Forms/EmailVerification';
import LoginForm from '../../Forms/Login';
import OtpForm, { OtpFormData } from '../../Forms/OtpForm';
import CrossIcon from '../../Assets/icons/Close.svg'
import SignupForm from '../../Forms/SignUp';
// import UserInterestForm from '../../Forms/UserInterestForm';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { useStoreState } from '../../Stores';
import { useHistory, useLocation } from 'react-router-dom';
import LoginPage from 'Features/Auth/LoginPage';
// import LoginPage from 'Features/Authentication/LoginPage';
import CloseIconButton from 'Components/Buttons/CloseIconButton';
import SignUpPage from 'Features/Auth/SignUpPage';
// import SignUpPage from 'Features/Authentication/SignUpPage';
import OtpPage from 'Features/Auth/OtpPage';
import JoinMembershipPage from 'Features/Onboarding/JoinMembershipPage';
import CardDetailPage from 'Features/Onboarding/CardDetailPage';
import Onboarding from 'Features/Onboarding';
import TagPreferenceSelectorPage from 'Features/Tag/TagPreferenceSelectorPage';
import { DIALOG_CONTENT_WIDTH } from 'Constants/dimension';
import { useGlobalStyle } from 'Constants/classes';
// import GRAPHIC from '../../Assets/images/payment/welcome.png';
import GRAPHIC from '../../Assets/animated/autorickshaw.json';
import LottieImage from 'Components/LottieImage';


export type AuthType = 'login' | 'signup' | 'check' | 'otp' | 'onboarding' | 'interest'


export interface AuthDialogProps extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
    authType: AuthType
    strictMode?: boolean
    onSubmit: Function
    handleCheck: (value: string) => Promise<boolean>
    onTypeChange?: (type: AuthType) => void
    onForgotPassword?: (email: string) => void
    errorMessage?: string
    loading?: boolean
    onClose?: () => void
}

const AuthDialog: React.FC<AuthDialogProps> = (props) => {
    const { authType, strictMode = false, loading = false, handleCheck: _handleCheck, onForgotPassword, onSubmit, errorMessage, onTypeChange, ...dialogProps } = props;
    // const [type, setType] = useState<AuthType>(authType)
    const { isDeviceSm } = useMediaQuery();
    const globalClasses = useGlobalStyle({});
    const [val, setVal] = useState('');
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))

    const classes = useStyles()
    let TITLE = ''
    if (authType === 'login')
        TITLE = 'You are already in our system.\nWelcome back!';
    else if (authType === 'check')
        TITLE = 'Welcome to Mamakoo'
    else
        TITLE = 'Create an account'


    const setAuthType = (type: AuthType) => {
        if (onTypeChange)
            onTypeChange(type)
    }

    const handleCheck = async (data: EmailVerificationFormData) => {
        setVal(data.email);
        try {
            const isRegistered = await props.handleCheck(data.email || '')
            if (isRegistered) setAuthType('login')
            else setAuthType('signup')
        } catch (error) {

        }
    }

    const handleOtpInput = async (data: OtpFormData) => {
        try {
            await props.onSubmit?.(data);
        } catch (error) {

        }
    }



    const otpHandler = useAsyncTask(handleOtpInput);
    const checkHandler = useAsyncTask(handleCheck)


    const closeDialog = () => {
        restartFlow();
        if (dialogProps.onClose)
            dialogProps.onClose()
    }


    const restartFlow = () => {
        setAuthType('check');
    }





    const handleForgotPassword = () => {
        if (!val || !onForgotPassword) return;
        onForgotPassword(val);
    }



    const editEmail = () => setAuthType('check');

    const AuthComponent: Record<AuthType, JSX.Element> = {
        login: <LoginPage onBack={restartFlow} initialData={{ email: val }} onSubmit={onSubmit} />,
        check: (
            <Box px={5} mt={4} pb={4}>
                <EmailVerificationForm onSubmit={checkHandler.run} isSubmitting={checkHandler.status === 'PROCESSING'}
                    initialData={{ email: val }}
                />
                {isDeviceSm ?
                    <div className={classes.img}>
                        <LottieImage lotteJSON={GRAPHIC} dimension={{ width: '100vw', height: 'unset' }} />
                    </div> : null
                    // <img src={GRAPHIC} alt='login-image' className={classes.img} /> : null
                }
            </Box>
        ),
        otp: <OtpPage initialData={{ email: val }} onSubmit={otpHandler.run} isSubmitting={otpHandler.status === 'PROCESSING'} />,
        signup: <SignUpPage onBack={restartFlow} initialData={{ email: val, newsletter: true }} onSubmit={onSubmit} />,
        onboarding: <Onboarding onDone={() => onSubmit()} />,
        interest: <TagPreferenceSelectorPage onDone={() => onSubmit()} />

    }


    const shouldShowCloseIcon = useMemo(() => {
        if (authType === 'otp' || authType === 'onboarding' || authType === 'interest' || strictMode) return false;
        return true;
    }, [authType, strictMode])


    return (
        <DialogWrapper {...dialogProps} fullScreen={isDeviceSm} disableBackdropClick scroll="body">
            <div className={classes.container}>
                {loading && !isDeviceSm ?
                    <CircularProgress />
                    : (
                        <Box
                            width={isDeviceSm ? '100%' : DIALOG_CONTENT_WIDTH}
                            height={isDeviceSm ? '100%' : undefined} mx="auto" position="relative"
                        >
                            <Box px={1} pt={1} display='flex' justifyContent='space-between'>
                                {authType === 'login' || authType === 'signup' || authType === 'otp' ? (
                                    <Box mb={2}>
                                        <Button className={globalClasses.underlinedTextButton} onClick={editEmail}>Edit email</Button>
                                    </Box>
                                ) : <div />}


                                {!shouldShowCloseIcon ? null : (
                                    <Box mb={2}>
                                        <CloseIconButton onClick={closeDialog} />
                                    </Box>
                                )}
                            </Box>
                            <Box className={shouldShowCloseIcon || authType === 'check' ? classes.content : ''} pb={0}>
                                {authType === 'check' ? (
                                    <Typo gutterBottom className={classes.title}>{TITLE}</Typo>
                                ) : null}

                                {/* <Box px={5}><ErrorText centered>{errorMessage}</ErrorText></Box> */}

                                {AuthComponent[authType]}
                            </Box>
                        </Box>
                    )}
            </div>
        </DialogWrapper >
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        [theme.breakpoints.down('sm')]: {
            height: '100vh'
        }
    },
    content: {
        [theme.breakpoints.down('sm')]: {
            // top: '45%',
            // position: 'relative',
            // transform: 'translateY(-50%)'
            paddingTop: theme.spacing(5)
        }

    },
    img: {
        width: 100,
        position: 'absolute',
        bottom: 0,
        left: 0,
        objectFits: 'cover'
    },
    closeButton: {
        color: theme.palette.primary['contrastText'],
        position: 'absolute',
        top: 16,
        right: 16
    },
    title: {
        ...theme.typography.h6,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            ...theme.typography.h5,
        }

    },
    outlinedIconButton: {
        // border: `1px solid ${theme.palette.text.primary}`,
    },
}))

export default AuthDialog