import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Link } from '@material-ui/core';
import SignUpForm, { SignUpFormProps } from 'Forms/SignUp';
import Typo from 'Components/Typo';
import { TERMS_CONDITIONS_URL } from 'Constants/links';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import GRAPHIC from '../../Assets/animated/autorickshaw.json';
import LottieImage from 'Components/LottieImage';

export interface SignUpPageProps extends SignUpFormProps {

}

const SignUpPage: FC<SignUpPageProps> = (props) => {
    const { children, ...signupFormProps } = props;
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles({});

    return (
        <Box px={5} pb={4}>
            <Typo variant='h5' align='center' color='primary' weight='medium'>Get started on your tasteful journey</Typo>
            <Box mt={5}>
                <SignUpForm {...signupFormProps} />
                <Box mt={4}>
                    <Typo variant='caption' fontFamily='secondary' weight='medium' align='center'>
                        By clicking next you are agreeing to the<br />
                        <Link href={TERMS_CONDITIONS_URL}>
                            Terms of Service
                        </Link>
                    </Typo>
                </Box>
            </Box>
            {isDeviceSm ?
                <div className={classes.img}>
                    <LottieImage lotteJSON={GRAPHIC} dimension={{ width: '100vw', height: 'unset' }} />
                </div> : null
            }
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    img: {
        width: 100,
        position: 'absolute',
        bottom: 0,
        left: 0,
        objectFits: 'cover'
    }
}));

export default SignUpPage;