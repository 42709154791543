import { action, Action, actionOn, ActionOn, thunk, Thunk } from "easy-peasy";
import { omit } from "lodash";
import ExploreModel from "Models/Explore";
import { TExplore } from "Models/Explore/@types";
import { TRootStore } from "Stores";

export interface TExploreScreenState {
    explore: TExplore | undefined
    loading: boolean
    setExplore: Action<TExploreScreenState, TExplore | undefined>
    setLoading: Action<TExploreScreenState, boolean>
    fetchExploreDetail: Thunk<TExploreScreenState, string, {}, TRootStore>


}

const ExploreScreenStore: TExploreScreenState = {
    explore: undefined,
    loading: false,
    setExplore: action((state, payload) => {
        state.explore = payload
    }),
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    fetchExploreDetail: thunk(async (actions, id) => {
        actions.setLoading(true);
        const { data } = await ExploreModel.get_Lists_id<TExplore>(id, {
            include: [
                {
                    relation: 'establishments',
                    scope: {
                        include: [
                            'cuisines',
                            'tags',
                            {
                                relation: 'destination',
                                scope: {
                                    fields: ['name', 'id', 'slug', 'destinationPackId'],
                                    include: {
                                        relation: 'destinationPack',
                                    },
                                },
                            },
                        ],
                    },
                },
                {
                    relation: 'destination',
                    scope: {
                        fields: ['name', 'id', 'slug'],
                    },
                },
            ],
        });
        actions.setExplore(data)
        actions.setLoading(false);
    }),

};

export default ExploreScreenStore;

