import React, { FC, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import Typo from 'Components/Typo';
import OtpForm, { OtpFormProps } from 'Forms/OtpForm';
import { useAuth } from './useAuth';
// import GRAPHIC from '../../Assets/images/payment/welcome.png';
import GRAPHIC from '../../Assets/animated/autorickshaw.json';

import { useMediaQuery } from 'Hooks/useMediaQuery';
import LottieImage from 'Components/LottieImage';


export interface OtpPageProps extends OtpFormProps {
    handleEditEmail?: () => void;
}

const OtpPage: FC<OtpPageProps> = (props) => {
    const { children, handleEditEmail, ...otpFormProps } = props;
    const classes = useStyles({});
    const email = otpFormProps.initialData?.email
    const { isDeviceSm } = useMediaQuery();
    const { sendEmailVerificationLink } = useAuth();

    useEffect(() => {
        // sendOtp();
    }, [])


    const sendOtp = async () => {
        if (!email) return;
        try {
            await sendEmailVerificationLink(email)
        } catch (error) {

        }
    }

    return (
        <Box pb={4}>
            <Typo variant='h5' align='center' color='primary' weight='medium'>Let’s make sure it’s you</Typo>
            <Typo variant='subtitle2' align='center' weight='medium'>Please enter the verification code sent to your email <br />{email}.</Typo>
            <Box px={5} mt={5}>
                <OtpForm
                    {...otpFormProps}
                />
                <Box mt={4} display='flex' justifyContent='space-between'>
                    <Button onClick={sendOtp} variant='text' color='primary'>
                        Resend code
                    </Button>
                    {handleEditEmail ? (
                        <Button onClick={handleEditEmail} variant='text' color='primary'>
                            Edit email
                        </Button>
                    ) : null}
                </Box>
            </Box>
            {isDeviceSm ?
                <div className={classes.img}>
                    <LottieImage lotteJSON={GRAPHIC} dimension={{ width: '100vw', height: 'unset' }} />
                </div> : null
            }
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    img: {
        width: 100,
        position: 'absolute',
        bottom: 0,
        left: 0,
        objectFits: 'cover'
    }
}));

export default OtpPage;