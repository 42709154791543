import React, { FC, useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import DialogWrapper from 'Dialogs';
import { TSubscriptionPack } from 'Models/SubscriptionPack/@types';
import { useStoreActions, useStoreState } from 'Stores';
import Loader from 'Components/Loader';
import { CouponInstance } from 'Features/Subscription/@types';
import useStepper from 'Hooks/useStepper';
import Typo from 'Components/Typo';
import SubscriptionSuccessPage from 'Features/Subscription/SubscriptionSuccessPage';
import { DIALOG_CONTENT_WIDTH } from 'Constants/dimension';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import clsx from 'clsx';
import GetSubscriptionWithPromo from 'Features/Subscription/SubscriptionSteps/GetSubscriptionWithPromo';
import CardDetailInput from 'Features/Subscription/SubscriptionSteps/CardDetailInput';
import PauseMembership from 'Features/Subscription/SubscriptionSteps/PauseMembership';
import UpgradeBenefits from 'Features/Subscription/UpgradeBenefits';
import useSubscriptionActions from 'Features/Subscription/useSubscriptionActions';
import useAsyncTask from 'Hooks/useAsyncTask';
import SubscriptionFailurePage from 'Features/Subscription/SubscriptionFailurePage';
import { initiateNativePayment } from 'Features/Subscription/utils';
import useUserActions from 'Features/User/useUserActions';
import { useHistory } from 'react-router-dom';
import utils from 'Utils';
import { TPlace } from 'Models/Place/@types';

export interface SubscriptionPackUpdateDialogProps {
    open: boolean
    onClose: () => void
    place?: TPlace
}

const SubscriptionPackUpdateDialog: FC<SubscriptionPackUpdateDialogProps> = ({ open, onClose, place }) => {
    const classes = useStyles({});
    const { fetchSubscriptionPacks, setSelectedPack, setIsTrailOver, setCoupon, setNativePayStatus } = useStoreActions(({ App: { fetchSubscriptionPacks }, SubscriptionStore: { setSelectedPack, setCoupon, setNativePayStatus, setIsTrailOver } }) => ({ setNativePayStatus, fetchSubscriptionPacks, setIsTrailOver, setSelectedPack, setCoupon }))
    const { subscriptionPacks, selectedPack, coupon, nativePayStatus, devicePlatform, isCordovaApp } = useStoreState(({ App: { subscriptionPacks, isCordovaApp, devicePlatform }, SubscriptionStore: { selectedPack, coupon, nativePayStatus } }) => ({ subscriptionPacks, selectedPack, devicePlatform, coupon, nativePayStatus, isCordovaApp }))
    const [loading, setLoading] = useState(false);
    const { isDeviceSm } = useMediaQuery();
    const { isTrailOver } = useUserActions();
    const { createTransaction } = useSubscriptionActions();
    const { currentIndex, resetStepper, hasNext, hasPrev, nextStep, prevStep, setCurrentIndex } = useStepper(4)

    let history = useHistory();

    const createTransactionHandler = useAsyncTask(async (_coupon: CouponInstance | null) => {
        if (selectedPack)
            await createTransaction({ packId: selectedPack.id, couponCode: _coupon ? _coupon.code : undefined })

    })

    useEffect(() => {
        if (nativePayStatus === 'success') {
            setCurrentIndex(3)
        }
    }, [nativePayStatus])

    // useEffect(() => {
    //     if (subscriptionPacks.length) return;
    //     getSubscriptionPacks();
    // }, [])

    // const getSubscriptionPacks = async () => {
    //     setLoading(true)
    //     try {
    //         fetchSubscriptionPacks();
    //     } catch (error) {

    //     }
    //     setLoading(false)
    // }

    useEffect(() => {
        const pack = subscriptionPacks.find(p => p.value === 'world');
        setSelectedPack(pack)
        if (pack)
            checkTrailStatus(pack.id)
    }, [subscriptionPacks])

    const checkTrailStatus = async (packId: string) => {
        const _isTrailOver = await isTrailOver(packId);
        setIsTrailOver(_isTrailOver);
    }



    const handlePackSelection = (pack: TSubscriptionPack | undefined, coupon: CouponInstance | null) => {
        setSelectedPack(pack);
        setCoupon(coupon)
        nextStep();
    }

    const onPaymentDone = async () => {
        nextStep();
        setCoupon(null);
        setSelectedPack(undefined);
    }

    const handleSubscriptionComplete = () => {
        closeDialog();
        window.location.reload();
    }

    const handleCouponEntry = async (coupon: CouponInstance | null) => {
        setCoupon(coupon)
        if (coupon && !!coupon.packBenefits) {
            await createTransactionHandler.run(coupon)
            nextStep(1);
        }
        else
            nextStep()

    }


    const closeDialog = () => {
        resetStepper();
        onClose();
    }

    const upgrade = () => {
        // if (isCordovaApp) {
        //     setNativePayStatus('initiated');
        //     initiateNativePayment();
        // } else {
        //     nextStep();
        // }
        // nextStep();
        // utils.setCookie('redirectURL', history.location.pathname);
        // utils.setCookie('redirectURL', place ? `/places/${place.slug}` : '/');
        closeDialog();
        history.push('/payment');
    }

    const Component = useMemo(() => {
        if (currentIndex === 0) return <UpgradeBenefits onAgree={upgrade} onCancel={closeDialog} />
        // else if (currentIndex === 1 && selectedPack) return <GetSubscriptionWithPromo onClose={closeDialog} onNext={handleCouponEntry} />
        // else if (currentIndex === 2) return <CardDetailInput onBack={prevStep} onClose={closeDialog} subscriptionPack={selectedPack} coupon={coupon} onSubmit={onPaymentDone} />
        // else if (currentIndex === 3) return <SubscriptionSuccessPage descriptionText='All places are unlocked.' buttonText='START EXPLORING' onClick={handleSubscriptionComplete} />
    }, [currentIndex, isCordovaApp, devicePlatform]);

    // const Component = useMemo(() => {
    //     if (currentIndex === 0) return <JoinMembershipPage onDone={handlePackSelection} onSkip={closeDialog} />
    //     else if (currentIndex === 1 && selectedPack) return <CardDetailPage onBack={prevStep} onClose={closeDialog} subscriptionPack={selectedPack} onDone={onPaymentDone} />
    //     else if (currentIndex === 2) return <SubscriptionSuccessPage descriptionText='All places are unlocked.' buttonText='START EXPLORING' onClick={handleSubscriptionComplete} />
    // }, [currentIndex]);

    return (
        // <DialogWrapper open={open} classes={{ paper: clsx({ [classes.rounded]: isDeviceSm && currentIndex === 0 }) }} fullScreen={isDeviceSm ? (currentIndex !== 0) : false}>
        <DialogWrapper open={open} >
            <div className={classes.container}>
                {nativePayStatus === 'error' ? (
                    <SubscriptionFailurePage onClick={() => setNativePayStatus('idle')} />
                ) : (
                    // loading || createTransactionHandler.status === 'PROCESSING' ? <Loader /> : Component
                    nativePayStatus === 'initiated' || loading || createTransactionHandler.status === 'PROCESSING' ? <Loader fullScreen /> : Component
                )
                }
            </div>
        </DialogWrapper>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: DIALOG_CONTENT_WIDTH,
            width: DIALOG_CONTENT_WIDTH
        }
    },
    rounded: {
        borderRadius: theme.shape.borderRadius * 3
    }
    // container: {
    // [theme.breakpoints.down('sm')]: {
    // width: '95vw',
    // height: '90vh'
    // }
    // }
}));

export default SubscriptionPackUpdateDialog;