import { SETTINGS_AUTH_SCREEN } from "Constants/keys";
import { TPicture } from "Models/@types";
import { TSettings } from "Models/AppModel/@types";

export const settingUtils = {
    getAuthScreenImage(device: 'lg' | 'sm', settings: TSettings[]): TPicture | null {
        const setting = settings.find(s => s.key === SETTINGS_AUTH_SCREEN);
        if(!setting) return null;
        if(device === 'lg') {
            return setting.value['lgPicture'] || null;
        } else if(device === 'sm') {
            return setting.value['smPicture'] || null;
        }
        return null;
    }
}