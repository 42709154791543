import React, { useState, useCallback } from 'react';
import SupportDialog from '.';


function useSupportFormDialog() {
    const [open, setOpen] = useState(false);

    const openDialog = useCallback(() => setOpen(true), []);
    const closeDialog = useCallback(() => setOpen(false), []);

    return {
        openDialog,
        closeDialog,
        DialogComponent: (
            <SupportDialog open={open} onClose={closeDialog} />
        )
    }


}

export default useSupportFormDialog;