import { action, Action, actionOn, ActionOn, thunk, Thunk } from "easy-peasy";
import CityModel from "Models/City";
import { TCity } from "Models/City/@types";
import { TPlace } from "Models/Place/@types";
import UserModel from "Models/User";
import { TUser } from "Models/User/@types";
import { TRootStore } from "Stores";

export interface TUserDetailScreenState {
    user: TUser | undefined;
    loading: boolean
    cities: TCity[];
    recommendedPlaces: TPlace[]
    getRecommendedPlaces: Thunk<TUserDetailScreenState, { userId: string }>
    getCities: Thunk<TUserDetailScreenState, { id: string }>;
    setRecommendedPlaces: Action<TUserDetailScreenState, TPlace[]>
    setCities: Action<TUserDetailScreenState, TCity[]>;
    setLoading: Action<TUserDetailScreenState, boolean>
    setUser: Action<TUserDetailScreenState, TUser | undefined>;
    fetchUserDetail: Thunk<TUserDetailScreenState, string, {}, TRootStore>

    onAppUserUpdated: ActionOn<TUserDetailScreenState, TRootStore>
}

const UserDetailScreenStore: TUserDetailScreenState = {
    user: undefined,
    loading: false,
    cities: [],
    recommendedPlaces: [],
    setRecommendedPlaces: action((state, payload) => {
        state.recommendedPlaces = payload
    }),
    setCities: action((state, payload) => {
        state.cities = payload;
    }),
    setUser: action((state, payload) => {
        state.user = payload
    }),
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    getCities: thunk(async (actions, { id }) => {

        const { data } = await CityModel.get_Destinations_pined_by_id<TCity[]>(id, {
            include: ['destinationPack']
        });

        actions.setCities(data);

    }),
    getRecommendedPlaces: thunk(async (actions, { userId }) => {

        const { data } = await UserModel.get_users_id_suggestions<TPlace[]>(userId, {
            include: ["cuisines", "tags", {
                relation: 'destination',
                scope: {
                    include: {
                        relation: 'destinationPack',
                    },
                },
            }],
        });
        actions.setRecommendedPlaces(data);

    }),
    fetchUserDetail: thunk(async (actions, userId) => {
        actions.setLoading(true);
        try {
            const { data } = await UserModel.get_users_profile(userId);
            actions.setUser(data)
        } catch (error) {
            actions.setUser(undefined)
        }
        actions.setLoading(false)
    }),

    // LISTENERS
    onAppUserUpdated: actionOn(
        (actions, storeActions) => storeActions.AuthStore.setAppUser,
        (state, { payload }) => {
            if (payload && state.user?.id === payload.id)
                state.user = { ...state.user, ...payload };
        }
    )
};

export default UserDetailScreenStore;

