import { Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useGlobalStyle = makeStyles<Theme, any>((theme: Theme) => createStyles({
    underlinedTextButton: {
        fontSize: 14,
        minWidth: 0,
        color: theme.palette.primary.main,
        textDecoration: 'underline !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }

    },
    caption: {
        position: 'absolute',
        transform: `translate(-50%, 0px)`,
        left: '50%',
        bottom: 8,
        [theme.breakpoints.down('sm')]: {
            left: 0,
            bottom: 20,
            transform: 'none'
        }
    }
}))