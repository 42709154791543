import { action, Action, actionOn, ActionOn, thunk, Thunk } from "easy-peasy";
import { omit } from "lodash";
import { TLocation } from "Models/@types";
import LandmarkModel from "Models/Landmark";
import { TLandmark } from "Models/Landmark/@types";
import PlaceModel from "Models/Place";
import { TPlace, TPlaceFilterResponse } from "Models/Place/@types";
import { TRootStore } from "Stores";

export interface TLandmarkDetailScreenState {
    landmark: TLandmark | undefined
    loading: boolean
    otherLandmarks: TLandmark[]
    places: TPlace[]
    loadingPlaces: boolean
    setLoadingPlaces: Action<TLandmarkDetailScreenState, boolean>
    setOtherLandmarks: Action<TLandmarkDetailScreenState, TLandmark[]>
    setPlaces: Action<TLandmarkDetailScreenState, TPlace[]>
    setLandmark: Action<TLandmarkDetailScreenState, TLandmark | undefined>
    setLoading: Action<TLandmarkDetailScreenState, boolean>
    fetchLandmarkDetail: Thunk<TLandmarkDetailScreenState, string, {}, TRootStore>
    fetchNearbyPlacesDetail: Thunk<TLandmarkDetailScreenState, { location: TLocation | undefined, destinationId: string }, {}, TRootStore>

}

const LandmarkDetailScreenStore: TLandmarkDetailScreenState = {
    landmark: undefined,
    loading: false,
    loadingPlaces: false,
    otherLandmarks: [],
    places: [],
    setLandmark: action((state, payload) => {
        state.landmark = payload
    }),
    setOtherLandmarks: action((state, payload) => {
        state.otherLandmarks = payload
    }),
    setLoadingPlaces: action((state, payload) => {
        state.loadingPlaces = payload
    }),
    setPlaces: action((state, payload) => {
        state.places = payload
    }),
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    fetchLandmarkDetail: thunk(async (actions, id) => {
        actions.setLoading(true);
        try {
            const { data } = await LandmarkModel.get_Landmarks_id<TLandmark>(id, {
                include: {
                    relation: 'destination',
                    scope: {
                        fields: ['id', 'name', 'location', 'slug', 'destinationPackId'],
                        include: [
                            {
                                relation: 'destinationPack',
                            },
                            {
                                relation: 'landmarks',
                            }
                        ]
                    }
                }
            });
            actions.fetchNearbyPlacesDetail({ location: data?.location, destinationId: data.destinationId })
            actions.setOtherLandmarks(data?.destination?.landmarks?.filter(landmark => landmark.id !== data.id) || [])
            actions.setLandmark(data);
        } catch (error) {
            actions.setLandmark(undefined);
        }
        actions.setLoading(false)
    }),
    fetchNearbyPlacesDetail: thunk(async (actions, { location, destinationId }) => {
        if (!location || !destinationId) return;
        actions.setLoadingPlaces(true);
        try {
            const { data } = await PlaceModel.get_Establishments_filter<TPlaceFilterResponse>({
                limit: 9,
                include: ["cuisines", "tags", {
                    relation: 'destination',
                    scope: {
                        fields: ['id', 'name', 'location', 'slug', 'destinationPackId'],
                        include: {
                            relation: 'destinationPack',
                        }
                    }
                }],
                where: {
                    destinationId: destinationId,
                    location: {
                        near: location,
                    },
                }
            })
            actions.setPlaces(data.results)
        } catch (error) {
            actions.setPlaces([])
        }
        actions.setLoadingPlaces(false)
    }),



};

export default LandmarkDetailScreenStore;

