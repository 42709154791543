import { action, Action } from "easy-peasy";
import { EntityActionResponse } from "Models/@types";
import { TComment } from "Models/Comment/@types";
import { TPlace } from "Models/Place/@types";

export interface TEntityActionsState {
    placeSaved: Action<TEntityActionsState, TPlace>
    placeUnSaved: Action<TEntityActionsState, string>
    commentAdded: Action<TEntityActionsState, { comment: TComment, placeId: string }>
    commentRemoved: Action<TEntityActionsState, { comment: TComment, placeId: string }>
}

const EntityActionsStore: TEntityActionsState = {
    placeSaved: action(() => { }),
    placeUnSaved: action(() => { }),
    commentAdded: action(() => { }),
    commentRemoved: action(() => { }),
};

export default EntityActionsStore;

