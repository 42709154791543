import Axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { action, Action, actionOn, ActionOn, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import { PLACE_CARD_FIELDS } from "Features/Place/PlaceCard";
import Query from "Features/Query";
import EstablishmentQuery from "Features/Query/EstablishmentQuery";
import { TSearchResult } from "Features/Search/@types";
import { omit } from "lodash";
import CuisineModel from "Models/Cuisine";
import { TCuisine } from "Models/Cuisine/@types";
import { TDish } from "Models/Dish/@types";
import { TPlace } from "Models/Place/@types";
import UserModel from "Models/User";
import { TRootStore } from "Stores";





export interface TCuisineDetailScreenState {
    cuisine: TCuisine | undefined
    loading: boolean
    places: TPlace[]
    dishes: TDish[]
    facetCityBuckets: { key: string, doc_count: number }[]
    query: EstablishmentQuery
    loadingPlaces: boolean

    setFacetCityBuckets: Action<TCuisineDetailScreenState, TCuisineDetailScreenState['facetCityBuckets']>
    setLoadingPlaces: Action<TCuisineDetailScreenState, boolean>
    setQuery: Action<TCuisineDetailScreenState, EstablishmentQuery>
    setDishes: Action<TCuisineDetailScreenState, TDish[]>
    setPlaces: Action<TCuisineDetailScreenState, TPlace[]>
    setCuisine: Action<TCuisineDetailScreenState, TCuisine | undefined>
    setLoading: Action<TCuisineDetailScreenState, boolean>
    fetchCuisine: Thunk<TCuisineDetailScreenState, string, {}, TRootStore>
    fetchCuisinePlaces: Thunk<TCuisineDetailScreenState, { cuisineId: string, query: EstablishmentQuery }, {}, TRootStore>

    onQueryChange: ThunkOn<TCuisineDetailScreenState, {}, TRootStore>

}


let cancelToken: CancelTokenSource | undefined = undefined;

const CuisineDetailScreenStore: TCuisineDetailScreenState = {
    cuisine: undefined,
    loading: false,
    dishes: [],
    places: [],
    facetCityBuckets: [],
    loadingPlaces: false,
    query: new EstablishmentQuery({}),
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    setFacetCityBuckets: action((state, payload) => {
        state.facetCityBuckets = payload
    }),
    setLoadingPlaces: action((state, payload) => {
        state.loadingPlaces = payload
    }),
    setDishes: action((state, payload) => {
        state.dishes = payload
    }),
    setPlaces: action((state, payload) => {
        state.places = payload
    }),
    setQuery: action((state, payload) => {
        state.query = payload
    }),
    setCuisine: action((state, payload) => {
        state.cuisine = payload
    }),
    fetchCuisine: thunk(async (actions, cuisineId) => {
        actions.setLoading(true);
        actions.fetchCuisinePlaces({ cuisineId, query: new EstablishmentQuery({}) });
        const { data } = await CuisineModel.get_Cuisines_id<TCuisine>(cuisineId, {
            include: ["foodDrinks"]
        })
        const { data: facets } = await UserModel.get_users_autocomplete<TSearchResult>('', {
            where: {
                doc_type: "EstablishmentCuisine",
                cuisineId: data.id,
            },
            limit: 1
        });
        actions.setFacetCityBuckets(facets?.aggregations?.placeDestination?.buckets || [])
        actions.setCuisine(data);
        actions.setLoading(false);
    }),
    fetchCuisinePlaces: thunk(async (actions, { cuisineId, query }) => {
        actions.setLoadingPlaces(true);

        if (cancelToken) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = Axios.CancelToken.source();

        const { data } = await CuisineModel.get_Cuisines_id_establishments<TPlace[]>(cuisineId, {
            include: ["cuisines", "tags", {
                relation: 'destination',
                scope: {
                    include: {
                        relation: 'destinationPack',
                    },
                },
            },],
            // fields: PLACE_CARD_FIELDS,
            limit: 20,
            ...query.getSearchFilter
        }, { cancelToken: cancelToken.token })
        // cancelToken = undefined;
        actions.setLoadingPlaces(false);
        actions.setPlaces(data);
    }),

    // LISTENERS
    onQueryChange: thunkOn(
        (actions) => actions.setQuery,
        async (actions, { payload }, { getState }) => {
            const state = getState();
            if (!state.cuisine) return;
            actions.fetchCuisinePlaces({ query: payload, cuisineId: state.cuisine.id })
        }
    ),


};

export default CuisineDetailScreenStore;

