import { Box, useScrollTrigger } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LOGO from 'Assets/images/logo-text.svg';
import clsx from 'clsx';
import LocationOnIconButton from 'Components/Buttons/LocationOnIconButton';
import SearchIconButton from 'Components/Buttons/SearchIconButton';
import useSearchDialog from 'Dialogs/Search/useSearchDialog';
import useAppUserStatus from 'Features/AppUser/useAppUserStatus';
import useAvatarMenu from 'Features/AppUser/useAvatarMenu';
import appNavigator from 'Features/Navigation/appNavigator';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React, { FC, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import helpers from 'Utils/helpers';
import { THEME_PALETTE, FONT_FAMILY } from 'Theme/themeConstants';
import LocationOnIcon from 'Components/Icons/LocationOnIcon';
import SearchIcon from 'Components/Icons/SearchIcon';
import Typo from 'Components/Typo';
import { useStoreState } from 'Stores';
import UserModel from 'Models/User';
// import { SHOP_URL } from 'Constants/links';

export interface AppHeaderProps {

}

const AppHeader: FC<AppHeaderProps> = (props) => {

    // const { appUser } = useStoreState(({AuthStore: { appUser } }) => ({ appUser }));
    
    const classes = useStyles({});
    const { pathname } = useLocation();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window : undefined,
    })
    const { openDialog: openSearch, DialogComponent: SearchDialogComponent } = useSearchDialog();
    const { UiComponent: UserUI, AuthComponent: AuthDialog, MenuComponent } = useAvatarMenu();
    const { UiComponent: Status } = useAppUserStatus();

    // const isLocked = UserModel.isAccessLimited(appUser);


    const { isDeviceSm } = useMediaQuery();


    const handleLogoClick = () => {
        if (window.location.pathname === '/')
            helpers.scrollTo('root');
    }

    return isDeviceSm ? null : (
        <>
            <header className={clsx(classes.root, { [classes.transparent]: !trigger, [classes.sticky]: true })}>
                
                <Box display='flex' flex={1} alignItems='center'>
                    <Link to="/" onClick={handleLogoClick}>
                        <img src={LOGO} width='160px' />
                    </Link>
                    <Box display="flex" alignItems="center">
                        <Box ml={4.5}>
                            <Link to="/destinations" className={pathname === '/destinations'? classes.tabActive : classes.tab}>DESTINATIONS</Link>
                        </Box>
                            {/* <Box ml={3}>
                                <Link to="/insider" className={pathname === '/insider'? classes.tabActive : classes.tab}>INSIDER</Link>
                            </Box> */}
                        <Box ml={3}>
                            <Link to="/about" className={pathname === '/about'? classes.tabActive : classes.tab}>ABOUT</Link>
                        </Box>
                        {/* <Box ml={3}>
                            <Link target="_blank" to={{ pathname: SHOP_URL }} className={pathname === '#'? classes.tabActive : classes.tab}>SHOP</Link>
                        </Box> */}
                    </Box>
                </Box>
                <Box justifyContent='flex-end' flex={1} display='flex' alignItems='center'>
                    <Box mr={4} onClick={openSearch} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ cursor: "pointer" }}>
                        {/* <SearchIconButton onClick={openSearch} /> */}
                        <SearchIcon fontSize="small" color='primary' />
                        <Typo fontFamily="secondary" weight="medium" variant="caption" className={classes.iconText}>SEARCH</Typo>
                    </Box>
                    <Box mr={4}>
                        <Link to={appNavigator.searchNearbyPlaces('list', undefined, true) as string}>
                            {/* <LocationOnIconButton isActive={pathname === '/nearby'} /> */}
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                <LocationOnIcon fontSize="small" className={/*pathname === '/nearby' ? classes.iconActive : */classes.iconInactive} />
                                <Typo fontFamily="secondary" weight="medium" variant="caption" className={classes.iconText}>NEARBY</Typo>
                            </Box>
                        </Link>
                    </Box>
                    {/* <Box mr={Status ? 1 : 0}>
                        {Status}
                    </Box> */}
                    {UserUI}
                </Box>
            </header>
            {AuthDialog}
            {SearchDialogComponent}
            {MenuComponent}
        </>
    )
}

export const APP_HEADER_HEIGHT = 70;

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        zIndex: theme.zIndex.appBar,
        padding: theme.spacing(0, 3),
        height: APP_HEADER_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        boxShadow: theme.shadows[2],
    },
    transparent: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    sticky: {
        position: 'sticky',
        top: 0,
    },
    tab: {
        font: `normal ${theme.typography.fontWeightMedium} 16px/12px ${FONT_FAMILY.secondary}`,
        color: THEME_PALETTE.grey[200],
    },
    tabActive: {
        font: `normal ${theme.typography.fontWeightMedium} 16px/12px ${FONT_FAMILY.secondary}`,
        color: THEME_PALETTE.primary.main,
    },
    iconInactive: {
        color: theme.palette.primary.main,
        fontSize: 20,
    },
    iconActive: {
        color: 'white',
    },
    iconText: {
        color: THEME_PALETTE.others.T4,
    },
}));

export default memo(AppHeader, () => true);