import axios, { AxiosRequestConfig } from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class usersBase {
    /**
     * Find a related item by id for roles.
     */
    static delete_user_account<T = any>(id: string, reason?: string) {
        return axios.request<T>({
            url: `/users/${id}/delete-account`,
            method: 'post',
            data: {
                reason,
            },
        });
    }

    /**
     * Find a related item by id for roles.
     */
    static get_users_id_roles_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/roles/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for roles.
     */
    static put_users_id_roles_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/roles/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for roles.
     */
    static delete_users_id_roles_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/roles/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Add a related item by id for roles.
     */
    static put_users_id_roles_rel_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/roles/rel/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Remove the roles relation to an item by id.
     */
    static delete_users_id_roles_rel_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/roles/rel/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for accessTokens.
     */
    static get_users_id_accessTokens_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/accessTokens/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for accessTokens.
     */
    static put_users_id_accessTokens_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/accessTokens/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for accessTokens.
     */
    static delete_users_id_accessTokens_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/accessTokens/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for pictures.
     */
    static get_users_id_pictures_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/pictures/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for pictures.
     */
    static put_users_id_pictures_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/pictures/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for pictures.
     */
    static delete_users_id_pictures_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/pictures/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Fetches hasOne relation profile_image.
     */
    static get_users_id_profile_image<T = any>(id: string, refresh?: any) {
        return axios.request<T>({
            url: `/users/${id}/profile_image`,
            method: 'get',
            params: {
                refresh,
            },
        });
    }

    /**
     * Creates a new instance in profile_image of this model.
     */
    static post_users_id_profile_image<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/profile_image`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Update profile_image of this model.
     */
    static put_users_id_profile_image<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/profile_image`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes profile_image of this model.
     */
    static delete_users_id_profile_image<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/profile_image`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for tags.
     */
    static get_users_id_tags_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/tags/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for tags.
     */
    static put_users_id_tags_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/tags/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for tags.
     */
    static delete_users_id_tags_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/tags/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Add a related item by id for tags.
     */
    static put_users_id_tags_rel_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/tags/rel/${fk}`,
            method: 'put',
        });
    }

    /**
     * Remove the tags relation to an item by id.
     */
    static delete_users_id_tags_rel_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/tags/rel/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Fetches belongsTo relation wallet.
     */
    static get_users_id_wallet<T = any>(id: string, refresh?: any) {
        return axios.request<T>({
            url: `/users/${id}/wallet`,
            method: 'get',
            params: {
                refresh,
            },
        });
    }

    /**
     * Find a related item by id for walletTransactions.
     */
    static get_users_id_walletTransactions_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/walletTransactions/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for walletTransactions.
     */
    static put_users_id_walletTransactions_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/walletTransactions/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for walletTransactions.
     */
    static delete_users_id_walletTransactions_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/walletTransactions/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for knowsAbouts.
     */
    static get_users_id_knowsAbouts_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/knowsAbouts/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for knowsAbouts.
     */
    static put_users_id_knowsAbouts_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/knowsAbouts/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for knowsAbouts.
     */
    static delete_users_id_knowsAbouts_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/knowsAbouts/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for roleMappings.
     */
    static get_users_id_roleMappings_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/roleMappings/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for roleMappings.
     */
    static put_users_id_roleMappings_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/roleMappings/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for roleMappings.
     */
    static delete_users_id_roleMappings_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/roleMappings/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for identities.
     */
    static get_users_id_identities_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/identities/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for identities.
     */
    static put_users_id_identities_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/identities/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for identities.
     */
    static delete_users_id_identities_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/identities/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for credentials.
     */
    static get_users_id_credentials_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/credentials/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for credentials.
     */
    static put_users_id_credentials_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/credentials/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for credentials.
     */
    static delete_users_id_credentials_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/credentials/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for favourites.
     */
    static get_users_id_favourites_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/favourites/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for favourites.
     */
    static put_users_id_favourites_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/favourites/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for favourites.
     */
    static delete_users_id_favourites_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/favourites/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for pins.
     */
    static get_users_id_pins_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/pins/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for pins.
     */
    static put_users_id_pins_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/pins/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for pins.
     */
    static delete_users_id_pins_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/pins/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for wishes.
     */
    static get_users_id_wishes_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/wishes/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for wishes.
     */
    static put_users_id_wishes_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/wishes/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for wishes.
     */
    static delete_users_id_wishes_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/wishes/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for visitedes.
     */
    static get_users_id_visitedes_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/visitedes/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for visitedes.
     */
    static put_users_id_visitedes_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/visitedes/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for visitedes.
     */
    static delete_users_id_visitedes_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/visitedes/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Find a related item by id for comments.
     */
    static get_users_id_comments_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/comments/${fk}`,
            method: 'get',
        });
    }

    /**
     * Update a related item by id for comments.
     */
    static put_users_id_comments_fk<T = any>(fk: string, id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/comments/${fk}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a related item by id for comments.
     */
    static delete_users_id_comments_fk<T = any>(fk: string, id: string) {
        return axios.request<T>({
            url: `/users/${id}/comments/${fk}`,
            method: 'delete',
        });
    }

    /**
     * Queries roles of user.
     */
    static get_users_id_roles<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/roles`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in roles of this model.
     */
    static post_users_id_roles<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/roles`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all roles of this model.
     */
    static delete_users_id_roles<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/roles`,
            method: 'delete',
        });
    }

    /**
     * Counts roles of user.
     */
    static get_users_id_roles_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/roles/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries accessTokens of user.
     */
    static get_users_id_accessTokens<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/accessTokens`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in accessTokens of this model.
     */
    static post_users_id_accessTokens<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/accessTokens`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all accessTokens of this model.
     */
    static delete_users_id_accessTokens<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/accessTokens`,
            method: 'delete',
        });
    }

    /**
     * Counts accessTokens of user.
     */
    static get_users_id_accessTokens_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/accessTokens/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries pictures of user.
     */
    static get_users_id_pictures<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/pictures`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in pictures of this model.
     */
    static post_users_id_pictures<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/pictures`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all pictures of this model.
     */
    static delete_users_id_pictures<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/pictures`,
            method: 'delete',
        });
    }

    /**
     * Counts pictures of user.
     */
    static get_users_id_pictures_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/pictures/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries tags of user.
     */
    static get_users_id_tags<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/tags`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in tags of this model.
     */
    static post_users_id_tags<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/tags`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all tags of this model.
     */
    static delete_users_id_tags<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/tags`,
            method: 'delete',
        });
    }

    /**
     * Counts tags of user.
     */
    static get_users_id_tags_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/tags/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries walletTransactions of user.
     */
    static get_users_id_walletTransactions<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/walletTransactions`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in walletTransactions of this model.
     */
    static post_users_id_walletTransactions<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/walletTransactions`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all walletTransactions of this model.
     */
    static delete_users_id_walletTransactions<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/walletTransactions`,
            method: 'delete',
        });
    }

    /**
     * Counts walletTransactions of user.
     */
    static get_users_id_walletTransactions_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/walletTransactions/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries knowsAbouts of user.
     */
    static get_users_id_knowsAbouts<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/knowsAbouts`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in knowsAbouts of this model.
     */
    static post_users_id_knowsAbouts<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/knowsAbouts`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all knowsAbouts of this model.
     */
    static delete_users_id_knowsAbouts<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/knowsAbouts`,
            method: 'delete',
        });
    }

    /**
     * Counts knowsAbouts of user.
     */
    static get_users_id_knowsAbouts_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/knowsAbouts/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries roleMappings of user.
     */
    static get_users_id_roleMappings<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/roleMappings`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in roleMappings of this model.
     */
    static post_users_id_roleMappings<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/roleMappings`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all roleMappings of this model.
     */
    static delete_users_id_roleMappings<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/roleMappings`,
            method: 'delete',
        });
    }

    /**
     * Counts roleMappings of user.
     */
    static get_users_id_roleMappings_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/roleMappings/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries identities of user.
     */
    static get_users_id_identities<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/identities`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in identities of this model.
     */
    static post_users_id_identities<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/identities`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all identities of this model.
     */
    static delete_users_id_identities<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/identities`,
            method: 'delete',
        });
    }

    /**
     * Counts identities of user.
     */
    static get_users_id_identities_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/identities/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries credentials of user.
     */
    static get_users_id_credentials<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/credentials`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in credentials of this model.
     */
    static post_users_id_credentials<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/credentials`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all credentials of this model.
     */
    static delete_users_id_credentials<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/credentials`,
            method: 'delete',
        });
    }

    /**
     * Counts credentials of user.
     */
    static get_users_id_credentials_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/credentials/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries favourites of user.
     */
    static get_users_id_favourites<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/favourites`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in favourites of this model.
     */
    static post_users_id_favourites<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/favourites`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all favourites of this model.
     */
    static delete_users_id_favourites<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/favourites`,
            method: 'delete',
        });
    }

    /**
     * Counts favourites of user.
     */
    static get_users_id_favourites_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/favourites/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries pins of user.
     */
    static get_users_id_pins<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/pins`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in pins of this model.
     */
    static post_users_id_pins<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/pins`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all pins of this model.
     */
    static delete_users_id_pins<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/pins`,
            method: 'delete',
        });
    }

    /**
     * Counts pins of user.
     */
    static get_users_id_pins_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/pins/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries wishes of user.
     */
    static get_users_id_wishes<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/wishes`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in wishes of this model.
     */
    static post_users_id_wishes<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/wishes`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all wishes of this model.
     */
    static delete_users_id_wishes<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/wishes`,
            method: 'delete',
        });
    }

    /**
     * Counts wishes of user.
     */
    static get_users_id_wishes_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/wishes/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries visitedes of user.
     */
    static get_users_id_visitedes<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/visitedes`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in visitedes of this model.
     */
    static post_users_id_visitedes<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/visitedes`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all visitedes of this model.
     */
    static delete_users_id_visitedes<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/visitedes`,
            method: 'delete',
        });
    }

    /**
     * Counts visitedes of user.
     */
    static get_users_id_visitedes_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/visitedes/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Queries comments of user.
     */
    static get_users_id_comments<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}/comments`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Creates a new instance in comments of this model.
     */
    static post_users_id_comments<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/comments`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Deletes all comments of this model.
     */
    static delete_users_id_comments<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/comments`,
            method: 'delete',
        });
    }

    /**
     * Counts comments of user.
     */
    static get_users_id_comments_count<T = any>(id: string, where?: any) {
        return axios.request<T>({
            url: `/users/${id}/comments/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     */
    static get_users<T = any>(filter?: any) {
        return axios.request<T>({
            url: `/users`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     */
    static post_users<T = any>(data?: any) {
        return axios.request<T>({
            url: `/users`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     */
    static put_users<T = any>(data?: any) {
        return axios.request<T>({
            url: `/users`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     */
    static patch_users<T = any>(data?: any) {
        return axios.request<T>({
            url: `/users`,
            method: 'patch',

            data: {
                ...data,
            },
        });
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     */
    static patch_users_autoRenewal<T = any>(enable: boolean) {
        return axios.request<T>({
            url: `/users/auto-renewal`,
            method: 'patch',
            params: { enable },
        });
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     */
    static post_users_replaceOrCreate<T = any>(data?: any) {
        return axios.request<T>({
            url: `/users/replaceOrCreate`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     */
    static post_users_upsertWithWhere<T = any>(where?: any, data?: any) {
        return axios.request<T>({
            url: `/users/upsertWithWhere`,
            method: 'post',
            params: {
                where,
            },
            data: {
                ...data,
            },
        });
    }

    /**
     * Check whether a model instance exists in the data source.
     */
    static get_users_id_exists<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/exists`,
            method: 'get',
        });
    }

    /**
     * Find a model instance by {{id}} from the data source.
     */
    static get_users_id<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/users/${id}`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     */
    static put_users_id<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}`,
            method: 'put',

            data: {
                ...data,
            },
        });
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     */
    static patch_users_id<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}`,
            method: 'patch',

            data: {
                ...data,
            },
        });
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     */
    static delete_users_id<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}`,
            method: 'delete',
        });
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     */
    static post_users_id_replace<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/replace`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     */
    static get_users_findOne<T = any>(filter?: any) {
        return axios.request<T>({
            url: `/users/findOne`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     */
    static post_users_update<T = any>(where?: any, data?: any) {
        return axios.request<T>({
            url: `/users/update`,
            method: 'post',
            params: {
                where,
            },
            data: {
                ...data,
            },
        });
    }

    /**
     * Count instances of the model matched by where from the data source.
     */
    static get_users_count<T = any>(where?: any) {
        return axios.request<T>({
            url: `/users/count`,
            method: 'get',
            params: {
                where,
            },
        });
    }

    /**
     * Create a change stream.
     */
    static get_users_change_stream<T = any>(options?: any) {
        return axios.request<T>({
            url: `/users/change-stream`,
            method: 'get',
            params: {
                options,
            },
        });
    }

    /**
     * Create a change stream.
     */
    static post_users_change_stream<T = any>(options?: any) {
        return axios.request<T>({
            url: `/users/change-stream`,
            method: 'post',

            data: {
                options,
            },
        });
    }

    /**
     * Login a user with username/email and password.
     */
    static post_users_login<T = any>(credentials: any, include?: any) {
        return axios.request<T>({
            url: `/users/login`,
            method: 'post',
            params: {
                include,
            },
            data: {
                ...credentials,
            },
        });
    }

    /**
     * Logout a user with access token.
     */
    static post_users_logout<T = any>() {
        return axios.request<T>({
            url: `/users/logout`,
            method: 'post',
        });
    }

    /**
     * Trigger user's identity verification with configured verifyOptions
     */
    static post_users_id_verify<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/verify`,
            method: 'post',
        });
    }

    /**
     * Confirm a user registration with identity verification token.
     */
    static get_users_confirm<T = any>(token: any, uid: any, redirect?: any) {
        return axios.request<T>({
            url: `/users/confirm`,
            method: 'get',
            params: {
                token,
                uid,
                redirect,
            },
        });
    }

    /**
     * Reset password for a user with email.
     */
    static post_users_reset<T = any>(options: any) {
        return axios.request<T>({
            url: `/users/reset`,
            method: 'post',

            data: {
                ...options,
            },
        });
    }

    /**
     * Change a user's password.
     */
    static post_users_change_password<T = any>(newPassword: any, oldPassword: any) {
        return axios.request<T>({
            url: `/users/change-password`,
            method: 'post',

            data: {
                newPassword,
                oldPassword,
            },
        });
    }

    /**
     * Reset user's password via a password-reset token.
     */
    static post_users_reset_password<T = any>(newPassword: any) {
        return axios.request<T>({
            url: `/users/reset-password`,
            method: 'post',

            data: {
                newPassword,
            },
        });
    }

    /**
     * check if user is registered by email
     */
    static get_users_is_registered<T = any>(email: any) {
        return axios.request<T>({
            url: `/users/is-registered`,
            method: 'get',
            params: {
                email,
            },
        });
    }

    /**
     * signup
     */
    static post_users_signupAuth<T = any>(data?: any) {
        return axios.request<T>({
            url: `/users/signupAuth`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Update Phone
     */
    static post_users_updatePhone<T = any>(phone: any, countryCode?: any) {
        return axios.request<T>({
            url: `/users/updatePhone`,
            method: 'post',

            data: {
                phone,
                countryCode,
            },
        });
    }

    /**
     *
     */
    static post_users_updatePhoneWithToken<T = any>(token: any, phone: any, countryCode?: any) {
        return axios.request<T>({
            url: `/users/updatePhoneWithToken`,
            method: 'post',

            data: {
                token,
                phone,
                countryCode,
            },
        });
    }

    /**
     * Update Phone
     */
    static post_users_updateEmail<T = any>(email: any) {
        return axios.request<T>({
            url: `/users/updateEmail`,
            method: 'post',

            data: {
                email,
            },
        });
    }

    /**
     *
     */
    static get_users_updateEmailWithToken<T = any>(token: any, email: any) {
        return axios.request<T>({
            url: `/users/updateEmailWithToken`,
            method: 'get',
            params: {
                token,
                email,
            },
        });
    }

    /**
     * Update Password
     */
    static post_users_updatePassword<T = any>(oldPassword?: any, newPassword?: any, defaultOTPVia?: any) {
        return axios.request<T>({
            url: `/users/updatePassword`,
            method: 'post',

            data: {
                oldPassword,
                newPassword,
                defaultOTPVia,
            },
        });
    }

    /**
     *
     */
    static post_users_updatePasswordWithToken<T = any>(token: any, newPassword: any, oldPassword?: any) {
        return axios.request<T>({
            url: `/users/updatePasswordWithToken`,
            method: 'post',

            data: {
                token,
                newPassword,
                oldPassword,
            },
        });
    }

    /**
     * Send token
     */
    static post_users_sendToken<T = any>(data?: any) {
        return axios.request<T>({
            url: `/users/sendToken`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Verifies token
     */
    static post_users_verifyToken<T = any>(data?: any) {
        return axios.request<T>({
            url: `/users/verifyToken`,
            method: 'post',

            data: {
                ...data,
            },
        });
    }

    /**
     * Generates CSV for User Model
     */
    static get_users_generateCSV<T = any>(filter?: any) {
        return axios.request<T>({
            url: `/users/generateCSV`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     * Verifies token
     */
    static get_users_verifyLink<T = any>(idType: any, token: any, uniqueId: any) {
        return axios.request<T>({
            url: `/users/verifyLink`,
            method: 'get',
            params: {
                idType,
                token,
                uniqueId,
            },
        });
    }

    /**
     * Updates sensitive data
     */
    static post_users_updateSensitiveData<T = any>(token: any, data: any) {
        return axios.request<T>({
            url: `/users/updateSensitiveData`,
            method: 'post',

            data: {
                token,
                data,
            },
        });
    }

    /**
     * handleForgetPasswordRequest
     */
    static post_users_forget_password<T = any>(email: any, redirectUrl?: any) {
        return axios.request<T>({
            url: `/users/forget-password`,
            method: 'post',

            data: {
                email,
                redirectUrl,
            },
        });
    }

    /**
     * Resend verification request.
     */
    static post_users_resend_verification<T = any>(email: any, redirectUrl?: any) {
        return axios.request<T>({
            url: `/users/resend-verification`,
            method: 'post',

            data: {
                email,
                redirectUrl,
            },
        });
    }

    /**
     * Register a new user
     */
    static post_users_signup<T = any>(redirectUrl: any, user: any) {
        return axios.request<T>({
            url: `/users/signup`,
            method: 'post',

            data: {
                redirectUrl,
                user,
            },
        });
    }

    /**
     *
     */
    static get_users_verify_email<T = any>(token: any, email: any, redirectUrl?: any) {
        return axios.request<T>({
            url: `/users/verify-email`,
            method: 'get',
            params: {
                token,
                email,
                redirectUrl,
            },
        });
    }

    /**
     *
     */
    static post_users_verify_email<T = any>(token: any, email: any) {
        return axios.request<T>({
            url: `/users/verify-email`,
            method: 'post',

            data: {
                token,
                email,
            },
        });
    }

    /**
     *
     */
    static get_users_admin_autocomplete<T = any>(term?: any, filter?: any) {
        return axios.request<T>({
            url: `/users/admin-autocomplete`,
            method: 'get',
            params: {
                term,
                filter,
            },
        });
    }

    /**
     *
     */
    static get_users_autocomplete<T = any>(term?: any, filter?: any) {
        return axios.request<T>({
            url: `/users/autocomplete`,
            method: 'get',
            params: {
                term,
                filter,
            },
        });
    }

    /**
     * Get user refer code
     */
    static get_users_id_refer_code<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/refer-code`,
            method: 'get',
        });
    }

    /**
     * Update a user.
     */
    static patch_users_id_update_user<T = any>(id: string, data?: any) {
        return axios.request<T>({
            url: `/users/${id}/update-user`,
            method: 'patch',

            data: {
                ...data,
            },
        });
    }

    /**
     * Get profile.
     */
    static get_users_profile<T = any>(id?: string) {
        return axios.request<T>({
            url: `/users/profile`,
            method: 'get',
            params: {
                id,
            },
        });
    }

    /**
     * Get my profile.
     */
    static get_users_me<T = any>(destinationSubscriptionFilter?: any) {
        return axios.request<T>({
            url: `/users/me`,
            method: 'get',
            params: {
                destinationSubscriptionFilter,
            },
        });
    }

    /**
     * Get own profile.
     */
    static post_users_user_login<T = any>(user?: any) {
        return axios.request<T>({
            url: `/users/user-login`,
            method: 'post',

            data: {
                ...user,
            },
        });
    }

    /**
     * details
     */
    static get_users_my_subscription<T = any>() {
        return axios.request<T>({
            url: `/users/my-subscription`,
            method: 'get',
        });
    }

    /**
     * details
     */
    static get_users_id_details<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/details`,
            method: 'get',
        });
    }

    /**
     * suggestions
     */
    static get_users_id_suggestions<T = any>(id: string, filter?: any, axiosConfig?: AxiosRequestConfig) {
        return axios.request<T>({
            ...axiosConfig,
            url: `/users/${id}/suggestions`,
            method: 'get',
            params: {
                filter,
            },
        });
    }

    /**
     *
     */
    static patch_users_cancel_subscription<T = any>(cancellationReason?: any) {
        return axios.request<T>({
            url: `/users/cancel-subscription`,
            method: 'patch',

            data: {
                cancellationReason,
            },
        });
    }

    static patch_users_pause_subscription<T = any>(pause: boolean) {
        return axios.request<T>({
            url: `/users/pause-subscription`,
            method: 'patch',

            data: {
                pause,
            },
        });
    }

    /**
     *
     */
    static get_users_generate_csv<T = any>(email: any) {
        return axios.request<T>({
            url: `/users/generate-csv`,
            method: 'get',
            params: {
                email,
            },
        });
    }

    /**
     *
     */
    static patch_users_id_change_cards<T = any>(cardData: any, id: string) {
        return axios.request<T>({
            url: `/users/${id}/change-cards`,
            method: 'patch',

            data: {
                cardData,
            },
        });
    }

    /**
     *
     */
    static get_users_id_saved_cards<T = any>(id: string) {
        return axios.request<T>({
            url: `/users/${id}/saved-cards`,
            method: 'get',
        });
    }

    /**
     *
     */
    static post_users_support<T = any>(message?: any) {
        return axios.request<T>({
            url: `/users/support`,
            method: 'post',

            data: {
                message,
            },
        });
    }

    /**
     *
     */
    static get_users_userId_get_comments<T = any>(userId: any, filter?: any) {
        return axios.request<T>({
            url: `/users/${userId}/get-comments`,
            method: 'get',
            params: {
                filter,
            },
        });
    }
}

export default usersBase;
