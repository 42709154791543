import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import usePasswordField from 'Components/Input/usePasswordField';
import { FormConfig, IFormActionProps } from 'react-forms';
import { FONT_FAMILY, THEME_PALETTE } from 'Theme/themeConstants';
import * as Yup from 'yup';





export const useFormConfig = () => {
    const classes = useStyles();
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email is required'),
        password: Yup.string().min(6).required('Password is required'),
        name: Yup.string().required('Name is required')
    })

    const actionConfig: IFormActionProps = {
        submitButtonText: 'SUBMIT',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large'
        },
        containerClassNames: classes.buttonContainer
    };

    const passwordField = usePasswordField('password', 'Password');


    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: 'text',
            valueKey: 'email',
            id: 'signup-email',
            fieldProps: {
                label: 'Email',
                fullWidth: true,
            }
        },
        {
            type: 'text',
            id: 'signup-name',
            valueKey: 'name',
            fieldProps: {
                label: 'Name',
                fullWidth: true,
            }
        },
        passwordField,
        // {
        //     type: 'checkbox',
        //     valueKey: 'newsletter',
        //     id: 'newsletter-subscribe',
        //     fieldProps: {
        //         label: 'Be the first to hear about new destination guides and email-exclusive offers',
        //         value: 'subscribe-to-newletters',
        //         formControlLabelProps: {
        //             classes: {
        //                 label: classes.newsletter,
        //             },
        //         },
        //     },
        // },
    ];


    return { actionConfig, inputConfig, validationSchema }
}




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            '& >button': {
                border: `1px solid ${THEME_PALETTE.primary.main}`,
                [theme.breakpoints.up('sm')]: {
                    padding: '14px 28px',
                    font: `normal ${theme.typography.fontWeightMedium} 18px/18px ${FONT_FAMILY.secondary}`,
                },
                [theme.breakpoints.down('sm')]: {
                    margin: '15px 0px 0px',
                    padding: '6px 16px',
                    font: `normal ${theme.typography.fontWeightMedium} 14px/25px ${FONT_FAMILY.secondary}`,
                },
            },
        },
        newsletter: {
            // color: THEME_PALETTE.grey[600],
            fontSize: 12,
            fontWeight: theme.typography.fontWeightMedium,
            // width: "70%",
            // marginTop: "15px",
        },
    })
);