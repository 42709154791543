import React from 'react';
import Lottie from 'react-lottie';

interface LottieImageProps {
    lotteJSON: any
    loop?: boolean
    dimension?: {
        width: string,
        height: string
    }
    style?: React.CSSProperties
}

const LottieImage: React.FC<LottieImageProps> = (props) => {
    const { dimension = { width: '100%', height: '250px' }, loop = true, style = {} } = props

    const defaultOptions = {
        loop,
        autoplay: true,
        animationData: props.lotteJSON,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <Lottie options={defaultOptions}
            height={dimension.height}
            width={dimension.width}
            style={style}
        />
    )
}


export default LottieImage