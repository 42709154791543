import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton, IconButtonProps } from '@material-ui/core';
import { Close } from '@material-ui/icons'
import clsx from 'clsx';

export interface CloseIconButtonProps extends Pick<IconButtonProps, 'onClick' | 'className'> {
    bordered?: boolean
}

const CloseIconButton: FC<CloseIconButtonProps> = ({ bordered, onClick, className }) => {
    const classes = useStyles({});

    return (
        <IconButton onClick={onClick} className={clsx(classes.root, className, {
            [classes.bordered]: !!bordered
        })}>
            <Close />
        </IconButton>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {

    },
    bordered: {
        border: `1px solid #D9D9D9`
    }
}));

export default CloseIconButton;