import React, { FC, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { TSearchResultItem } from './@types';
import clsx from 'clsx';
import Typo from 'Components/Typo';
import { TPlace } from 'Models/Place/@types';
import { TCuisine } from 'Models/Cuisine/@types';
import { TCity } from 'Models/City/@types';
import { TDish } from 'Models/Dish/@types';
import { TTag } from 'Models/Tag/@types';
import TagImage from '../../Assets/images/icons/tag_white.svg';
import { GRADIENTS } from 'Theme/themeConstants';
import textHighlight from 'Utils/textHighlight';
import appNavigator from 'Features/Navigation/appNavigator';
import Query from 'Features/Query';
import { Link } from 'react-router-dom';
import { TUser } from 'Models/User/@types';
import { TLandmark } from 'Models/Landmark/@types';
import { TExplore } from 'Models/Explore/@types';
import helpers from 'Utils/helpers';
import PlaceModel from 'Models/Place';
import { useStoreState } from 'Stores';
import UserModel from 'Models/User';
import CityModel from 'Models/City';
import { TEatinerary } from 'Models/Eatinerary/@type';


export interface SearchResultItemProps {
    item: TSearchResultItem
    searchText?: string
    className?: string
    closeDialog?: () => void
    handleLockedPlaceClick?: () => void
}


type SearchResponse<T> = T & {
    highlight: Record<string, string[]>
}
const SearchResultItem: FC<SearchResultItemProps> = (props) => {
    const { className, item, searchText = '', closeDialog, handleLockedPlaceClick } = props;
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))
    const classes = useStyles({});

    const isPlaceLocked = useMemo(() => UserModel.isAccessLimited(appUser), [item, appUser])


    const handleClick = () => {

        if (isPlaceLocked && item.doc_type === 'Establishment') {
            handleLockedPlaceClick?.();
            return;
        }

        closeDialog?.();
    }


    const data = useMemo<{
        name: string,
        imageUrl: string,
        subtitle: string[],
        route: string
        description?: string
    }>(() => {
        let imageUrl = '';
        let route = '';
        let subtitle: string[] = [];
        if (item.doc_type === 'Establishment') {
            const entity = item as SearchResponse<TPlace>;
            imageUrl = helpers.getPictureUrl(entity?._pictures?.[0], 300, 300) || ''
            route = isPlaceLocked ? '#' : appNavigator.navigateToPlace(entity.slug || entity.id, true) as string
            // subtitle.push('Place');
            entity.types?.forEach(t => subtitle.push(t));
            if (entity?.destination?.name)
                subtitle.push(entity?.destination?.name)
        }
        else if (item.doc_type === 'Cuisine') {
            const entity = item as SearchResponse<TCuisine>;
            imageUrl = helpers.getPictureUrl(entity?._pictures?.[0], 300, 300) || ''
            route = appNavigator.navigateToCuisine(entity.slug || entity.id, undefined, true) as string
            subtitle.push('Cuisine')
        }
        else if (item.doc_type === 'Destination') {
            const entity = item as SearchResponse<TCity>;
            imageUrl = helpers.getPictureUrl(CityModel.getPortraitImage(entity), 300, 300) || ''
            route = appNavigator.navigateToCity(entity.slug || entity.id, true) as string
            subtitle.push('City')
        }
        else if (item.doc_type === 'FoodDrink') {
            const entity = item as SearchResponse<TDish>;
            imageUrl = helpers.getPictureUrl(entity?._pictures?.[0], 300, 300) || ''
            route = appNavigator.navigateToDish(entity.slug || entity.id, undefined, true) as string
            subtitle.push('Dish')
        }
        else if (item.doc_type === 'Tag') {
            const entity = item as SearchResponse<TTag>;
            imageUrl = TagImage
            const q = new Query({ tagIds: [entity.id] })
            route = appNavigator.searchPlaces(q.getQueryString, true) as string
            subtitle.push('Tag')
        }
        else if (item.doc_type === 'user') {
            const entity = item as SearchResponse<TUser>;
            imageUrl = helpers.getPictureUrl(entity?._profile_image || undefined, 300, 300) || ''
            route = appNavigator.navigateToProfile(entity.slug || entity.id, true) as string
            subtitle.push('Member')
        }
        else if (item.doc_type === 'Landmark') {
            const entity = item as SearchResponse<TLandmark>;
            imageUrl = helpers.getPictureUrl(entity?._pictures?.[0], 300, 300) || ''
            route = appNavigator.navigateToLandmark(entity.slug || entity.id, true) as string
            subtitle.push('Landmark')
            if (entity.destination)
                subtitle.push(entity.destination.name)
        }
        else if (item.doc_type === 'List') {
            const entity = item as SearchResponse<TExplore>;
            imageUrl = helpers.getPictureUrl(entity?._pictures?.[0], 300, 300) || ''
            route = appNavigator.navigateToExplore(entity.slug || entity.id, true) as string
            subtitle.push('Explore')
            if (entity.destination)
                subtitle.push(entity.destination.name)
        }
        else if (item.doc_type === 'Eateries') {
            const entity = item as unknown as SearchResponse<TEatinerary>;
            imageUrl = helpers.getPictureUrl(entity?._pictures?.[0], 300, 300) || ''
            route = appNavigator.navigateToEatineries(entity.slug || entity.id, true) as string
            subtitle.push('Eatinerary')
        }
        return {
            route,
            imageUrl,
            name: item.name,
            subtitle,
            description: item.highlight?.shortDescription?.[0]
        }
    }, [item, isPlaceLocked])




    const isRoundedImage = useMemo(() => {
        if (item.doc_type === 'Tag' || item.doc_type === 'FoodDrink')
            return true;
        return false;
    }, [item])


    return (
        <Link onClick={handleClick} to={data.route}>
            <Box className={clsx(classes.root, className)}>
                <img src={data.imageUrl} className={clsx(classes.image, { [classes.roundImage]: isRoundedImage, [classes.padding]: item.doc_type === 'Tag' })} />
                <Box ml={1} display='flex' flexDirection='column'>
                    <Typo
                        fontFamily='secondary' weight='medium' className={classes.title}
                        dangerouslySetInnerHTML={{ __html: textHighlight({ searchWords: [searchText], textToHighlight: data.name }) }}
                    />
                    <Typo fontFamily='secondary'
                        color='textSecondary' weight='medium' className={classes.subtitle}
                        dangerouslySetInnerHTML={{ __html: data.subtitle.join('<span></span>') }}
                    />
                    {data.description ? (
                        <Typo fontFamily='secondary'
                            color='textSecondary' weight='medium' className={classes.description}
                            dangerouslySetInnerHTML={{ __html: data.description }}
                        />
                    ) : null}
                </Box>
            </Box>
        </Link>
    )
}


const CARD_HEIGHT = 72;
const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        minHeight: CARD_HEIGHT,
        display: 'flex',
        // alignItems: 'center'
    },
    image: {
        flexShrink: 0,
        background: GRADIENTS.primary,
        borderRadius: theme.shape.borderRadius,
        width: CARD_HEIGHT,
        height: CARD_HEIGHT,
        objectFit: 'cover'
    },
    roundImage: {
        borderRadius: CARD_HEIGHT / 2
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            marginTop: 15
        }
        // fontSize: 16,
    },
    padding: {
        padding: 20,
    },
    subtitle: {
        display: 'flex !important',
        flexWrap: 'wrap',
        alignItems: 'center',
        fontSize: 12,
        '& > span': {
            width: 3,
            height: 3,
            margin: `0px 5px`,
            display: 'inline-block',
            borderRadius: 1.5,
            backgroundColor: '#4D4D4D',
        },

    },
    description: {
        fontSize: 12,
        '& > span': {
            width: 3,
            height: 3,
            margin: `0px 5px`,
            display: 'inline-block',
            borderRadius: 1.5,
            backgroundColor: '#4D4D4D',
        },
        '& em': {
            backgroundColor: '#FF625026',
            margin: '0px 2px'
        }
    }

}));

export default SearchResultItem;