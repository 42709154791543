import Axios, { CancelTokenSource } from "axios";
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import Query from "Features/Query";
import EstablishmentQuery from "Features/Query/EstablishmentQuery";
import { TSearchResult } from "Features/Search/@types";
import { TCuisine } from "Models/Cuisine/@types";
import DishModel from "Models/Dish";
import { TDish } from "Models/Dish/@types";
import { TPlace } from "Models/Place/@types";
import UserModel from "Models/User";
import { TRootStore } from "Stores";

export interface TDishDetailScreenState {
    dish: TDish | undefined
    places: TPlace[]
    cuisines: TCuisine[]
    facetCityBuckets: { key: string, doc_count: number }[]
    query: EstablishmentQuery
    loading: boolean
    loadingPlaces: boolean
    setQuery: Action<TDishDetailScreenState, EstablishmentQuery>
    setFacetCityBuckets: Action<TDishDetailScreenState, TDishDetailScreenState['facetCityBuckets']>
    setCuisines: Action<TDishDetailScreenState, TCuisine[]>
    setLoading: Action<TDishDetailScreenState, boolean>
    setLoadingPlaces: Action<TDishDetailScreenState, boolean>
    setDish: Action<TDishDetailScreenState, TDish | undefined>
    setPlaces: Action<TDishDetailScreenState, TPlace[]>
    fetchPageData: Thunk<TDishDetailScreenState, string, {}, TRootStore>
    fetchDishPlaces: Thunk<TDishDetailScreenState, { dishId: string, query: EstablishmentQuery }, {}, TRootStore>

    onQueryChange: ThunkOn<TDishDetailScreenState, {}, TRootStore>
}


let cancelToken: CancelTokenSource | undefined = undefined;

const DishDetailScreenStore: TDishDetailScreenState = {
    dish: undefined,
    places: [],
    cuisines: [],
    facetCityBuckets: [],
    loading: false,
    query: new EstablishmentQuery({}),

    loadingPlaces: false,
    setDish: action((state, payload) => {
        state.dish = payload
    }),
    setFacetCityBuckets: action((state, payload) => {
        state.facetCityBuckets = payload
    }),
    setPlaces: action((state, payload) => {
        state.places = payload
    }),
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    setLoadingPlaces: action((state, payload) => {
        state.loadingPlaces = payload
    }),
    setQuery: action((state, payload) => {
        state.query = payload
    }),
    setCuisines: action((state, payload) => {
        state.cuisines = payload
    }),
    fetchDishPlaces: thunk(async (actions, { dishId, query }) => {
        actions.setLoadingPlaces(true);
        if (cancelToken) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = Axios.CancelToken.source();

        const { data } = await DishModel.get_FoodDrinks_id_establishments<TPlace[]>(dishId, {
            include: ["cuisines", "tags", {
                relation: 'destination',
                scope: {
                    include: {
                        relation: 'destinationPack',
                    },
                },
            },],
            limit: 20,
            ...query.getSearchFilter
        }, { cancelToken: cancelToken.token })


        actions.setLoadingPlaces(false);
        actions.setPlaces(data);
    }),
    fetchPageData: thunk(async (actions, dishId) => {
        actions.setLoading(true);
        actions.fetchDishPlaces({ dishId, query: new EstablishmentQuery({}) });
        // const { data: cuisines } = await CuisineModel.get_Cuisines_filterBy<TCuisine[]>(dishId, 'FoodDrink')
        const { data } = await DishModel.get_FoodDrinks_id<TDish>(dishId, {
            include: ["cuisines"]
        });
        const { data: facets } = await UserModel.get_users_autocomplete<TSearchResult>('', {
            where: {
                doc_type: "EstablishmentFoodDrink",
                foodDrinkId: data.id,
            },
            limit: 1
        });
        actions.setFacetCityBuckets(facets.aggregations?.placeDestination?.buckets || [])
        actions.setCuisines(data.cuisines || []);
        actions.setDish(data);
        actions.setLoading(false);
    }),


    // LISTENERS
    onQueryChange: thunkOn(
        (actions) => actions.setQuery,
        async (actions, { payload }, { getState }) => {
            const state = getState();
            if (!state.dish) return;
            actions.fetchDishPlaces({ query: payload, dishId: state.dish.id })
        }
    ),


};

export default DishDetailScreenStore;

