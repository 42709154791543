import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import { FONT_FAMILY, THEME_PALETTE } from 'Theme/themeConstants';
import * as Yup from 'yup';
import { LoginFormProps } from '.';
import usePasswordField from '../../../Components/Input/usePasswordField';





export const useFormConfig = (props: LoginFormProps) => {
    const classes = useStyles();
    const actionConfig: IFormActionProps = {
        submitButtonText: 'SUBMIT',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large',
        },
        containerClassNames: classes.buttonContainer,
    };

    const passwordField = usePasswordField('password', 'Password', true);
    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: 'text',
            valueKey: 'email',
            id: 'login-email',
            fieldProps: {
                label: 'Email',
                fullWidth: true,
            }
        },
        passwordField,
    ];
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email is required'),
        password: Yup.string().required('Password is required')
    })

    return {
        actionConfig,
        inputConfig,
        validationSchema,
    }
}


const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        buttonContainer: {
            '& >button': {
                border: `1px solid ${THEME_PALETTE.primary.main}`,
                [theme.breakpoints.up('sm')]: {
                    padding: '14px 28px',
                    font: `normal ${theme.typography.fontWeightMedium} 18px/18px ${FONT_FAMILY.secondary}`,
                },
                [theme.breakpoints.down('sm')]: {
                    margin: '15px 0px 0px',
                    padding: "6px 16px",
                    font: `normal ${theme.typography.fontWeightMedium} 14px/25px ${FONT_FAMILY.secondary}`,
                },
            },
        },
    })
);