import { Box, CircularProgress, DialogProps, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DialogWrapper from '..';
import Typo from '../../Components/Typo';
import EmailVerificationForm, { EmailVerificationFormData } from '../../Forms/EmailVerification';
import Loader from 'Components/Loader';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { useStoreState } from '../../Stores';
import LoginPage from 'Features/Authentication/LoginPage';
import CloseIconButton from 'Components/Buttons/CloseIconButton';
import SignUpPage from 'Features/Authentication/SignUpPage';
import OtpPage from 'Features/Auth/OtpPage';
import Onboarding from 'Features/Onboarding';
import TagPreferenceSelectorPage from 'Features/Tag/TagPreferenceSelectorPage';
import { useGlobalStyle } from 'Constants/classes';
import GRAPHIC from '../../Assets/animated/autorickshaw.json';
import LottieImage from 'Components/LottieImage';
import { OtpFormData } from 'Forms/OtpForm';
import { Backdrop } from '@material-ui/core';


export type AuthType = 'login' | 'signup' | 'check' | 'otp' | 'onboarding' | 'interest'


export interface AuthDialogProps extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
    type: AuthType;
    strictMode?: boolean;
    onSubmit: Function;
    handleCheck: (value: string) => Promise<boolean>;
    onTypeChange?: (type: AuthType) => void;
    onForgotPassword?: (email: string) => void;
    errorMessage?: string;
    onClose?: () => void;
    children?: (StepperComponent?: JSX.Element, handleNext?: () => void) => JSX.Element | JSX.Element[];
    hasCloseBtn?: boolean;
}

const AuthDialog: React.FC<AuthDialogProps> = (props) => {
    const { children, type, onTypeChange, hasCloseBtn = true, strictMode = false, handleCheck: _handleCheck, onForgotPassword, onSubmit, errorMessage, ...dialogProps } = props;
    const { isDeviceSm } = useMediaQuery();
    const [val, setVal] = useState('');
    const [loading, setLoading] = useState(false);

    const classes = useStyles()
    let TITLE = ''
    if (type === 'login') TITLE = 'You are already in our system.\nWelcome back!';
    else if (type === 'check') TITLE = 'Welcome to Mamakoo';
    else TITLE = 'Create an account';

    const setAuthType = (type: AuthType) => {
        if (onTypeChange)
            onTypeChange(type)
    }

    const handleCheck = async (data: EmailVerificationFormData) => {
        setVal(data.email);
        try {
            const isRegistered = await props.handleCheck(data.email || '')
            if (isRegistered) setAuthType('login')
            else setAuthType('signup')
        } catch (error) {

        }
    }

    const handleOtpInput = async (data: OtpFormData) => {
        try {
            await props.onSubmit?.(data);
        } catch (error) {

        }
    }

    const otpHandler = useAsyncTask(handleOtpInput);
    const checkHandler = useAsyncTask(handleCheck)

    const closeDialog = () => {
        if (dialogProps.onClose)
            dialogProps.onClose()
    }


    const restartFlow = () => {
        setAuthType('check');
    }

    const handleForgotPassword = () => {
        if (!val || !onForgotPassword) return;
        onForgotPassword(val);
    }

    const AuthComponent = (type: AuthType, StepperComponent?: JSX.Element, handleNext?: () => void) => {
            const AuthComponentRecord: Record<AuthType, JSX.Element> = {
                login: (
                    <LoginPage
                        isInsideDialog
                        onBack={restartFlow}
                        openSignup={() => setAuthType('signup')}
                        onSubmit={async (...args) => {
                            setLoading(true);
                            await onSubmit(...args)
                                .then((e) => {
                                    if (e) handleNext?.();
                                })
                                .catch(() => {
                                    setLoading(false);
                                });
                            setLoading(false);
                        }}
                        StepperComponent={StepperComponent}
                    />
                ),
                check: (
                    <Box px={5} mt={4} pb={4}>
                        <EmailVerificationForm
                            onSubmit={checkHandler.run}
                            isSubmitting={checkHandler.status === 'PROCESSING'}
                            initialData={{ email: val }}
                        />
                        {
                            isDeviceSm ? (
                                <div className={classes.img}>
                                    <LottieImage lotteJSON={GRAPHIC} dimension={{ width: '100vw', height: 'unset' }} />
                                </div>
                            ) : null
                            // <img src={GRAPHIC} alt='login-image' className={classes.img} /> : null
                        }
                    </Box>
                ),
                otp: (
                    <OtpPage
                        initialData={{ email: val }}
                        onSubmit={otpHandler.run}
                        isSubmitting={otpHandler.status === 'PROCESSING'}
                    />
                ),
                signup: (
                    <SignUpPage
                        isInsideDialog
                        onBack={restartFlow}
                        initialData={{ email: val, newsletter: true }}
                        openLogin={() => setAuthType('login')}
                        onSubmit={async (...args) => {
                            setLoading(true);
                            try {
                                await onSubmit(...args).then((e) => {
                                    if (e) handleNext?.();
                                });
                            } catch (error) {
                                
                            }
                            setLoading(false);
                        }}
                        StepperComponent={StepperComponent}
                    />
                ),
                onboarding: <Onboarding onDone={() => onSubmit()} />,
                interest: <TagPreferenceSelectorPage onDone={() => onSubmit()} />,
            };

            return AuthComponentRecord[type];
        }


    const shouldShowCloseIcon = useMemo(() => {
        if (type === 'otp' || type === 'onboarding' || type === 'interest' || strictMode) return false;
        return true;
    }, [type, strictMode]);

    const numOfSteps = React.Children.count(children?.()) + 1;

    return (
        <>
            <DialogWrapper
                {...dialogProps}
                fullScreen={isDeviceSm}
                disableBackdropClick
                scroll="body"
                hasStepper
                numOfSteps={numOfSteps}
            >
                {(Stepper, handleNext, currentStep) => {
                    const nextStep = () => {
                        if (currentStep === numOfSteps - 1) {
                            closeDialog();
                        }
                        handleNext();
                    };
                    return (
                        <>
                            <div className={classes.container}>
                                {/* {loading && !isDeviceSm &&
                                <CircularProgress />
                            } */}

                                <Box height={isDeviceSm ? '100%' : undefined} mx="auto" position="relative">
                                    {!shouldShowCloseIcon ? null : (
                                        <Box
                                            pr={1}
                                            mt={1}
                                            display="flex"
                                            justifyContent="flex-end"
                                            position="absolute"
                                            width={'100%'}
                                            className={classes.closeIconBtn}
                                        >
                                            {hasCloseBtn && <CloseIconButton onClick={closeDialog} />}
                                        </Box>
                                    )}
                                    <Box
                                        className={shouldShowCloseIcon || type === 'check' ? classes.content : ''}
                                        pb={0}
                                    >
                                        {type === 'check' ? (
                                            <Typo gutterBottom className={classes.title}>
                                                {TITLE}
                                            </Typo>
                                        ) : null}
                                        {currentStep === 0
                                            ? AuthComponent(type, Stepper(), nextStep)
                                            : React.Children.toArray(
                                                  typeof children === 'function'
                                                      ? children(Stepper(), nextStep)
                                                      : children
                                              )[currentStep - 1]}
                                    </Box>
                                </Box>
                            </div>
                        </>
                    );
                }}
            </DialogWrapper>
            <DialogWrapper open={loading} fullScreen disableBackdropClick scroll="body">
                <Backdrop open={true} style={{ zIndex: 10000 }}>
                    <Loader fullScreen overlayed /> 
                </Backdrop>
            </DialogWrapper>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            overflowY: 'clip',
            [theme.breakpoints.down('sm')]: {
                height: '100vh',
                overflowY: 'auto',
            },
        },
        closeIconBtn: {
            '& >button': {
                padding: '5px',
                [theme.breakpoints.down('sm')]: {
                    backgroundColor: `#4d4d4d80`,
                    '& svg': {
                        color: 'white',
                    },
                },
            },
        },
        content: {
            [theme.breakpoints.down('sm')]: {
                // paddingTop: theme.spacing(5)
            },
        },
        img: {
            width: 100,
            position: 'absolute',
            bottom: 0,
            left: 0,
            objectFits: 'cover',
        },
        closeButton: {
            color: theme.palette.primary['contrastText'],
            position: 'absolute',
            top: 16,
            right: 16,
        },
        title: {
            ...theme.typography.h6,
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                ...theme.typography.h5,
            },
        },
        outlinedIconButton: {
            // border: `1px solid ${theme.palette.text.primary}`,
        },
    })
);

export default AuthDialog;