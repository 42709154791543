import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import { THEME_PALETTE } from 'Theme/themeConstants';

const useStepper = (numOfSteps: number ) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if (activeStep < numOfSteps - 1) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = Array.from({ length: numOfSteps }, (v, i) => i);

    const StepperComponent = () => {
        return (
            <div className={classes.root}>
                <Stepper alternativeLabel activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <QontoConnector />
                        </Step>
                    ))}
                </Stepper>
            </div>
        );
    }

    return {
        StepperComponent,
        handleNext,
        handleBack,
        currentStep: activeStep,
    };
};


const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(0px)',
        right: 'calc(0px)',
    },
    active: {
        '& $line': {
            borderColor: `${THEME_PALETTE.primary.main} !important`,
        },
    },
    completed: {
        '& $line': {
            borderColor: `${THEME_PALETTE.primary.main} !important`,
        },
    },
    line: {
        borderColor: `${THEME_PALETTE.others.Pearl} !important`,
        borderTopWidth: 4,
        borderRadius: 0,
    },
    

})(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            "& >*": {
                backgroundColor: "transparent",
            },
            '& .MuiStepConnector-line': {
                borderColor: 'transparent',
            },
            '& .MuiStep-horizontal:first-child': {
                '& .MuiStepConnector-line ': {
                    borderBottomLeftRadius: 2,
                    borderTopLeftRadius: 2,
                },
            },
            '& .MuiStep-horizontal:last-child': {
                '& .MuiStepConnector-line ': {
                    borderBottomRightRadius: 2,
                    borderTopRightRadius: 2,
                },
            },
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    })
);

export default useStepper;