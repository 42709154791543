import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import reduxActionMapping from "../Analytics/actionMapping";


const analyticsMap: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
    next(action);
    reduxActionMapping(action, store);
    return;
}




export default analyticsMap