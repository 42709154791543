import { useAnalyticsAction } from "Analytics/analyticActions";
import { AxiosResponse } from "axios";
import { GooglePlaceRecommendFormData } from "Features/Google/GooglePlaceRecommendCard";
import appNavigator from "Features/Navigation/appNavigator";
import { SupportFormData } from "Forms/Support";
import useToastMessage from "Hooks/useToastMessage";
import PlaceModel from "Models/Place";
import TagCategoryModel from "Models/TagCategory";
import UserModel from "Models/User";
import { TKnowsAbout, TUser } from "Models/User/@types";
import { useStoreActions, useStoreState } from "Stores";




function useUserActions() {
    const { appUser, tagCategories, cities, selectedPack } = useStoreState(({ AuthStore: { appUser }, App: { tagCategories, cities }, SubscriptionStore: { selectedPack } }) => ({ appUser, tagCategories, cities, selectedPack }))
    const { setAppUser } = useStoreActions(({ AuthStore: { setAppUser } }) => ({ setAppUser }))
    const withToast = useToastMessage();
    const { dispatchUserEvent } = useAnalyticsAction();


    const isTrailOver = async (packId: string) => {
        if (!appUser) return false;
        const { data } = await UserModel.isTrailRedeemed(appUser.id, packId);
        return data;
    }


    const updateUser = async (userData: Partial<TUser>, successMessage?: string) => {
        if (!appUser) return;
        try {
            // dispatchUserEvent({eventName: 'UPDATE_USER_DETAIL', ...userData})
            const { data } = await withToast(async () => await UserModel.patch_users_id_update_user<TUser>(appUser.id, userData), {
                successToastMessage: successMessage || 'Profile updated successfully'
            }) as AxiosResponse<TUser>;
            setAppUser({ ...data })
        } catch (error) {
            throw error;
        }
    }

    const sendFeedback = async (data: SupportFormData) => {
        if (!appUser) return;
        try {
            dispatchUserEvent({ eventName: 'SEND_FEEDBACK', ...data })
            await withToast(async () => {
                await UserModel.post_users_support(data.message);
            }, {
                successToastMessage: `Thank you for your feedback!`
            })
        } catch (error) {
            throw error;
        }
    }


    const sendResetPasswordLink = async (email: string) => {
        try {
            dispatchUserEvent({ eventName: 'SEND_RESET_PASSWORD_LINK' })
            await withToast(async () => {
                const { data } = await UserModel.post_users_forget_password(email, window.location.origin);

            }, {
                successToastMessage: `Password reset link sent to ${email}`
            })
        } catch (error) {

        }
    }

    const changePassword = async (password: string) => {
        try {
            dispatchUserEvent({ eventName: 'CHANGE_PASSWORD' })
            await withToast(async () => {
                const { data } = await UserModel.post_users_reset_password(password);
                appNavigator.navigateToHome();
            }, {
                successToastMessage: 'Password updated successfully'
            })
        } catch (error) {

        }
    }


    const addKnowsAbout = async (formData: Record<string, any>) => {
        if (!appUser) return;
        const _knowsAbouts = (appUser?._knowsAbouts || [])
        _knowsAbouts.push(formData as TKnowsAbout);
        await updateUser({ ...appUser, _knowsAbouts })
        dispatchUserEvent({
            eventName: 'ADD KNOWS ABOUT CITY',
            destinationId: formData.destinationId,
            startDate: formData.startDate,
            endDate: formData.endDate,
            how: formData.how,
            destinationName: cities.find(c => c.id === formData.destinationId)?.name
        })
    }

    const removeKnowsAbout = async (knowsAbout: TKnowsAbout) => {
        if (!appUser) return;
        const _knowsAbouts = (appUser._knowsAbouts || []).filter((k) => k.id !== knowsAbout.id);
        await updateUser({ ...appUser, _knowsAbouts });

        dispatchUserEvent({
            eventName: 'REMOVE KNOWS ABOUT CITY',
            destinationId: knowsAbout.destinationId,
            destinationName: cities.find(c => c.id === knowsAbout.destinationId)?.name
        })
    }






    return {
        updateUser,
        sendResetPasswordLink,
        changePassword,
        sendFeedback,
        addKnowsAbout,
        removeKnowsAbout,
        isTrailOver
    }
}


export default useUserActions;