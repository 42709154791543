import React, { FC, useCallback, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import useTagSelector from './useTagSelector';
import Accordion, { AccordionProps } from 'Components/Accordion';
import Typo from 'Components/Typo';
import { TTag } from 'Models/Tag/@types';
import SubmitButton from 'Components/SubmitButton';
import Loader from 'Components/Loader';
import useAsyncTask from 'Hooks/useAsyncTask';
import useUserActions from 'Features/User/useUserActions';
import { useStoreState } from 'Stores';
import { DIVIDER_BORDER } from 'Theme/themeConstants';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import TagCategoryModel from 'Models/TagCategory';
import { DIALOG_CONTENT_WIDTH } from 'Constants/dimension';
import { useAnalyticsAction } from 'Analytics/analyticActions';

export interface TagPreferenceSelectorPageProps {
    onDone?: () => void;
}

const TagPreferenceSelectorPage: FC<TagPreferenceSelectorPageProps> = (props) => {
    const classes = useStyles({});
    const { dispatchUserEvent } = useAnalyticsAction();
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))
    const { selectedTagIds, tagCategories, toggleSelect } = useTagSelector(appUser?.tagIds || []);
    const { updateUser } = useUserActions();
    const { isDeviceSm } = useMediaQuery();

    const interestSaveHandler = useAsyncTask<string[]>(async (_tagIds) => {
        try {
            await updateUser({ tagIds: selectedTagIds }, "Interests saved");
            interestUpdateAnalyticAction(selectedTagIds)
            props.onDone?.();
        } catch (error) {

        }
    })

    const interestUpdateAnalyticAction = (ids: string[]) => {
        const tags: { name: string, id: string }[] = [];
        tagCategories.forEach(tc => {
            tc?.tags?.forEach(t => {
                if (ids.includes(t.id))
                    tags.push({ name: t.name, id: t.id })
            })
        })
        dispatchUserEvent({ eventName: 'USER_INTEREST_UPDATED', tags })
    }


    const getTitle = useCallback((title: string) => (
        <Typo>{title}</Typo>
    ), [])

    const getBody = useCallback((tags: TTag[]) => (
        <Box display='flex' flexDirection='column'>
            {tags.map(t => (
                <FormControlLabel
                    key={t.id}
                    control={<Checkbox classes={{ checked: classes.checkbox }} checked={selectedTagIds.includes(t.id)} onChange={e => toggleSelect(t.id)} name="tagIds" />}
                    label={<Typo weight='medium' variant='subtitle2'>{t.name}</Typo>}
                />
            ))}
        </Box>
    ), [selectedTagIds])


    const options = useMemo<AccordionProps['options']>(() => {
        return tagCategories.filter(c => c.tags.length).map(c => ({ title: getTitle(c.name), body: getBody(c.tags || []) }))
    }, [tagCategories, selectedTagIds])






    return tagCategories.length ? (
        <Box className={classes.root}>
            <Box p={3} display='flex' flexDirection='column' >
                <Typo variant='h5' align='center' color='primary' weight='medium' className={classes.title}>Hi {appUser?.name}</Typo>
                <Typo weight='medium' align='center' fontFamily='secondary' className={classes.subtitle}>
                    Select at least 3 interests <br />
                    you can follow.
                </Typo>
                <Box borderBottom={DIVIDER_BORDER} mt={2} />
            </Box>
            <Box p={2}>
                <Accordion options={options} />
            </Box>
            <Box className={classes.saveButton}>
                <SubmitButton
                    onClick={() => interestSaveHandler.run(selectedTagIds)}
                    size='large'
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={selectedTagIds.length < 3}
                    loading={interestSaveHandler.status === 'PROCESSING'}
                >
                    SAVE
                </SubmitButton>
            </Box>
        </Box>
    ) : <Loader fullScreen={isDeviceSm} />
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        // maxHeight: '70vh',
        // overflow: 'auto',
        width: DIALOG_CONTENT_WIDTH,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxHeight: '100vh',
            height: '100vh'
        }
    },
    checkbox: {
        backgroundColor: 'transparent',
        '& svg': {
            color: theme.palette.primary.main
        }
    },
    saveButton: {
        display: 'flex',
        justifyContent: 'center',

        position: 'sticky',
        bottom: 0,
        '& > button': {
            borderRadius: 0,
        },
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            width: '100%'
        }
    },

    title: {

    },
    subtitle: {

    }
}));

export default TagPreferenceSelectorPage;