import { useAnalyticsAction } from "Analytics/analyticActions";
import useAsyncTask from "Hooks/useAsyncTask";
import { debounce } from "lodash";
import UserModel from "Models/User";
import { useCallback, useState } from "react";
import { TSearchResult, TSearchResultItem } from "./@types";


const SEARCH_FILTER_QUERY_BOOST = {
    "should": [
        [
            {
                "match": {
                    "doc_type": {
                        "query": "Destination",
                        "boost": 2430
                    }
                }
            },
            {
                "match": {
                    "doc_type": {
                        "query": "Establishment",
                        "boost": 810
                    }
                }
            },
            {
                "match": {
                    "doc_type": {
                        "query": "Cuisine",
                        "boost": 270
                    }
                }
            },
            {
                "match": {
                    "doc_type": {
                        "query": "Dish",
                        "boost": 90
                    }
                }
            },
            {
                "match": {
                    "doc_type": {
                        "query": "Explore",
                        "boost": 30
                    }
                }
            },
            {
                "match": {
                    "doc_type": {
                        "query": "Landmark",
                        "boost": 10
                    }
                }
            }
        ]
    ]
}

function useSearch() {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState<TSearchResultItem[]>([]);
    const [loading, setLoading] = useState(false);
    const { dispatchUserEvent } = useAnalyticsAction();
    // const searchHandler = useAsyncTask()

    const debouncedSearch = debounce(async (val: string) => {
        setLoading(true);
        if (val) {
            dispatchUserEvent({ eventName: 'SEARCH', searchText: val })
            // const { data } = await UserModel.get_users_autocomplete<TSearchResult>(val, {
            const { data } = await UserModel.get_users_autocomplete<any>(val, {
                ...SEARCH_FILTER_QUERY_BOOST,
                "doc_type": [
                    "Destination",
                    "FoodDrink",
                    "Cuisine",
                    "Establishment",
                    "Tag",
                    "User",
                    "Landmark",
                    "List",
                    "Eateries"
                ],
                limit: 30
            })
            setSuggestions(data?.results || []);
        } else
            setSuggestions([]);
        setLoading(false);
    }, 300)

    const handleChange = useCallback((newVal: string) => {
        setValue(newVal)
        debouncedSearch(newVal)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])






    return {
        value,
        loading,
        handleChange,
        suggestions,
    }

}

export default useSearch;