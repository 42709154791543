import { Avatar, Button, Box, useTheme } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import helpers from 'Utils/helpers';
import { useStoreActions, useStoreState } from 'Stores';
import { useAuthDialog } from 'Dialogs/Authentication/useAuthDialog';
import { useAuth } from 'Features/Auth/useAuth';
import AppModelModel from 'Models/AppModel';
import UserModel from 'Models/User';
import Menu from 'Components/Menu';
import appNavigator from 'Features/Navigation/appNavigator';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import Spacer from 'Components/Spacer';
import BadgeTag from 'Components/BadgeTag';
import Typo from 'Components/Typo';
import { THEME_PALETTE } from 'Theme/themeConstants';

type Config = {
    shouldHideLoginBtn?: boolean;
    shouldHideSignupBtn?: boolean;
};
function useAvatarMenu(config?: Config) {
    // REDUX STUFF
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const classes = useStyles({});
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const { isDeviceSm } = useMediaQuery();
    const { userLogin, userSignup, isRegistered, submitOtp, userLogout } = useAuth();
    const { openSignupDialog, openLoginDialog, AuthComponent } = useAuthDialog({
        onLogin: async (data) => {
            await userLogin(data);
            // window.location.reload();
        },
        onSignup: userSignup,
        handleCheck: isRegistered,
        onOtpSubmit: submitOtp,
    });
    const theme = useTheme();

    const closeMenu = () => setMenuAnchorEl(null);

    const handleAvatarClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.persist();
        if (!appUser) return;
        if (isDeviceSm) {
            appNavigator.navigateToProfile(appUser?.slug || appUser?.id);
        } else setMenuAnchorEl((el) => (el ? null : e.currentTarget));
    };

    const data = useMemo(
        () => ({
            name: UserModel.getFirstName(appUser),
            subscriptionName: appUser ? UserModel.getMembershipName(appUser) : '',
        }),
        [appUser]
    );

    const UiComponent = useMemo(
        () =>
            appUser ? (
                isDeviceSm ? (
                    <Avatar
                        onClick={handleAvatarClick}
                        src={UserModel.getAvatarImageUrl(appUser)}
                        className={classes.root}
                    />
                ) : (
                    // <Button onClick={openAuthDialog} variant='outlined' color='primary'>SIGN IN</Button>
                    <Box display="inline" position="relative">
                        <Avatar
                            onClick={handleAvatarClick}
                            src={UserModel.getAvatarImageUrl(appUser)}
                            className={classes.avatar}
                        />
                        {/* <Box
                            onClick={handleAvatarClick}
                            display="flex"
                            alignItems="center"
                            position="absolute"
                            bottom={-4}
                            left="50%"
                            className={classes.badgeBox}
                        >
                            <BadgeTag size='medium' text={data.subscriptionName.toUpperCase()} textColor='white' bgColor={theme.palette.secondary.main} /> 
                            <Typo
                                fontFamily="secondary"
                                weight="medium"
                                variant="caption"
                                contrast
                                className={classes.badge}
                            >
                                {data.subscriptionName.toUpperCase()}
                            </Typo>
                        </Box> */}
                    </Box>
                )
            ) : (
                <>
                    {config?.shouldHideSignupBtn ? null : (
                        <>
                            <Button
                                onClick={() => openSignupDialog()}
                                variant="contained"
                                color="primary"
                                className={classes.btn}
                            >
                                SIGN UP
                            </Button>
                            <Spacer width={8} />
                        </>
                    )}
                    {config?.shouldHideLoginBtn ? null : (
                        <Button
                            onClick={() => openLoginDialog()}
                            variant="outlined"
                            color="primary"
                            className={classes.btn}
                        >
                            LOG IN
                        </Button>
                    )}
                </>
            ),
        [
            appUser,
            classes.avatar,
            classes.btn,
            classes.root,
            handleAvatarClick,
            isDeviceSm,
            openLoginDialog,
            openSignupDialog,
        ]
    );

    const OPTIONS = [
        {
            onClick: () => {
                if (!appUser) return;
                closeMenu();
                appNavigator.navigateToProfile(appUser?.slug || appUser?.id);
            },
            label: 'Profile',
        },
        {
            onClick: () => {
                userLogout();
                closeMenu();
            },
            label: 'Logout',
        },
    ];

    return {
        UiComponent,
        AuthComponent: <AuthComponent />,
        MenuComponent: <Menu id="user-menu" anchorEl={menuAnchorEl} handleClose={closeMenu} options={OPTIONS} />,
    };
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        width: 44,
        cursor: 'pointer',
        height: 44,
        border: `1px solid white`,
        boxShadow: theme.shadows[1],
    },
    avatar: {
        // width: 30,
        // height: 30,
        cursor: 'pointer',
        border: `1px solid white`,
        boxShadow: theme.shadows[1],
        // top: -8,
    },
    btn: {
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    badgeBox: {
        transform: `translateX(-50%)`,
        cursor: 'pointer',
    },
    badge: {
        backgroundColor: THEME_PALETTE.primary.main,
        lineHeight: 1,
        padding: '1px 2px 2px',
    },
}));

export default useAvatarMenu;
