
import axios, { AxiosRequestConfig } from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class FoodDrinksBase {

    /** 
     * Find a related item by id for pictures.
     */
    static get_FoodDrinks_id_pictures_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/pictures/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for pictures.
     */
    static put_FoodDrinks_id_pictures_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/pictures/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for pictures.
     */
    static delete_FoodDrinks_id_pictures_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/pictures/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for tags.
     */
    static get_FoodDrinks_id_tags_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for tags.
     */
    static put_FoodDrinks_id_tags_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for tags.
     */
    static delete_FoodDrinks_id_tags_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for tags.
     */
    static put_FoodDrinks_id_tags_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags/rel/${fk}`,
            method: 'put',


        })
    }

    /** 
     * Remove the tags relation to an item by id.
     */
    static delete_FoodDrinks_id_tags_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for establishments.
     */
    static get_FoodDrinks_id_establishments_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/establishments/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for establishments.
     */
    static put_FoodDrinks_id_establishments_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/establishments/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for establishments.
     */
    static delete_FoodDrinks_id_establishments_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/establishments/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for establishments.
     */
    static put_FoodDrinks_id_establishments_rel_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/establishments/rel/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Remove the establishments relation to an item by id.
     */
    static delete_FoodDrinks_id_establishments_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/establishments/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for cuisines.
     */
    static get_FoodDrinks_id_cuisines_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for cuisines.
     */
    static put_FoodDrinks_id_cuisines_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for cuisines.
     */
    static delete_FoodDrinks_id_cuisines_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for cuisines.
     */
    static put_FoodDrinks_id_cuisines_rel_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines/rel/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Remove the cuisines relation to an item by id.
     */
    static delete_FoodDrinks_id_cuisines_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for destinations.
     */
    static get_FoodDrinks_id_destinations_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for destinations.
     */
    static put_FoodDrinks_id_destinations_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for destinations.
     */
    static delete_FoodDrinks_id_destinations_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for destinations.
     */
    static put_FoodDrinks_id_destinations_rel_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations/rel/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Remove the destinations relation to an item by id.
     */
    static delete_FoodDrinks_id_destinations_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Queries pictures of FoodDrink.
     */
    static get_FoodDrinks_id_pictures<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/pictures`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in pictures of this model.
     */
    static post_FoodDrinks_id_pictures<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/pictures`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all pictures of this model.
     */
    static delete_FoodDrinks_id_pictures<T = any>(id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/pictures`,
            method: 'delete',


        })
    }

    /** 
     * Counts pictures of FoodDrink.
     */
    static get_FoodDrinks_id_pictures_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/pictures/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries tags of FoodDrink.
     */
    static get_FoodDrinks_id_tags<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in tags of this model.
     */
    static post_FoodDrinks_id_tags<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all tags of this model.
     */
    static delete_FoodDrinks_id_tags<T = any>(id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags`,
            method: 'delete',


        })
    }

    /** 
     * Counts tags of FoodDrink.
     */
    static get_FoodDrinks_id_tags_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/tags/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries establishments of FoodDrink.
     */
    static get_FoodDrinks_id_establishments<T = any>(id: string, filter?: any, axiosConfig?: AxiosRequestConfig) {
        return axios.request<T>({
            ...axiosConfig,
            url: `/FoodDrinks/${id}/establishments`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in establishments of this model.
     */
    static post_FoodDrinks_id_establishments<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/establishments`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all establishments of this model.
     */
    static delete_FoodDrinks_id_establishments<T = any>(id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/establishments`,
            method: 'delete',


        })
    }

    /** 
     * Counts establishments of FoodDrink.
     */
    static get_FoodDrinks_id_establishments_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/establishments/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries cuisines of FoodDrink.
     */
    static get_FoodDrinks_id_cuisines<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in cuisines of this model.
     */
    static post_FoodDrinks_id_cuisines<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all cuisines of this model.
     */
    static delete_FoodDrinks_id_cuisines<T = any>(id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines`,
            method: 'delete',


        })
    }

    /** 
     * Counts cuisines of FoodDrink.
     */
    static get_FoodDrinks_id_cuisines_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/cuisines/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries destinations of FoodDrink.
     */
    static get_FoodDrinks_id_destinations<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in destinations of this model.
     */
    static post_FoodDrinks_id_destinations<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all destinations of this model.
     */
    static delete_FoodDrinks_id_destinations<T = any>(id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations`,
            method: 'delete',


        })
    }

    /** 
     * Counts destinations of FoodDrink.
     */
    static get_FoodDrinks_id_destinations_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/destinations/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Find all instances of the model matched by filter from the data source.
     */
    static get_FoodDrinks<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Create a new instance of the model and persist it into the data source.
     */
    static post_FoodDrinks<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Replace an existing model instance or insert a new one into the data source.
     */
    static put_FoodDrinks<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Patch an existing model instance or insert a new one into the data source.
     */
    static patch_FoodDrinks<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks`,
            method: 'patch',

            data: {
                ...data
            },
        })
    }

    /** 
     * Replace an existing model instance or insert a new one into the data source.
     */
    static post_FoodDrinks_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/replaceOrCreate`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     */
    static post_FoodDrinks_upsertWithWhere<T = any>(where?: any, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

    /** 
     * Check whether a model instance exists in the data source.
     */
    static get_FoodDrinks_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/exists`,
            method: 'get',


        })
    }

    /** 
     * Find a model instance by {{id}} from the data source.
     */
    static get_FoodDrinks_id<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Replace attributes for a model instance and persist it into the data source.
     */
    static put_FoodDrinks_id<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Patch attributes for a model instance and persist it into the data source.
     */
    static patch_FoodDrinks_id<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}`,
            method: 'patch',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a model instance by {{id}} from the data source.
     */
    static delete_FoodDrinks_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}`,
            method: 'delete',


        })
    }

    /** 
     * Replace attributes for a model instance and persist it into the data source.
     */
    static post_FoodDrinks_id_replace<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/replace`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Find first instance of the model matched by filter from the data source.
     */
    static get_FoodDrinks_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/findOne`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Update instances of the model matched by {{where}} from the data source.
     */
    static post_FoodDrinks_update<T = any>(where?: any, data?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

    /** 
     * Count instances of the model matched by where from the data source.
     */
    static get_FoodDrinks_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Create a change stream.
     */
    static get_FoodDrinks_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/change-stream`,
            method: 'get',
            params: {
                options
            },

        })
    }

    /** 
     * Create a change stream.
     */
    static post_FoodDrinks_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/change-stream`,
            method: 'post',

            data: {
                options
            },
        })
    }

    /** 
     * Filter FoodDrink
     */
    static get_FoodDrinks_filter<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/FoodDrinks/filter`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * details
     */
    static get_FoodDrinks_id_details<T = any>(id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/details`,
            method: 'get',


        })
    }

    /** 
     * Publish entity.
     */
    static post_FoodDrinks_id_publish<T = any>(publish: any, id: string,) {
        return axios.request<T>({
            url: `/FoodDrinks/${id}/publish`,
            method: 'post',

            data: {
                publish
            },
        })
    }

}

export default FoodDrinksBase;
