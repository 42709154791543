import axios from 'axios';
import { TTag } from './@types';
import Tags from 'BaseModels/Tags'

class TagModel extends Tags {

    
}

export default TagModel;
