import qs from 'qs';
import { HeaderFilter } from "Features/Header/FilterHeader";
import isEqual from 'lodash/isEqual'
import { TLocation } from 'Models/@types';


export type TEstablishmentQuery = {
    cityId?: string
    mamakooRating?: number
    dishId?: string
    range?: number[]
    cuisineId?: string
    tagId?: string
    bookWeeksAhead?: boolean;
};

export type TPaginationEstablishmentQuery = {
    page?: number
}

class EstablishmentQuery {
    _query: TEstablishmentQuery = {}
    _pageNation: TPaginationEstablishmentQuery = {
        page: 1
    }
    constructor(query: TEstablishmentQuery, pagination?: TPaginationEstablishmentQuery) {
        this._query = query
        this._pageNation = pagination ?? { page: 1 }
    }

    static getLocationFilter(location: TLocation) {
        return {
            "sort": [
                {
                    "_geo_distance": {
                        "location": {
                            "lat": location.lat,
                            "lon": location.lng
                        },
                        "order": "asc",
                        "unit": "km",
                        "distance_type": "plane"
                    }
                }
            ]
        }
    }

    get query() {
        return this._query;
    }

    static fromQuery(query: string) {
        let { page, ...data } = qs.parse(query, { ignoreQueryPrefix: true }) as TEstablishmentQuery & TPaginationEstablishmentQuery;
        if (data.mamakooRating)
            data.mamakooRating = Number(data.mamakooRating)
        return new EstablishmentQuery({ ...data, range: data.range?.map(d => Number(d)) }, { page: Number(page) || 1 })
    }

    get getSearchFilter() {
        let _filter: Record<string, any> = {
            where: {}
        };

        const { cityId, range, cuisineId, tagId, dishId, mamakooRating, bookWeeksAhead } = this._query;
        if (cityId)
            _filter.where.destinationId = { inq: [cityId] }
        if (tagId)
            _filter.where.tagIds = { inq: [tagId] }
        if (range)
            _filter.where.range = { inq: range }
        if (mamakooRating)
            _filter.where.mamakooRating = { inq: [mamakooRating] }
        if (dishId)
            _filter.fooddrinks = [dishId]
        if (cuisineId)
            _filter.cuisines = [cuisineId]
        if (bookWeeksAhead)
            _filter.where.bookWeeksAhead = true
        return _filter
    }

    get paginationQuery() {
        return {
            limit: 30,
            skip: 30 * ((this._pageNation.page || 1) - 1),
        }
    }

    isSame(q: EstablishmentQuery) {
        if (!isEqual(this._query, q._query))
            return false;
        if (!isEqual(this._pageNation, q._pageNation))
            return false;
        return true
    }

    clone(query: TEstablishmentQuery, pagination?: TPaginationEstablishmentQuery) {
        return new EstablishmentQuery({
            mamakooRating: query.mamakooRating ?? this._query.mamakooRating,
            cityId: query.cityId,
            cuisineId: query.cuisineId ?? this._query.cuisineId,
            dishId: query.dishId ?? this._query.dishId,
            range: query.range,
            tagId: query.tagId ?? this._query.tagId
        }, pagination)
    }

    get getQueryString() {

        return qs.stringify({ ...this._query, ...this._pageNation })
    }



}

export default EstablishmentQuery;