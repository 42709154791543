import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { TTag } from 'Models/Tag/@types';
import Accordion, { AccordionProps } from 'Components/Accordion';
import { useStoreActions, useStoreState } from 'Stores';
import Typo from 'Components/Typo';
import useAsyncTask from 'Hooks/useAsyncTask';
import Loader from 'Components/Loader';
import TagCard from './TagCard';
import { intersection } from 'lodash';

export interface TagAccordionProps {
    onClick?: (tag: TTag) => void
    tagIds?: string[]
}

const TagAccordion: FC<TagAccordionProps> = ({ onClick, tagIds }) => {
    const classes = useStyles({});
    const { tagCategories } = useStoreState(({ App: { tagCategories } }) => ({ tagCategories }))
    const { fetchTagCategories } = useStoreActions(({ App: { fetchTagCategories } }) => ({ fetchTagCategories }))

    const tagCategoriesFetchHandler = useAsyncTask(fetchTagCategories);

    const handleTagClick = (tag: TTag) => () => {
        onClick?.(tag);
    }


    useEffect(() => {
        if (tagCategories.length) return;
        tagCategoriesFetchHandler.run();
    }, []);

    const getTitle = useCallback((title: string, _tagIds: string[]) => {
        return <Typo variant='caption' weight='medium'>{title.toUpperCase()}</Typo>
        // return <Typo variant='caption' weight='medium'>{title.toUpperCase()} {tagIds && tagIds.length ? ` - ${intersection(tagIds, _tagIds).length}` : ''}</Typo>
    }, [])


    const getBody = useCallback((tags: TTag[]) => (
        <Box display='flex' flexWrap='wrap'>
            {tags.map(t => (tagIds && !tagIds.includes(t.id)) ? null : (
                <Box m={0.5} key={t.id} >
                    <TagCard onClicked={handleTagClick(t)} tag={t} />
                </Box>
            ))}
        </Box>
    ), [])



    const options = useMemo<AccordionProps['options']>(() => {
        return tagCategories.filter(c => tagIds?.length ? intersection(c.tagIds, tagIds).length : true).filter(c => c.tags.length).map(c => ({ title: getTitle(c.name, c.tagIds), body: getBody(c.tags || []) }))
    }, [tagCategories])

    return tagCategoriesFetchHandler.status === 'PROCESSING' ? <Loader /> : (
        <Accordion options={options} />
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default TagAccordion;