
import axios, { AxiosRequestConfig } from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class EstablishmentsBase {

    /** 
     * Find a related item by id for pictures.
     */
    static get_Establishments_id_pictures_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/pictures/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for pictures.
     */
    static put_Establishments_id_pictures_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/pictures/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for pictures.
     */
    static delete_Establishments_id_pictures_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/pictures/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Fetches belongsTo relation destination.
     */
    static get_Establishments_id_destination<T = any>(id: string, refresh?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/destination`,
            method: 'get',
            params: {
                refresh
            },

        })
    }

    /** 
     * Find a related item by id for similarEstablishments.
     */
    static get_Establishments_id_similarEstablishments_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for similarEstablishments.
     */
    static put_Establishments_id_similarEstablishments_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for similarEstablishments.
     */
    static delete_Establishments_id_similarEstablishments_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for similarEstablishments.
     */
    static put_Establishments_id_similarEstablishments_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments/rel/${fk}`,
            method: 'put',


        })
    }

    /** 
     * Remove the similarEstablishments relation to an item by id.
     */
    static delete_Establishments_id_similarEstablishments_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for createdBy.
     */
    static get_Establishments_id_createdBy_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for createdBy.
     */
    static put_Establishments_id_createdBy_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for createdBy.
     */
    static delete_Establishments_id_createdBy_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for createdBy.
     */
    static put_Establishments_id_createdBy_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy/rel/${fk}`,
            method: 'put',


        })
    }

    /** 
     * Remove the createdBy relation to an item by id.
     */
    static delete_Establishments_id_createdBy_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for primarySuggesters.
     */
    static get_Establishments_id_primarySuggesters_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for primarySuggesters.
     */
    static put_Establishments_id_primarySuggesters_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for primarySuggesters.
     */
    static delete_Establishments_id_primarySuggesters_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for primarySuggesters.
     */
    static put_Establishments_id_primarySuggesters_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters/rel/${fk}`,
            method: 'put',


        })
    }

    /** 
     * Remove the primarySuggesters relation to an item by id.
     */
    static delete_Establishments_id_primarySuggesters_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Fetches hasOne relation openingDays.
     */
    static get_Establishments_id_openingDays<T = any>(id: string, refresh?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/openingDays`,
            method: 'get',
            params: {
                refresh
            },

        })
    }

    /** 
     * Creates a new instance in openingDays of this model.
     */
    static post_Establishments_id_openingDays<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/openingDays`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Update openingDays of this model.
     */
    static put_Establishments_id_openingDays<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/openingDays`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes openingDays of this model.
     */
    static delete_Establishments_id_openingDays<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/openingDays`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for tags.
     */
    static get_Establishments_id_tags_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for tags.
     */
    static put_Establishments_id_tags_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for tags.
     */
    static delete_Establishments_id_tags_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for tags.
     */
    static put_Establishments_id_tags_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags/rel/${fk}`,
            method: 'put',


        })
    }

    /** 
     * Remove the tags relation to an item by id.
     */
    static delete_Establishments_id_tags_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for foodDrinks.
     */
    static get_Establishments_id_foodDrinks_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for foodDrinks.
     */
    static put_Establishments_id_foodDrinks_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for foodDrinks.
     */
    static delete_Establishments_id_foodDrinks_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for foodDrinks.
     */
    static put_Establishments_id_foodDrinks_rel_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks/rel/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Remove the foodDrinks relation to an item by id.
     */
    static delete_Establishments_id_foodDrinks_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for cuisines.
     */
    static get_Establishments_id_cuisines_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for cuisines.
     */
    static put_Establishments_id_cuisines_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for cuisines.
     */
    static delete_Establishments_id_cuisines_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for cuisines.
     */
    static put_Establishments_id_cuisines_rel_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines/rel/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Remove the cuisines relation to an item by id.
     */
    static delete_Establishments_id_cuisines_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for suggestions.
     */
    static get_Establishments_id_suggestions_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/suggestions/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for suggestions.
     */
    static put_Establishments_id_suggestions_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/suggestions/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for suggestions.
     */
    static delete_Establishments_id_suggestions_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/suggestions/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for favourites.
     */
    static get_Establishments_id_favourites_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/favourites/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for favourites.
     */
    static put_Establishments_id_favourites_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/favourites/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for favourites.
     */
    static delete_Establishments_id_favourites_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/favourites/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for wishes.
     */
    static get_Establishments_id_wishes_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/wishes/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for wishes.
     */
    static put_Establishments_id_wishes_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/wishes/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for wishes.
     */
    static delete_Establishments_id_wishes_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/wishes/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for visitedes.
     */
    static get_Establishments_id_visitedes_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/visitedes/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for visitedes.
     */
    static put_Establishments_id_visitedes_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/visitedes/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for visitedes.
     */
    static delete_Establishments_id_visitedes_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/visitedes/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for comments.
     */
    static get_Establishments_id_comments_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/comments/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for comments.
     */
    static put_Establishments_id_comments_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/comments/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for comments.
     */
    static delete_Establishments_id_comments_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/comments/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Queries pictures of Establishment.
     */
    static get_Establishments_id_pictures<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/pictures`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in pictures of this model.
     */
    static post_Establishments_id_pictures<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/pictures`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all pictures of this model.
     */
    static delete_Establishments_id_pictures<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/pictures`,
            method: 'delete',


        })
    }

    /** 
     * Counts pictures of Establishment.
     */
    static get_Establishments_id_pictures_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/pictures/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries similarEstablishments of Establishment.
     */
    static get_Establishments_id_similarEstablishments<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in similarEstablishments of this model.
     */
    static post_Establishments_id_similarEstablishments<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all similarEstablishments of this model.
     */
    static delete_Establishments_id_similarEstablishments<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments`,
            method: 'delete',


        })
    }

    /** 
     * Counts similarEstablishments of Establishment.
     */
    static get_Establishments_id_similarEstablishments_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/similarEstablishments/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries createdBy of Establishment.
     */
    static get_Establishments_id_createdBy<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in createdBy of this model.
     */
    static post_Establishments_id_createdBy<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all createdBy of this model.
     */
    static delete_Establishments_id_createdBy<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy`,
            method: 'delete',


        })
    }

    /** 
     * Counts createdBy of Establishment.
     */
    static get_Establishments_id_createdBy_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/createdBy/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries primarySuggesters of Establishment.
     */
    static get_Establishments_id_primarySuggesters<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in primarySuggesters of this model.
     */
    static post_Establishments_id_primarySuggesters<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all primarySuggesters of this model.
     */
    static delete_Establishments_id_primarySuggesters<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters`,
            method: 'delete',


        })
    }

    /** 
     * Counts primarySuggesters of Establishment.
     */
    static get_Establishments_id_primarySuggesters_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/primarySuggesters/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries tags of Establishment.
     */
    static get_Establishments_id_tags<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in tags of this model.
     */
    static post_Establishments_id_tags<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all tags of this model.
     */
    static delete_Establishments_id_tags<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags`,
            method: 'delete',


        })
    }

    /** 
     * Counts tags of Establishment.
     */
    static get_Establishments_id_tags_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/tags/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries foodDrinks of Establishment.
     */
    static get_Establishments_id_foodDrinks<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in foodDrinks of this model.
     */
    static post_Establishments_id_foodDrinks<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all foodDrinks of this model.
     */
    static delete_Establishments_id_foodDrinks<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks`,
            method: 'delete',


        })
    }

    /** 
     * Counts foodDrinks of Establishment.
     */
    static get_Establishments_id_foodDrinks_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/foodDrinks/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries cuisines of Establishment.
     */
    static get_Establishments_id_cuisines<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in cuisines of this model.
     */
    static post_Establishments_id_cuisines<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all cuisines of this model.
     */
    static delete_Establishments_id_cuisines<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines`,
            method: 'delete',


        })
    }

    /** 
     * Counts cuisines of Establishment.
     */
    static get_Establishments_id_cuisines_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/cuisines/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries suggestions of Establishment.
     */
    static get_Establishments_id_suggestions<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/suggestions`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in suggestions of this model.
     */
    static post_Establishments_id_suggestions<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/suggestions`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all suggestions of this model.
     */
    static delete_Establishments_id_suggestions<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/suggestions`,
            method: 'delete',


        })
    }

    /** 
     * Counts suggestions of Establishment.
     */
    static get_Establishments_id_suggestions_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/suggestions/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries favourites of Establishment.
     */
    static get_Establishments_id_favourites<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/favourites`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in favourites of this model.
     */
    static post_Establishments_id_favourites<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/favourites`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all favourites of this model.
     */
    static delete_Establishments_id_favourites<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/favourites`,
            method: 'delete',


        })
    }

    /** 
     * Counts favourites of Establishment.
     */
    static get_Establishments_id_favourites_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/favourites/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries wishes of Establishment.
     */
    static get_Establishments_id_wishes<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/wishes`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in wishes of this model.
     */
    static post_Establishments_id_wishes<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/wishes`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all wishes of this model.
     */
    static delete_Establishments_id_wishes<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/wishes`,
            method: 'delete',


        })
    }

    /** 
     * Counts wishes of Establishment.
     */
    static get_Establishments_id_wishes_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/wishes/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries visitedes of Establishment.
     */
    static get_Establishments_id_visitedes<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/visitedes`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in visitedes of this model.
     */
    static post_Establishments_id_visitedes<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/visitedes`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all visitedes of this model.
     */
    static delete_Establishments_id_visitedes<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/visitedes`,
            method: 'delete',


        })
    }

    /** 
     * Counts visitedes of Establishment.
     */
    static get_Establishments_id_visitedes_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/visitedes/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries comments of Establishment.
     */
    static get_Establishments_id_comments<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/comments`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in comments of this model.
     */
    static post_Establishments_id_comments<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/comments`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all comments of this model.
     */
    static delete_Establishments_id_comments<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/comments`,
            method: 'delete',


        })
    }

    /** 
     * Counts comments of Establishment.
     */
    static get_Establishments_id_comments_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/comments/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Find all instances of the model matched by filter from the data source.
     */
    static get_Establishments<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Establishments`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Create a new instance of the model and persist it into the data source.
     */
    static post_Establishments<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Establishments`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Replace an existing model instance or insert a new one into the data source.
     */
    static put_Establishments<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Establishments`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Patch an existing model instance or insert a new one into the data source.
     */
    static patch_Establishments<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Establishments`,
            method: 'patch',

            data: {
                ...data
            },
        })
    }

    /** 
     * Replace an existing model instance or insert a new one into the data source.
     */
    static post_Establishments_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Establishments/replaceOrCreate`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     */
    static post_Establishments_upsertWithWhere<T = any>(where?: any, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

    /** 
     * Check whether a model instance exists in the data source.
     */
    static get_Establishments_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/exists`,
            method: 'get',


        })
    }

    /** 
     * Find a model instance by {{id}} from the data source.
     */
    static get_Establishments_id<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Replace attributes for a model instance and persist it into the data source.
     */
    static put_Establishments_id<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Patch attributes for a model instance and persist it into the data source.
     */
    static patch_Establishments_id<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}`,
            method: 'patch',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a model instance by {{id}} from the data source.
     */
    static delete_Establishments_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}`,
            method: 'delete',


        })
    }

    /** 
     * Replace attributes for a model instance and persist it into the data source.
     */
    static post_Establishments_id_replace<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/replace`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Find first instance of the model matched by filter from the data source.
     */
    static get_Establishments_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/findOne`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Update instances of the model matched by {{where}} from the data source.
     */
    static post_Establishments_update<T = any>(where?: any, data?: any,) {
        return axios.request<T>({
            url: `/Establishments/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

    /** 
     * Count instances of the model matched by where from the data source.
     */
    static get_Establishments_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Establishments/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Create a change stream.
     */
    static get_Establishments_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Establishments/change-stream`,
            method: 'get',
            params: {
                options
            },

        })
    }

    /** 
     * Create a change stream.
     */
    static post_Establishments_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Establishments/change-stream`,
            method: 'post',

            data: {
                options
            },
        })
    }

    /** 
     * Download csv of nearby establishments
     */
    static get_Establishments_admin_suggest<T = any>(location: any, radius?: any,) {
        return axios.request<T>({
            url: `/Establishments/admin-suggest`,
            method: 'get',
            params: {
                location, radius
            },

        })
    }

    /** 
     * Suggest a place
     */
    static post_Establishments_suggest<T = any>(establishment: any, suggestion: any,) {
        return axios.request<T>({
            url: `/Establishments/suggest`,
            method: 'post',

            data: {
                establishment, suggestion
            },
        })
    }

    /** 
     * Filter establishment
     */
    static get_Establishments_filter<T = any>(filter?: any, axiosConfig?: AxiosRequestConfig) {
        return axios.request<T>({
            ...axiosConfig,
            url: `/Establishments/filter`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * details
     */
    static get_Establishments_id_details<T = any>(id: string, filter?: any) {
        return axios.request<T>({
            url: `/Establishments/${id}/details`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * updateSuggestions
     */
    static put_Establishments_id_update_suggestions<T = any>(suggestions: any, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/update-suggestions`,
            method: 'put',

            data: {
                suggestions
            },
        })
    }

    /** 
     * 
     */
    static get_Establishments_generate_csv<T = any>(email: any,) {
        return axios.request<T>({
            url: `/Establishments/generate-csv`,
            method: 'get',
            params: {
                email
            },

        })
    }

    /** 
     * Publish entity.
     */
    static post_Establishments_id_publish<T = any>(publish: any, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/publish`,
            method: 'post',

            data: {
                publish
            },
        })
    }

    /** 
     * 
     */
    static get_Establishments_id_favouriters<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/favouriters`,
            method: 'get',


        })
    }

    /** 
     * 
     */
    static get_Establishments_favourited_by_id<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/favourited-by/${id}`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * 
     */
    static post_Establishments_id_create_favourite_userId<T = any>(userId: any, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/create-favourite/${userId}`,
            method: 'post',


        })
    }

    /** 
     * 
     */
    static get_Establishments_id_wishers<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/wishers`,
            method: 'get',


        })
    }

    /** 
     * 
     */
    static get_Establishments_wished_by_id<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/wished-by/${id}`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * 
     */
    static post_Establishments_id_create_wish_userId<T = any>(userId: any, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/create-wish/${userId}`,
            method: 'post',


        })
    }

    /** 
     * 
     */
    static get_Establishments_id_visiters<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/visiters`,
            method: 'get',


        })
    }

    /** 
     * 
     */
    static get_Establishments_visited_by_id<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/visited-by/${id}`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * 
     */
    static post_Establishments_id_create_visited_userId<T = any>(userId: any, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/create-visited/${userId}`,
            method: 'post',


        })
    }

    /** 
     * 
     */
    static get_Establishments_id_commenters<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/commenters`,
            method: 'get',


        })
    }

    /** 
     * 
     */
    static get_Establishments_commented_entities_id<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/commented-entities/${id}`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * 
     */
    static post_Establishments_id_create_comment_userId<T = any>(userId: any, id: string,) {
        return axios.request<T>({
            url: `/Establishments/${id}/create-comment/${userId}`,
            method: 'post',


        })
    }

    /** 
     * 
     */
    static get_Establishments_id_get_comments<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Establishments/${id}/get-comments`,
            method: 'get',
            params: {
                filter
            },

        })
    }

}

export default EstablishmentsBase;
