import { useAuthDialog } from 'Dialogs/Auth/useAuthDialog';
import { useAuth } from 'Features/Auth/useAuth';
import React, { useState, useCallback } from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import SubscriptionPackUpdateDialog from '.';
import { TPlace } from 'Models/Place/@types';
import utils from 'Utils';


export type SubscriptionPackUpgradeConfig = {
    onClose?: () => void;
    onAgree?: () => void;
    strictModeLogin?: boolean;
    place?: TPlace;
}


function useSubscriptionPackUpgradeDialog() {
    const [config, setConfig] = useState<SubscriptionPackUpgradeConfig>();
    const { setShouldAllowWindowRefresh } = useStoreActions(({ App: { setShouldAllowWindowRefresh } }) => ({ setShouldAllowWindowRefresh }))
    const [open, setOpen] = useState(false);
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))
    const { userLogin, userSignup, isRegistered, submitOtp, userLogout } = useAuth();
    const { openAuthDialog, AuthComponent } = useAuthDialog({
        onLogin: async data => {
            await userLogin(data);
            // window.location.reload();
        },
        onSignup: userSignup,
        handleCheck: isRegistered,
        onOtpSubmit: submitOtp
    })



    const openDialog = useCallback((config?: SubscriptionPackUpgradeConfig) => {
        setShouldAllowWindowRefresh(false);
        setConfig(config);
        if (!appUser) {
            openAuthDialog(config?.strictModeLogin);
            return;
        }
        setOpen(true);
        utils.setCookie('redirectURL', config?.place ? `/places/${config.place.slug || config.place.id}` : '/');
        config?.onAgree?.();
    }, [appUser, config]);



    const closeDialog = useCallback(() => {
        setShouldAllowWindowRefresh(true);
        setOpen(false);
        config?.onClose?.();
    }, [config]);


    return {
        openDialog,
        closeDialog,
        DialogComponent: (
            <>
                <SubscriptionPackUpdateDialog open={open} place={config?.place} onClose={closeDialog} />
                {AuthComponent}
            </>
        )
    }
}


export default useSubscriptionPackUpgradeDialog;