import { trackEvent, identifyUser, trackPageView, reset } from '@redux-beacon/segment';


const URL_CHANGE_EVENT = 'URL CHANGE EVENT';
const USER_EVENT_NAME = 'USER EVENT';


// PAGE VIEW TRACKER
export const pageViewTracker = trackPageView((action, prevState, nextState) => {
    return {
        name: URL_CHANGE_EVENT,
        properties: action.data
    };
});




// EVENT TRACKERS
export const eventTracker = trackEvent((action, prevState, nextState) => {
    return {
        name: action.data?.eventName || USER_EVENT_NAME,
        properties: action.data,
    }
});


export const analyticsSetUser = identifyUser((action, prevState, nextState) => {
    return {
        userId: action.data ? action.data.userId : '',
        traits: action.data ? action.data.traits : {},
    }
});


export const analyticsRemoveUser = reset()

