import axios from 'axios';
import { TDish } from './@types';
import FoodDrinks from 'BaseModels/FoodDrinks'

class DishModel extends FoodDrinks {

    static getImage(dish: TDish) {
        return dish._pictures?.[0]
    }

    static getCuisinesNameList(dish: TDish, limit?: number) {
        return dish.cuisines?.slice(0, limit || Number.POSITIVE_INFINITY).map(c => c.name).join(", ")
    }
}

export default DishModel;
