
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class AppModelsBase {

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_AppModels<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/AppModels`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_AppModels<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/AppModels`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static put_AppModels<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/AppModels`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
    static patch_AppModels<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/AppModels`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static post_AppModels_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/AppModels/replaceOrCreate`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
    static post_AppModels_upsertWithWhere<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/AppModels/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Check whether a model instance exists in the data source.
 */ 
    static get_AppModels_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/AppModels/${id}/exists`,
            method: 'get',
            
            
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_AppModels_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/AppModels/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static put_AppModels_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/AppModels/${id}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_AppModels_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/AppModels/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
    static delete_AppModels_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/AppModels/${id}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static post_AppModels_id_replace<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/AppModels/${id}/replace`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_AppModels_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/AppModels/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
    static post_AppModels_update<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/AppModels/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Count instances of the model matched by where from the data source.
 */ 
    static get_AppModels_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/AppModels/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static get_AppModels_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/AppModels/change-stream`,
            method: 'get',
            params: {
                options
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static post_AppModels_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/AppModels/change-stream`,
            method: 'post',
            
            data: {
                options
            },
        })
    }

/** 
 * 
 */ 
    static get_AppModels_constants<T = any>() {
        return axios.request<T>({
            url: `/AppModels/constants`,
            method: 'get',
            
            
        })
    }

/** 
 * 
 */ 
    static get_AppModels_summary<T = any>() {
        return axios.request<T>({
            url: `/AppModels/summary`,
            method: 'get',
            
            
        })
    }

/** 
 * 
 */ 
    static put_AppModels_relations<T = any>(newIds: any,id: string,secondModel: any,firstModel: any,) {
        return axios.request<T>({
            url: `/AppModels/relations`,
            method: 'put',
            
            data: {
                newIds, id, secondModel, firstModel
            },
        })
    }

}

export default AppModelsBase;
    