import Config from './Config';
import Utils from './Utils';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import helpers from 'Utils/helpers';
import config from './Config';

const SEGMENT_ANALYTICS_DEV = `
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
    analytics.load("DVhFB7XcMduhQOl2bqjEsRyZjN2K7WPV");
    analytics.page();
    }}();
`
const SEGMENT_ANALYTICS_PRODUCTION = `
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
        analytics.load("aEFJSig3Gdc825UYakPALfmlgnuewCXv");
        analytics.page();
    }}();
`


const VALIDATE_CONFIG_PROPERTIES = ['BASE_URL', 'API_URL'];

const validateConfig = () => {
    VALIDATE_CONFIG_PROPERTIES.forEach(key => {
        const val = Config.get(key);
        // if (!val)
        //     throw new Error(`App config must define ${key}`);
    });
}


const addScripts = () => {
    const segmentScript = document.createElement('script');
    if (config.get('ANALYTICS') === 'true') {
        if (helpers.isProduction())
            segmentScript.innerHTML = SEGMENT_ANALYTICS_PRODUCTION;
        else
            segmentScript.innerHTML = SEGMENT_ANALYTICS_DEV;
    }


    document.body.appendChild(segmentScript);
}

const Boot = () => {
    return new Promise((resolve, reject) => {
        dayjs.extend(customParseFormat);
        addScripts();
        validateConfig();
        /** Override console.log as per environment file */
        if (Config.get('LOGGING') === 'false') {
            console.log = () => { }
        }

        Utils.setBaseAPI_URL(Config.get('API_URL'));
        Utils.setAPIVersion('2.1');
        resolve(true);
    })
};

export default Boot;