import Axios from "axios";

class HomeModel {

    static get_HomeDetails<T = any>(filter?: any) {
        return Axios.request<T> ({
            url: `/HomeSections`,
            method: 'get',
            params: {
                filter
            },
        });
    }

    static get_Quotes<T = any>(modelName: string, count: number, filter?: any) {
        return Axios.request<T> ({
            url: `/AppModels/random-content`,
            method: 'get',
            params: {
                modelName,
                count,
                filter
            },
        });
    }
    
}

export default HomeModel;