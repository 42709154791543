import React, { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { useAnalyticsAction } from 'Analytics/analyticActions';
import NewNavigationTab, { NewNavigationTabItem } from 'Components/NewNavigationTab';

export interface NewBottomTabBarProps {

}

const NewBottomTabBar: FC<NewBottomTabBarProps> = (props) => {
    const classes = useStyles({});
    const [tab, setTab] = useState<NewNavigationTabItem>('home');
    const { pathname } = useLocation();
    const { dispatchUserEvent } = useAnalyticsAction();
    const { isDeviceSm } = useMediaQuery();







    useEffect(() => {
        onTabChange(pathname);
    }, [pathname])



    const onTabChange = useCallback((path: string) => {
        switch (path) {
            case '/':
                dispatchUserEvent({ eventName: 'BOTTOM_TAB_PRESSED', url: path })
                return setTab('home');
            case '/nearby':
                dispatchUserEvent({ eventName: 'BOTTOM_TAB_PRESSED', url: path })
                return setTab('nearby');
            case '/search':
                dispatchUserEvent({ eventName: 'BOTTOM_TAB_PRESSED', url: path })
                return setTab('search');
            case '/destinations':
                dispatchUserEvent({ eventName: 'BOTTOM_TAB_PRESSED', url: path })
                return setTab('destinations');
            // case '/search':
            //     dispatchUserEvent({ eventName: 'BOTTOM_TAB_PRESSED', url: path })
            //     return setTab('search');
            default:
                return setTab('none')
        }
    }, [])

    if (!isDeviceSm)
        return null

    return (
        <div className={classes.tabBar}>
            <NewNavigationTab selectedTab={tab} />
        </div>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    tabBar: {
        position: 'fixed',
        bottom: 0,
        width: '100vw',
        zIndex: 999,
    }
}));

export default NewBottomTabBar;