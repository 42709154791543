import axios from 'axios';
import { TTagCategory } from './@types';
import TagCategories from 'BaseModels/TagCategories'
import { TTag } from 'Models/Tag/@types';

class TagCategoryModel extends TagCategories {

    static getTagsFromIds = (tagCategories: TTagCategory[], tagIds: string[]) => {
        const allTags: TTag[] = TagCategoryModel.getTagsFromTagCategories(tagCategories);
        return allTags.filter(t => tagIds.includes(t.id));
    }


    static getTagsFromTagCategories = (tagCategories: TTagCategory[]) => {
        let allTags: TTag[] = [];
        let _takenIds: string[] = [];
        tagCategories.forEach(c => {
            allTags = [...allTags, ...c.tags.filter(t => !_takenIds.includes(t.id))];
            _takenIds = allTags.map(t => t.id);
        })
        return allTags;
    }
}

export default TagCategoryModel;
