import React from 'react';
import { ReactForm, attachField } from 'react-forms';
import { useFormConfig } from './config';

export interface EmailVerificationFormData {
    email: string
}
// attachField('pg-text', <PgTextField />)
// attachField('pg-password', <PgTextField />, { type: 'password' })
interface EmailVerificationProps {
    onSubmit: Function,
    initialData: Partial<EmailVerificationFormData>
    isSubmitting: boolean
}


const EmailVerificationForm: React.FC<EmailVerificationProps> = (props) => {
    const { actionConfig, inputConfig, validationSchema } = useFormConfig()
    return (
        <ReactForm
            initialValues={props.initialData}
            formId="email-verification"
            config={inputConfig}
            actionConfig={actionConfig}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        />
    )
}



export default EmailVerificationForm