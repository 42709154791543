import Axios from "axios";

class InsiderModel {

    static get_UserReview<T = any>(filter?: any) {
        return Axios.request<T> ({
            url: `/userReviews`,
            method: 'get',
            params: {
                filter
            }
        });
    }
    
}

export default InsiderModel;