import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import LoginForm, { LoginFormProps } from 'Forms/Login';
import Typo from 'Components/Typo';
import useUserActions from 'Features/User/useUserActions';
import useAsyncTask from 'Hooks/useAsyncTask';
import SubmitButton from 'Components/SubmitButton';
import GRAPHIC from '../../Assets/animated/autorickshaw.json';
// import GRAPHIC from '../../Assets/images/payment/welcome.png';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import LottieImage from 'Components/LottieImage';


export interface LoginPageProps extends LoginFormProps {
}

const LoginPage: FC<LoginPageProps> = (props) => {
    const classes = useStyles({});
    const { children, ...loginFormProps } = props;
    const { sendResetPasswordLink } = useUserActions();
    const { isDeviceSm } = useMediaQuery();
    const email = loginFormProps.initialData?.email || '';

    const forgotPasswordHandler = useAsyncTask(async () => {
        if (!email) return;
        await sendResetPasswordLink(email);
    })

    return (
        <Box pb={4}>
            <Typo variant='h5' align='center' color='primary' weight='medium'>Welcome back!</Typo>
            <Typo variant='subtitle2' align='center' weight='medium'>{email}</Typo>
            <Box px={5} mt={5}>
                <LoginForm
                    {...loginFormProps}
                />
                <Box mt={4}>
                    <SubmitButton loading={forgotPasswordHandler.status === 'PROCESSING'} onClick={forgotPasswordHandler.run} className={classes.submitBtn} color='primary'>
                        Forgot password?
                    </SubmitButton>
                </Box>
            </Box>
            {isDeviceSm ?
                <div className={classes.img}>
                    <LottieImage lotteJSON={GRAPHIC} dimension={{ width: '100vw', height: 'unset' }} />
                </div> : null
                // <img src={GRAPHIC} alt='login-image' className={classes.img} /> : null
            }
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    img: {
        width: 100,
        position: 'absolute',
        bottom: 0,
        left: 0,
        objectFits: 'cover'
    },
    submitBtn: {
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
}));

export default LoginPage;