import React, { ComponentType, FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, ButtonProps, Button } from '@material-ui/core';
import Lottie, { Options } from 'react-lottie';



export interface AnimatableContainerProps {
    shouldAnimate: boolean
    renderAnimation?: () => JSX.Element
    lottieJSON?: any
}


const AnimatableContainer: FC<AnimatableContainerProps> = (props) => {
    const { renderAnimation, lottieJSON, children, shouldAnimate } = props;
    const classes = useStyles({});






    const handleAnimation = () => {
        if (renderAnimation) renderAnimation();
        if (lottieJSON) {
            const defaultOptions: Options = {
                loop: true,
                autoplay: true,

                animationData: lottieJSON,
                rendererSettings: {
                    // preserveAspectRatio: 'xMidYMid slice'
                }
            };
            return <Lottie height="24px" options={defaultOptions} />
        }
        return null
    }

    return shouldAnimate ? handleAnimation() : (
        <>{children}</>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default AnimatableContainer;