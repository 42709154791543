import { FormControl, Icon, InputAdornment, TextField, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { EditOutlined } from '@material-ui/icons';
import { get } from 'lodash';
import React from 'react';
import { IFieldProps } from 'react-forms';


interface EmailFieldProps extends IFieldProps {
    handleBack?: () => void
}

const EmailField: React.FC<EmailFieldProps> = (props) => {

    const classes = useStyles()

    const value = props.formikProps?.values[props.fieldConfig?.valueKey || '']
    const onBack = get(props, 'fieldProps.onBack') || ((email: string) => { })

    const error = !!props.formikProps?.errors['email']
    const errorText = props.formikProps?.errors['email'] || ''



    return (
        <div>
            <FormControl className={classes.formControl}>
                <TextField
                    value={value || ''}
                    label="Email"
                    // onChange={handleChange}
                    error={error}
                    name='email'
                    helperText={errorText}

                    InputProps={{
                        endAdornment: (
                            <InputAdornment className={classes.adornment} onClick={() => onBack(value)} position="end">
                                <EditOutlined />
                            </InputAdornment>
                        ),
                        classes: {
                            underline: classes.underline,
                            root: error ? '' : classes.root,

                        }
                    }}
                />
            </FormControl>
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        display: 'flex'
    },
    adornment: {
        cursor: 'pointer',
        color: theme.palette.primary['contrastText']
    },
    root: {
        borderBottom: '1px solid #fff',
    },
    underline: {
    }
}))

export default EmailField