import { action, Action, thunk, Thunk } from "easy-peasy";
import EntityModal from "Models/EntityList";
import { TEntityList, TUserlistEntity } from "Models/EntityList/@types";

export interface TEntityListsState {
    lists: TEntityList[];
    userList: TEntityList | undefined;
    listEntities: TUserlistEntity[];
    entityCount: number;
    getAllLists: Thunk<TEntityListsState, { userId: string }>;
    getUserList: Thunk<TEntityListsState, { userId: string, listId: string }>;
    getUserlistEntities: Thunk<TEntityListsState, { id: string }>;
    getUserlistEntityCount: Thunk<TEntityListsState, { id: string }>;
    setLists: Action<TEntityListsState, TEntityList[]>;
    setUserList: Action<TEntityListsState, TEntityList>;
    setListEntities: Action<TEntityListsState, TUserlistEntity[]>;
    setEntityCount: Action<TEntityListsState, number>;
}

const EntityListsStore: TEntityListsState = {

    lists: [],
    userList: undefined,
    listEntities: [],
    entityCount: 0,

    getAllLists: thunk( async (actions, { userId }) => {

        const { data } = await EntityModal.get_all_Userlist_id(userId, { include: 'subject' });

        actions.setLists(data);
        
    }),

    getUserList: thunk( async (actions, { userId, listId }) => {

        const { data } = await EntityModal.get_Userlist_id_fk<TEntityList>(userId, listId);

        actions.setUserList(data);

    }),

    getUserlistEntities: thunk( async (actions, { id }) => {

        const { data } = await EntityModal.get_Userlist_entity_id(id, { include: 'subject' });

        actions.setListEntities(data);
        
    }),

    getUserlistEntityCount: thunk( async (actions, { id }) => {

        const { data } = await EntityModal.get_Userlist_entity_count_id<{ count: number }>(id);

        actions.setEntityCount(data.count);
        
    }),

    setLists: action((state, payload) => {
        state.lists = payload;
    }),

    setUserList: action((state, payload) => {
        state.userList = payload;
    }),
    
    setListEntities: action((state, payload) => {
        state.listEntities = payload;
    }),

    setEntityCount: action((state, payload) => {
        state.entityCount = payload;
    }),
    
};

export default EntityListsStore;

