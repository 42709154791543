import { TEatinerary } from "Models/Eatinerary/@type";
import { Thunk, Action, thunk, action } from "easy-peasy";
import EatineraryModel from "Models/Eatinerary";

export interface TEatineraryDetailScreenState {
    loading: boolean;
    eatineraryDetails: TEatinerary | undefined;
    getEatineraryDetails: Thunk<TEatineraryDetailScreenState, string>;
    setEatineraryDetails: Action<TEatineraryDetailScreenState, TEatinerary | undefined>;
    setLoading: Action<TEatineraryDetailScreenState, boolean>;
}

const EatineraryDetailScreenStore: TEatineraryDetailScreenState = {

    loading: true,
    eatineraryDetails: undefined,

    getEatineraryDetails: thunk(async (actions, id) => {

        actions.setLoading(true);

        try {

            const { data } = await EatineraryModel.get_Eatinerary_id(id, {
                "include": {
                    "relation": "destination",
                    "scope": {
                        "fields": ["name", "id", "slug", "destinationPackId"],
                        "include": {
                            "relation": "destinationPack",
                        }
                    }
                }
            });

            actions.setEatineraryDetails(data);
            
        } catch (error) {
            actions.setEatineraryDetails(undefined);
        }

        actions.setLoading(false);

    }),

    setEatineraryDetails: action((state, payload) => {
        state.eatineraryDetails = payload;
    }),

    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    
};

export default EatineraryDetailScreenStore;

