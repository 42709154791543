import { Action, action, thunk, Thunk } from "easy-peasy";
import CityModel from "Models/City";
import { TCity } from "Models/City/@types";

export interface TUserPinedCitiesScreenState {
    cities: TCity[];
    getCities: Thunk<TUserPinedCitiesScreenState, { id: string }>;
    setCities: Action<TUserPinedCitiesScreenState, TCity[]>;
}

const UserPinedCitiesScreenStore: TUserPinedCitiesScreenState = {

    cities: [],

    getCities: thunk(async (actions, { id }) => {

        const { data } = await CityModel.get_Destinations_pined_by_id<TCity[]>(id, {
            include: ['destinationPack']
        });

        actions.setCities(data);

    }),

    setCities: action((state, payload) => {
        state.cities = payload;
    }),


};

export default UserPinedCitiesScreenStore;

