import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from 'Components/Loader';
import useAppInit from 'Features/AppInit/useAppInit';
import useCordovaEvents from 'Features/Cordova/useCordovaEventListeners';
import { createBrowserHistory } from 'history';
import React, { memo, useEffect, useRef } from 'react';
import { Router } from 'react-router-dom';
import MobileAuthHandler from 'MobileAuthHandler';
import Boot from './Boot';
import RootLayout from './Screens/RootLayout';
import Theme from './Theme';
export const appHistory = createBrowserHistory();


export const authPopupInterval = 30000; // in milliseconds

const App: React.FC = () => {

  const { init, loading } = useAppInit();


  useCordovaEvents();

  useEffect(() => {
    Boot().then(initialize);
  }, [init])

  const initialize = async () => {
    await init();
  }

  const count = useRef(0);

  console.log('App', count.current++);

  return (
    <Router history={appHistory} >
      <MuiThemeProvider theme={Theme}>
        {loading ? <Loader fullScreen /> : <RootLayout />}
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
