import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Link as MaterialLink, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SignUpForm, { SignUpFormData, SignUpFormProps } from 'Forms/AuthForms/SignUp';
import Typo from 'Components/Typo';
import { TERMS_CONDITIONS_URL } from 'Constants/links';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import GRAPHIC from '../../Assets/animated/autorickshaw.json';
import LottieImage from 'Components/LottieImage';
import { THEME_PALETTE } from 'Theme/themeConstants';
import Spacer from 'Components/Spacer';
import useStepper from 'Components/Stepper';
import { useAuth } from 'Features/Auth/useAuth';
import Wrapper from 'Components/Wrapper';
import { settingUtils } from 'Models/Settings';
import { useStoreState } from 'Stores';
import helpers from 'Utils/helpers';

export interface SignUpPageProps extends SignUpFormProps {
    isInsideDialog?: boolean;
    StepperComponent?: JSX.Element;
    openLogin?: () => void;
}

const SignUpPage: FC<SignUpPageProps> = (props) => {
    const { children, openLogin, isInsideDialog = false, StepperComponent, ...signupFormProps } = props;
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles({ isInsideDialog, isDeviceSm });
    const { settings } = useStoreState(({ App: { settings } }) => ({ settings }));

    const View = ({ children }) => {
        return isDeviceSm || isInsideDialog ? <Box mb={10}>{children}</Box> : <Paper elevation={2}>{children}</Paper>;
    };

    const image = settingUtils.getAuthScreenImage(isDeviceSm ? 'sm' : 'lg', settings || []);

    return (
        <Box display="flex" flexDirection={isDeviceSm ? 'column' : 'row'} className={classes.root}>
            <Wrapper
                imgLink={image ? helpers.getPictureUrl(image, isDeviceSm ? 600 : 400) : ''}
                isInsideDialog={isInsideDialog}
            >
                <View>
                    <Box className={classes.stepper} display="flex" justifyContent="center">
                        {StepperComponent}
                    </Box>
                    <Spacer height={12} />
                    {/* <Typo variant="subtitle1" align="center" weight="medium" className={classes.text}>
                        Welcome to Mamakoo
                    </Typo> */}
                    <Typo variant="h5" align="center" weight="medium" fontFamily="font3" className={classes.text}>
                        Sign Up
                    </Typo>
                    <Spacer height={30} />
                    <Box className={classes.signupForm}>
                        <SignUpForm {...signupFormProps} />
                    </Box>
                    <Box mt={4} display="flex" flexDirection="column" alignItems="start">
                        <Typo
                            variant="caption"
                            fontFamily="secondary"
                            weight="medium"
                            align="left"
                            className={classes.text}
                        >
                            By clicking next you are agreeing to the
                            <br />
                            <MaterialLink href={TERMS_CONDITIONS_URL}>Terms of Service</MaterialLink>
                        </Typo>
                        <Spacer height={12} />
                        <Typo
                            variant="caption"
                            fontFamily="secondary"
                            weight="medium"
                            align="left"
                            className={classes.text}
                        >
                            Already have an account?
                            <br />
                            {openLogin ? (
                                <Button onClick={openLogin} className={classes.submitBtn} color="primary">
                                    Log In
                                </Button>
                            ) : (
                                <Link to="/login" className={classes.submitBtn}>
                                    Log In
                                </Link>
                            )}
                        </Typo>
                    </Box>
                </View>
                {isDeviceSm && !isInsideDialog ? (
                    <div className={classes.img}>
                        <LottieImage lotteJSON={GRAPHIC} dimension={{ width: '100vw', height: 'unset' }} />
                    </div>
                ) : null}
            </Wrapper>
        </Box>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        backgound: THEME_PALETTE.background.default,
    },
    stepper: {
        '& >*': {
            width: '56%',
            [theme.breakpoints.down('sm')]: {
                width: '65%',
            },
        },
    },
    text: {
        color: THEME_PALETTE.grey[200],
    },
    signupForm: {
        width: '39vw',
        maxWidth: '388px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },
    img: {
        width: 100,
        position: 'absolute',
        top: '44rem',
        bottom: '0px',
        left: 0,
        objectFit: 'cover',
        padding: '0 !important',
    },
    submitBtn: {
        ...theme.typography.caption,
        padding: 0,
        justifyContent: 'flex-start',
    },
}));

export default SignUpPage;
