import config from 'Config';
import { isString, map, filter, indexOf } from 'lodash';
import { TLocation, TPicture } from 'Models/@types';



let helpers = {
    scrollTo: (id: string) => {
        const el = document.getElementById(id);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' })
        }
    },
    appendApostrophe: (text?: string) => {
        if (!text) return;
        if (text[text.length - 1] === 's')
            return `${text}'`
        return `${text}'s`
    },
    isProduction: () => {
        let env = process.env.REACT_APP_NODE_ENV?.trim();
        return (env === 'production')
    },
    copyToClipboard: (value: string) => {
        if (window.navigator && window.navigator.clipboard && window.navigator.clipboard.writeText)
            window.navigator?.clipboard?.writeText(value)
        else {
            const el = document.createElement('textarea');
            el.value = value
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    },
    successLog: (text: string) => console.log(`%c${text}`, "font-size: 14px; color: #0d0; border: 1px solid #0d0; padding: 1px 4px;"),
    errorLog: (text: string) => console.log(`%c${text}`, "font-size: 14px; background-color: #f10; color: #fff; padding: 1px 4px;"),
    toggleItemFromList: (list: any[] = [], item: any, key: string = 'id', comparisonFunction?: (currentItem: any, item: any) => boolean) => {
        let updatedList: any[] = [...list];
        let index = list.findIndex(i => comparisonFunction ? comparisonFunction(i, item) : i[key] === item[key]);
        index === -1 ? updatedList.push(item) : updatedList.splice(index, 1);
        return updatedList;
    },
    getPlaceholderImageUrl: (w: number = 400, h: number = 400, text: string = w + 'x' + h) => `https://via.placeholder.com/${w}x${h}?text=${text}`,
    getDummyImageUrl: (w: number = 800, h: number = 800) => `https://source.unsplash.com/random/${w}x${h}`,
    bytesToSize: (bytes: number) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    },
    mockRequest: (returnData?: any, fail?: boolean, time?: number,) => {
        return new Promise<typeof returnData>((resolve, reject) => {
            setTimeout(() => {
                if (fail) reject(returnData)
                else
                    resolve(returnData)
            }, time || 2000);
        })
    },
    isListSimilar: (list1: Array<any>, list2: Array<any>) => {
        if (list1.length !== list2.length) return false;
        list1.forEach(elem => {
            let index = list2.findIndex(i => i.id === elem.id);
            if (index === -1)
                return false;
        });
        return true;
    },
    validateFileType: (acceptedType: string, typeToValidate: string) => {
        let accept = acceptedType.split(',').map(i => i.replace(/ /g, ''));
        let isValid = false;
        accept.forEach(i => {

            if (i.includes('*')) {
                if (i === '*/*')
                    isValid = true;
                if (typeToValidate.includes(i.split('/')[0]))
                    isValid = true;
            } else if (i.includes('.')) {
                if (typeToValidate.includes(i.split('.')[0]))
                    isValid = true;
            } else {
                if (typeToValidate.includes(i))
                    isValid = true;
            }
        })
        return isValid;
    },
    updateItemList: (list: Array<any>, item: any, action: 'ADD' | 'DELETE' | 'UPDATE' | 'PUT', key: any = 'id'): typeof list => {
        list = list || [];
        let newList = list.slice();
        let itemIndex;
        if (action === 'UPDATE') {
            itemIndex = newList.findIndex(listItem => item[key] === listItem[key]);
            if (itemIndex !== -1)
                newList.splice(itemIndex, 1, item);
            return newList;
        } else if (action === 'ADD') {
            newList.unshift(item);
            return newList;
        } else if (action === 'DELETE') {
            return newList.filter(listItem => item[key] !== listItem[key]);
        }
        else if (action === 'PUT') {
            itemIndex = newList.findIndex(listItem => item[key] === listItem[key]);
            if (itemIndex !== -1)
                newList.splice(itemIndex, 1, item);
            else {
                newList.push(item);
            }
            return newList;
        }
        return newList;
    },
    resolveConstant: <T = any>(list: Array<T>, value: string, key: string = 'value') => {
        const constant = list.find((item: any) => typeof item === 'string' ? item === value : item[key] === value);
        return constant
    },
    findValues: (superSet: (Array<Object>), subSet: (string | Array<string>), findKey: string = 'value', mapKey: string = 'name', isReturnItem: Boolean = false) => {
        // findKey = findKey || 'value';
        // mapKey = mapKey || 'name';
        if (isString(subSet))
            subSet = [subSet];
        let filteredValues = filter(superSet, (item: any) => {
            return (indexOf(subSet, item[findKey]) !== -1);
        });
        if (isReturnItem)
            return filteredValues;
        return map(filteredValues, mapKey);
    },
    hexToRgb: (hex: string, opacity = '0.1') => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result)
            return `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)} ,${parseInt(result[3], 16)},${opacity})`
        // r: parseInt(result[1], 16),
        // g: parseInt(result[2], 16),
        // b: parseInt(result[3], 16)
        else return '';
    },
    validateEmail: (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    getPictureUrl: (picture?: TPicture, width = 50, height?: number) => {
        if (!picture)
            return '';

        let tr = `?tr=w-${width}`
        if(height) tr += `,h-${height}`;
        return picture.url + tr
        // if (helpers.isProduction()) {
        //     return _getPictureUrlImageKit(picture, width, height);
        // } else {
        //     return _getPictureUrl(picture, width, height);
        // }
    },
    getDistance: (coord1: TLocation, coord2: TLocation, unit: 'km' | 'miles' = 'km'): number => {
        const toRad = (x: number) => x * Math.PI / 180;
        var lat1 = coord1.lat;
        var lon1 = coord1.lng;
        var lat2 = coord2.lat;
        var lon2 = coord2.lng;
        var x1 = lat2 - lat1;

        // Earth's mean readius in KMs
        const R = 6371;

        var dLat = toRad(x1);
        var x2 = lon2 - lon1;
        var dLon = toRad(x2);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;

        if (unit === 'km')
            //Distance in KMs
            return d;
        else
            return d / 1.60934
    },

    addSectionIdInUrl: (id: string, shouldScroll = false) => {
        window.history.pushState(null, '', `#${id}`);
        if (shouldScroll)
            helpers.scrollTo(id);
    },

    numToString: (num?: number) => {
        if (num) {
            var ones = [
                ' zero',
                ' one',
                ' two',
                ' three',
                ' four',
                ' five',
                ' six',
                ' seven',
                ' eight',
                ' nine',
                ' ten',
                ' eleven',
                ' twelve',
                ' thirteen',
                ' fourteen',
                ' fifteen',
                ' sixteen',
                ' seventeen',
                ' eighteen',
                ' nineteen',
            ];
            var tens = ['', '', ' twenty', ' thirty', ' forty', ' fifty', ' sixty', ' seventy', ' eighty', ' ninety'];
            var hundred = ' hundred';
            var output = '';
            var numString = num.toString();

            if (num < 20) return ones[num];

            //100 and more
            if (numString.length === 3) {
                output = ones[parseInt(numString.charAt(0))] + hundred;
                output += tens[parseInt(numString.charAt(1))];
                output += ones[parseInt(numString.charAt(2))];
                return output;
            }

            output += tens[parseInt(numString.charAt(0))];
            output += ones[parseInt(numString.charAt(1))];

            return output;
        }
    }
}



export default helpers;