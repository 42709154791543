import { action, Action, actionOn, ActionOn, thunk, Thunk } from "easy-peasy";
import { TComment } from "Models/Comment/@types";
import PlaceModel from "Models/Place";
import { TRootStore } from "Stores";

export interface TPlaceCommentsScreenState {
    comments: TComment[]
    setComments: Action<TPlaceCommentsScreenState, TComment[]>
    fetchComments: Thunk<TPlaceCommentsScreenState, string, {}, TRootStore>
    onCommentAdded: ActionOn<TPlaceCommentsScreenState, TRootStore>
}

const PlaceCommentsScreenStore: TPlaceCommentsScreenState = {
    comments: [],
    setComments: action((state, payload) => {
        state.comments = payload;
    }),
    fetchComments: thunk(async (actions, placeId) => {
        const { data } = await PlaceModel.get_Establishments_id_get_comments<TComment[]>(placeId, { "include": "user", "order": "created DESC" });
        actions.setComments(data)
    }),

    onCommentAdded: actionOn(
        (actions, storeActions) => storeActions.EntityActionsStore.commentAdded,
        (state, { payload }) => {
            state.comments = [payload.comment, ...state.comments]
            // state.place = { ...state.place, comments: [...state.place.comments, payload.comment] }
        }
    ),
};

export default PlaceCommentsScreenStore;

