import { Thunk, ThunkOn, ActionOn, action, thunk, thunkOn, actionOn } from "easy-peasy";
import Query from "Features/Query";
import { TPlace } from "Models/Place/@types";
import { Action } from "easy-peasy";
import { TRootStore } from "Stores";
import PlaceModel from "Models/Place";
import { omit } from "lodash";

export interface TUserSavedPlacesScreenState {
    places: TPlace[]
    loading: boolean
    query: Query
    pageNumber: number
    hasMore: boolean
    setHasMore: Action<TUserSavedPlacesScreenState, boolean>
    setPageNumber: Action<TUserSavedPlacesScreenState, number>
    setPlaces: Action<TUserSavedPlacesScreenState, TPlace[]>
    setLoading: Action<TUserSavedPlacesScreenState, boolean>
    loadMore: Thunk<TUserSavedPlacesScreenState, void, {}, TRootStore>
    setQuery: Action<TUserSavedPlacesScreenState, Query>
    searchPlaces: Thunk<TUserSavedPlacesScreenState, { query: Query, pageNumber?: number, append?: boolean }, {}, TRootStore>

    onQueryChange: ThunkOn<TUserSavedPlacesScreenState, {}, TRootStore>
    // onPlaceSave: ActionOn<TUserSavedPlacesScreenState, TRootStore>
    // onPlaceUnSave: ActionOn<TUserSavedPlacesScreenState, TRootStore>
}

const LISTING_LIMIT = 20;



const UserSavedPlacesScreenStore: TUserSavedPlacesScreenState = {
    places: [],
    loading: true,
    hasMore: true,
    pageNumber: 1,
    query: new Query({}),
    setPlaces: action((state, payload) => {
        state.places = payload;
    }),
    setHasMore: action((state, payload) => {
        state.hasMore = payload;
    }),
    setPageNumber: action((state, payload) => {
        state.pageNumber = payload;
    }),
    setQuery: action((state, payload) => {
        state.query = payload;
    }),
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    loadMore: thunk(async (actions, _, { getState }) => {
        const { query, pageNumber } = getState();
        await actions.searchPlaces({ query, pageNumber: pageNumber + 1, append: true })
    }),
    searchPlaces: thunk(async (actions, { pageNumber = 1, query, append = false }, { getState, getStoreState }) => {
        const state = getState();
        const { UserDetailScreenStore: { user } } = getStoreState();
        if (!user) return;
        actions.setPageNumber(pageNumber);
        actions.setLoading(true);
        const { data } = await PlaceModel.get_Establishments_wished_by_id<TPlace[]>(user.id, {
            include: ["cuisines", "tags", {
                relation: 'destination',
                scope: {
                    include: {
                        relation: 'destinationPack',
                    },
                },
            }],
            limit: LISTING_LIMIT,
            skip: LISTING_LIMIT * (pageNumber - 1),
            ...query.getSearchFilter
        });
        if (!data.length || data.length < LISTING_LIMIT)
            actions.setHasMore(false)
        else
            actions.setHasMore(true)
        actions.setPlaces(append ? [...state.places, ...data] : data);
        actions.setLoading(false)
    }),


    onQueryChange: thunkOn(
        (actions) => actions.setQuery,
        async (actions, { payload }) => {
            actions.searchPlaces({ query: payload })
        }
    ),

    // onPlaceSave: actionOn(
    //     (actions, storeActions) => storeActions.EntityActionsStore.placeSaved,
    //     (state, { payload }) => {
    //         if (!state.places || !state.places.length) return;
    //         const places = state.places.map(e => e.id === payload.id ? payload : e);
    //         state.places = places;
    //     }
    // ),
    // onPlaceUnSave: actionOn(
    //     (actions, storeActions) => storeActions.EntityActionsStore.placeUnSaved,
    //     (state, { payload }) => {
    //         if (!state.places || !state.places.length) return;
    //         const places = state.places.map(e => e.id === payload ? omit(e, ['wishId', 'isWished']) : e);
    //         state.places = places;
    //     }
    // ),
};

export default UserSavedPlacesScreenStore;

