import React, { useState, useCallback } from "react";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Menu, MenuItem } from "@material-ui/core";
import Typo from "./Typo";
import { THEME_PALETTE, FONT_FAMILY } from "Theme/themeConstants";
import { useLocation, Link } from "react-router-dom";
import { useStoreState } from "Stores";
import appNavigator from "Features/Navigation/appNavigator";
// import { SHOP_URL } from "Constants/links";

// export interface MoreOptions {
//     id: string;
// }

function useMoreOptionsMenu() {

    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { pathname } = useLocation();
    const classes = useStyles({});


    const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), []);

    const closeMenu = useCallback(() => setAnchorEl(null), []);

    const open = Boolean(anchorEl);

    // const profileLink = appUser ? appNavigator.navigateToProfile(appUser.slug || appUser.id) : "#";

    return {
        openMenu,
        closeMenu,
        component: (
            <>
                <Menu
                    // id={config.id}
                    open={open}
                    onClose={closeMenu}
                    onClick={closeMenu}
                    // anchorReference="anchorEl"
                    anchorEl={anchorEl}
                    className={classes.paper}
                    PaperProps={{
                        elevation: 0,
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                    <Box width={70}>
                        {appUser ? (
                            <MenuItem className={classes.btnBox}>
                                <Box width="100%">
                                    <Link
                                        to={`/profile/${appUser.slug || appUser.id}`}
                                        className={pathname === `/profile/${appUser.slug || appUser.id}` ? classes.btnActive : classes.btn}
                                    >PROFILE</Link>
                                </Box>
                            </MenuItem>
                        ) : (
                            <MenuItem className={classes.btnBox}>
                                <Box width="100%">
                                    <Link
                                        to={appNavigator.navigateToAuth(true) as string}
                                        className={pathname === appNavigator.navigateToAuth(true) as string ? classes.btnActive : classes.btn}
                                    >SIGN IN</Link>
                                </Box>
                            </MenuItem>
                        )}
                        <MenuItem className={classes.btnBox}>
                            <Box width="100%">
                                <Link to="/insider" className={pathname === '/insider' ? classes.btnActive : classes.btn}>INSIDER</Link>
                            </Box>
                        </MenuItem>
                        <MenuItem className={classes.btnBox}>
                            <Box width="100%">
                                <Link to="/about" className={pathname === '/about' ? classes.btnActive : classes.btn}>ABOUT</Link>
                            </Box>
                        </MenuItem>
                        {/* <MenuItem className={classes.btnBox}>
                            <Box width="100%">
                                <Link to={{ pathname: SHOP_URL }} target="_blank" className={classes.btn}>SHOP</Link>
                            </Box>
                        </MenuItem> */}
                    </Box>
                </Menu>
            </>
        ),
    };
}

export default useMoreOptionsMenu;

const useStyles = makeStyles<Theme, any>((theme) => ({
    paper: {
        '& .MuiPaper-root': {
            top: 'unset !important',
            left: 'unset !important',
            right: '10px',
            bottom: '55px',
            boxShadow: theme.shadows[2],
        },
    },
    btnBox: {
        minHeight: '0px !important',
        height: '30px !important',
    },
    btn: {
        color: THEME_PALETTE.common.black,
        font: `normal ${theme.typography.fontWeightMedium} 8px/12px ${FONT_FAMILY.secondary}`,
    },
    btnActive: {
        color: THEME_PALETTE.primary.main,
        font: `normal ${theme.typography.fontWeightMedium} 8px/12px ${FONT_FAMILY.secondary}`,
    },
}));