
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class ListsBase {

/** 
 * Fetches belongsTo relation destination.
 */ 
    static get_Lists_id_destination<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/destination`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Find a related item by id for pictures.
 */ 
    static get_Lists_id_pictures_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/pictures/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for pictures.
 */ 
    static put_Lists_id_pictures_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/pictures/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for pictures.
 */ 
    static delete_Lists_id_pictures_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/pictures/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for establishments.
 */ 
    static get_Lists_id_establishments_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for establishments.
 */ 
    static put_Lists_id_establishments_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for establishments.
 */ 
    static delete_Lists_id_establishments_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Add a related item by id for establishments.
 */ 
    static put_Lists_id_establishments_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments/rel/${fk}`,
            method: 'put',
            
            
        })
    }

/** 
 * Remove the establishments relation to an item by id.
 */ 
    static delete_Lists_id_establishments_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments/rel/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Queries pictures of List.
 */ 
    static get_Lists_id_pictures<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/pictures`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in pictures of this model.
 */ 
    static post_Lists_id_pictures<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/pictures`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all pictures of this model.
 */ 
    static delete_Lists_id_pictures<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/pictures`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts pictures of List.
 */ 
    static get_Lists_id_pictures_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/pictures/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries establishments of List.
 */ 
    static get_Lists_id_establishments<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in establishments of this model.
 */ 
    static post_Lists_id_establishments<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all establishments of this model.
 */ 
    static delete_Lists_id_establishments<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts establishments of List.
 */ 
    static get_Lists_id_establishments_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/establishments/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Lists<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Lists`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_Lists<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Lists`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static put_Lists<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Lists`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
    static patch_Lists<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Lists`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete all matching records.
 */ 
    static delete_Lists<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Lists`,
            method: 'delete',
            
            data: {
                where
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static post_Lists_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Lists/replaceOrCreate`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
    static post_Lists_upsertWithWhere<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Lists/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Check whether a model instance exists in the data source.
 */ 
    static get_Lists_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/exists`,
            method: 'get',
            
            
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_Lists_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static put_Lists_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_Lists_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
    static delete_Lists_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static post_Lists_id_replace<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Lists/${id}/replace`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_Lists_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Lists/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
    static post_Lists_update<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Lists/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Count instances of the model matched by where from the data source.
 */ 
    static get_Lists_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Lists/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static get_Lists_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Lists/change-stream`,
            method: 'get',
            params: {
                options
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static post_Lists_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Lists/change-stream`,
            method: 'post',
            
            data: {
                options
            },
        })
    }

/** 
 * Publish entity.
 */ 
    static post_Lists_id_publish<T = any>(publish: any,id: string,) {
        return axios.request<T>({
            url: `/Lists/${id}/publish`,
            method: 'post',
            
            data: {
                publish
            },
        })
    }

/** 
 * getDeleted
 */ 
    static get_Lists_deleted<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Lists/deleted`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * moveToTrash
 */ 
    static delete_Lists_trash<T = any>(ids: any,) {
        return axios.request<T>({
            url: `/Lists/trash`,
            method: 'delete',
            
            data: {
                ids
            },
        })
    }

}

export default ListsBase;
    