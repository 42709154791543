import { requestDeviceLocation } from "Features/Cordova/nativeEvents";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { useEffect } from "react";
import { useStoreActions, useStoreState } from "Stores";


function useGeoLocation() {
    const { setGeoLocation, setAppPermissions } = useStoreActions(({ App: { setGeoLocation, setAppPermissions } }) => ({ setGeoLocation, setAppPermissions }))
    const { geoLocation, isCordovaApp } = useStoreState(({ App: { geoLocation, isCordovaApp } }) => ({ geoLocation, isCordovaApp }))
    const { isDeviceSm } = useMediaQuery();
    // useEffect(() => {
    //     document.addEventListener('geoCoords', (e: any) => {
    //         if (e?.detail?.error) {
    //             console.log("location error")
    //             alert("location error: " + JSON.stringify(e?.detail?.error?.code))
    //             setAppPermissions({ location: false });
    //         }
    //         else if (e?.detail?.coords?.latitude && e?.detail?.coords?.longitude) {
    //             const loc = { lat: e?.detail?.coords?.latitude, lng: e?.detail?.coords?.longitude }
    //             if (loc.lat && loc.lng) {
    //                 setAppPermissions({ location: true });
    //                 setGeoLocation(loc);
    //             }
    //         }
    //     });
    // }, [])

    useEffect(() => {
        if (!geoLocation || (!geoLocation.lat || !geoLocation.lng)) {

            if (isCordovaApp) {
                initNativeGeoLocation();
            } else {
                initWebGeoLocation()
            }
            // if (appUser && isDeviceSm && !isCordovaApp) {
            //     alert("setting geolocation - web -mobile")
            // }
            // else if (appUser && isCordovaApp) {
            //     alert("setting geolocation - cordova")
            // }
            // else if (!isDeviceSm) {
            //     initWebGeoLocation()
            //     alert("setting geolocation - web")
            // }
        }
    }, [geoLocation, isCordovaApp])



    const initWebGeoLocation = () => {
        if (!navigator.geolocation) return;

        navigator.geolocation.getCurrentPosition(
            (position: any) => {
                console.log('geolocation success', position)
                const currentLocation = { lat: position?.coords?.latitude, lng: position?.coords?.longitude }
                setGeoLocation(currentLocation);
                setAppPermissions({ location: true });
            },
            (positionError: any) => {
                console.log('Position Error', positionError);
                setGeoLocation(undefined);
                setAppPermissions({ location: false });
            },
            { enableHighAccuracy: true }
        );
    }


    const initNativeGeoLocation = () => {
        requestDeviceLocation();
        // document.dispatchEvent(new CustomEvent('requestLocation'));
    }

    // return { initGeoLocation, initNativeGeoLocation };
}


export default useGeoLocation;