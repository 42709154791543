import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, fade, IconButton, IconButtonProps } from '@material-ui/core';
import clsx from 'clsx';


export interface RoundedIconButtonProps extends IconButtonProps {
    disableElevation?: boolean,
    transparent?: boolean
}

const RoundedIconButton: FC<RoundedIconButtonProps> = (props) => {
    const { disableElevation = false, transparent = false, className, ...iconButtonProps } = props;
    const classes = useStyles({ disableElevation });
    return (
        <IconButton className={clsx(classes.root, className, { [classes.transparent]: transparent })} {...iconButtonProps} />
    )
}

const useStyles = makeStyles<Theme, { disableElevation: boolean }>((theme) => ({
    root: {

        boxShadow: props => props.disableElevation ? 'none' : theme.shadows[1]
    },
    transparent: {
        backgroundColor: fade('#000', 0.2)
    }
}));

export default RoundedIconButton;