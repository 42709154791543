import React, { FC, lazy, Suspense, useCallback, useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Box, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import Page404 from '../Screens/Page404';
import lazyImport from '../lazy-import';
import { useStoreActions, useStoreState } from 'Stores';
import Loader from 'Components/Loader';
import useScreenReinitialize from 'Features/AppInit/useScreenReinitialize';
import useGeoLocation from 'Features/geoLocation/useGeoLocation';
const PlaceComments = lazyImport('../Screens/PlaceComments');
const PlaceDetail = lazyImport('../Screens/PlaceDetail');
const PlaceDishes = lazyImport('../Screens/PlaceDishes');

const PlaceNavigation: FC = () => {
    const { id: placeId } = useParams<{ id: string }>();
    const { fetchPlaceDetail, setPlace } = useStoreActions(
        ({ PlaceDetailScreenStore: { fetchPlaceDetail, setPlace } }) => ({ fetchPlaceDetail, setPlace })
    );
    const { place, loading } = useStoreState(({ PlaceDetailScreenStore: { place, loading } }) => ({ place, loading }));
    const classes = useStyles({ loading });
    useGeoLocation();
    useScreenReinitialize(async () => fetchPlaceDetail(placeId));

    useEffect(() => {
        // if (place && (place.id === placeId || place.slug === placeId)) return;
        // resetPageData();
        fetchPageData(placeId);
    }, [placeId]);

    const fetchPageData = async (placeId: string) => {
        fetchPlaceDetail(placeId);
    };

    const resetPageData = () => {
        setPlace(undefined);
    };

    return (
        <div className={classes.root}>
            {loading && (
                <Box className={classes.loader}>
                    <Loader fullScreen />
                </Box>
            )}
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route path="/places/:id/dishes" exact component={PlaceDishes} />
                    <Route path="/places/:id/comments" exact component={PlaceComments} />
                    <Route path="/places/:id" exact component={PlaceDetail} />
                    <Route path="/*" exact component={Page404} />
                </Switch>
            </Suspense>
        </div>
    );
};

const useStyles = makeStyles<Theme, { loading: boolean }>((theme) => ({
    root: {
        '& >*': {
            display: (props) => (props.loading ? 'none' : ''),
        },
    },
    loader: {
        display: 'block !important',
    },
}));


export default PlaceNavigation;
