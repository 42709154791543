import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Accordion as MuiAccordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Typo from './Typo';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

export type AccordionExpandAllRef = { expandAll: () => void } | null;

export interface AccordionItemProps {
    option: { title: JSX.Element; body: JSX.Element };
    detailClasses?: string;
    isOpenAllOptions?: boolean;
}

export interface AccordionProps {
    options: { title: JSX.Element; body: JSX.Element }[];
    detailClasses?: string;
    isOpenAllOptions?: boolean;
}

const AccordionItem: FC<AccordionItemProps> = ({ option, detailClasses, isOpenAllOptions }) => {
    const classes = useStyles({});
    const [isOptionOpen, setIsOptionOpen] = useState(false);

    useEffect(() => {
        if(isOpenAllOptions !== undefined)setIsOptionOpen(isOpenAllOptions);
    }, [isOpenAllOptions]);
    

    return (
        <MuiAccordion className={classes.containerPaper} expanded={isOptionOpen} defaultExpanded>
            <AccordionSummary
                classes={{ expandIcon: classes.icon }}
                expandIcon={<ExpandMore color="primary" fontSize="small" />}
                onClick={() => setIsOptionOpen(!isOptionOpen)}
            >
                {option.title}
            </AccordionSummary>
            <AccordionDetails className={`${detailClasses} ${classes.detail}`}>{option.body}</AccordionDetails>
        </MuiAccordion>
    );
};

const Accordion: FC<AccordionProps> = ({ options, detailClasses, isOpenAllOptions }) => {
    const classes = useStyles({});
    return (
        <div className={clsx(classes.root)}>
            {options.map((o, i) => (
                <AccordionItem
                    key={i}
                    option={o}
                    detailClasses={detailClasses}
                    isOpenAllOptions={isOpenAllOptions}
                />
            ))}
        </div>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    containerPaper: {
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
    detail: {
        paddingTop: 0
    },
    icon: {
        background: 'transparent',
    }
}));

export default Accordion;