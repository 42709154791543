import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';
import { ForgotPasswordFormProps } from '.';


export const useFormConfig = (props: ForgotPasswordFormProps) => {
    const classes = useStyles();
    const actionConfig: IFormActionProps = {
        submitButtonText: 'Send Reset Link',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large',
        },
        containerClassNames: classes.buttonContainer,
    };

    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: 'text',
            valueKey: 'email',
            id: 'forgot-password-email',
            fieldProps: {
                label: 'Email',
                fullWidth: true,
            },
        },
    ];
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email is required'),
    });

    return {
        actionConfig,
        inputConfig,
        validationSchema,
    };
};


const useStyles = makeStyles<Theme>((theme) => createStyles({

}))