
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class PacksBase {

/** 
 * Fetches belongsTo relation trialForPack.
 */ 
    static get_Packs_id_trialForPack<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Packs/${id}/trialForPack`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Fetches belongsTo relation role.
 */ 
    static get_Packs_id_role<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Packs/${id}/role`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Packs<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Packs`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_Packs<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Packs`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static put_Packs<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Packs`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
    static patch_Packs<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Packs`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete all matching records.
 */ 
    static delete_Packs<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Packs`,
            method: 'delete',
            
            data: {
                where
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static post_Packs_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Packs/replaceOrCreate`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
    static post_Packs_upsertWithWhere<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Packs/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Check whether a model instance exists in the data source.
 */ 
    static get_Packs_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Packs/${id}/exists`,
            method: 'get',
            
            
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_Packs_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Packs/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static put_Packs_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Packs/${id}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_Packs_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Packs/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
    static delete_Packs_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Packs/${id}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static post_Packs_id_replace<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Packs/${id}/replace`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_Packs_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Packs/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
    static post_Packs_update<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Packs/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Count instances of the model matched by where from the data source.
 */ 
    static get_Packs_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Packs/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static get_Packs_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Packs/change-stream`,
            method: 'get',
            params: {
                options
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static post_Packs_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Packs/change-stream`,
            method: 'post',
            
            data: {
                options
            },
        })
    }

/** 
 * Get Packs
 */ 
    static get_Packs_get_packs<T = any>() {
        return axios.request<T>({
            url: `/Packs/get-packs`,
            method: 'get',
            
            
        })
    }

/** 
 * Publish entity.
 */ 
    static post_Packs_id_publish<T = any>(publish: any,id: string,) {
        return axios.request<T>({
            url: `/Packs/${id}/publish`,
            method: 'post',
            
            data: {
                publish
            },
        })
    }

}

export default PacksBase;
    