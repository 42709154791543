import React, { FC, Suspense, useEffect } from 'react'
import { LinearProgress } from '@material-ui/core'
import { useParams, Switch, Route } from 'react-router-dom'
import lazyImport from '../lazy-import'
import Page404 from '../Screens/Page404'
import { useStoreActions, useStoreState } from 'Stores'
import Loader from 'Components/Loader'
import useScreenReinitialize from 'Features/AppInit/useScreenReinitialize'

const EatineraryDetail = lazyImport('../Screens/EatineraryDetail')

const EatineraryNavigation: FC = () => {
    const { id: eatId } = useParams<{ id: string }>()

    const { getEatineraryDetails, setEatineraryDetails, setLoading } = useStoreActions(
        ({ EatineraryDetailScreenStore: { getEatineraryDetails, setEatineraryDetails, setLoading } }) => ({
            getEatineraryDetails,
            setEatineraryDetails,
            setLoading,
        })
    )
    const { eatineraryDetails, loading } = useStoreState(
        ({ EatineraryDetailScreenStore: { eatineraryDetails, loading } }) => ({ eatineraryDetails, loading })
    )

    useScreenReinitialize(async () => {
        setLoading(true)
        await getEatineraryDetails(eatId)
        setLoading(false)
    })

    useEffect(() => {
        if (eatineraryDetails && (eatineraryDetails.id === eatId || eatineraryDetails.slug === eatId)) return

        resetDetails()
        getEatineraryDetails(eatId)
    }, [eatId])

    const resetDetails = () => {
        setEatineraryDetails(undefined)
    }

    return (
        <>
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    {/* {loading ? <Loader overlayed /> : null} */}
                    <Route path="/eatinerary/:id" exact component={EatineraryDetail} />
                    <Route path="/*" exact component={Page404} />
                </Switch>
            </Suspense>
        </>
    )
}

export default EatineraryNavigation
