import { useStoreDispatch } from "easy-peasy";

export const ANALYTICS_USER_EVENT = 'ANALYTICS_USER_EVENT';
export const ANALYTICS_IDENTIFY_USER = 'ANALYTICS_IDENTIFY_USER';
export const ANALYTICS_RESET_USER = 'ANALYTICS_RESET_USER';
export const ANALYTICS_LOCATION_CHANGED = 'ANALYTICS_LOCATION_CHANGED';



export type TAnalyticDispatchPayload = { eventName: string } & Record<string, any>



export const useAnalyticsAction = () => {
    const dispatch = useStoreDispatch();

    const dispatchUserEvent = (payload: TAnalyticDispatchPayload) => {
        dispatch({ type: ANALYTICS_USER_EVENT, data: payload });
    }
    const dispatchPageEvent = () => {
        dispatch({ type: ANALYTICS_LOCATION_CHANGED, url: window.location.href });
    }

    return {
        dispatchPageEvent,
        dispatchUserEvent
    }
}
