import { Avatar, Box, Portal, useTheme } from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import helpers from 'Utils/helpers';
import Typo from 'Components/Typo';
import BadgeTag from 'Components/BadgeTag';
import { useStoreState } from 'Stores';
import UserModel from 'Models/User';
import LinkButton from 'Components/LinkButton';
import { DialogContext } from 'Dialogs/DialogContext';
import LOGO from 'Assets/images/logo-text.svg';

function useAppUserStatus() {
    // REDUX STUFF
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const classes = useStyles({});
    const theme = useTheme();
    const { openSubscriptionUpgradeDialog } = useContext(DialogContext);

    const data = useMemo(
        () => ({
            name: UserModel.getFirstName(appUser),
            subscriptionName: appUser ? UserModel.getMembershipName(appUser) : '',
        }),
        [appUser]
    );

    return {
        UiComponent: appUser ? (
            <>
                <div className={classes.root}>
                    <Box flex={1}>
                        <Typo weight="medium">Hi {data.name}!</Typo>
                    </Box>
                    {/* <Box display="flex" alignItems='center'>
                        {data.subscriptionName ? (
                            <Box display='flex' alignItems='center'>
                                <BadgeTag size='small' text={data.subscriptionName.toUpperCase()} textColor='white' bgColor={theme.palette.primary.main} />
                            </Box>
                        ) : null}

                    </Box> */}
                </div>
            </>
        ) : (
            <>
                <img src={LOGO} alt="Mamakoo Logo" className={classes.logo} />
            </>
        ),
    };
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            // alignItems: 'center'
        },
    },
    button: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    logo: {
        width: '35%',
        position: 'absolute',
    },
}));

export default useAppUserStatus;
