import React from 'react';
import { ReactForm, attachField } from 'react-forms';
import { useFormConfig } from './config';

export interface SupportFormData {
    message: string
}

export interface SupportFormProps {
    onSubmit: (data: SupportFormData) => any,
    initialData: Partial<SupportFormData>
    isSubmitting: boolean
}


const SupportForm: React.FC<SupportFormProps> = (props) => {
    const { actionConfig, inputConfig, validationSchema } = useFormConfig()
    return (
        <ReactForm
            initialValues={props.initialData}
            formId="Support"
            config={inputConfig}
            actionConfig={actionConfig}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
            formSettings={{
                verticalSpacing: 20,
            }}
            isInProgress={props.isSubmitting}
        />
    )
}



export default SupportForm
