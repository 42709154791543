import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, fade } from '@material-ui/core';
import clsx from 'clsx';
import LOADING_ANIMATION from 'Assets/images/loader.gif';
import { MOBILE_HEADER_HEIGHT } from 'Features/Header/MobileHeader';
import { APP_HEADER_HEIGHT } from 'Features/Header/AppHeader';
import { NAVIGATION_TAB_HEIGHT } from './NavigationTab';

export interface LoaderProps {
    fullScreen?: boolean
    overlayed?: boolean
}

const Loader: FC<LoaderProps> = ({ fullScreen = false, overlayed = false }) => {
    const classes = useStyles({});

    return (
        <Box className={clsx({
            [classes.fullScreen]: fullScreen,
            [classes.overlayed]: overlayed,
        })} display='flex' width='100%' justifyContent='center'>
            <img src={LOADING_ANIMATION} width={100} height={100} />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    fullScreen: {
        height: `calc(100vh - ${NAVIGATION_TAB_HEIGHT}px)`,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`
        }
    },
    overlayed: {
        position: 'fixed',
        top: 0,
        left: 0,
        backdropFilter: 'blur(20px)',
        zIndex: theme.zIndex.appBar + 1000,
        height: `100vh`,
        width: `100vw`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: fade('#fff', 0.4)
    }
}));

export default Loader;