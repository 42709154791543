
const withLink = (href: string, body: string, classNames?: string) => `<a class="${classNames}" href="${href}" target="_blank">${body}</a>`
const _regex = /<mention>name:([^;]+?);([^<]+?)<\/mention>/ig


export function renderHtmlStr(name: string, meta: string, shouldBlurPlaceName: boolean = false) {
    const _meta = JSON.parse(meta);
    if (!_meta.doc_type || !_meta.slug) return withLink('#', name);
    const baseUrl = window.location.origin + '/';
    const id = _meta.slug;

    switch (_meta.doc_type) {
        case 'Cuisine': return withLink(baseUrl + `cuisines/${id}`, name);
        case 'Establishment': return withLink(baseUrl + `places/${id}`, name, shouldBlurPlaceName ? 'mamakoo-blurred-place-name' : '');
        case 'Destination': return withLink(baseUrl + `cities/${id}`, name);
        case 'FoodDrink': return withLink(baseUrl + `dishes/${id}`, name);
        case 'List': return withLink(baseUrl + `explore/${id}`, name);
        case 'Eateries': return withLink(baseUrl + `eatinerary/${id}`, name);
        case 'Landmark': return withLink(baseUrl + `landmarks/${id}`, name);
    }
    return `<a href="#">${name}</a>`
}

export function getTextNode(text: string, renderMention: (name: string, meta: string) => string) {

    const _res = text.matchAll(_regex)
    let _htmlStr = text;

    for (let r of _res) {
        _htmlStr = _htmlStr.replace(r[0], renderMention(r[1], r[2]))
    }
    return _htmlStr
}