import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Typo from './Typo';
import LinkButton from './LinkButton';
import { FONT_FAMILY, TEXT_COLOR, THEME_PALETTE } from 'Theme/themeConstants';
import { ButtonClickEvent } from 'Constants/nativeEvents';

export interface SectionTitleProps {
    actionText?: string
    title: string | JSX.Element
    handleAction?: (e: ButtonClickEvent) => void
    subtitle?: string
    textOnly?: boolean
    convertTitleCase?: boolean
    badge?: string
}

const SectionTitle: FC<SectionTitleProps> = (props) => {
    const { title, handleAction, textOnly, subtitle, actionText = 'See all', convertTitleCase = true, badge } = props;
    const classes = useStyles({ convertTitleCase });



    return textOnly ? (
        typeof title === 'string' ?
            <Typo fontFamily='font3' className={classes.title} /* variant='body2' weight='medium' */ weight='bold'>{convertTitleCase ? title.toLowerCase() : title}</Typo> : title
    ) : (
            <Box className={classes.root}>
                <Box className={classes.titleContainer}>
                    <Box display='flex' alignItems='center'>
                        {typeof title === 'string' ? (
                            <Typo fontFamily='font3' className={classes.title} /* variant='body2' weight='medium' */ weight='bold'>{convertTitleCase ? title.toLowerCase() : title}</Typo>
                        ) : (title)}
                        {badge ? (
                            <Typo fontFamily="secondary" weight="medium" variant="caption" contrast className={classes.badge}>{badge}</Typo>
                        ):null}
                    </Box>
                    {handleAction ? (
                        <Box ml={1} className={classes.actionContainer}>
                            <LinkButton text={actionText} onClick={handleAction} />
                        </Box>
                    ) : null}
                </Box>
                <Box>
                    {subtitle ? (
                        <Typo className={classes.subtitle} variant='caption' color='textSecondary' fontFamily='secondary'>{subtitle}</Typo>
                    ) : null}
                </Box>
            </Box>
        )
}

const useStyles = makeStyles<Theme, { convertTitleCase: boolean }>((theme) => createStyles({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        alignItems: 'flex-start',
        // justifyContent: 'space-between',
        margin: theme.spacing(1, 0),
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(2, 0),
            // margin: theme.spacing(0, 5),
        }
    },
    title: {
        // color: TEXT_COLOR.title,
        color: THEME_PALETTE.common.black,
        textTransform: props => props.convertTitleCase ? 'capitalize' : 'none',
        // ...theme.typography.body2,
        fontSize: 18,
        // fontFamily: FONT_FAMILY.secondary,
        [theme.breakpoints.up('sm')]: {
            weight: theme.typography.fontWeightBold,
            // ...theme.typography.h6,
            fontSize: 24,
        }
    },
    subtitle: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        }
    },
    titleContainer: {
        display: 'flex',
        // alignItems: 'center',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            width: '100%',
        }
    },
    actionContainer: {
        [theme.breakpoints.down('sm')]: {
            '& .MuiTouchRipple-root': {
                height: 0,
            },
            '& > button': {
                padding: 0,
            },
        },
    },
   
    badge: {
        backgroundColor: THEME_PALETTE.primary.main,
        lineHeight: 1,
        padding: '1px 2px 2px',
        margin: theme.spacing(0, 0.5),
    },
}));

export default SectionTitle;