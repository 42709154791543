/* eslint-disable react-hooks/exhaustive-deps */
import { useAnalyticsAction } from 'Analytics/analyticActions';
import AppDownloadReminder from 'Features/Cordova/AppDownloadReminder';
import { useLocationEventListener } from 'Features/EventListeners/useLocationEventListener';
import usePaymentEventListener from 'Features/EventListeners/usePaymentEventListener';
import AppHeader from 'Features/Header/AppHeader';
import BottomTabBar from 'Features/TabNavigation/BottomTabBar';
import MobileAuthHandler from 'MobileAuthHandler';
import React, { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import RootContextProviders from '../Contexts';
import AppNavigation from '../Routes/AppNavigation';
import NewBottomTabBar from 'Features/TabNavigation/NewBottomTabBar';
import AppFooter from 'Features/Footer/AppFooter';
import { useAuth } from 'Features/Auth/useAuth';
import { useAuthDialog } from 'Dialogs/Authentication/useAuthDialog';
import { useStoreState } from 'Stores';
import { authPopupInterval } from 'App';
import { AppVariablesContext } from 'Contexts/AppVariablesContext';

const pagesWithNoGlobalAuthPopUp = ["/login", "/sign-up"];

const Layout: FC = memo(() => {
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const [isGlobalAuthCheckDone, setIsglobalAuthCheckDone] = useState(false);
    const { userLogin, userSignup, isRegistered, submitOtp, userLogout } = useAuth();
    const { isDialogOpen } = useContext(AppVariablesContext);
    const { openSignupDialog, AuthComponent } = useAuthDialog({
        onLogin: async (data) => {
            await userLogin(data);
            // window.location.reload();
        },
        onSignup: userSignup,
        handleCheck: isRegistered,
        onOtpSubmit: submitOtp,
    });

    const shouldHideAuthPop = (paths: string[], _pathname: string) => {
        return paths.reduce((acc, path) => {
            const reg = new RegExp(path);
            return acc || reg.test(_pathname);
        }, false);
    };

     const history = useHistory();

    useEffect(() => {
        const authPopupTimer =
            !appUser &&
            !isDialogOpen &&
            !isGlobalAuthCheckDone &&
            !shouldHideAuthPop(pagesWithNoGlobalAuthPopUp, history.location.pathname)
                ? setTimeout(function () {
                      if (!isDialogOpen) {
                          openSignupDialog();
                          setIsglobalAuthCheckDone(true);
                      }
                  }, authPopupInterval)
                : null;

        if (isDialogOpen && authPopupTimer && isGlobalAuthCheckDone) {
            clearTimeout(authPopupTimer);
        }

        return () => {
            if (authPopupTimer) clearTimeout(authPopupTimer);
        };
    }, [appUser, isDialogOpen, isGlobalAuthCheckDone]);

    return (
        <>
            <AppDownloadReminder />
            <AppHeader />
            <AppNavigation />
            <NewBottomTabBar />
            {/* <BottomTabBar /> */}
            <MobileAuthHandler />
            <AppFooter />
            <AuthComponent />
        </>
    );
}, () => true)


const RootLayout: FC = memo(() => {
    const { pathname } = useLocation();
    const { dispatchPageEvent } = useAnalyticsAction();
    useLocationEventListener();
    usePaymentEventListener();

    useEffect(() => {
        window.scrollTo({ top: 0 })
        dispatchPageEvent();
    }, [dispatchPageEvent, pathname])    

    return (
        <RootContextProviders>
            <Layout />
        </RootContextProviders>
    );
}, () => true)

export default RootLayout;

