import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, fade } from '@material-ui/core';
import IMG_1 from 'Assets/images/upgrade-benefits/1.jpg';
import IMG_2 from 'Assets/images/upgrade-benefits/2.jpg';
import IMG_3 from 'Assets/images/upgrade-benefits/3.jpg';
import IMG_4 from 'Assets/images/upgrade-benefits/4.jpg';
import Carousel from 'Components/Carousel';
import { DIALOG_CONTENT_WIDTH } from 'Constants/dimension';
import Typo from 'Components/Typo';
import Spacer from 'Components/Spacer';
import { useMediaQuery } from 'Hooks/useMediaQuery';

export interface UpgradeBenefitsProps {
    onAgree: () => void
    onCancel: () => void
    buttonText?: string
}


const BENEFITS = [
    { img: IMG_1, title: 'Full access to our<br/>restaurant list', subtitle: 'Handpicked and hyper-curated' },
    { img: IMG_2, title: 'Enjoy the best version<br/>of every dish', subtitle: 'Authentic, local, artisanal' },
    { img: IMG_3, title: 'Wander with confidence', subtitle: 'Say no to tourist traps, even near popular landmarks' },
    // { img: IMG_4, title: 'Stay flexible', subtitle: 'Pause when your travels end. Resume for your next adventure' },
]

const UpgradeBenefits: FC<UpgradeBenefitsProps> = (props) => {
    const { buttonText = 'UPGRADE', onAgree, onCancel } = props;
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles({});

    const renderItem = (config: typeof BENEFITS[0]) => {
        return (
            <div className={classes.content} style={{ backgroundImage: `url(${config.img})` }}>
                <div className={classes.overlay}>
                    <div className={classes.infoContainer}>
                        <Typo variant='h6' contrast dangerouslySetInnerHTML={{ __html: config.title }}></Typo>
                        <Typo fontFamily='secondary' className={classes.sub} contrast>{config.subtitle}</Typo>
                        <Spacer height={8 * 4 + 36 * 2 - 40} />
                        {/* <Button onClick={onAgree} className={classes.button} fullWidth color='primary' variant='contained'>{buttonText}</Button>
                        <Button onClick={onCancel} className={classes.button} fullWidth color='primary' >Maybe Later</Button> */}
                    </div>
                </div>
            </div>
        )
    }

    const beforeCarouselSlideChange = (cur: number, next: number) => {
        if (cur === BENEFITS.length - 1 && next === 0) {
            onAgree()
        }

    }
    return (
        <Box className={classes.root}>
            <Carousel
                showArrows={!isDeviceSm}
                carouselSettings={{ beforeChange: beforeCarouselSlideChange }}
                items={BENEFITS}
                renderItem={renderItem}
            />
            <div className={classes.buttonContainer}>
                <Button onClick={onAgree} className={classes.button} fullWidth color='primary' variant='contained'>{buttonText}</Button>
                <Button onClick={onCancel} className={classes.button} fullWidth color='primary' >Maybe Later</Button>
            </div>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        position: 'relative',
        width: DIALOG_CONTENT_WIDTH,
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            overflow: 'hidden'
        },
        '& .slick-dots': {
            display: 'flex !important',
            // top: '66vh',
            bottom: 300,
            width: 'fit-content',
            position: 'absolute',
            left: 40,
            [theme.breakpoints.up('sm')]: {
                top: '395px'
            }
        },
        '& .slick-prev, & .slick-next': {
            top: '50%'
        }

    },
    overlay: {
        width: '100%',
        height: '100%',
        background: fade('#0000007D', 0.50)
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 6,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            bottom: 60,
        },
    },
    button: {
        margin: theme.spacing(1, 0),
        // width: 'f'
    },
    content: {
        position: 'relative',
        width: '100%',
        height: '640px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: '100vh'
        }
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        left: 0,
        right: 0,
        position: 'absolute',
        // top: '66vh',
        // bottom: 0,
        bottom: 70,
        padding: theme.spacing(5),
        [theme.breakpoints.up('sm')]: {
            // top: '395px',
            bottom: 0,
        }
    },
    sub: {
        fontSize: 18,
        marginBottom: 16,
        marginTop: 8
    }
}));

export default UpgradeBenefits;