import React, { FC, lazy, Suspense, useEffect } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { LinearProgress } from '@material-ui/core'
import Page404 from '../Screens/Page404'
import lazyImport from '../lazy-import'
import { useStoreActions, useStoreState } from 'Stores'
import Loader from 'Components/Loader'
import ProtectedRoute from 'Components/ProtectedRoute'
import useScreenReinitialize from 'Features/AppInit/useScreenReinitialize'
import useGeoLocation from 'Features/geoLocation/useGeoLocation'
const UserSetting = lazyImport('../Screens/UserSetting')
const UserSuggestedPlaces = lazyImport('../Screens/UserSuggestedPlaces')
const UserSavedPlaces = lazyImport('../Screens/UserSavedPlaces')
const UserDetail = lazyImport('../Screens/UserDetail')
const UserPinedCities = lazyImport('../Screens/UserPinedCities')
const UserSavedList = lazyImport('../Screens/UserSavedList')

const UserNavigation: FC = () => {
    const { id: userId } = useParams<{ id: string }>()
    const { user, loading } = useStoreState(({ UserDetailScreenStore: { user, loading } }) => ({ user, loading }))
    const { fetchUserDetail, setUser } = useStoreActions(({ UserDetailScreenStore: { fetchUserDetail, setUser } }) => ({
        fetchUserDetail,
        setUser,
    }))

    useGeoLocation()
    useScreenReinitialize(async (updatedUser) => fetchPageData(userId))

    useEffect(() => {
        if (user && (user.id === userId || user.slug === userId)) return
        resetPageData()
        fetchPageData(userId)
    }, [userId])

    const fetchPageData = async (userId: string) => {
        fetchUserDetail(userId)
    }

    const resetPageData = () => {
        setUser(undefined)
    }

    return loading ? (
        <Loader fullScreen />
    ) : (
        <>
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route path="/profile/:id/passport" exact component={UserPinedCities} />
                    <Route path="/profile/:id/saved" exact component={UserSavedPlaces} />
                    <Route path="/profile/:id/suggested" exact component={UserSuggestedPlaces} />
                    {/* <ProtectedRoute path='/profile/:id/settings' exact component={UserSetting} /> */}
                    <Route path="/profile/:id" exact component={UserDetail} />
                    <Route path="/profile/:id/savedlist/:listId" exact component={UserSavedList} />
                    <Route path="/*" component={Page404} />
                </Switch>
            </Suspense>
        </>
    )
}

export default UserNavigation
