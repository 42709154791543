import { action, Action, actionOn, ActionOn, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import CityModel from "Models/City";
import { TCity } from "Models/City/@types";
import PlaceModel from "Models/Place";
import { TPlace } from "Models/Place/@types";
import { TRootStore } from "Stores";

export interface THomeScreenState {
    currentCityIndex: number
    setCurrentCityIndex: Action<THomeScreenState, number>
    savedPlaces: TPlace[],
    fetchSavedPlaces: Thunk<THomeScreenState, string, {}, TRootStore>
    setSavedPlaces: Action<THomeScreenState, TPlace[]>
}

const HomeScreenStore: THomeScreenState = {
    savedPlaces: [],
    currentCityIndex: 0,

    setSavedPlaces: action((state, payload) => {
        state.savedPlaces = payload;
    }),
    setCurrentCityIndex: action((state, payload) => {
        state.currentCityIndex = payload
    }),
    fetchSavedPlaces: thunk(async (actions, userId) => {
        const { data } = await PlaceModel.get_Establishments_wished_by_id<TPlace[]>(userId, {
            include: ["cuisines", "tags", "destination"]
        });
        actions.setSavedPlaces(data);
    }),

};

export default HomeScreenStore;

