import { fade, Theme, } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';
import { SpreadAssignment } from 'typescript';

export const FONT_FAMILY = {
    // primary: 'Quicksand',
    primary: 'Assistant',
    secondary: 'Assistant',
    font3: 'ivypresto-headline',
    font4: 'Arial',
}
export const TEXT_COLOR = {
    primary: '#505050',
    secondary: '#707070',
    title: '#262626'
}

export const THEME_PALETTE = {
    grey: {
        100: '#262626',
        200: '#4D4D4D',
        300: '#808080',
        400: '#D9D9D9',
        500: '#474747',
        600: '#707070',
        700: '#F5F5F5',
        800: '#363535',
        900: '#333333',
    },
    text: {
        primary: TEXT_COLOR.primary,
        secondary: TEXT_COLOR.secondary,
        // primary: "#4D4D4D",
        // secondary: "#808080"
    },
    background: {
        default: '#FFFAF8',
        paper: '#fff',
        background2: '#FFFBF8',
    },
    common: {
        black: '#000',
        white: '#fff',
    },
    action: {
        active: '#000',
        readMore: '#FF6250',
    },
    error: {
        main: '#B71840',
    },
    others: {
        ternary: '#8AAAC3',
        badgeGray: '#EBE4DB',
        // TagGray: '#324b4d',
        TagGray: '#707070',
        TitleGray: '#334B4D',
        DescriptionGray: '#18172B',
        Pearl: '#EBE4DB',
        T1: '#211F1D',
        T2: '#324B4D',
        T3: '#384B4D',
        T4: '#201F1D',
        T5: '#111111',
        B1: '#EFE6DC',
        B2: '#FD6250',
        B3: '#E9D9C6',
    },
    primary: {
        main: '#FF6250',
        light: '#FF9B75',
        contrastText: '#FFFFFF',
    },
    secondary: {
        // main: '#00576C',
        main: '#334B4D',
        contrastText: '#FFFFFF',
    },
    success: {
        main: '#4caf50',
    },
    warning: {
        main: '#ff9800',
    },
    info: {
        main: '#2196f3',
    },
};

export const DIVIDER_BORDER = `1px solid #D9D9D9`;
export const DIVIDER_BORDER_DOTTED = `1px dashed #D9D9D9`;
export const DIVIDER_BORDER_DOTTED_2 = '2px dotted #707070';

export const GRADIENTS = {
    primary: `transparent linear-gradient(120deg, #9FC2BB 0%, #8AAAC3 100%) 0% 0% no-repeat padding-box`,
    primaryFaded: `transparent linear-gradient(120deg, ${fade('#9FC2BB', 0.85)} 0%, ${fade('#8AAAC3', 0.85)} 100%) 0% 0% no-repeat padding-box`,
}