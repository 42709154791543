import { round } from "lodash";
import { TSubscriptionPack } from "Models/SubscriptionPack/@types";
import { CouponInstance } from "./@types";
import { BillingFormData } from "./CardDetailInput";
import { TTransactionData } from "./useSubscriptionActions";

export function initiateNativePayment() {
    document.dispatchEvent(new CustomEvent('buySubscription', {
        detail: {
        }
    }));
}




export function getDiscountedValue(pack: TSubscriptionPack, coupon: CouponInstance) {
    const val = pack.currencyValue;
    if (coupon.discountType === 'flat') {
        return val - coupon.discountValue;
    }
    else if (coupon.discountType === 'percent') {
        return val - (val * round(coupon.discountValue / 100, 2))
    }
    return val;
}

export function getDiscountValue(pack: TSubscriptionPack, coupon: CouponInstance) {
    const val = pack.currencyValue;
    if (coupon.discountType === 'flat') {
        return coupon.discountValue;
    }
    else if (coupon.discountType === 'percent') {
        return (val * round(coupon.discountValue / 100, 2))
    }
    return 0;
}


export function transformBillingFormData({ card, billing }: BillingFormData) {
    return {
        card,
        billingInfo: {
            name: billing.name,
            address: {
                line1: billing.address1,
                city: billing.city,
                postal_code: billing.zipCode
            }
        }
    }
}

export function limit(val: any, max: any) {
    if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            val = '01';

            //this can happen when user paste number
        } else if (val > max) {
            val = max;
        }
    }

    return val;
}

export function cardExpiry(val: any) {
    let month = limit(val.substring(0, 2), '12');
    let year = val.substring(2, 4);

    return month + (year.length ? '/' + year : '');
}



export function getCardImage(card: string) {
    try {
        const url = require(`../../Assets/images/credit-card/${card}.png`);
        return url
    } catch (error) {
        return '';
    }
}


