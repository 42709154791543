
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class LandmarksBase {

/** 
 * Fetches belongsTo relation destination.
 */ 
    static get_Landmarks_id_destination<T = any>(id: string,refresh?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/destination`,
            method: 'get',
            params: {
                refresh
            },
            
        })
    }

/** 
 * Find a related item by id for pictures.
 */ 
    static get_Landmarks_id_pictures_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/pictures/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for pictures.
 */ 
    static put_Landmarks_id_pictures_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/pictures/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for pictures.
 */ 
    static delete_Landmarks_id_pictures_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/pictures/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Find a related item by id for places.
 */ 
    static get_Landmarks_id_places_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places/${fk}`,
            method: 'get',
            
            
        })
    }

/** 
 * Update a related item by id for places.
 */ 
    static put_Landmarks_id_places_fk<T = any>(fk: string,id: string,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places/${fk}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a related item by id for places.
 */ 
    static delete_Landmarks_id_places_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Add a related item by id for places.
 */ 
    static put_Landmarks_id_places_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places/rel/${fk}`,
            method: 'put',
            
            
        })
    }

/** 
 * Remove the places relation to an item by id.
 */ 
    static delete_Landmarks_id_places_rel_fk<T = any>(fk: string,id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places/rel/${fk}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Queries pictures of Landmark.
 */ 
    static get_Landmarks_id_pictures<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/pictures`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in pictures of this model.
 */ 
    static post_Landmarks_id_pictures<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/pictures`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all pictures of this model.
 */ 
    static delete_Landmarks_id_pictures<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/pictures`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts pictures of Landmark.
 */ 
    static get_Landmarks_id_pictures_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/pictures/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Queries places of Landmark.
 */ 
    static get_Landmarks_id_places<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Creates a new instance in places of this model.
 */ 
    static post_Landmarks_id_places<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Deletes all places of this model.
 */ 
    static delete_Landmarks_id_places<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places`,
            method: 'delete',
            
            
        })
    }

/** 
 * Counts places of Landmark.
 */ 
    static get_Landmarks_id_places_count<T = any>(id: string,where?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/places/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
    static get_Landmarks<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Landmarks`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
    static post_Landmarks<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Landmarks`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static put_Landmarks<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Landmarks`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
    static patch_Landmarks<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Landmarks`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
    static post_Landmarks_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/replaceOrCreate`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
    static post_Landmarks_upsertWithWhere<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Check whether a model instance exists in the data source.
 */ 
    static get_Landmarks_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/exists`,
            method: 'get',
            
            
        })
    }

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
    static get_Landmarks_id<T = any>(id: string,filter?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static put_Landmarks_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}`,
            method: 'put',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
    static patch_Landmarks_id<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}`,
            method: 'patch',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
    static delete_Landmarks_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}`,
            method: 'delete',
            
            
        })
    }

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
    static post_Landmarks_id_replace<T = any>(id: string,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/replace`,
            method: 'post',
            
            data: {
                ...data
            },
        })
    }

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
    static get_Landmarks_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Landmarks/findOne`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
    static post_Landmarks_update<T = any>(where?: any,data?: any,) {
        return axios.request<T>({
            url: `/Landmarks/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

/** 
 * Count instances of the model matched by where from the data source.
 */ 
    static get_Landmarks_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Landmarks/count`,
            method: 'get',
            params: {
                where
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static get_Landmarks_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Landmarks/change-stream`,
            method: 'get',
            params: {
                options
            },
            
        })
    }

/** 
 * Create a change stream.
 */ 
    static post_Landmarks_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Landmarks/change-stream`,
            method: 'post',
            
            data: {
                options
            },
        })
    }

/** 
 * Publish entity.
 */ 
    static post_Landmarks_id_publish<T = any>(publish: any,id: string,) {
        return axios.request<T>({
            url: `/Landmarks/${id}/publish`,
            method: 'post',
            
            data: {
                publish
            },
        })
    }

/** 
 * getDeleted
 */ 
    static get_Landmarks_deleted<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Landmarks/deleted`,
            method: 'get',
            params: {
                filter
            },
            
        })
    }

/** 
 * moveToTrash
 */ 
    static delete_Landmarks_trash<T = any>(ids: any,) {
        return axios.request<T>({
            url: `/Landmarks/trash`,
            method: 'delete',
            
            data: {
                ids
            },
        })
    }

}

export default LandmarksBase;
    