import { TUserReview } from "Models/Insider/@types";
import { Thunk, Action, thunk, action } from "easy-peasy";
import InsiderModel from "Models/Insider";

export interface TInsiderScreenState {
    reviewData: TUserReview[];
    getReviewData: Thunk<TInsiderScreenState>;
    setReviewData: Action<TInsiderScreenState, TUserReview[]>;
}

const InsiderScreenStore: TInsiderScreenState = {

    reviewData: [],

    getReviewData: thunk(async (actions) => {

        const { data } = await InsiderModel.get_UserReview<TUserReview[]>();

        actions.setReviewData(data);

    }),

    setReviewData: action((state, payload) => {
        state.reviewData = payload;
    }),
    
};

export default InsiderScreenStore;

