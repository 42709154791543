import { TUser } from 'Models/User/@types';
import { useEffect, useRef, useState } from 'react';
import { useStoreActions, useStoreState } from 'Stores';


function useScreenReinitialize(reinitializeFn: (appUser: TUser) => Promise<any>) {
    const { } = useStoreActions(({ }) => ({}))
    const [current, setCurrent] = useState<TUser | undefined>();
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))
    const count = useRef(0);

    useEffect(() => {
        if (count.current)
            reinitializeScreen(appUser);
        if (!count.current)
            count.current++;
        // setRenderCount(prev => prev + 1)
    }, [appUser])


    const reinitializeScreen = async (appUser: TUser | undefined) => {
        if (!appUser) {
            setCurrent(appUser)
            return;
        };
        if (appUser && !current) {
            reinitializeFn(appUser)
            setCurrent(current);
            return;
        }
        if (appUser && appUser.subscriptions?.currentSubscription?.roleId !== current?.subscriptions?.currentSubscription?.roleId) {
            reinitializeFn(appUser)
            setCurrent(current);
        }
    }

}

export default useScreenReinitialize;