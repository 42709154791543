import { MiddlewareAPI } from "redux";
import { TRootStore } from "../Stores";
import { ANALYTICS_IDENTIFY_USER, ANALYTICS_LOCATION_CHANGED, ANALYTICS_RESET_USER, ANALYTICS_USER_EVENT } from "./analyticActions";



function reduxActionMapping(action: { type: string, payload: any } & Record<string, any>, store: MiddlewareAPI) {
    // ADD LISTENERS TO YOUR REDUX ACTIONS
    const { dispatch } = store;

    const getRootStoreState = () => {
        return store.getState() as TRootStore;
    }


    switch (action.type) {
        case '@action.App.locationChangeEvent': {
            dispatch({ type: ANALYTICS_LOCATION_CHANGED })
            break;
        }
        case '@action.AuthStore.setIsAuthenticated': {
            const state = getRootStoreState();
            if (action.payload)
                dispatch({ type: ANALYTICS_IDENTIFY_USER, data: { userId: state.AuthStore.appUser?.id, email: state.AuthStore.appUser?.email, traits: { email: state.AuthStore.appUser?.email } } })
            else
                dispatch({ type: ANALYTICS_RESET_USER })
            break
        }
        case '@action.PlaceListingStore.setQuery':
        case '@action.CuisineDetailScreenStore.setQuery':
        case '@action.CuisinePlacesScreenStore.setQuery':
        case '@action.DishDetailScreenStore.setQuery':
        case '@action.DishPlacesScreenStore.setQuery':
        case '@action.NearbyScreenStore.setQuery':
        case '@action.UserSuggestedPlacesScreenStore.setQuery':
        case '@action.UserSavedPlacesScreenStore.setQuery':
            dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'FILTER_APPLIED', path: window.location.pathname, ...action.payload } })
            break;

        case '@action.CityDetailScreenStore.setCity':
            if (action.payload)
                dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'CITY_SELECTED', path: window.location.pathname, id: action?.payload?.id, name: action?.payload?.name } })
            break;

        case '@action.CuisineDetailScreenStore.setCuisine':
            if (action.payload)
                dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'CUISINE_SELECTED', path: window.location.pathname, id: action?.payload?.id, name: action?.payload?.name } })
            break;

        case '@action.DishDetailScreenStore.setDish':
            if (action.payload)
                dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'DISH_SELECTED', path: window.location.pathname, id: action?.payload?.id, name: action?.payload?.name } })
            break;

        case '@action.PlaceDetailScreenStore.setPlace':
            if (action.payload)
                dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'PLACE_SELECTED', path: window.location.pathname, id: action?.payload?.id, name: action?.payload?.name } })
            break;

        case '@action.LandmarkDetailScreenStore.setLandmark':
            if (action.payload)
                dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'LANDMARK_SELECTED', path: window.location.pathname, id: action?.payload?.id, name: action?.payload?.name, destination: action?.payload?.destination?.name, destinationId: action?.payload?.destinationId } })
            break;

        case '@action.ExploreScreenStore.setExplore':
            if (action.payload)
                dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'EXPLORE_SELECTED', path: window.location.pathname, id: action?.payload?.id, name: action?.payload?.name, destination: action?.payload?.destination?.name, destinationId: action?.payload?.destinationId } })
            break;



    }
}


export default reduxActionMapping;