import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import DialogWrapper, { DialogWrapperProps } from 'Dialogs';
import SearchPage from 'Features/Search/SearchPage';

export interface SearchDialogProps extends DialogWrapperProps {

}

const SearchDialog: FC<SearchDialogProps> = (props) => {
    const classes = useStyles({});

    return (
        <DialogWrapper {...props} disableBackdropClick={false} classes={{ paper: classes.paper }}>
            <SearchPage onClose={props.onClose} />
        </DialogWrapper>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    paper: {
        borderRadius: 0,
        [theme.breakpoints.up('sm')]: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            width: 525,
            margin: 0,
            padding: theme.spacing(1),
            boxShadow: theme.shadows[3]
        }
    }
}));

export default SearchDialog;