import React, { FC, lazy, Suspense, useEffect } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { LinearProgress } from '@material-ui/core'
import Page404 from '../Screens/Page404'
import lazyImport from '../lazy-import'
import { useStoreActions, useStoreState } from 'Stores'
import Loader from 'Components/Loader'
import useGeoLocation from 'Features/geoLocation/useGeoLocation'

const CuisinePlaces = lazyImport('../Screens/CuisinePlaces')
const CuisineDetail = lazyImport('../Screens/CuisineDetail')
const CuisineDishes = lazyImport('../Screens/CuisineDishes')

const CuisineNavigation: FC = () => {
    const { id: cuisineId } = useParams<{ id: string }>()
    const { cuisine, loading } = useStoreState(({ CuisineDetailScreenStore: { cuisine, loading } }) => ({
        cuisine,
        loading,
    }))
    const { fetchCuisine, setCuisine } = useStoreActions(
        ({ CuisineDetailScreenStore: { fetchCuisine, setCuisine, setQuery } }) => ({
            fetchCuisine,
            setQuery,
            setCuisine,
        })
    )
    useGeoLocation()

    useEffect(() => {
        if (cuisine && (cuisine.id === cuisineId || cuisine.slug === cuisineId)) return
        resetPageData()
        fetchPageData(cuisineId)
    }, [cuisineId])

    const fetchPageData = async (cuisineId: string) => {
        fetchCuisine(cuisineId)
    }

    const resetPageData = () => {
        setCuisine(undefined)
    }

    return loading ? (
        <Loader fullScreen />
    ) : (
        <>
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route path="/cuisines/:id/places" exact component={CuisinePlaces} />
                    <Route path="/cuisines/:id/dishes" exact component={CuisineDishes} />
                    <Route path="/cuisines/:id" exact component={CuisineDetail} />

                    <Route path="/*" exact component={Page404} />
                </Switch>
            </Suspense>
        </>
    )
}

export default CuisineNavigation
