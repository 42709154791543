import React, { FC, lazy, Suspense, useEffect, useState, useCallback } from 'react';
import { Route, Switch, useParams } from 'react-router-dom'
import { Box, LinearProgress, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Page404 from '../Screens/Page404'
import lazyImport from '../lazy-import'
import { useStoreActions, useStoreState } from 'Stores'
import Loader from 'Components/Loader'
import useScreenReinitialize from 'Features/AppInit/useScreenReinitialize'
import useGeoLocation from 'Features/geoLocation/useGeoLocation'

const CityCuisines = lazyImport('../Screens/CityCuisines')
const CityLandmarks = lazyImport('../Screens/CityLandmarks')
const CityExplores = lazyImport('../Screens/CityExplores')
const CityDishes = lazyImport('../Screens/CityDishes')
const CityEatineraries = lazyImport('../Screens/CityEatineraries')
const CityDetail = lazyImport('../Screens/CityDetail')

const CityNavigation: FC = () => {
    const { setCity, fetchCity } = useStoreActions(({ CityDetailScreenStore: { setCity, fetchCity } }) => ({
        fetchCity,
        setCity,
    }))

    const { id: cityId } = useParams<{ id: string }>()
    const [loading, setLoading] = useState(false);
    const classes = useStyles({loading});

    useGeoLocation()
    useScreenReinitialize(async () => getCityDetail(cityId))

    const getCityDetail = useCallback(
        async (cityId: string) => {
            setLoading(true);
            // setCity(undefined)
            await fetchCity({ id: cityId });
            setLoading(false);
        },
        [fetchCity]
    );

    //TODO: Optimize this flow (Currently fetching cities everytime an user visits )
    useEffect(() => {
        // if (city && (city.id === cityId || city.slug === cityId)) return
        getCityDetail(cityId);
    }, [cityId, getCityDetail]);
// city, cityId, getCityDetail

    return (
        <div className={classes.root}>
            {loading && (
                <Box className={classes.loader}>
                    <Loader fullScreen />
                </Box>
            )}
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route path="/cities/:id" exact component={CityDetail} />
                    <Route path="/cities/:id/landmarks" exact component={CityLandmarks} />
                    <Route path="/cities/:id/explores" exact component={CityExplores} />
                    <Route path="/cities/:id/dishes" exact component={CityDishes} />
                    <Route path="/cities/:id/cuisines" exact component={CityCuisines} />
                    <Route path="/cities/:id/eatineraries" exact component={CityEatineraries} />
                    <Route path="/*" exact component={Page404} />
                </Switch>
            </Suspense>
        </div>
    );
}

const useStyles = makeStyles<Theme, { loading: boolean }>((theme) => ({
    root: {
        '& >*': {
            display: (props) => (props.loading ? 'none' : ''),
        },
    },
    loader: {
        display: 'block !important',
    },
}));


export default CityNavigation


