import { get } from 'lodash';
import React, { useState, StrictMode } from 'react';
import AuthDialog, { AuthType } from '.';
import { LoginFormData } from '../../Forms/Login';
import { SignUpFormData } from '../../Forms/SignUp';
import helpers from '../../Utils/helpers';
import useToastMessage from '../../Hooks/useToastMessage';
import { TUser } from '../../Models/User/@types';
import { OtpFormData } from 'Forms/OtpForm';
import AuthScreen from 'Screens/Auth/AuthScreen';
import { Redirect, useHistory } from 'react-router-dom';



export type useAuthDialogConfig = {
    onLogin: (form: LoginFormData) => Promise<void>
    onSignup: (form: SignUpFormData) => Promise<void>
    onOtpSubmit: (form: OtpFormData) => Promise<void>
    // onInterestSubmit: (form: Partial<TUser>) => Promise<void>
    onClose?: () => void
    onForgotPassword?: (email: string) => Promise<void>
    handleCheck?: (val: string) => Promise<boolean>
}


const EMAIL_EXISTS_ERROR = 'Email already exists. Please login.'
const FORM_ERROR = 'Please check username or password and try again.'

export const useAuthDialog = (config: useAuthDialogConfig) => {
    const [open, setOpen] = useState(false);
    const [strictMode, setStrictMode] = useState(false);
    const [type, setType] = useState<AuthType>('login');
    const [errorMessage, setErrorMessage] = useState('')
    const withToast = useToastMessage();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [isPage, setIsPage] = useState(false);

    let history = useHistory();

    const isScreen = (e: boolean) => {
        setIsPage(e);
    }

    const openAuthDialog = (strictMode: boolean = false) => {
        setErrorMessage('');
        setType('check');
        setOpen(true);
        setStrictMode(strictMode);
    }

    const openOtpDialog = () => {
        setErrorMessage('');
        setType('otp');
        setOpen(true);
    }

    const openLoginDialog = () => {
        setErrorMessage('')
        setType('login');
        setOpen(true);
    }



    const openSignupDialog = () => {
        setErrorMessage('')
        setType('signup');
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
        setStrictMode(false);
    }

    const handleClose = () => {
        closeDialog()
        if (config.onClose)
            config.onClose()
    }


    const handleTypeChange = (type: AuthType) => {
        setErrorMessage('')
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setType(type)
        }, 500);
    }

    const handleEmailCheck = async (email: string) => {
        setErrorMessage('')
        if (!helpers.validateEmail(email)) {
            setErrorMessage('Invalid email');
            throw ''
        }

        try {
            setLoading(true);
            if (config.handleCheck) {
                setLoading(false)
                return await config.handleCheck(email)
            }
            else {
                setLoading(false)
                return false
            }
        } catch (error) {
            setLoading(false);
            throw error
        }

    }

    const handleForgotPassword = async (email: string) => {
        if (!config.onForgotPassword) return;
        withToast(async () => await config.onForgotPassword!(email), {
            successToastMessage: 'Password reset link has been sent to ' + email
        })
    }

    const handleSubmit = async (formData: any) => {
        setLoading(true);
        setErrorMessage('')
        try {
            switch (type) {
                case 'login': {
                    await config.onLogin(formData);
                    if (isPage === true) {
                        history.push('/');
                    } else {
                        closeDialog();
                    }
                    break;
                }
                case 'signup': {
                    await config.onSignup(formData)
                    setType('otp')
                    setPassword(formData.password);
                    // await config.onLogin({ email: formData.email, password: formData.password });
                    break;
                }
                case 'otp': {
                    await config.onOtpSubmit(formData)
                    await config.onLogin({ email: formData.email, password: password });
                    // setType('interest')
                    // setType('onboarding').
                    if (isPage === true) {
                        history.push('/');
                    } else {
                        closeDialog();
                    }
                    break;
                }
                case 'onboarding': {
                    // setType('interest');
                    break;
                }
                // case 'interest': {
                //     closeDialog();
                //     break;
                // }

            }

        } catch (error) {
            if (get(error, 'response.data.error.statusCode') === 422)
                setErrorMessage(EMAIL_EXISTS_ERROR)
            else if (get(error, 'response.data.error.statusCode') === 401)
                setErrorMessage(FORM_ERROR)
            else
                setErrorMessage(get(error, 'response.data.error.message'))
        }
        setLoading(false);
        // if (type === 'otp') closeDialog();
    }
    return {
        openAuthDialog,
        openLoginDialog,
        openSignupDialog,
        openOtpDialog,
        closeDialog,
        isScreen,
        authType: type,
        AuthComponent: (
            open ?
                <AuthDialog strictMode={strictMode} loading={loading} onForgotPassword={handleForgotPassword} handleCheck={handleEmailCheck} onTypeChange={handleTypeChange} errorMessage={errorMessage} authType={type} open={open} onSubmit={handleSubmit} onClose={handleClose} />
                : null
        ),
        AuthScreenComponent: (
            <AuthScreen loading={loading} onForgotPassword={handleForgotPassword} handleCheck={handleEmailCheck} onTypeChange={handleTypeChange} errorMessage={errorMessage} authType={type} onSubmit={handleSubmit} />
        )
    }
}


