import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';
import { LoginFormProps } from '.';
import usePasswordField from '../../Components/Input/usePasswordField';





export const useFormConfig = (props: LoginFormProps) => {
    const classes = useStyles();
    const actionConfig: IFormActionProps = {
        submitButtonText: 'LOG IN',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large'
        },
        containerClassNames: classes.buttonContainer
    };

    const passwordField = usePasswordField('password', 'Password', true);
    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        // {
        //     type: 'email',
        //     valueKey: 'email',
        //     fieldProps: {
        //         fullWidth: true,
        //         onBack: props.onBack
        //     }
        // },
        passwordField,
    ];
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email is required'),
        password: Yup.string().required('Password is required')
    })

    return {
        actionConfig,
        inputConfig,
        validationSchema,
    }
}


const useStyles = makeStyles<Theme>((theme) => createStyles({

}))