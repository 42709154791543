import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Link, Paper } from '@material-ui/core';
import { Link as ReactLink } from 'react-router-dom';
import LoginForm, { LoginFormData, LoginFormProps } from 'Forms/AuthForms/Login';
import Typo from 'Components/Typo';
import useUserActions from 'Features/User/useUserActions';
import useAsyncTask from 'Hooks/useAsyncTask';
import SubmitButton from 'Components/SubmitButton';
import GRAPHIC from '../../Assets/animated/autorickshaw.json';
// import GRAPHIC from '../../Assets/images/payment/welcome.png';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import LottieImage from 'Components/LottieImage';
import Spacer from 'Components/Spacer';
import useStepper from 'Components/Stepper';
import { useAuth } from 'Features/Auth/useAuth';
import Wrapper from 'Components/Wrapper';
import ForgotPasswordForm from 'Forms/AuthForms/ForgotPassword';
import EmailField from 'Components/Input/EmailField';
import { settingUtils } from 'Models/Settings';
import { useStoreState } from 'Stores';
import helpers from 'Utils/helpers';

export interface LoginPageProps extends LoginFormProps {
    isInsideDialog?: boolean;
    StepperComponent?: JSX.Element;
    openSignup?: () => void;
    openForgotPassword?: boolean;
}

const LoginPage: FC<LoginPageProps> = (props) => {
    const {
        children,
        openSignup,
        isInsideDialog = false,
        StepperComponent,
        openForgotPassword = false,
        ...loginFormProps
    } = props;
    const classes = useStyles({ isInsideDialog });
    const [showForgotPassDialog, setShowForgotPassDialog] = useState(openForgotPassword);
    const { sendResetPasswordLink } = useUserActions();
    const { isDeviceSm } = useMediaQuery();
    const { settings } = useStoreState(({ App: { settings } }) => ({ settings }));

    const forgotPasswordHandler = useAsyncTask(async (input: { email: string }) => {
        await sendResetPasswordLink(input.email);
    });

    const View = ({ children }) => {
        return isDeviceSm || isInsideDialog ? <Box>{children}</Box> : <Paper elevation={2}>{children}</Paper>;
    };

    const image = settingUtils.getAuthScreenImage(isDeviceSm ? 'sm' : 'lg', settings || []);

    return (
        <Box display="flex" flexDirection={isDeviceSm ? 'column' : 'row'}>
            <Wrapper
                imgLink={image ? helpers.getPictureUrl(image, isDeviceSm ? 600 : 400) : ''}
                isInsideDialog={isInsideDialog}
            >
                <View>
                    {!showForgotPassDialog ? (
                        <>
                            <Box className={classes.stepper} display="flex" justifyContent="center">
                                {StepperComponent}
                            </Box>
                            <Spacer height={12} />
                            {/* <Typo variant="subtitle1" align="center" weight="medium" className={classes.text}>
                                Welcome to Mamakoo
                            </Typo> */}
                            <Typo
                                variant="h5"
                                align="center"
                                fontFamily="font3"
                                weight="medium"
                                className={classes.text}
                            >
                                Log In
                            </Typo>
                            <Spacer height={isInsideDialog && isDeviceSm ? 50 : 60} />
                            <Box className={classes.loginForm}>
                                <LoginForm {...loginFormProps} />
                            </Box>
                            <Box mt={4} display="flex" flexDirection="column" alignItems="start">
                                <Button
                                    onClick={() => setShowForgotPassDialog(true)}
                                    className={classes.submitBtn}
                                    color="primary"
                                >
                                    Forgot password?
                                </Button>

                                <Spacer height={12} />
                                <Typo
                                    variant="caption"
                                    fontFamily="secondary"
                                    weight="medium"
                                    align="left"
                                    className={classes.text}
                                >
                                    Don't have an account?
                                    <br />
                                    {openSignup ? (
                                        <Button onClick={openSignup} className={classes.submitBtn} color="primary">
                                            Sign Up
                                        </Button>
                                    ) : (
                                        <ReactLink to="/sign-up" className={classes.submitBtn}>
                                            Sign Up
                                        </ReactLink>
                                    )}
                                </Typo>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Spacer height={12} />
                            <Typo variant="subtitle1" align="center" weight="medium" className={classes.text}>
                                Welcome to Mamakoo
                            </Typo>
                            <Typo variant="h5" align="center" weight="medium" className={classes.text}>
                                Forgot Password
                            </Typo>
                            <Spacer height={50} />
                            <Box className={classes.loginForm}>
                                <ForgotPasswordForm
                                    onSubmit={forgotPasswordHandler.run}
                                    isSubmitting={forgotPasswordHandler.status === 'PROCESSING'}
                                />
                            </Box>
                            <Box mt={4} display="flex" flexDirection="column" alignItems="start">
                                <Typo
                                    variant="caption"
                                    fontFamily="secondary"
                                    weight="medium"
                                    align="left"
                                    className={classes.text}
                                >
                                    Already have an account?
                                    <br />
                                    <Button
                                        onClick={() => setShowForgotPassDialog(false)}
                                        className={classes.submitBtn}
                                        color="primary"
                                    >
                                        Log In
                                    </Button>
                                </Typo>

                                <Spacer height={12} />
                                <Typo
                                    variant="caption"
                                    fontFamily="secondary"
                                    weight="medium"
                                    align="left"
                                    className={classes.text}
                                >
                                    Don't have an account?
                                    <br />
                                    {openSignup ? (
                                        <Button onClick={openSignup} className={classes.submitBtn} color="primary">
                                            Sign Up
                                        </Button>
                                    ) : (
                                        <ReactLink to="/sign-up" className={classes.submitBtn}>
                                            Sign Up
                                        </ReactLink>
                                    )}
                                </Typo>
                            </Box>
                        </>
                    )}
                </View>
                {
                    isDeviceSm && !isInsideDialog ? (
                        <div className={classes.img}>
                            <LottieImage lotteJSON={GRAPHIC} dimension={{ width: '100vw', height: 'unset' }} />
                        </div>
                    ) : null
                    // <img src={GRAPHIC} alt='login-image' className={classes.img} /> : null
                }
            </Wrapper>
        </Box>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    stepper: {
        '& >*': {
            width: '56%',
            [theme.breakpoints.down('sm')]: {
                width: '65%',
            },
        },
    },
    img: {
        width: 100,
        position: 'absolute',
        top: '40rem',
        bottom: '0px',
        left: 0,
        objectFit: 'cover',
        padding: '0 !important',
    },
    loginForm: {
        width: '39vw',
        maxWidth: '390px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },
    submitBtn: {
        ...theme.typography.caption,
        padding: 0,
        justifyContent: 'flex-start',
    },
}));

export default LoginPage;
