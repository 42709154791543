import { TCity } from "Models/City/@types";

export const DESTINATION_FIELDS: (keyof TCity)[] = [
    '_landscapePictures',
    '_pictures',
    'id',
    'continent',
    'destinationPackId',
    'country',
    'counts',
    'foodDrinksCount',
    'establishmentCount',
    'description',
    'isPined',
    'location',
    'name',
    'pinId',
    'placeId',
    'slug',
    'state'
]