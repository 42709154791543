import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Card } from '@material-ui/core';
import { TTag } from 'Models/Tag/@types';
import appNavigator from 'Features/Navigation/appNavigator';
import { Link } from 'react-router-dom';
import Typo from 'Components/Typo';
import Query from 'Features/Query';

export interface TagCardProps {
    tag: TTag;
    onClicked?: () => void
}

const TagCard: FC<TagCardProps> = (props) => {
    const classes = useStyles({});
    const { tag, onClicked } = props;

    const query = new Query({ tagIds: [tag.id] })
    return (
        <Link onClick={onClicked} to={appNavigator.searchPlaces(query.getQueryString, true) as string}>
            <Card className={classes.root}>
                <Typo className={classes.text} weight='medium' fontFamily='secondary'>{tag.name}</Typo>
            </Card>
        </Link>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 1),
        }
    },
    text: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: 2.5
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: theme.typography.body2.fontSize
        // }
    }
}));

export default TagCard;