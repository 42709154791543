import PacksBase from 'BaseModels/Packs';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn, } from 'easy-peasy';
import { fetchCities, fetchSettings } from 'Features/AppInit/useAppInit';
import { TSavedCard } from 'Features/Subscription/@types';
import { TLocation } from 'Models/@types';
import { TAppConstants, TSettings } from 'Models/AppModel/@types';
import { TCity } from 'Models/City/@types';
import { TPlace } from 'Models/Place/@types';
import { TSubscriptionPack } from 'Models/SubscriptionPack/@types';
import { TTag } from 'Models/Tag/@types';
import TagCategoryModel from 'Models/TagCategory';
import { TTagCategory } from 'Models/TagCategory/@types';
import UserModel from 'Models/User';
import { TRootStore } from 'Stores';
import sortBy from 'lodash/sortBy';
import { TUserReview } from 'Models/Insider/@types';
import InsiderModel from 'Models/Insider';

export interface TAppPermission {
    location: boolean
    gps: boolean
}

export interface TState {
    appConstants?: TAppConstants;
    cities: TCity[];
    settings: TSettings[];
    isCordovaApp: boolean;
    shouldAllowWindowRefresh: boolean;
    devicePlatform?: 'android' | 'ios';
    permissions: TAppPermission;
    loadingCities: boolean;
    appInitialized: boolean;
    geoLocation: TLocation | undefined;
    tagsForPlaceRecommend: TTag[];
    savedCards: TSavedCard[];
    tagCategories: TTagCategory[];
    subscriptionPacks: TSubscriptionPack[];
    tags: TTag[];
    setTags: Action<TState, TTag[]>;
    reviewData: TUserReview[];

    setShouldAllowWindowRefresh: Action<TState, boolean>;
    setAppPermissions: Action<TState, Partial<TAppPermission>>;
    setDevicePlatform: Action<TState, TState['devicePlatform']>;
    setIsCordovaApp: Action<TState, boolean>;
    setSavedCards: Action<TState, TSavedCard[]>;
    setAppInitialized: Action<TState, boolean>;
    setLoadingCities: Action<TState, boolean>;
    fetchCities: Thunk<TState, undefined | TLocation, {}, TRootStore>;
    fetchSettings: Thunk<TState>;
    setSubscriptionPacks: Action<TState, TSubscriptionPack[]>;
    setGeoLocation: Action<TState, TLocation | undefined>;
    fetchSubscriptionPacks: Thunk<TState, void, {}, TRootStore>;
    fetchTagCategories: Thunk<TState, void, {}, TRootStore>;
    fetchSavedCards: Thunk<TState, string, {}, TRootStore>;
    setTagsForPlaceRecommend: Action<TState, TTag[]>;
    setTagCategories: Action<TState, TTagCategory[]>;
    setCities: Action<TState, TCity[]>;
    setSettings: Action<TState, TSettings[]>;
    setAppConstants: Action<TState, TAppConstants>;
    getReviewData: Thunk<TState>;
    setReviewData: Action<TState, TUserReview[]>;
    // onGeoLocationSet: ThunkOn<TState, {}, TRootStore>
}

const State: TState = {
    cities: [],
    settings: [],
    tags: [],
    reviewData: [],
    shouldAllowWindowRefresh: true,
    permissions: {
        location: false,
        gps: false,
    },
    isCordovaApp: false,
    loadingCities: false,
    appInitialized: false,
    savedCards: [],
    geoLocation: undefined,
    tagCategories: [],
    tagsForPlaceRecommend: [],
    subscriptionPacks: [],
    setTags: action((state, payload) => {
        state.tags = payload;
    }),
    setDevicePlatform: action((state, payload) => {
        state.devicePlatform = payload;
    }),
    setShouldAllowWindowRefresh: action((state, payload) => {
        state.shouldAllowWindowRefresh = payload;
    }),
    setSubscriptionPacks: action((state, payload) => {
        state.subscriptionPacks = payload;
    }),
    setIsCordovaApp: action((state, payload) => {
        state.isCordovaApp = payload;
    }),
    setAppPermissions: action((state, payload) => {
        state.permissions = { ...state.permissions, ...payload };
    }),
    setLoadingCities: action((state, payload) => {
        state.loadingCities = payload;
    }),
    fetchCities: thunk(async (actions, payload, { getState }) => {
        // if (getState().cities.length) return { data: getState().cities };
        actions.setLoadingCities(true);
        const { data } = await fetchCities(payload);
        actions.setCities(sortBy(data, 'name'));
        actions.setLoadingCities(false);
    }),
    fetchSettings: thunk(async (actions) => {
        actions.setLoadingCities(true);
        const { data } = await fetchSettings();
        actions.setSettings(data);
        actions.setLoadingCities(false);
    }),
    fetchSavedCards: thunk(async (actions, payload) => {
        const { data } = await UserModel.get_users_id_saved_cards<TSavedCard[]>(payload);
        actions.setSavedCards(data);
    }),
    setAppInitialized: action((state, payload) => {
        state.appInitialized = payload;
    }),
    setGeoLocation: action((state, payload) => {
        state.geoLocation = payload;
    }),
    setSavedCards: action((state, payload) => {
        state.savedCards = payload;
    }),
    fetchSubscriptionPacks: thunk(async (actions) => {
        const { data } = await PacksBase.get_Packs_get_packs<TSubscriptionPack[]>();
        actions.setSubscriptionPacks(data);
    }),
    fetchTagCategories: thunk(async (actions) => {
        const { data } = await TagCategoryModel.get_TagCategories<TTagCategory[]>({
            include: 'tags',
            order: 'order ASC',
        });
        actions.setTagCategories(data);
        actions.setTags(TagCategoryModel.getTagsFromTagCategories(data));
    }),

    setAppConstants: action((state, payload) => {
        state.appConstants = payload;
    }),
    setCities: action((state, payload) => {
        state.cities = payload;
    }),
    setSettings: action((state, payload) => {
        state.settings = payload;
    }),
    setTagCategories: action((state, payload) => {
        state.tagCategories = payload;
    }),
    setTagsForPlaceRecommend: action((state, payload) => {
        state.tagsForPlaceRecommend = payload;
    }),

    getReviewData: thunk(async (actions) => {
        Promise.all([await InsiderModel.get_UserReview<TUserReview[]>()]).then((data) => {
            actions.setReviewData(data[0].data);
        });
    }),

    setReviewData: action((state, payload) => {
        state.reviewData = payload;
    }),

    // onGeoLocationSet: thunkOn(actions => actions.setGeoLocation,
    //     async (actions, { payload }) => {
    //         if (payload && payload.lat && payload.lng) {
    //             const { data: cities } = await fetchCities(payload)
    //             actions.setCities(cities);
    //         }
    //     })
};

export default State;