import Axios, { CancelTokenSource } from "axios";
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import Query from "Features/Query";
import EstablishmentQuery from "Features/Query/EstablishmentQuery";
import { Facets, TLocation } from "Models/@types";
import PlaceModel from "Models/Place";
import { TPlace, TPlaceFilterResponse } from "Models/Place/@types";
import { TTag } from "Models/Tag/@types";
import { TRootStore } from "Stores";

export interface TNearbyScreenState {
    places: TPlace[]
    loading: boolean
    tag: TTag | undefined
    initialFacets: Facets
    facets: Facets
    shouldRecalculateBounds: boolean
    userSelectedGooglePlace: google.maps.places.PlaceResult | undefined
    query: Query
    pageNumber: number
    hasMore: boolean
    facetCityBuckets: { key: string, doc_count: number }[]
    isFirstTime: boolean
    setShouldRecalculateBounds: Action<TNearbyScreenState, boolean>
    setIsFirstTime: Action<TNearbyScreenState, boolean>
    setInitialFacets: Action<TNearbyScreenState, Facets>
    setFacetCityBuckets: Action<TNearbyScreenState, TNearbyScreenState['facetCityBuckets']>
    setTag: Action<TNearbyScreenState, TTag | undefined>
    setFacets: Action<TNearbyScreenState, Facets>
    setHasMore: Action<TNearbyScreenState, boolean>
    setPageNumber: Action<TNearbyScreenState, number>
    loadMore: Thunk<TNearbyScreenState, void, {}, TRootStore>
    setQuery: Action<TNearbyScreenState, Query>
    setLoading: Action<TNearbyScreenState, boolean>
    setUserSelectedGooglePlace: Action<TNearbyScreenState, google.maps.places.PlaceResult | undefined>
    setPlaces: Action<TNearbyScreenState, TPlace[]>
    fetchNearbyPlaces: Thunk<TNearbyScreenState, { query: Query, pageNumber?: number, append?: boolean }, {}, TRootStore>
    onQueryChange: ThunkOn<TNearbyScreenState, {}, TRootStore>
}

const LISTING_LIMIT = 21;

let cancelToken: CancelTokenSource | undefined = undefined;

const NearbyScreenStore: TNearbyScreenState = {
    places: [],
    loading: false,
    shouldRecalculateBounds: true,
    userSelectedGooglePlace: undefined,
    hasMore: true,
    pageNumber: 1,
    query: new Query({}),
    tag: undefined,
    initialFacets: {},
    facets: {},
    facetCityBuckets: [],
    isFirstTime: true,
    setShouldRecalculateBounds: action((state, payload) => {
        state.shouldRecalculateBounds = payload
    }),
    setIsFirstTime: action((state, payload) => {
        state.isFirstTime = payload
    }),
    setInitialFacets: action((state, payload) => {
        state.initialFacets = payload
    }),
    setFacetCityBuckets: action((state, payload) => {
        state.facetCityBuckets = payload
    }),
    setTag: action((state, payload) => {
        state.tag = payload;
    }),
    setFacets: action((state, payload) => {
        state.facets = payload
    }),
    setLoading: action((state, payload) => {
        state.loading = payload
    }),
    setUserSelectedGooglePlace: action((state, payload) => {
        state.userSelectedGooglePlace = payload
    }),
    setPlaces: action((state, payload) => {
        state.places = payload
    }),
    setHasMore: action((state, payload) => {
        state.hasMore = payload;
    }),
    setPageNumber: action((state, payload) => {
        state.pageNumber = payload;
    }),
    setQuery: action((state, payload) => {
        state.query = payload;
    }),
    loadMore: thunk(async (actions, _, { getState }) => {
        const { query, pageNumber } = getState();
        await actions.fetchNearbyPlaces({ query, pageNumber: pageNumber + 1, append: true })
    }),
    fetchNearbyPlaces: thunk(async (actions, { pageNumber = 1, query, append = false }, { getState, getStoreState }) => {
        const state = getState();
        actions.setPageNumber(pageNumber);
        actions.setLoading(true);
        const userSelectedGooglePlace = state.userSelectedGooglePlace?.geometry?.location
        const { App: { geoLocation: location } } = getStoreState();
        let locationFilter = {};
        if (userSelectedGooglePlace) {
            locationFilter = EstablishmentQuery.getLocationFilter({
                "lat": userSelectedGooglePlace.lat(),
                "lng": userSelectedGooglePlace.lng()
            })
        } else if (location) {
            locationFilter = EstablishmentQuery.getLocationFilter({
                "lat": location.lat,
                "lng": location.lng
            })
        }

        if (cancelToken) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = Axios.CancelToken.source();

        const { data } = await PlaceModel.searchPlaces({
            Establishment: {
                include: ["cuisines", "tags", {
                    relation: 'destination',
                    scope: {
                        include: {
                            relation: 'destinationPack',
                        },
                    },
                }],
            },
            limit: LISTING_LIMIT,
            skip: LISTING_LIMIT * (pageNumber - 1),
            ...query.getSearchFilter,
            ...locationFilter,
            where: {
                ...query.getSearchFilter.where
            },
        }, { cancelToken: cancelToken.token });
        const places = PlaceModel.transformSearchResult(data.hits);
        if (!places.length || places.length < LISTING_LIMIT)
            actions.setHasMore(false)
        else
            actions.setHasMore(true)

        if (state.isFirstTime) {
            actions.setInitialFacets(data.facets)
            actions.setIsFirstTime(false)
        }

        actions.setFacets(data.facets)
        actions.setPlaces(append ? [...state.places, ...places] : places);
        actions.setLoading(false)
    }),
    onQueryChange: thunkOn(
        (actions) => actions.setQuery,
        async (actions, { payload }) => {
            actions.fetchNearbyPlaces({ query: payload })
        }
    ),


};

export default NearbyScreenStore;

