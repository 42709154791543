import config from 'Config';
import { useEffect } from 'react';
import io from 'socket.io-client';
import { useStoreState } from 'Stores';
import utils from 'Utils';
import helpers from 'Utils/helpers';



export const SOCKET_EVENT_KEYS = {
    JOIN: 'join',
    SUBSCRIPTION_CANCEL: 'REVENUECAT:CANCELLATION'
}

const socket = io.connect(config.get('API_BASE_URL'), { autoConnect: false });

function useSocketListener() {
    const { appUser, shouldAllowWindowRefresh } = useStoreState(({ AuthStore: { appUser }, App: { shouldAllowWindowRefresh } }) => ({ appUser, shouldAllowWindowRefresh }))


    useEffect(() => {
        if (appUser)
            init();
        else disconnect();
    }, [appUser, shouldAllowWindowRefresh])

    const init = () => {
        try {
            disconnect();
            socket.connect();
            socket.on('connect', () => {
                const accessToken = utils.getAccessToken();
                console.log('socket connection successful', accessToken)
                if (!accessToken) return;
                socket.emit(SOCKET_EVENT_KEYS.JOIN, {
                    accessToken
                }, (data: any) => {
                    console.log("connected", data)
                })
            });


            socket.on(SOCKET_EVENT_KEYS.SUBSCRIPTION_CANCEL, () => {
                if (shouldAllowWindowRefresh)
                    window.location.reload();
            })

        } catch (error) {
            console.log("error", error);
            helpers.errorLog("SOCKET COULD NOT BE ESTABLISHED")
        }
    }

    const disconnect = () => {
        socket.removeAllListeners();
    }

    return {
        init,
        disconnect
    }
}

export default useSocketListener;