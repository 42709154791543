import { createMuiTheme, fade } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import { DIVIDER_BORDER, FONT_FAMILY, TEXT_COLOR, THEME_PALETTE } from './themeConstants';


const { others: otherColors, ...palette } = THEME_PALETTE;

shadows[1] = '0px 1px 1px #00000029';
// shadows[2] = '0px 3px 6px #00000029';
shadows[2] = '0px 2px 2px #00000029';
shadows[3] = '-3px 0px 6px #00000029';
shadows[4] = '0px 4px 4px 1px #00000029'
// shadows[4] = '0px 10px 20px 3px rgb(0 0 0 / 20% )';

export default createMuiTheme({
    props: {
        MuiLink: {
            style: { textDecoration: 'none' },
            target: '_blank'
        }
    },
    palette,
    shadows,
    typography: {
        fontFamily: FONT_FAMILY.primary,
        fontWeightBold: 700,
        fontWeightMedium: 600,
        fontWeightRegular: 400,
        h1: {
            fontSize: 96
        },
        h2: {
            fontSize: 60
        },
        h3: {
            fontSize: 48
        },
        h4: {
            fontSize: 38
        },
        h5: {
            fontSize: 24
        },
        h6: {
            fontSize: 20
        },
        button: {
            fontSize: 18,
            lineHeight: '27px',
            textTransform: 'none',
        },
        subtitle1: {
            fontFamily: FONT_FAMILY.secondary,
            fontSize: 16,
            lineHeight: '21px'
        },
        subtitle2: {
            fontFamily: FONT_FAMILY.secondary,
            fontSize: 14,
            lineHeight: 1.4
        },
        body1: {
            fontSize: 16
        },
        body2: {
            fontSize: 14
        },
        caption: {
            fontSize: 12,
            fontWeight: 600,
        },
        overline: {
            fontSize: 10,
            lineHeight: 1,
            fontWeight: 600,
            textTransform: 'none'
        },
        

    },
    overrides: {

        MuiTooltip: {
            // popper: {
            //     backgroundColor: fade('#000', 0.5),
            //     color: 'white'
            // }
        },
        MuiContainer: {
            maxWidthLg: {
                maxWidth: 1000,
                '@media (min-width: 1280px)': {
                    maxWidth: 1100,
                }
            }
        },
        MuiFormLabel: {
            root: {
                fontFamily: FONT_FAMILY.secondary,
                fontWeight: 600
            }
        },
        MuiFormControlLabel: {

            root: {
                fontFamily: FONT_FAMILY.secondary,
                fontWeight: 600
            }

        },
        MuiInputBase: {
            root: {
                fontFamily: FONT_FAMILY.secondary,
                fontWeight: 600
            },
            multiline: {
                border: DIVIDER_BORDER,
                padding: 2,

            }
        },
        MuiSnackbar: {
            root: {
                zIndex: 2000
            },
        },
        MuiDrawer: {

            paper: {
                backgroundColor: THEME_PALETTE.background.default
            }
        },
        MuiChip: {
            root: {
                backgroundColor: '#F5F5F5',
                color: TEXT_COLOR.secondary,
                '&:focus': {
                    backgroundColor: fade('#F5F5F5', 0.1)
                }
            },
            deletable: {
                '&:focus': {
                    backgroundColor: fade('#F5F5F5', 0.5)
                }

            }
        },
        MuiSvgIcon: {
            colorAction: {
                color: '#F5F5F5',
            },
            root: {
                color: THEME_PALETTE.text.secondary
            },

        },
        MuiIconButton: {
            root: {
                backgroundColor: 'white',
                padding: 9,
                '&:hover': {
                    backgroundColor: '#fff8 !important',

                },
            },

            sizeSmall: {
                padding: 6
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                }
            },
            outlinedPrimary: {
                borderColor: THEME_PALETTE.grey['400']
            }
        }
    }
})