import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';
import OTP from 'react-otp-input';
export interface OtpInputProps extends IFieldProps { }

const OtpInput: FC<OtpInputProps> = (props) => {
    const value = props.formikProps?.values[props.fieldConfig?.valueKey || '']
    // const error = !!props.formikProps?.errors['email']
    // const errorText = props.formikProps?.errors['email'] || ''
    const handleChange = (value: any) => {
        props.formikProps?.setFieldValue(props.fieldConfig?.valueKey || '', value)
    }
    return (
        <OTP
            numInputs={6}
            onChange={handleChange}
            value={value}
            isInputNum={true}
            containerStyle={{ justifyContent: 'center' }}
            focusStyle={{
                // backgroundColor: '#707070',
                height: 42,
                width: 42,
                fontSize: 20,
                // color: '#fff',
                margin: '0px 4px'
            }}
            inputStyle={{
                // backgroundColor: '#707070',
                fontSize: 20,
                height: 42,
                width: 42,
                // color: '#fff',
                margin: '0px 4px'
            }}
        />
    )
}

export default OtpInput