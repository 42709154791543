import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAuthDialog } from 'Dialogs/Auth/useAuthDialog';
import { useAuth } from 'Features/Auth/useAuth';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStoreState } from 'Stores';

export interface MobileAuthHandlerProps {


}

const MobileAuthHandler: FC<MobileAuthHandlerProps> = () => {
  const classes = useStyles();
  const { isDeviceSm } = useMediaQuery();
  const location = useLocation();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))
  const { userLogin, userSignup, isRegistered, submitOtp, } = useAuth();
  const { openAuthDialog, AuthComponent } = useAuthDialog({
    onLogin: userLogin,
    onSignup: userSignup,
    handleCheck: isRegistered,
    onOtpSubmit: submitOtp
  })


  useEffect(() => {
    const isResetPassword = location.pathname.split('/').includes('reset-password');
    const isSettings = location.pathname.split('/').includes('settings');

    // ONLY RESET_PASSWORD PAGE is available for non-logged in user in sm devices
    // PROTECTED ROUTES already have Auth handling, so it is also not required
    if (isResetPassword || isSettings) return;

    // if (isDeviceSm && !appUser) {
    //   openAuthDialog(true);
    // }
  }, [appUser, isDeviceSm, location])

  return AuthComponent
}

const useStyles = makeStyles<Theme>((theme) => {
  return (createStyles({

  }))
})

export default MobileAuthHandler