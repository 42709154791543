import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Link as MuiLink } from '@material-ui/core';
import { String } from 'lodash';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ButtonClickEvent } from 'Constants/nativeEvents';

export interface LinkButtonProps {
    onClick?: (e: ButtonClickEvent) => void
    url?: string
    text: string
    external?: boolean
    className?: string
}

const LinkButton: FC<LinkButtonProps> = (props) => {
    const { onClick, external, url, text, className } = props;
    const classes = useStyles({});

    return url ? (
        external ? (
            <MuiLink target="_blank" href={url} className={clsx(classes.actionButton, className)} >
                {text}
            </MuiLink>
        ) : (
                <Link to={url} className={clsx(classes.actionButton, className)} >
                    {text}
                </Link>
            )
    ) : (
            <Button onClick={onClick} className={clsx(classes.actionButton, className)} variant='text' color='primary'>{text}</Button>
        )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    actionButton: {
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        // ...theme.typography.caption,
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
            // fontSize: 14,
            fontSize: 16,

        },
        '&:hover': {
            textDecoration: 'none'
        }

    }
}));

export default LinkButton;