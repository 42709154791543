import { action, Action } from "easy-peasy";
import { CouponInstance } from "Features/Subscription/@types";
import { TSubscriptionPack } from "Models/SubscriptionPack/@types";

export interface TSubscriptionState {
    coupon: CouponInstance | null
    isTrailOver: boolean
    setIsTrailOver: Action<TSubscriptionState, boolean>
    setCoupon: Action<TSubscriptionState, CouponInstance | null>
    selectedPack: TSubscriptionPack | undefined;
    setSelectedPack: Action<TSubscriptionState, TSubscriptionPack | undefined>;
    nativePayStatus: 'initiated' | 'success' | 'error' | 'idle'
    setNativePayStatus: Action<TSubscriptionState, TSubscriptionState['nativePayStatus']>;
}

const SubscriptionStore: TSubscriptionState = {
    selectedPack: undefined,
    isTrailOver: false,
    coupon: null,
    nativePayStatus: 'idle',
    setNativePayStatus: action((state, payload) => {
        state.nativePayStatus = payload
    }),
    setIsTrailOver: action((state, payload) => {
        state.isTrailOver = payload
    }),
    setSelectedPack: action((state, payload) => {
        state.selectedPack = payload
    }),
    setCoupon: action((state, payload) => {
        state.coupon = payload
    })
};

export default SubscriptionStore;

