import axios from 'axios';
import { TUser } from './@types';
import usersBase from 'BaseModels/users'
import Axios from 'axios';


// GET /users/{userId}/trial-redeemed
class UserModel extends usersBase {
    static baseUrl = '/users'
    static getAvatarImageUrl(user: TUser) {
        return user?._profile_image?.url
    }


    static getSubscriptionStatus(user: TUser) {
        let subscriptionMode: 'app' | 'web' = 'app';
        let subscriptionStatus: 'active' | 'cancelled' = 'cancelled';
        if (user.subscriptions?.currentSubscription?.isWebTransaction) {
            subscriptionMode = 'web';
            if (user.isPaidUser)
                subscriptionStatus = 'active'
            else
                subscriptionStatus = 'cancelled'
        } else {
            subscriptionMode = 'app';
            if (user.subscriptions?.currentSubscription?.lastSubscriptionEventType === 'CANCELLATION')
                subscriptionStatus = 'cancelled'
            else
                subscriptionStatus = 'active'
        }

        return {
            subscriptionMode, subscriptionStatus
        }
    }

    static getFirstName(user?: TUser) {
        return user?.name?.split(' ')?.[0] || ''
    }

    static isAccessLimited(user?: TUser) {
        if (!user) return true;
        // if (UserModel.getMembershipName(user) === 'BASIC') return true;
        return false;
    }
    static getMembershipName(user: TUser) {
        // user?.subscriptions?.currentSubscription?.
        return user?.subscriptions?.currentSubscription?.pack?.name.toUpperCase() || 'BASIC'
    }

    static isTrailRedeemed(userId: string, packId: string) {
        return Axios.request<boolean>({
            url: this.baseUrl + `/${userId}/trial-redeemed`,
            params: { packId }
        })
    }

    static isSameUser(userA: TUser, userB: TUser) {
        return userA.id === userB.id;
    }

}

export default UserModel;
