import React, { FC, lazy, memo, Suspense } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import Page404 from '../Screens/Page404';
import UserNavigation from './UserNavigation';
import PlaceNavigation from './PlaceNavigation';
import DishNavigation from './DishNavigation';
import CuisineNavigation from './CuisineNavigation';
import CityNavigation from './CityNavigation';
import lazyImport from '../lazy-import';
import ProtectedRoute from 'Components/ProtectedRoute';
import EatineraryNavigation from './EatineraryNavigation';
import { useStoreState, useStoreActions } from 'Stores';
import UserModel from 'Models/User';
import useScreenReinitialize from 'Features/AppInit/useScreenReinitialize';
import { APP_HEADER_HEIGHT } from 'Features/Header/AppHeader';
import { NAVIGATION_TAB_HEIGHT } from 'Components/NewNavigationTab';
import { APP_FOOTER_HEIGHT, MOBILE_APP_FOOTER_HEIGHT } from 'Features/Footer/AppFooter';
import SignUpPage from 'Screens/Authentication/Signup';
import LoginPage from 'Screens/Authentication/Login';
import Authentication from 'Screens/Authentication';
import PrivateRoute from 'Components/PrivateRoute';

const UserSetting = lazyImport('../Screens/UserSetting');
const Nearby = lazyImport('../Screens/Nearby');
const ResetPassword = lazyImport('../Screens/ResetPassword');
const SearchPage = lazyImport('../Features/Search/SearchPage');
const LandmarkDetail = lazyImport('../Screens/LandmarkDetail');
const ExploreScreen = lazyImport('../Screens/ExploreScreen');
const PlaceListing = lazyImport('../Screens/PlaceListing');
const Auth = lazyImport('../Screens/Auth');
const Payment = lazyImport('../Screens/Payment');
const Landing = lazyImport('../Screens/Landing');
const Destination = lazyImport('../Screens/Destination');
const Insider = lazyImport('../Screens/Insider');
const About = lazyImport('../Screens/About');

const Home = lazyImport('../Screens/Home');

const AppNavigation: FC = memo(
    () => {
        // const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
        const { getHomeData, setLoading } = useStoreActions(({ LandingScreenStore: { getHomeData, setLoading } }) => ({
            getHomeData,
            setLoading,
        }));
        // const isLocked = UserModel.isAccessLimited(appUser);

        useScreenReinitialize(async () => {
            setLoading(true);
            await getHomeData({ quotesModelName: 'Quote', quoteCount: 1 });
            setLoading(false);
        });

        const classes = useStyles({});

        return (
            <div className={classes.root}>
                <Suspense fallback={<LinearProgress />}>
                    <Switch>
                        <Route path="/" exact component={Landing} />
                        <Route path="/reset-password/:token" exact component={ResetPassword} />
                        <Route path="/landmarks/:id" exact component={LandmarkDetail} />
                        <Route path="/explore/:id" exact component={ExploreScreen} />
                        <Route path="/places" exact component={PlaceListing} />
                        <Route path="/search" exact component={SearchPage} />
                        <PrivateRoute path="/settings" exact component={UserSetting} />
                        <Route
                            path="/nearby"
                            component={memo(
                                () => (
                                    <Nearby />
                                ),
                                () => true
                            )}
                        />
                        {/* <Route path="/authentication" exact component={Auth} /> */}
                        <Route path="/payment" exact component={Payment} />
                        <Route path="/landing" exact component={Landing} />
                        <Route path="/destinations" exact component={Destination} />
                        {/* {isLocked ? <Route path='/insider' exact component={Insider} /> : null} */}
                        <Route path="/insider" exact component={Insider} />
                        <Route path="/about" exact component={About} />

                        <Route path="/cities/:id" component={CityNavigation} />
                        <Route path="/cuisines/:id" component={CuisineNavigation} />
                        <Route path="/dishes/:id" component={DishNavigation} />
                        <Route path="/eatinerary/:id" component={EatineraryNavigation} />
                        <Route path="/places/:id" component={PlaceNavigation} />
                        <Route path="/profile/:id" component={UserNavigation} />
                        {/* <Route path="/auth" exact component={Authentication} /> */}
                        <Route path="/sign-up" exact component={SignUpPage} />
                        <Route path="/login" exact component={LoginPage} />

                        <Route path="/*" exact component={Page404} />
                    </Switch>
                </Suspense>
            </div>
        );
    },
    () => true
);

export default AppNavigation;

const useStyles = makeStyles<Theme, any>((theme: Theme) =>
    createStyles({
        root: {
            minHeight: `calc(100vh - ${APP_HEADER_HEIGHT + APP_FOOTER_HEIGHT}px)`,
            [theme.breakpoints.down('sm')]: {
                minHeight: `calc(100vh - ${NAVIGATION_TAB_HEIGHT + MOBILE_APP_FOOTER_HEIGHT}px)`,
            },
        },
    })
);
