import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import Typo from 'Components/Typo';
import CloseIconButton from 'Components/Buttons/CloseIconButton';
import SearchBox from 'Features/Search/SearchBox';
import { APP_HEADER_HEIGHT } from 'Features/Header/AppHeader';
import { useMediaQuery } from 'Hooks/useMediaQuery';

export interface DialogHeaderProps {
    onSearch?: (text: string) => void
    title?: string
    onClose?: () => void
    searchPlaceHolder?: string
}

const DialogHeader: FC<DialogHeaderProps> = (props) => {
    const { onClose, onSearch, title, searchPlaceHolder } = props;
    const classes = useStyles({});
    const { isDeviceSm } = useMediaQuery();


    return (
        <AppBar elevation={1} position='sticky'>
            <Toolbar className={classes.toolbar}>
                <Box flex={1}>
                    <Typo className={classes.title}>{title}</Typo>
                </Box>
                <Box className={classes.searchBoxContainer}>
                    {onSearch && !isDeviceSm ? (
                        <Box width={400} className={classes.searchBar}>
                            <SearchBox placeHolder={searchPlaceHolder} onChange={onSearch} />
                        </Box>
                    ) : null}
                </Box>
                <Box flex={onSearch ? 1 : undefined} display='flex' justifyContent='flex-end'>
                    <CloseIconButton onClick={onClose} />
                </Box>
            </Toolbar>
            {onSearch && isDeviceSm ? (
                <Toolbar className={classes.toolbar}>
                    <Box width={400} className={classes.searchBar}>
                        <SearchBox onChange={onSearch} />
                    </Box>
                </Toolbar>
            ) : null}
        </AppBar>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    toolbar: {
        backgroundColor: '#fff',
    },

    title: {
        fontWeight: theme.typography.fontWeightMedium,
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,

        }
    },
    searchBar: {
        [theme.breakpoints.up('sm')]: {
            transform: `translateY(32px)`
        }
    },
    searchBoxContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default DialogHeader;