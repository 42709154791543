import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import usePasswordField from 'Components/Input/usePasswordField';
import { FormConfig, IFormActionProps } from 'react-forms';
import { THEME_PALETTE } from 'Theme/themeConstants';
import * as Yup from 'yup';





export const useFormConfig = () => {
    const classes = useStyles();
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email is required'),
        password: Yup.string().min(6).required('Password is required'),
        name: Yup.string().required('Name is required')
    })

    const actionConfig: IFormActionProps = {
        submitButtonText: 'NEXT',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large'
        },
        containerClassNames: classes.buttonContainer
    };

    const passwordField = usePasswordField('password', 'Password');


    const inputConfig: Array<Array<FormConfig> | FormConfig> = [
        {
            type: 'text',
            valueKey: 'email',
            id: 'signup-email',
            fieldProps: {
                label: 'Email',
                disabled: true,
                fullWidth: true,
            }
        },
        {
            type: 'text',
            id: 'signup-name',
            valueKey: 'name',
            fieldProps: {
                label: 'Name',
                fullWidth: true,
                autoFocus: true,
            }
        },
        passwordField,
        {
            type: 'checkbox',
            valueKey: 'newsletter',
            id: 'newsletter-subscribe',
            fieldProps: {
                label: 'Be the first to hear about new destination guides and email-exclusive offers',
                value: 'subscribe-to-newletters',
                formControlLabelProps: {
                    classes: {
                        label: classes.newsletter,
                    },
                    // checked: true,
                },
            },
        },
    ];


    return { actionConfig, inputConfig, validationSchema }
}




const useStyles = makeStyles((theme: Theme) => createStyles({
    buttonContainer: {

    },
    newsletter: {
        // color: THEME_PALETTE.grey[600],
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 15,
    },
}))