import React from "react";
import { useCallback, useState } from "react";
import InterestDialog from ".";



function useInterestDialog() {
    const [open, setOpen] = useState(false);


    const closeDialog = useCallback(() => setOpen(false), []);
    const openDialog = useCallback(() => setOpen(true), []);

    return {
        openDialog,
        closeDialog,
        DialogComponent: (
            <InterestDialog open={open} onClose={closeDialog} />
        )
    }
}


export default useInterestDialog;