
import axios, { AxiosRequestConfig } from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

class CuisinesBase {

    /** 
     * Find a related item by id for pictures.
     */
    static get_Cuisines_id_pictures_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/pictures/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for pictures.
     */
    static put_Cuisines_id_pictures_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/pictures/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for pictures.
     */
    static delete_Cuisines_id_pictures_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/pictures/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for establishments.
     */
    static get_Cuisines_id_establishments_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/establishments/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for establishments.
     */
    static put_Cuisines_id_establishments_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/establishments/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for establishments.
     */
    static delete_Cuisines_id_establishments_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/establishments/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for establishments.
     */
    static put_Cuisines_id_establishments_rel_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/establishments/rel/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Remove the establishments relation to an item by id.
     */
    static delete_Cuisines_id_establishments_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/establishments/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for destinations.
     */
    static get_Cuisines_id_destinations_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for destinations.
     */
    static put_Cuisines_id_destinations_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for destinations.
     */
    static delete_Cuisines_id_destinations_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for destinations.
     */
    static put_Cuisines_id_destinations_rel_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations/rel/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Remove the destinations relation to an item by id.
     */
    static delete_Cuisines_id_destinations_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Find a related item by id for foodDrinks.
     */
    static get_Cuisines_id_foodDrinks_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks/${fk}`,
            method: 'get',


        })
    }

    /** 
     * Update a related item by id for foodDrinks.
     */
    static put_Cuisines_id_foodDrinks_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a related item by id for foodDrinks.
     */
    static delete_Cuisines_id_foodDrinks_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Add a related item by id for foodDrinks.
     */
    static put_Cuisines_id_foodDrinks_rel_fk<T = any>(fk: string, id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks/rel/${fk}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Remove the foodDrinks relation to an item by id.
     */
    static delete_Cuisines_id_foodDrinks_rel_fk<T = any>(fk: string, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks/rel/${fk}`,
            method: 'delete',


        })
    }

    /** 
     * Queries pictures of Cuisine.
     */
    static get_Cuisines_id_pictures<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/pictures`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in pictures of this model.
     */
    static post_Cuisines_id_pictures<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/pictures`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all pictures of this model.
     */
    static delete_Cuisines_id_pictures<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/pictures`,
            method: 'delete',


        })
    }

    /** 
     * Counts pictures of Cuisine.
     */
    static get_Cuisines_id_pictures_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/pictures/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries establishments of Cuisine.
     */
    static get_Cuisines_id_establishments<T = any>(id: string, filter?: any, axiosRequestConfig?: AxiosRequestConfig) {
        return axios.request<T>({
            ...axiosRequestConfig,
            url: `/Cuisines/${id}/establishments`,
            method: 'get',

            params: {
                filter
            },


        })
    }

    /** 
     * Creates a new instance in establishments of this model.
     */
    static post_Cuisines_id_establishments<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/establishments`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all establishments of this model.
     */
    static delete_Cuisines_id_establishments<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/establishments`,
            method: 'delete',


        })
    }

    /** 
     * Counts establishments of Cuisine.
     */
    static get_Cuisines_id_establishments_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/establishments/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries destinations of Cuisine.
     */
    static get_Cuisines_id_destinations<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in destinations of this model.
     */
    static post_Cuisines_id_destinations<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all destinations of this model.
     */
    static delete_Cuisines_id_destinations<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations`,
            method: 'delete',


        })
    }

    /** 
     * Counts destinations of Cuisine.
     */
    static get_Cuisines_id_destinations_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/destinations/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Queries foodDrinks of Cuisine.
     */
    static get_Cuisines_id_foodDrinks<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Creates a new instance in foodDrinks of this model.
     */
    static post_Cuisines_id_foodDrinks<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Deletes all foodDrinks of this model.
     */
    static delete_Cuisines_id_foodDrinks<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks`,
            method: 'delete',


        })
    }

    /** 
     * Counts foodDrinks of Cuisine.
     */
    static get_Cuisines_id_foodDrinks_count<T = any>(id: string, where?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/foodDrinks/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Find all instances of the model matched by filter from the data source.
     */
    static get_Cuisines<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Cuisines`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Create a new instance of the model and persist it into the data source.
     */
    static post_Cuisines<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Cuisines`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Replace an existing model instance or insert a new one into the data source.
     */
    static put_Cuisines<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Cuisines`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Patch an existing model instance or insert a new one into the data source.
     */
    static patch_Cuisines<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Cuisines`,
            method: 'patch',

            data: {
                ...data
            },
        })
    }

    /** 
     * Replace an existing model instance or insert a new one into the data source.
     */
    static post_Cuisines_replaceOrCreate<T = any>(data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/replaceOrCreate`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     */
    static post_Cuisines_upsertWithWhere<T = any>(where?: any, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/upsertWithWhere`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

    /** 
     * Check whether a model instance exists in the data source.
     */
    static get_Cuisines_id_exists<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/exists`,
            method: 'get',


        })
    }

    /** 
     * Find a model instance by {{id}} from the data source.
     */
    static get_Cuisines_id<T = any>(id: string, filter?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Replace attributes for a model instance and persist it into the data source.
     */
    static put_Cuisines_id<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}`,
            method: 'put',

            data: {
                ...data
            },
        })
    }

    /** 
     * Patch attributes for a model instance and persist it into the data source.
     */
    static patch_Cuisines_id<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}`,
            method: 'patch',

            data: {
                ...data
            },
        })
    }

    /** 
     * Delete a model instance by {{id}} from the data source.
     */
    static delete_Cuisines_id<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}`,
            method: 'delete',


        })
    }

    /** 
     * Replace attributes for a model instance and persist it into the data source.
     */
    static post_Cuisines_id_replace<T = any>(id: string, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/replace`,
            method: 'post',

            data: {
                ...data
            },
        })
    }

    /** 
     * Find first instance of the model matched by filter from the data source.
     */
    static get_Cuisines_findOne<T = any>(filter?: any,) {
        return axios.request<T>({
            url: `/Cuisines/findOne`,
            method: 'get',
            params: {
                filter
            },

        })
    }

    /** 
     * Update instances of the model matched by {{where}} from the data source.
     */
    static post_Cuisines_update<T = any>(where?: any, data?: any,) {
        return axios.request<T>({
            url: `/Cuisines/update`,
            method: 'post',
            params: {
                where
            },
            data: {
                ...data
            },
        })
    }

    /** 
     * Count instances of the model matched by where from the data source.
     */
    static get_Cuisines_count<T = any>(where?: any,) {
        return axios.request<T>({
            url: `/Cuisines/count`,
            method: 'get',
            params: {
                where
            },

        })
    }

    /** 
     * Create a change stream.
     */
    static get_Cuisines_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Cuisines/change-stream`,
            method: 'get',
            params: {
                options
            },

        })
    }

    /** 
     * Create a change stream.
     */
    static post_Cuisines_change_stream<T = any>(options?: any,) {
        return axios.request<T>({
            url: `/Cuisines/change-stream`,
            method: 'post',

            data: {
                options
            },
        })
    }

    /** 
     * filterBy
     */
    static get_Cuisines_filterBy<T = any>(instanceId: any, modelName: any, filter?: any,) {
        return axios.request<T>({
            url: `/Cuisines/filterBy`,
            method: 'get',
            params: {
                instanceId, modelName, filter
            },

        })
    }

    /** 
     * details
     */
    static get_Cuisines_id_details<T = any>(id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/details`,
            method: 'get',


        })
    }

    /** 
     * Publish entity.
     */
    static post_Cuisines_id_publish<T = any>(publish: any, id: string,) {
        return axios.request<T>({
            url: `/Cuisines/${id}/publish`,
            method: 'post',

            data: {
                publish
            },
        })
    }

}

export default CuisinesBase;
