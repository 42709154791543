import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, IconButton, Paper } from '@material-ui/core';
import { useStoreState } from '../../Stores';
import CloseIconButton from 'Components/Buttons/CloseIconButton';
import Typo from 'Components/Typo';
import { useMediaQuery } from 'Hooks/useMediaQuery';

export interface AppDownloadReminderProps {

}

const TEXT = "We look better on the app 😎";

let isNative = false;

function shouldOpen() {
    return !isNative;
}

const AppDownloadReminder: FC<AppDownloadReminderProps> = (props) => {
    const classes = useStyles({});
    const [open, setOpen] = useState(false);
    const { isDeviceSm } = useMediaQuery();


    useEffect(() => {
        setTimeout(() => {
            setOpen(isDeviceSm && shouldOpen());
        }, 6000)
        document.addEventListener('nativeAppLoaded', () => {
            isNative = true;
        })
    }, [])

    return open ? (
        <Paper square className={classes.root}>
            <CloseIconButton onClick={() => setOpen(false)} />
            <Typo weight='medium'>{TEXT}</Typo>
            <Button variant="text" color="primary">
                <a href="https://mamakoo.page.link/home" target="_blank">OPEN</a>
            </Button>
        </Paper>
    ) : <div />
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        zIndex: theme.zIndex.modal,
        padding: '16px 10px 30px 10px',
        boxSizing: 'border-box',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > button:first-child': {
            marginLeft: 'auto',
        },
        '& a': {
            color: 'inherit'
        }
    }
}));

export default AppDownloadReminder;