import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DialogWrapper from 'Dialogs';
import DialogHeader from 'Dialogs/DialogHeader';
import { Box } from '@material-ui/core';
import { DIALOG_CONTENT_WIDTH } from 'Constants/dimension';
import Typo from 'Components/Typo';
import SectionTitle from 'Components/SectionTitle';
import SupportForm, { SupportFormData } from 'Forms/Support';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import useUserActions from 'Features/User/useUserActions';

export interface SupportDialogProps {
    open: boolean
    onClose: () => void
}

const SupportDialog: FC<SupportDialogProps> = ({ open, onClose }) => {
    const classes = useStyles({});
    const { isDeviceSm } = useMediaQuery();
    const { sendFeedback } = useUserActions();

    const submitHandler = useAsyncTask<SupportFormData>(async (data) => {
        try {
            await sendFeedback(data);
            onClose();
        } catch (error) {

        }

    })

    return (
        <DialogWrapper fullScreen open={open} onClose={onClose}>
            <DialogHeader title='Support' onClose={onClose} />
            <Box p={2} className={classes.container}>
                <SectionTitle convertTitleCase={false} textOnly title='How can we help you?' />
                <Typo variant='subtitle2'>Are you experiencing an issue with Mamakoo or wanting to share your feedback?</Typo>
                <Box mt={2}>
                    <SupportForm
                        initialData={{}}
                        isSubmitting={submitHandler.status === 'PROCESSING'}
                        onSubmit={submitHandler.run}
                    />
                </Box>
            </Box>
        </DialogWrapper>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    container: {
        margin: '0 auto',
        width: 800,
        // width: DIALOG_CONTENT_WIDTH,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    title: {

    }
}));

export default SupportDialog;