import { findAll } from "highlight-words-core";
import _ from 'lodash'

export default (config: { searchWords: Array<string>, textToHighlight: string }) => {
    const chunks = findAll(config);

    return (_.map(chunks, (chunk, index) => {
        const { end, highlight, start } = chunk;
        const text = config.textToHighlight.substr(start, end - start);
        if (highlight) {
            return `<mark>${text}</mark>`;
        } else if (text !== 'undefined') {
            return text;
        } else return ''
    }).join('')
    )
}