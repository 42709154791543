export const PRIVACY_POLICY_URL = 'https://shop.mamakoo.com/policies/privacy-policy';
export const TERMS_CONDITIONS_URL = 'https://shop.mamakoo.com/policies/terms-of-service';
export const FAQ_URL = 'https://shop.mamakoo.com/pages/faq';
export const SHOP_URL = 'https://shop.mamakoo.com/'

export const BECOME_A_RECOMMENDER_EXTERNAL_FORM_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLScyxhpsnBNkqSG2ZfAVRwbKrokz25kBEwK-_NHlqXUCXUez-w/viewform'


export const ITUNES_LINK = 'https://finance-app.itunes.apple.com/account/subscriptions';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/account/subscriptions';

export const FACEBOOK_URL = 'https://www.facebook.com/mamakooapp/';
export const INSTAGRAM_URL = 'https://www.instagram.com/mamakoo_';
export const TWITTER_URL = 'https://twitter.com/mamakoo_';
export const TIK_TOK_URL = 'https://vm.tiktok.com/ZM8atRAre/';