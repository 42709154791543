import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import JoinMembershipPage from './JoinMembershipPage';
import CardDetailPage from './CardDetailPage';
import { useStoreActions, useStoreState } from 'Stores';
import Loader from 'Components/Loader';
import TagPreferenceSelectorPage from 'Features/Tag/TagPreferenceSelectorPage';
import { TSubscriptionPack } from 'Models/SubscriptionPack/@types';
import { CouponInstance } from 'Features/Subscription/@types';
import SubscriptionSuccessPage from '../Subscription/SubscriptionSuccessPage';
import UpgradeBenefits from 'Features/Subscription/UpgradeBenefits';
import GetSubscriptionWithPromo from 'Features/Subscription/SubscriptionSteps/GetSubscriptionWithPromo';

export interface OnboardingProps {
    onDone?: () => void

}

export type OnboardingStep = 'join' | 'cardDetail' | 'thankyou' | 'promoInput'

const Onboarding: FC<OnboardingProps> = (props) => {
    const classes = useStyles({});
    const [step, setStep] = useState<OnboardingStep>('join');
    const [loading, setLoading] = useState(false);
    const { fetchSubscriptionPacks, setSelectedPack, setCoupon } = useStoreActions(({ App: { fetchSubscriptionPacks }, SubscriptionStore: { setSelectedPack, setCoupon } }) => ({ fetchSubscriptionPacks, setSelectedPack, setCoupon }))
    const { subscriptionPacks, selectedPack, coupon } = useStoreState(({ App: { subscriptionPacks }, SubscriptionStore: { selectedPack, coupon } }) => ({ subscriptionPacks, selectedPack, coupon }))

    // useEffect(() => {
    //     if (subscriptionPacks.length) return;
    //     getSubscriptionPacks();
    // }, [])

    // const getSubscriptionPacks = async () => {
    //     setLoading(true)
    //     try {
    //         fetchSubscriptionPacks();
    //     } catch (error) {

    //     }
    //     setLoading(false)
    // }

    useEffect(() => {
        const pack = subscriptionPacks.find(p => p.value === 'world');
        setSelectedPack(pack)
    }, [subscriptionPacks])

    const handleJoinMembership = (selectedPack: TSubscriptionPack, selectedCoupon: CouponInstance | null) => {
        setSelectedPack(selectedPack);
        setCoupon(selectedCoupon);
        setStep('cardDetail')
    }

    const onTransactionComplete = () => {
        setStep('thankyou');
    }

    const onComplete = () => {
        setStep('join');
        props.onDone?.();
    }


    // const pages: Record<OnboardingStep, JSX.Element> = {

    // join: <UpgradeBenefits onAgree={() => setStep('promoInput')} onClose={() => props?.onDone?.()}/>,
    // promoInput: <GetSubscriptionWithPromo onNext={handleCouponEntry} />,
    // cardDetail: selectedPack ? <CardDetailPage onBack={() => setStep('join')} onClose={props.onDone} onDone={onTransactionComplete} subscriptionPack={selectedPack} /> : <div />,
    // thankyou: <SubscriptionSuccessPage buttonText="CONTINUE" onClick={onComplete} />,
    // }

    return null

    // return loading ? <Loader /> : (
    //     <Box>
    //         {pages[step]}
    //     </Box>
    // )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default Onboarding;