import { useState } from "react";

const useStepper = (length: number) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextStep = (skip: number = 0) => {
        if (currentIndex + skip + 1 >= length) return;
        setCurrentIndex(currentIndex + 1 + skip);
    }

    const prevStep = () => {
        if (currentIndex - 1 < 0) return;
        setCurrentIndex(currentIndex - 1);
    }

    const resetStepper = () => setCurrentIndex(0);

    return {
        currentIndex,
        nextStep,
        prevStep,
        setCurrentIndex,
        hasNext: currentIndex + 1 < length,
        hasPrev: currentIndex - 1 >= 0,
        resetStepper
    }
}

export default useStepper;