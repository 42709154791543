import React, { FC, memo } from 'react';
import _ from 'lodash';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import clsx from 'clsx';


export interface SectionContainerProps {
    margin?: 'small' | 'medium'
    isFirstContainer?: boolean
    className?: string
    id?: string
}

const SectionContainer: FC<SectionContainerProps> = (props) => {
    const classes = useStyles({});
    const { margin, isFirstContainer = false, className, id } = props;
    const { isDeviceSm } = useMediaQuery();

    return (
        <Container id={id} className={clsx(classes.container, {
            [classes.marginSmall]: margin === 'small',
            [classes.marginMedium]: margin === 'medium',
            [classes.isFirstContainer]: isFirstContainer,

        }, className)} maxWidth={isDeviceSm ? undefined : 'lg'}>
            {props.children || <div />}
        </Container>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    container: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 3)
        }
    },
    marginMedium: {
        margin: theme.spacing(10, 'auto'),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(4, 'auto'),
        }
    },
    marginSmall: {
        margin: theme.spacing(6, 'auto'),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 'auto'),
        }
    },
    isFirstContainer: {
        paddingTop: 60,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 32,
        }
    }
}));

export default memo(SectionContainer, (a, b) => _.isEqual(a, b)) as typeof SectionContainer;