import { Avatar, IconButton } from '@material-ui/core';
import { makeStyles, Theme, fade } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import clsx from 'clsx';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React, { FC, LegacyRef, useRef } from 'react';
import Slider, { Settings } from "react-slick";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../node_modules/slick-carousel/slick/slick.css";
import helpers from '../Utils/helpers';
import RoundedIconButton from './Buttons/RoundedIconButton';
import ImageCaption from './ImageCaption';


export interface CarouselProps<T> {
    items: T[]
    // variant?: 'default' | 'upgradeScreen'

    showArrows?: boolean
    carouselSettings?: Settings,
    dotsPosition?: 'left' | 'center' | 'right'
    renderItem: (item: T, index: number) => JSX.Element
    renderLabel?: (item: T, index: number) => JSX.Element
}



function Carousel<T = unknown>(props: CarouselProps<T>) {
    const { items, showArrows, carouselSettings, dotsPosition = 'center', renderItem, renderLabel } = props;
    const classes = useStyles({ dotsPosition });
    const ref = useRef<Slider | null>(null);
    const { isDeviceSm } = useMediaQuery();
    const settings: Settings = {
        dots: !showArrows,
        autoplaySpeed: 5000,
        autoplay: false,
        infinite: true,
        arrows: showArrows,
        nextArrow: <CarouselNextButton />,
        prevArrow: <CarouselPrevButton />,
        pauseOnHover: true,
        speed: 500,
        swipe: true,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        ...carouselSettings

    }


    const nextSlide = () => {
        if (!isDeviceSm || !ref.current) return;
        ref.current.slickNext();
    }


    return (
        <Slider ref={ref} className={classes.root} {...settings}>
            {
                items.map((it, i) => {
                    return (
                        <div key={i} onClick={nextSlide} className={classes.carouselItem}>
                            {renderItem(it, i)}
                            {renderLabel ? renderLabel(it, i) : null}
                        </div>
                    )
                })
            }
        </Slider>
    )
}



export const CarouselPrevButton = (props: { onClick?: () => {}, className?: string, alt?: boolean }) => {
    return (
        <RoundedIconButton onClick={props.onClick} className={props.className} style={{ left: 20 }}>
            <KeyboardArrowLeft color={props.alt ? 'action' : 'primary'} />
        </RoundedIconButton>
    )
}

export const CarouselNextButton = (props: { onClick?: () => {}, className?: string, alt?: boolean }) => {
    return (
        <RoundedIconButton onClick={props.onClick} className={props.className} style={{ right: 20 }}>
            <KeyboardArrowRight color={props.alt ? 'action' : 'primary'} />
        </RoundedIconButton>
    )
}




const useStyles = makeStyles<Theme, { dotsPosition: 'left' | 'center' | 'right' }>((theme) => ({
    root: {

        height: '100%',
        // display: 'flex',
        // alignItems: 'flex-start',

        '& .slick-track': {
            marginLeft: 0,
            marginRight: 0
        },
        '& .slick-arrow': {
            zIndex: 1,
            color: theme.palette.common.white,
            fontSize: 20,
            '&::after, &::before': {
                display: 'none'
            }
        },
        '& .slick-prev': {
            position: 'absolute',
            top: '95%',
            width: 34,
            height: 34,
            backgroundColor: 'white',
            '&:focus': {
                backgroundColor: 'white',
            },

        },
        '& .slick-next': {
            position: 'absolute',
            top: '95%',
            width: 34,
            height: 34,
            backgroundColor: 'white',
            '&:focus': {
                backgroundColor: 'white',
            },
        },
        '& .slick-dots': {
            display: 'flex !important',
            bottom: 0,
            justifyContent: props => props.dotsPosition === 'center' ? 'center' : props.dotsPosition === 'left' ? 'flex-start' : 'flex-end' + ' !important',

        },
        '& .slick-dots li': {
            width: '5px !important',
            height: '20x !important',
            margin: '0 5px !important',

        },
        '& .slick-dots ': {
            '&::before': {
                width: '5px !important',
                height: '5px !important',
                margin: '0 !important',
                color: 'white',
                opacity: 0.7
            }
        },
        '& .slick-active > button': {
            '&::before': {

                color: 'white !important',
                opacity: '1 !important'
            }
        }
    },
    img: {
        // border: DIVIDER_BORDER_LIGHT,
        // height: IMAGE_CONTAINER_SIZE,
        // width: IMAGE_CONTAINER_SIZE,
        [theme.breakpoints.down('sm')]: {
            //marginTop: 0,
            height: 125,
            width: 125,
        }
        // '&:first-child': {
        //     height: 200,
        //     width: 200,
        // }
    },
    fallback: {
        height: 200,
        width: 200,
        [theme.breakpoints.down('sm')]: {
            //marginTop: 0,
            height: 125,
            width: 125,
        }
    },
    outline: {
        '&:focus': {
            outline: 'none !important'
        }
    },
    arrowButton: {
        position: 'absolute',
        top: window.innerWidth * 0.3,
        width: 40,
        height: 40,
        borderRadius: 20,
        display: 'flex',
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        cursor: 'pointer',
        transition: '200ms linear',
        '& i': {
            color: theme.palette.primary.main
        },
        '&:hover': {
            opacity: 0.8
        }
    },
    carouselItem: {
        position: 'relative',
    }
}));

export default Carousel;