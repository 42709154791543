import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export interface WrapperProps {
    imgLink?: string;
    imgClasses?: string;
    isInsideDialog?: boolean;
}

const Wrapper: FC<WrapperProps> = (props) => {
    const { children, imgLink, imgClasses, isInsideDialog=false } = props;
    const classes = useStyles({ isInsideDialog });

    return (
        <>
            {imgLink && <Box className={`${classes.imgClasses} ${imgClasses}`}>
                <img alt="signup" src={imgLink} />
            </Box>}
            <Box
                className={classes.formBody}
                display="flex"
                flexDirection="column"
                alignItems="center"
                alignSelf={isInsideDialog ? 'start' : 'center'}
            >
                {children}
            </Box>
        </>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    imgClasses: {
        height: (props) => (props.isInsideDialog ? 'auto' : 'calc(100vh - 70px)'),
        [theme.breakpoints.down('sm')]: {
            height: (props) => (!props.isDeviceSm ? 'auto' : '12rem'),
        },
        '& >img': {
            height: (props) => (props.isInsideDialog ? '100%' : 'calc(100vh - 70px)'),
            minHeight: '40rem',
            width: (props) => (props.isInsideDialog ? '22rem' : 'auto'),
            objectFit: 'cover',

            [theme.breakpoints.down('sm')]: {
                height: (props) => (!props.isDeviceSm ? 'auto' : '12rem'),
                minHeight: '0rem',
                width: '100% !important',
            },
        },
    },
    formBody: {
        width: '100%',
        '& >*': {
            paddingLeft: '3rem',
            paddingRight: '3rem',
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
            margin: '3rem',
            marginTop: (props) => (props.isInsideDialog ? '0rem' : '3rem'),
            [theme.breakpoints.down('sm')]: {
                margin: '0rem !important',
                width: '100%',
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '5rem',
        },
    },
}));

export default Wrapper;
