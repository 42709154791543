import App from './App';
import EntityListsStore from './EntityLists'
import UserPinedCitiesScreenStore from './UserPinedCitiesScreen'
import NearbyScreenStore from './NearbyScreen'
import CuisinePlacesScreenStore from './CuisinePlacesScreen'
import DishPlacesScreenStore from './DishPlacesScreen'
import PlaceCommentsScreenStore from './PlaceCommentsScreen'
import UserSuggestedPlacesScreenStore from './UserSuggestedPlacesScreen'
import UserSavedPlacesScreenStore from './UserSavedPlacesScreen'
import SubscriptionStore from './Subscription'
import EntityActionsStore from './EntityActions'
import UserDetailScreenStore from './UserDetailScreen'
import PlaceDetailScreenStore from './PlaceDetailScreen'
import LandmarkDetailScreenStore from './LandmarkDetailScreen'
import ExploreScreenStore from './ExploreScreen'
import CuisineDetailScreenStore from './CuisineDetailScreen'
import DishDetailScreenStore from './DishDetailScreen'
import AuthStore from './Auth'
import PlaceListingStore from './PlaceListing'
import CityDetailScreenStore from './CityDetailScreen'
import HomeScreenStore from './HomeScreen'
import { createStore, createTypedHooks, State } from 'easy-peasy';
import logger from 'redux-logger';
import segmentMiddleware from '../Analytics';
import helpers from 'Utils/helpers';
import { Middleware } from 'redux';
import analyticsMap from 'Middlewares/analyticsMap';
import config from 'Config';
import { isEqual } from 'lodash';
import EatineraryDetailScreenStore from './EatineraryDetailScreen';
import LandingScreenStore from './LandingScreen';
import DestinationScreenStore from './DestinationScreen';
import InsiderScreenStore from './InsiderScreen';

export type TRootStore = typeof RootStore;

const RootStore = {
    EntityListsStore,
    UserPinedCitiesScreenStore,
    InsiderScreenStore,
    DestinationScreenStore,
    LandingScreenStore,
    EatineraryDetailScreenStore,
    NearbyScreenStore,
    CuisinePlacesScreenStore,
    DishPlacesScreenStore,
    PlaceCommentsScreenStore,
    UserSuggestedPlacesScreenStore,
    UserSavedPlacesScreenStore,
    SubscriptionStore,
    EntityActionsStore,
    UserDetailScreenStore,
    PlaceDetailScreenStore,
    LandmarkDetailScreenStore,
    ExploreScreenStore,
    CuisineDetailScreenStore,
    DishDetailScreenStore,
    AuthStore,
    PlaceListingStore,
    CityDetailScreenStore,
    HomeScreenStore,
    App,
}

const typedHooks = createTypedHooks<TRootStore>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
// export const useStoreState = typedHooks.useStoreState;
export const useStoreState = <Result>(a: (state: State<TRootStore>) => Result, b?: any) => typedHooks.useStoreState(a, b || isEqual);


const getMiddleware = () => {
    let middleware: Middleware[] = [];
    if (config.get('ANALYTICS') === 'true') {
        middleware.push(analyticsMap)
        middleware.push(segmentMiddleware)
    }
    if (config.get('LOGGING') === 'true') {
        middleware.push(logger);
    }

    return middleware
}


export default createStore(RootStore, {
    middleware: getMiddleware()
    //Put your dependency injections here
});

    

    

    
