import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, ButtonBase } from '@material-ui/core';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { HomeOutlined, LocationOnOutlined, SearchOutlined } from '@material-ui/icons';
import Typo from './Typo';
import appNavigator from 'Features/Navigation/appNavigator';
import HomeIcon from './Icons/HomeIcon';
import SearchIcon from './Icons/SearchIcon';
import LocationOnIcon from './Icons/LocationOnIcon';
import Spacer from './Spacer';
import AnimatedButton from './Buttons/AnimatedButton';
import HOME_LOTTIE from 'Assets/animated/home.json';
import SEARCH_LOTTIE from 'Assets/animated/search.json';
import NEARBY_LOTTIE from 'Assets/animated/nearby.json';
import HOME_ICON from 'Assets/images/icons/home.svg';
import SEARCH_ICON from 'Assets/images/icons/search.svg';
import NEARBY_ICON from 'Assets/images/icons/nearby.svg';

import HOME_ACTIVE_ICON from 'Assets/images/icons/home_active.png';
import SEARCH_ACTIVE_ICON from 'Assets/images/icons/search_active.png';
import NEARBY_ACTIVE_CON from 'Assets/images/icons/nearby_active.png';
import AnimatableContainer from './AnimatableContainer';

export interface NavigationTabProps {
    selectedTab: NavigationTabItem
}

export type NavigationTabItem = 'home' | 'search' | 'nearby' | 'none'

const NavigationTab: FC<NavigationTabProps> = ({ selectedTab }) => {
    const classes = useStyles({});
    const { isDeviceSm } = useMediaQuery();
    const [isAnimating, setIsAnimating] = useState({ home: false, search: false, nearby: false })


    const handleTabClick = (tab: NavigationTabItem) => () => {
        if (tab === 'home') {
            setIsAnimating({ home: true, nearby: false, search: false })
            setTimeout(() => {
                setIsAnimating({ home: false, nearby: false, search: false })
                appNavigator.navigateToHome();
            }, 1000);
        } else if (tab === 'search') {
            setIsAnimating({ home: false, nearby: false, search: true })
            setTimeout(() => {
                setIsAnimating({ home: false, nearby: false, search: false })
                appNavigator.navigateToSearch();
            }, 1000);
        } else if (tab === 'nearby') {
            setIsAnimating({ home: false, nearby: true, search: false })
            setTimeout(() => {
                setIsAnimating({ home: false, nearby: false, search: false })
                appNavigator.searchNearbyPlaces('list');
            }, 1000);
        }
    }

    return isDeviceSm ? (
        <div className={classes.root}>
            <Box component={ButtonBase} onClick={handleTabClick('home')} className={classes.tab}>
                <AnimatableContainer shouldAnimate={isAnimating.home} lottieJSON={HOME_LOTTIE}>
                    <img src={selectedTab === 'home' ? HOME_ACTIVE_ICON : HOME_ICON} style={{ height: 24 }} />
                    {/* <HomeIcon style={{ height: 16 }} color={selectedTab === 'home' ? 'primary' : undefined} /> */}
                </AnimatableContainer>
                <Spacer height={4} />
                <Typo color={selectedTab === 'home' ? 'primary' : undefined} weight='medium' className={classes.tabText}>HOME</Typo>
            </Box>
            {/* <Box component={ButtonBase} onClick={() => appNavigator.navigateToHome()} className={classes.tab}>
                <HomeIcon style={{ height: 16 }} color={selectedTab === 'home' ? 'primary' : undefined} />
                <Spacer height={4} />
                <Typo color={selectedTab === 'home' ? 'primary' : undefined} weight='medium' className={classes.tabText}>HOME</Typo>
            </Box> */}
            <Box component={ButtonBase} onClick={handleTabClick('search')} className={classes.tab}>
                <AnimatableContainer shouldAnimate={isAnimating.search} lottieJSON={SEARCH_LOTTIE}>
                    <img src={selectedTab === 'search' ? SEARCH_ACTIVE_ICON : SEARCH_ICON} style={{ height: 24 }} />
                    {/* <SearchIcon style={{ height: 16 }} color={selectedTab === 'search' ? 'primary' : undefined} /> */}
                </AnimatableContainer>
                <Spacer height={4} />
                <Typo color={selectedTab === 'search' ? 'primary' : undefined} weight='medium' className={classes.tabText}>SEARCH</Typo>
            </Box>
            <Box component={ButtonBase} onClick={handleTabClick('nearby')} className={classes.tab}>
                <AnimatableContainer shouldAnimate={isAnimating.nearby} lottieJSON={NEARBY_LOTTIE}>
                    <img src={selectedTab === 'nearby' ? NEARBY_ACTIVE_CON : NEARBY_ICON} style={{ height: 24 }} />
                    {/* <LocationOnIcon style={{ height: 16 }} color={selectedTab === 'nearby' ? 'primary' : undefined} /> */}
                </AnimatableContainer>
                <Spacer height={4} />
                <Typo color={selectedTab === 'nearby' ? 'primary' : undefined} weight='medium' className={classes.tabText}>NEARBY</Typo>
            </Box>
        </div>
    ) : null
}
export const NAVIGATION_TAB_HEIGHT = 50;

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingBottom: `env(safe-area-inset-bottom)`
    },
    tab: {
        height: NAVIGATION_TAB_HEIGHT,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tabText: {
        fontSize: 8,
    }
}));

export default NavigationTab;