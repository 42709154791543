import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, SvgIcon, SvgIconProps } from '@material-ui/core';

export interface SearchIconProps extends SvgIconProps {

}

const SearchIcon: FC<SearchIconProps> = (props) => {
    const classes = useStyles({});

    return (
        <SvgIcon {...props} viewBox="0 0 13.679 14.5">
            <g transform="translate(0 0)"><g transform="translate(0 0)"><g transform="translate(0 0.101)"><circle cx="6.041" cy="6.041" r="6.041" fill="none" /></g><path d="M6.041,11.982a6.041,6.041,0,1,1,6.041-6.041A6.059,6.059,0,0,1,6.041,11.982Zm0-10.974A4.985,4.985,0,0,0,1.007,6.041a4.985,4.985,0,0,0,5.034,5.034,4.985,4.985,0,0,0,5.034-5.034A5.049,5.049,0,0,0,6.041,1.007Z" transform="translate(0 0.1)" /></g><g transform="translate(9.408 10.229)"><rect width="1.007" height="5.034" transform="matrix(0.707, -0.707, 0.707, 0.707, 0, 0.712)" /></g></g>
        </SvgIcon>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default SearchIcon;