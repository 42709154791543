import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { useStoreState } from 'Stores';
import TagCard from 'Features/Tag/TagCard';
import useInterestDialog from 'Dialogs/Interest/useInterestDialog';

export interface UserTagsProps {
    closeDialog?: () => void
}

const UserTags: FC<UserTagsProps> = (props) => {
    const classes = useStyles({});
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))
    const { openDialog, DialogComponent } = useInterestDialog();

    return appUser ? (
        <>
            <Box display='flex' flexWrap='wrap' alignItems='center'>
                {/* {appUser.tags?.map(t => (
                    <Box m={0.5} key={t.id}>
                        <TagCard onClicked={props.closeDialog} tag={t} />
                    </Box>
                ))} */}
            </Box>
            {/* <Box display='flex' justifyContent='flex-end'>
                <Button color='primary' onClick={openDialog}>Edit</Button>
            </Box> */}
            {DialogComponent}
        </>

    ) : null
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default UserTags;