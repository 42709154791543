import Axios from "axios";

class EatineraryModel {

    static get_Eatinerary_id<T = any>(id: string, filter?: any) {

        return Axios.request<T>({
            url: `/Eateries/${id}`,
            method: 'get',
            params: {
                filter
            },
        });
        
    }
    
}

export default EatineraryModel;