import React, { useState } from 'react'
import { InputAdornment } from '@material-ui/core';
import { FormConfig } from 'react-forms';
import { Visibility, VisibilityOff } from '@material-ui/icons';


export default (valueKey: string, label: string, autoFocus?: boolean, id?: string) => {
    const [passType, setPassType] = useState<'text' | 'password'>('password')
    const toggleType = () => {
        setPassType(type => type === 'password' ? 'text' : 'password');
    }

    const Icon = passType === 'password' ? Visibility : VisibilityOff
    return {
        type: passType === 'text' ? 'text' : 'password',
        valueKey,
        id,
        fieldProps: {
            label,
            fullWidth: true,
            InputProps: {
                endAdornment: <InputAdornment style={{ cursor: 'pointer' }} position="end" onClick={toggleType}><Icon /></InputAdornment>
            },
        }
    } as FormConfig
}