import Axios, { CancelTokenSource } from "axios";
import { action, Action, actionOn, ActionOn, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import Query from "Features/Query";
import EstablishmentQuery from "Features/Query/EstablishmentQuery";
import { omit } from "lodash";
import { Facets } from "Models/@types";
import DishModel from "Models/Dish";
import PlaceModel from "Models/Place";
import { TPlace, TPlaceFilterResponse } from "Models/Place/@types";
import { TTag } from "Models/Tag/@types";
import { TRootStore } from "Stores";

export interface TDishPlacesScreenState {
    places: TPlace[]
    loading: boolean
    query: Query
    tag: TTag | undefined
    initialFacets: Facets
    facets: Facets
    pageNumber: number
    hasMore: boolean
    facetCityBuckets: { key: string, doc_count: number }[]
    isFirstTime: boolean
    setIsFirstTime: Action<TDishPlacesScreenState, boolean>
    setInitialFacets: Action<TDishPlacesScreenState, Facets>
    setFacetCityBuckets: Action<TDishPlacesScreenState, TDishPlacesScreenState['facetCityBuckets']>
    setTag: Action<TDishPlacesScreenState, TTag | undefined>
    setFacets: Action<TDishPlacesScreenState, Facets>
    setHasMore: Action<TDishPlacesScreenState, boolean>
    setPageNumber: Action<TDishPlacesScreenState, number>
    setPlaces: Action<TDishPlacesScreenState, TPlace[]>
    setLoading: Action<TDishPlacesScreenState, boolean>
    loadMore: Thunk<TDishPlacesScreenState, void, {}, TRootStore>
    setQuery: Action<TDishPlacesScreenState, Query>
    searchPlaces: Thunk<TDishPlacesScreenState, { query: Query, pageNumber?: number, append?: boolean }, {}, TRootStore>

    onQueryChange: ThunkOn<TDishPlacesScreenState, {}, TRootStore>
}

const LISTING_LIMIT = 21;

let cancelToken: CancelTokenSource | undefined = undefined;

const DishPlacesScreenStore: TDishPlacesScreenState = {
    places: [],
    loading: true,
    hasMore: true,
    pageNumber: 1,
    query: new Query({}),
    tag: undefined,
    initialFacets: {},
    facets: {},
    facetCityBuckets: [],
    isFirstTime: true,
    setInitialFacets: action((state, payload) => {
        state.initialFacets = payload
    }),
    setIsFirstTime: action((state, payload) => {
        state.isFirstTime = payload
    }),
    setFacetCityBuckets: action((state, payload) => {
        state.facetCityBuckets = payload
    }),
    setTag: action((state, payload) => {
        state.tag = payload;
    }),
    setFacets: action((state, payload) => {
        state.facets = payload
    }),
    setPlaces: action((state, payload) => {
        state.places = payload;
    }),
    setHasMore: action((state, payload) => {
        state.hasMore = payload;
    }),
    setPageNumber: action((state, payload) => {
        state.pageNumber = payload;
    }),
    setQuery: action((state, payload) => {
        state.query = payload;
    }),
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    loadMore: thunk(async (actions, _, { getState }) => {
        const { query, pageNumber } = getState();
        await actions.searchPlaces({ query, pageNumber: pageNumber + 1, append: true })
    }),
    searchPlaces: thunk(async (actions, { pageNumber = 1, query, append = false }, { getState, getStoreState }) => {
        const { DishDetailScreenStore: { dish }, App: { geoLocation: location } } = getStoreState();
        if (!dish) return;

        const state = getState();
        actions.setPageNumber(pageNumber);
        actions.setLoading(true);
        const locationFilter = location ? EstablishmentQuery.getLocationFilter(location) : {};

        if (cancelToken) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = Axios.CancelToken.source();

        // const { data } = await DishModel.get_FoodDrinks_id_establishments<TPlace[]>(dish?.id, {
        //     include: ["cuisines", "tags", "destination"],
        //     limit: LISTING_LIMIT,
        //     ...query.getSearchFilter,
        //     where: {
        //         ...locationFilter,
        //         ...query.getSearchFilter.where
        //     },
        // }, { cancelToken: cancelToken.token })

        const { data } = await PlaceModel.searchPlaces({
            Establishment: {
                include: ["cuisines", "tags", {
                    relation: 'destination',
                    scope: {
                        include: {
                            relation: 'destinationPack',
                        },
                    },
                }],
            },
            limit: LISTING_LIMIT,
            ...query.getSearchFilter,
            ...locationFilter,
            where: {
                'foodDrinkIds': [dish.id],
                ...query.getSearchFilter.where
            },
        }, { cancelToken: cancelToken.token })

        const places = PlaceModel.transformSearchResult(data.hits);

        // if (!data.length || data.length < LISTING_LIMIT)
        if (!places.length || places.length < LISTING_LIMIT)
            actions.setHasMore(false)
        else
            actions.setHasMore(true)
        // actions.setPlaces(append ? [...state.places, ...data] : data);

        if (state.isFirstTime) {
            actions.setInitialFacets(data.facets)
            actions.setIsFirstTime(false)
        }

        actions.setFacets(data.facets)
        actions.setPlaces(append ? [...state.places, ...places] : places);
        actions.setLoading(false)
    }),


    onQueryChange: thunkOn(
        (actions) => actions.setQuery,
        async (actions, { payload }) => {
            actions.searchPlaces({ query: payload })
        }
    ),


};

export default DishPlacesScreenStore;

