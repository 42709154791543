import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, ButtonBase, Portal } from '@material-ui/core';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { MoreVert, Map } from '@material-ui/icons';
import Typo from './Typo';
import appNavigator from 'Features/Navigation/appNavigator';
import Spacer from './Spacer';
import HOME_LOTTIE from 'Assets/animated/home.json';
import SEARCH_LOTTIE from 'Assets/animated/search.json';
import NEARBY_LOTTIE from 'Assets/animated/nearby.json';
import HOME_ICON from 'Assets/images/icons/home.svg';
import SEARCH_ICON from 'Assets/images/icons/search.svg';
import NEARBY_ICON from 'Assets/images/icons/nearby.svg';

import HOME_ACTIVE_ICON from 'Assets/images/icons/home_active.png';
import SEARCH_ACTIVE_ICON from 'Assets/images/icons/search_active.png';
import NEARBY_ACTIVE_CON from 'Assets/images/icons/nearby_active.png';
import AnimatableContainer from './AnimatableContainer';
import useMoreOptionsMenu from './useMoreOptionsMenu';
import useAvatarMenu from 'Features/AppUser/useAvatarMenu';
import { useStoreState } from 'Stores';

export interface NewNavigationTabProps {
    selectedTab: NewNavigationTabItem;
}

export type NewNavigationTabItem = 'home' | 'destinations' | 'search' | 'nearby' | 'moreOption' | 'none';

const NewNavigationTab: FC<NewNavigationTabProps> = ({ selectedTab }) => {
    const classes = useStyles({});
    const { isDeviceSm } = useMediaQuery();
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const { UiComponent, AuthComponent } = useAvatarMenu({ shouldHideSignupBtn: true });
    const [isAnimating, setIsAnimating] = useState({
        home: false,
        destinations: false,
        search: false,
        nearby: false,
        moreOption: false,
    });

    const { openMenu, component } = useMoreOptionsMenu();

    const handleTabClick = (tab: NewNavigationTabItem) => () => {
        if (tab === 'home') {
            setIsAnimating({ home: true, destinations: false, nearby: false, search: false, moreOption: false });
            setTimeout(() => {
                setIsAnimating({ home: false, destinations: false, nearby: false, search: false, moreOption: false });
                appNavigator.navigateToHome();
            }, 1000);
        } else if (tab === 'search') {
            setIsAnimating({ home: false, destinations: false, nearby: false, search: true, moreOption: false });
            setTimeout(() => {
                setIsAnimating({ home: false, destinations: false, nearby: false, search: false, moreOption: false });
                appNavigator.navigateToSearch();
            }, 1000);
        } else if (tab === 'nearby') {
            setIsAnimating({ home: false, destinations: false, nearby: true, search: false, moreOption: false });
            setTimeout(() => {
                setIsAnimating({ home: false, destinations: false, nearby: false, search: false, moreOption: false });
                appNavigator.searchNearbyPlaces('list');
            }, 1000);
        } else if (tab === 'destinations') {
            setIsAnimating({ home: false, destinations: true, nearby: false, search: false, moreOption: false });
            setTimeout(() => {
                setIsAnimating({ home: false, destinations: false, nearby: false, search: false, moreOption: false });
                appNavigator.navigateToDestinations();
            }, 1000);
        }
        // } else if (tab === 'moreOption') {
        //     setIsAnimating({ home: false, destinations: false, nearby: false, search: false, moreOption: true })
        //     setTimeout(() => {
        //         setIsAnimating({ home: false, destinations: false, nearby: false, search: false, moreOption: false })
        //         // appNavigator.searchNearbyPlaces();
        //     }, 1000);
        // }
    };

    return isDeviceSm ? (
        <div className={classes.root}>
            <Box component={ButtonBase} onClick={handleTabClick('home')} className={classes.tab}>
                <AnimatableContainer shouldAnimate={isAnimating.home} lottieJSON={HOME_LOTTIE}>
                    <img src={selectedTab === 'home' ? HOME_ACTIVE_ICON : HOME_ICON} style={{ height: 24 }} />
                    {/* <HomeIcon style={{ height: 16 }} color={selectedTab === 'home' ? 'primary' : undefined} /> */}
                </AnimatableContainer>
                <Spacer height={4} />
                <Typo
                    color={selectedTab === 'home' ? 'primary' : undefined}
                    weight="medium"
                    className={classes.tabText}
                >
                    EXPLORE
                </Typo>
            </Box>
            <Box component={ButtonBase} onClick={handleTabClick('destinations')} className={classes.tab}>
                <AnimatableContainer
                    shouldAnimate={isAnimating.destinations}
                    lottieJSON={<Map style={{ height: 24 }} color="primary" />}
                >
                    {/* <img src={selectedTab === 'home' ? HOME_ACTIVE_ICON : HOME_ICON} style={{ height: 24 }} /> */}
                    <Map style={{ height: 24 }} color={selectedTab === 'destinations' ? 'primary' : undefined} />
                    {/* <HomeIcon style={{ height: 16 }} color={selectedTab === 'home' ? 'primary' : undefined} /> */}
                </AnimatableContainer>
                <Spacer height={4} />
                <Typo
                    color={selectedTab === 'destinations' ? 'primary' : undefined}
                    weight="medium"
                    className={classes.tabText}
                >
                    DESTINATIONS
                </Typo>
            </Box>
            {/* <Box component={ButtonBase} onClick={handleTabClick('search')} className={classes.tab}>
                <AnimatableContainer shouldAnimate={isAnimating.search} lottieJSON={SEARCH_LOTTIE}>
                    <img src={selectedTab === 'search' ? SEARCH_ACTIVE_ICON : SEARCH_ICON} style={{ height: 24 }} />
                </AnimatableContainer>
                <Spacer height={4} />
                <Typo color={selectedTab === 'search' ? 'primary' : undefined} weight='medium' className={classes.tabText}>SEARCH</Typo>
            </Box> */}
            <Box component={ButtonBase} onClick={handleTabClick('nearby')} className={classes.tab}>
                <AnimatableContainer shouldAnimate={isAnimating.nearby} lottieJSON={NEARBY_LOTTIE}>
                    <img src={selectedTab === 'nearby' ? NEARBY_ACTIVE_CON : NEARBY_ICON} style={{ height: 24 }} />
                    {/* <LocationOnIcon style={{ height: 16 }} color={selectedTab === 'nearby' ? 'primary' : undefined} /> */}
                </AnimatableContainer>
                <Spacer height={4} />
                <Typo
                    color={selectedTab === 'nearby' ? 'primary' : undefined}
                    weight="medium"
                    className={classes.tabText}
                >
                    NEARBY
                </Typo>
            </Box>
            <Box component={ButtonBase} className={classes.tab}>
                <AnimatableContainer shouldAnimate={false}>{UiComponent}</AnimatableContainer>
                {appUser && (
                    <>
                        <Spacer height={4} />
                        <Typo
                            color={selectedTab === 'nearby' ? 'primary' : undefined}
                            weight="medium"
                            className={classes.tabText}
                        >
                            PROFILE
                        </Typo>
                    </>
                )}
            </Box>
            {/* <Box component={ButtonBase} onClick={(e) => openMenu(e)} className={classes.tab}>
                <AnimatableContainer shouldAnimate={isAnimating.moreOption} lottieJSON={NEARBY_LOTTIE}>
                    <MoreVert style={{ height: 24 }} color={selectedTab === 'moreOption' ? 'primary' : undefined} />
                </AnimatableContainer>
                <Spacer height={4} />
            </Box> */}
            <Portal>
                {component}
                {AuthComponent}
            </Portal>
        </div>
    ) : null;
};
export const NAVIGATION_TAB_HEIGHT = 50;

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingBottom: `env(safe-area-inset-bottom)`,
    },
    tab: {
        height: NAVIGATION_TAB_HEIGHT,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiAvatar-root': {
            height: 24,
            width: 24,
        },
    },
    tabText: {
        fontSize: 8,
    },
}));

export default NewNavigationTab;
