export const CORDOVA_LOCAL_STORAGE_USER_KEY = 'ajs_user_id';
export const CORDOVA_LOCAL_STORAGE_USER_EMAIL = 'ajs_user_email';

export const SETTINGS_CITY_PAGE = "cityPageConfig";
export const SETTINGS_CITY_PAGE_PLACES = "allPlaces";
export const SETTINGS_CITY_PAGE_BOOK_WEEKS_AHEAD = "bookWeeksAhead";
export const SETTINGS_CITY_PAGE_CUISINES = "cuisines";
export const SETTINGS_CITY_PAGE_DISHES = "dishes";
export const SETTINGS_CITY_PAGE_EXPLORE = "explore";
export const SETTINGS_CITY_PAGE_LANDMARKS = "landmarks";
export const SETTINGS_CITY_PAGE_EATINERARY = "eatinerary";

export const SETTINGS_AUTH_SCREEN = "authScreenConfig";