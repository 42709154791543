import Axios from "axios";

class EntityModal {

    static create_new_Userlist_id<T = any>(id: string, data: string, filter?: any) {

        return Axios.request<T>({
            url: `/users/${id}/userLists`,
            method: 'post',
            data: { name: data },
            params: {
                filter
            },
        });

    }

    static get_all_Userlist_id<T = any>(id: string, filter?: any) {

        return Axios.request<T>({
            url: `/users/${id}/UserLists`,
            method: 'get',
            params: {
                filter
            },
        });
        
    }

    // static delete_all_Userlists_id<T = any>(id: string, filter?: any) {

    //     return Axios.request<T>({
    //         url: `/users/${id}/userLists`,
    //         method: 'delete',
    //         params: {
    //             filter
    //         },
    //     });
        
    // }
    
    static get_Userlist_id_fk<T = any>(id: string, fk: string, filter?: any) {

        return Axios.request<T>({
            url: `/users/${id}/userLists/${fk}`,
            method: 'get',
            params: {
                filter
            },
        });
        
    }

    // static update_Userlist_id_fk<T = any>(id: string, fk: string, data: string, filter?: any) {

    //     return Axios.request<T>({
    //         url: `/users/${id}/userLists/${fk}`,
    //         method: 'put',
    //         data: { name: data },
    //         params: {
    //             filter
    //         },
    //     });
        
    // }

    // static delete_Userlist_id_fk<T = any>(id: string, fk: string, filter?: any) {

    //     return Axios.request<T>({
    //         url: `/users/${id}/userLists/${fk}`,
    //         method: 'delete',
    //         params: {
    //             filter
    //         },
    //     });
        
    // }

    static get_Userlists_count_id<T = any>(id: string, filter?: any) {

        return Axios.request<T>({
            url: `/users/${id}/userLists/count`,
            method: 'get',
            params: {
                filter
            },
        });
        
    }

    static post_entity_Userlist_id<T = any>(id: string, data: { subjectId: string, subjectType: string, userId: string, userListId: string }, filter?: any) {

        return Axios.request<T>({
            url: `/UserLists/${id}/userListItems`,
            method: 'post',
            data: data,
            params: {
                filter
            },
        });
        
    }

    static get_Userlist_entity_id<T = any>(id: string, filter?: any) {

        return Axios.request<T>({
            url: `/UserLists/${id}/userListItems`,
            method: 'get',
            params: {
                filter
            },
        });
        
    }

    static update_Userlist_id<T = any>(id: string, data: string, filter?: any) {

        return Axios.request<T>({
            url: `/UserLists/${id}/update-data`,
            method: 'patch',
            data: { name: data },
            params: {
                filter
            },
        });
        
    }

    static delete_Userlist<T = any>(ids: string, filter?: any) {

        return Axios.request<T>({
            url: `/UserLists/move-to-trash`,
            method: 'delete',
            params: {
                ids,
                filter
            },
        });
        
    }

    // static update_Userlist_entity_id<T = any>(id: string, filter?: any) {

    //     return Axios.request<T>({
    //         url: `/UserListItems/${id}/update-data`,
    //         method: 'patch',
    //         params: {
    //             filter
    //         },
    //     });
        
    // }

    static delete_Userlist_entity<T = any>(ids: string, filter?: any) {

        return Axios.request<T>({
            url: `/UserListItems/move-to-trash`,
            method: 'delete',
            params: {
                ids,
                filter
            },
        });
        
    }

    static get_Userlist_entity_count_id<T = any>(id: string, filter?: any) {

        return Axios.request<T>({
            url: `/UserLists/${id}/userListItems/count`,
            method: 'get',
            params: {
                filter
            },
        });
        
    }

}

export default EntityModal;
