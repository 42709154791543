import React from 'react'
import { Theme, TypographyProps, Typography, Link } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { FONT_FAMILY } from 'Theme/themeConstants';
import { getTextNode, renderHtmlStr } from 'Features/Mention';
import { useStoreState } from 'Stores';
import UserModel from 'Models/User';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export interface TypoProps extends TypographyProps {
    weight?: 'regular' | 'medium' | 'bold'
    underline?: boolean
    contrast?: boolean
    limitLines?: number
    fontFamily?: 'primary' | 'secondary' | 'font3' | 'font4'
    whiteSpace?: React.CSSProperties['whiteSpace']
    renderMentionHTMLStr?: (name: string, meta: string) => string
}
const Typo = React.forwardRef<HTMLElement, TypoProps>((props, ref) => {
    const { children, fontFamily, renderMentionHTMLStr, limitLines, weight, whiteSpace, underline = false, contrast = false, ...typographyProps } = props;
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))


    const theme = useTheme()
    const style: TypographyProps['style'] = {
        ...typographyProps.style,
        fontFamily: getFontFamily(fontFamily),
        textDecoration: underline ? 'underline' : undefined,
        fontWeight: getFontWeight(theme, weight),
        whiteSpace: whiteSpace,
        color: contrast ? 'white' : undefined,
        overflow: limitLines ? 'hidden' : undefined,
        textOverflow: limitLines ? 'ellipsis' : undefined,
        lineClamp: limitLines,
        WebkitLineClamp: limitLines,
        display: typographyProps.display ?? '-webkit-box',
        WebkitBoxOrient: 'vertical',
        boxOrient: 'vertical',


    }


    const __html = typeof children === 'string' ? getTextNode(children, (name, meta) => {
        if (renderMentionHTMLStr) {
            return renderMentionHTMLStr(name, meta)
        }
        return renderHtmlStr(name, meta, UserModel.isAccessLimited(appUser))
    }) : children


    // if (typeof props.children === 'string' && isUrl(props.children)) {
    //     return <Link ref={ref} rel="noopener" href={props.children} target="_blank" {...typographyProps} style={{ ...style, cursor: 'pointer' }}>{props.children}</Link>
    // }

    if (typeof children === 'string')
        return (
            <Typography ref={ref} color='textPrimary'  {...typographyProps} style={style} dangerouslySetInnerHTML={{ __html: __html as string }}>
            </Typography>
        )


    return (
        <Typography ref={ref} color='textPrimary' {...typographyProps} style={style}>{children}</Typography>
    )
});


const getFontWeight = (theme: Theme, weight: TypoProps['weight']) => {
    if (weight === 'bold') return theme.typography.fontWeightBold
    if (weight === 'medium') return theme.typography.fontWeightMedium
    if (weight === 'regular') return theme.typography.fontWeightRegular
    return theme.typography.fontWeightRegular
}


const getFontFamily = (value: TypoProps['fontFamily']) => {
    if (value)
        return FONT_FAMILY[value];
    return undefined;
}


const isUrl = (text: string) => {
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ig.test(text)
}

export default Typo