import React, { FC, useState } from 'react';

interface ContextProps {
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppVariablesContext = React.createContext<ContextProps>({
    isDialogOpen: false,
    setIsDialogOpen: () => {},
});

export const AppVariablesProvider: FC = (props) => {
    
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    return (
        <AppVariablesContext.Provider
            value={{
                isDialogOpen,
                setIsDialogOpen,
            }}
        >
            {props.children}
        </AppVariablesContext.Provider>
    );
};
export default AppVariablesProvider;
